import React from 'react';
import { MultiList as BaseMultiList } from '@appbaseio/reactivesearch';

import CheckboxGrid, {
    FilterItem,
    ValueType,
} from '@extensions/components/search-core/CheckboxGrid';
import { Divider, styled, Typography } from '@mui/material';
import theme from '@extensions/services/Theme';

type BaseMultiListProps = React.ComponentProps<typeof BaseMultiList>;

const StyledCheckbox = styled(CheckboxGrid)(({
    marginTop: '0.5rem',
}))

export interface IMultiListProps
    extends Omit<
        BaseMultiListProps,
        'render' | 'renderItem' | 'innerClass' | 'showCheckbox'
    > {
    title: string | React.ReactNode;
    maxLabelWidth?: string;
}

interface RenderArgs {
    loading: boolean;
    error: object;
    data: FilterItem[];
    value: ValueType;
    handleChange: (event: { target: { value: string } }) => void;
}

const MultiList: React.FC<IMultiListProps> = ({ title, maxLabelWidth, ...baseProps }) => {
    const titleElementId = `${baseProps.componentId}-title`;
    return (
        <BaseMultiList
            {...baseProps}
            showCheckbox={true}
            showSearch={false}
            render={({ loading, error, ...renderProps }: RenderArgs) => {
                if (loading || error || !renderProps.data || renderProps.data.length === 0) {
                    return null;
                }
                return (
                    <>
                        <Typography 
                            sx={{
                                my: '0.5rem',
                                color: theme.palette.text.secondary
                            }}
                            component={'div'}
                        >
                            {title}
                        </Typography>
                        <Divider sx={{ mx: -2 }} />
                        <StyledCheckbox
                            {...renderProps}
                            checkboxColumnHeader={title}
                            labeledBy={titleElementId}
                            maxLabelWidth={maxLabelWidth}
                        />
                    </>
                );
            }}
        />
    );
}

export default MultiList;
import { Card } from '@mui/material';
import React, { useState } from 'react';
import { react } from '@appbaseio/reactivesearch/lib/types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import MultiList from '@extensions/components/core/MultiList';
import DatasetIconLegend from '@extensions/components/dataset-search/DatasetIconLegend';

export const DATA_LEVEL_REACTIVE_ID = 'DataLevelFilter';

interface DataLevelFilterProps {
  react?: react;
}

const DataLevelFilter: React.FC<DataLevelFilterProps> = ({ react }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  return (
    <div>
      <MultiList
        className="data-filter"
        componentId={DATA_LEVEL_REACTIVE_ID}
        dataField="dataLevel.keyword"
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            Data Level
            <InfoOutlinedIcon
              onClick={handleDialogOpen}
              style={{ cursor: 'pointer' }}
            />
          </div>
        }
        filterLabel="Level"
        showSearch={false}
        react={react}
        URLParams={true}
      />

      <DatasetIconLegend open={dialogOpen} onClose={handleDialogClose} />
    </div>
  );
};

export default DataLevelFilter;

import React, { useState } from 'react';
import {
    Grid,
    Box,
    Divider,
    Alert,
    IconButton,
    Collapse,
    styled,
    useMediaQuery,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import HomeMetrics from '@extensions/components/page/home-page/HomeMetrics';
import WDHNotificationText from '@extensions/components/page/home-page/WDHNotificationText';
import HomePageNewDatasets from '@extensions/components/page/home-page/HomePageNewDatasets';
import HomePagePopularProjects from '@extensions/components/page/home-page/HomePagePopularProjects';
import HomePageNewPublications from '@extensions/components/page/home-page/HomePageNewPublications';

import theme from '@extensions/services/Theme';

interface HomeBeforeLoginProps {
    showBanner?: boolean;
};

const StyledAlert = styled(Alert)({
    backgroundColor: '#f1c40f',
    color: '#FFF',
    fontWeight: 800,
    '& .MuiAlert-message': {
        margin: 'auto',
    },
    '& .MuiAlert-action': {
        marginLeft: 0,
        paddingLeft: 0,
    }
});

const StyledDiv = styled('div')(({ theme }) => ({
    margin: '0em 1em',
    [theme.breakpoints.down('xs')]: {
        margin: '0 0em',
    },
}));


const HomeBeforeLogin: React.FC<HomeBeforeLoginProps> = ({ showBanner }) => {
    const [isShowAlert, setIsShowAlert] = useState(true);
    const isSmallScreen = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <StyledDiv>
            <Grid
                container
                spacing={3}
            >
                <Grid item xs={12} sm={12} md={12} lg={8}>
                    <Box>
                        <WDHNotificationText />
                    </Box>
                    {showBanner &&
                        <Box>
                            <Collapse in={isShowAlert}>
                                <StyledAlert
                                    icon={false}
                                    severity="warning"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setIsShowAlert(false);
                                            }}
                                        >
                                            <CancelIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                >
                                    Some datasets won't be downloadable without signing in or creating an account.
                                </StyledAlert>
                            </Collapse>
                        </Box>
                    }
                    <HomeMetrics />
                    <HomePagePopularProjects />
                </Grid>

                <Grid item lg={0.3}>
                    {isSmallScreen && <Divider orientation="vertical" sx={{ height: '100%', borderRightWidth: 2, borderColor: 'grey' }} />}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={3.7}>
                    <HomePageNewDatasets />
                    <HomePageNewPublications />
                </Grid>
            </Grid>
        </StyledDiv>
    );
}

export default (HomeBeforeLogin);
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  faLockOpen,
  faLock,
} from '@fortawesome/free-solid-svg-icons';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, Divider, styled, Modal, Typography, Button } from '@mui/material';

import theme from '@extensions/services/Theme';
import { useCartService, useSecurityService } from '@extensions/hooks/useService';

import AddIcon from '@extensions/utils/AddIcon';
import Link from '@dapclient/components/core/Link';
import SignInForm from '@extensions/components/nav/SignInForm';
import WDHHeaderLogo from '@extensions/resources/WDH Logo.png';
import UserToolMenu from '@extensions/components/nav/UserToolMenu';

const StyledIconSpan = styled('span')(({
  marginLeft: '0.75rem'
}));

const StyledLockIconDiv = styled('div')(({
  color: theme.palette.primary.main,
}));

const StyledModalContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '900px',
  minHeight: '500px',
  display: 'flex',
  boxShadow: '0 6px 18px rgba(0,0,0,0.3)',
  borderRadius: '12px',
  overflow: 'hidden',
}));

const StyledLeftColumn = styled(Box)(() => ({
  flex: 1,
  backgroundColor: '#fff',
  padding: '3rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledRightColumn = styled(Box)(() => ({
  flex: 1,
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  padding: '3rem',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledTitle = styled(Typography)(() => ({
  fontFamily: 'sans-serif',
  fontSize: '2rem',
  fontStyle: 'italic',
  fontWeight: 'bold',
  marginBottom: '1.5rem',
  textAlign: 'center',
}));

const StyledSubTitle = styled(Typography)(() => ({
  fontFamily: 'sans-serif',
  fontSize: '1.1rem',
  fontStyle: 'italic',
  lineHeight: 1.4,
  marginBottom: '2.5rem',
  textAlign: 'center',
}));

const StyledSignUpLink = styled('a')(() => ({
  color: '#fff',
  cursor: 'pointer',
  textDecoration: 'underline',
  fontSize: '1.20rem',
  fontFamily: 'sans-serif',
}));

const StyledImg = styled('img')({
  marginTop: '1.5rem',
  marginLeft: '1.5rem',
  marginBottom: '0.85rem',
  width: '220px',
  height: '50px',
});

const StyledWDHATag = styled('a')({
  maxWidth: '100%',
  height: '100px',
});

const StyledCountDiv = styled('div')({
  display: 'flex',
  fontSize: '.8rem',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: theme.typography.fontWeightBold,
  position: 'absolute',
  top: '0.5rem',
  left: '2rem',
  borderRadius: '50%',
  width: '1rem',
  height: '1rem',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
});

const SignInAndRegister: React.FC = () => {
  const securityService = useSecurityService();
  const { user } = securityService;

  const loggedIn = Boolean(user && user.authenticated);
  const withMfa = Boolean(loggedIn && user?.authenticatedMfa);

  const firstName = user !== null ? user.firstname : "";
  const lastName = user !== null ? user.lastname : "";

  const cartService = useCartService();
  const count = cartService.orderItems.length;

  const [openModal, setOpenModal] = useState(false);

  const LinkStyleObject = {
    fontWeight: theme.typography.fontWeightBold,
    color: `${theme.palette.primary.main} !important`,
    '&:hover': {
      color: theme.palette.common.white,
    },
    '&:visited': {
      color: theme.palette.primary.dark,
    },
  }

  const handleContinueAsGuest = () => {
    setOpenModal(false);
  }

  const handleSignUp = () => {
    setOpenModal(false);
    window.location.href = '/register';
  };

  return (
    <>
      <Box sx={{
        display: 'inline-flex',
        alignItems: 'center',
      }}>
        <Link
          aria-label={`Cart (${count} datasets)`}
          to="/profile/cart"
          underline='hover'
          sx={{
            position: 'relative'
          }}
        >
          <ShoppingCartOutlinedIcon
            sx={{
              fontSize: '2.5rem',
              marginTop: '0.75rem',
              color: theme.palette.primary.main
            }}
          />
          <StyledCountDiv aria-hidden>
            <span>{count}</span>
          </StyledCountDiv>
        </Link>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            ml: 4,
            mr: 2,
            color: theme.palette.primary.main,
            borderWidth: '1px',
            background: theme.palette.primary.main,
            height: '60px'
          }}
        />
        {loggedIn ? (
          <>
            <StyledLockIconDiv>
              <AddIcon
                icon={withMfa ? faLock : faLockOpen}
                title={withMfa ? "Logged in with MFA token" : "NOT logged in with MFA token"}
              />
            </StyledLockIconDiv>
            <Link to="/profile" sx={LinkStyleObject}>
              <StyledIconSpan>
                {`${firstName} ${lastName.slice(0, 1)}. `}
              </StyledIconSpan>
            </Link>
            {
              user && user.authenticated && (
                <UserToolMenu tools={user.tools} isLight={true}/>
              )
            }
          </>
        ) : (
          <Box
            component="button"
            sx={{
              background: 'none',
              border: 'none',
              fontSize: '1.1rem',
              color: theme.palette.primary.main,
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={() => setOpenModal(true)}
          >
            Sign In
          </Box>
        )}
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0,0,0,0.3)',
            backdropFilter: 'blur(6px)',
          },
        }}
      >
        <StyledModalContainer>
          <StyledLeftColumn>
            <Box sx={{ mb: 3, textAlign: 'center' }}>
              <StyledWDHATag href="/">
                <StyledImg alt="WDH Logo" src={WDHHeaderLogo} />
              </StyledWDHATag>
            </Box>
            <SignInForm onLoginSuccess={() => setOpenModal(false)} />
          </StyledLeftColumn>
          <StyledRightColumn>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <StyledTitle>Don’t have a login?</StyledTitle>
              <Box sx={{ mb: 3 }}>
                <StyledSignUpLink onClick={handleSignUp}>
                  Sign up here!
                </StyledSignUpLink>
              </Box>
              <StyledSubTitle>
                It’s quick and easy and we can provide a better experience when
                we know your interests.
              </StyledSubTitle>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                variant="text"
                onClick={handleContinueAsGuest}
                sx={{
                  color: '#fff',
                  textTransform: 'none',
                  fontSize: '1rem',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                Continue as guest
                <Box component="span" sx={{ fontWeight: 'bold', mt: 1 }}>
                  <ChevronRightIcon />
                </Box>
              </Button>
              <Typography
                variant="caption"
                sx={{
                  mt: 1,
                  fontSize: '0.85rem',
                  whiteSpace: 'nowrap',
                  display: 'block',
                }}
              >
                *Some datasets won’t be downloadable without an account
              </Typography>
            </Box>
          </StyledRightColumn>
        </StyledModalContainer>
      </Modal>
    </>
  );
};

export default observer(SignInAndRegister);

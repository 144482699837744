import React from 'react';

import {
  Card,
  CardContent,
  styled,
} from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  margin: theme.spacing(1, 0),
}));

export interface DescriptionPanelProps {
  children: React.ReactNode,
}

const DescriptionPanel: React.FC<DescriptionPanelProps> = ({ children }): React.ReactNode => {
  return (
    <StyledCard variant='outlined'>
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
};

export default DescriptionPanel;
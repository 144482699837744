import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { RouteComponentProps, useParams } from 'react-router-dom';

import PublicationSummary from '@extensions/components/publications/PublicationSummary';
import PublicationNotFound from '@extensions/components/publications/PublicationNotFound';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import { usePublicationService } from '@extensions/hooks/useService'
import { IPublicationService } from '@extensions/services/IPublicationService'

const StyledWrapper = styled('div')(({ theme }) => ({
  margin: '0 1em',
  [theme.breakpoints.down('xl')]: {
      margin: '0 1em',
  },
  [theme.breakpoints.down('xs')]: {
      margin: '0 0em',
  },
}));

export interface IPublicationDetailsProps extends RouteComponentProps<{ id: string }> {
  className?: string;
  publicationService?: IPublicationService;
}

const PublicationDetails: React.FC<IPublicationDetailsProps> = observer(() => {
  const publicationService = usePublicationService();
  const { id: idString } = useParams<{ id: string }>();
  const id = Number(idString);

  useEffect(() => {
    if(publicationService) {
      publicationService.selectPublication(id);
    }
  }, [id, publicationService])
  
  let body: React.ReactNode;

  const renderNotFound = () => <PublicationNotFound />;

  const renderSummary = () => {
    if (publicationService?.selectedPublication) {
      const selected = publicationService.selectedPublication;
      return <PublicationSummary publication={selected} editMode={false} />
    }
    return null;
  }

  if (publicationService?.selectedPublicationNotFound) {
    body = <GlobalSearchBarWrap renderChildren={renderNotFound}/>;
  } else if (publicationService?.selectedPublication) {
      body = <GlobalSearchBarWrap renderChildren={renderSummary}/>;
  }
  return <StyledWrapper>{body}</StyledWrapper>
});

export default PublicationDetails;

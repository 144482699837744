import React from 'react';
import { Link } from 'react-router-dom';
import { styled, Typography } from '@mui/material';

import ProjectManagement from '@extensions/components/project/ProjectManagement';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import ProjectModel from '@extensions/models/Project';

interface IManageContentProps {
    project: ProjectModel;
}

const StyledWrapper = styled('div')(()=> ({
    margin: '0 1rem'
}))

const ManageContent: React.FC<IManageContentProps> = ({ project }) => {

    const renderContent = (): React.ReactNode => {
        return (
            <StyledWrapper>
                <Link to={`/project/${project.name}`} style={{ textDecoration: 'none' }}>Back to Project</Link>
                <Typography variant="h2" gutterBottom>
                    Project Management: {project.identifier.toUpperCase()}
                </Typography>
                <Typography variant="h3" gutterBottom>
                    {project.title}
                </Typography>
                <ProjectManagement />
            </StyledWrapper>
        )
    }

    let content = <div />
    content = (
        <React.Fragment>
            <GlobalSearchBarWrap renderChildren={renderContent}/>
        </React.Fragment>
    )
    return content;
}

export default ManageContent;
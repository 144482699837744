import React from 'react';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';

import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  styled
} from '@mui/material';

import theme from '@extensions/services/Theme';
import AddIcon from '@extensions/utils/AddIcon';
import FileOrderGroup from '@extensions/models/FileOrderGroup';

const StyledHeader = styled(Typography)(({
  fontSize: '1.5rem',
}));

const CustomList = ({ ...props }) => (
  <List {...props} />
);

const CustomListItem = ({ ...props }) => (
  <ListItem {...props} />
);

const StyledList = styled(CustomList)(({
  maxHeight: '15rem',
  overflow: 'auto',
}));

const StyledListItem = styled(CustomListItem)(({
  '&:hover': {
    color: theme.palette.text.primary,
  },
}));

const StyledLi = styled('li')(({
  width: '100%',
}));

const StyledIcon = styled(ListItemIcon)(({
  minWidth: 'auto',
  marginRight: theme.spacing(2),
}));

interface DatasetListProps {
  order: FileOrderGroup;
}

const DatasetList: React.FC<DatasetListProps> = ({ order }) => {
  const headerId = 'datasets-header';
  return (
    <Card>
      <CardContent>
        <StyledHeader id={headerId}>
          Datasets
        </StyledHeader>
        <StyledList aria-labelledby={headerId} component="ul">
          {order.datasetNames.map((datasetName) => (
            <StyledLi key={datasetName}>
              <StyledListItem button component="a" href={`/data/${datasetName}`}>
                <StyledIcon>
                  <AddIcon icon={faDatabase} size="lg" />
                </StyledIcon>
                <ListItemText primary={datasetName} />
              </StyledListItem>
            </StyledLi>
          ))}
        </StyledList>
      </CardContent>
    </Card>
  );
};

export default DatasetList;

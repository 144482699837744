import React, { useEffect, useState } from 'react';
import { Box, styled, Tabs, Tab } from '@mui/material';

import EmptyUserFavPage from '@extensions/components/page/home-page/EmptyUserFavPage';
import ProjectResultCard from '@extensions/components/project-search/ProjectResultCard';
import UserFavoriteProjectTab from '@extensions/components/page/home-page/UserFavoriteProjectTab';
import UserFavoriteDatasetTab from '@extensions/components/page/home-page/UserFavoriteDatasetTab';
import DatasetResultCard from '@extensions/components/page/search-components/DatasetResultCard';

import Dataset from '@extensions/models/Dataset';
import Project from '@extensions/models/Project';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import useWindowDimensions from '@extensions/hooks/useWindowDimensions';
import { useNotificationService, useSecurityService } from '@extensions/hooks/useService';

import { Status } from '@extensions/services/INotificationService';

const NOTIFICATION_ID = 'FAVORITE_PROJECTS';

const StyledEmptyMessageDiv = styled('div')(({
    marginBottom: '-5rem'
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTab-root': {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75em',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '0.8em',
        }
    }   
}));

interface IUserFavoritesProps { }

const UserFavorites: React.FC<IUserFavoritesProps> = () => {
    const [fetchedFavProjects, setFetchedFavProjects] = useState<Array<Project>>([]);
    const [fetchedFavDatasets, setFetchedFavDatasets] = useState<Array<Dataset>>([]);

    const [tabIndex, setTabIndex] = useState(0)

    const notificationService = useNotificationService();
    const securityService = useSecurityService();
    const { height } = useWindowDimensions();

    const { user } = securityService;
    const userFavProjects = user?.fav_project;
    const userFavDatasets = user?.fav_ds;

    useEffect(() => {
        const fetchFavProject = async () => {
            if (userFavProjects && userFavProjects.length > 0) {
                const queryStrings = userFavProjects.map((project) => {
                    return {
                        query_string: {
                            query: project,
                            fields: ["name"],
                            default_operator: "and"
                        }
                    }
                });

                try {
                    await DapApiAgent.agent
                        .post('/api/projects/_msearch')
                        .send(
                            JSON.stringify({ preference: "SearchResult" }) + "\n" +
                            JSON.stringify({
                                query: {
                                    bool: {
                                        should: queryStrings
                                    }
                                },
                                size: 500,
                            })
                        ).then((res) => {
                            const curedProjects = res.body.responses[0].hits.hits.map((hit) => {
                                return new Project(hit._source, hit._source.name);
                            }).filter(item => userFavProjects.includes(item.name));
                            setFetchedFavProjects(curedProjects);
                        });
                } catch (error: any) {
                    notificationService!.addNotification(
                        NOTIFICATION_ID,
                        Status.Error,
                        `Could not fetch user's favorite projects.`,
                        error.message
                    );
                }
            }
        };

        const fetchFavDataset = async () => {
            if (userFavDatasets && userFavDatasets.length > 0) {
                let queryStrings = userFavDatasets.map((ds) => {
                    if (ds) {
                        return {
                            query_string: {
                                query: ds.slice(ds.indexOf('/') + 1),
                                fields: ["name"],
                                default_operator: "and"
                            }
                        }
                    }
                    return {};
                });
                try {
                    await DapApiAgent.agent
                        .post('/api/datasets/_msearch?')
                        .send(
                            JSON.stringify({ preference: "SearchResult" }) + "\n" +
                            JSON.stringify({
                                "_source": {
                                    "excludes": [
                                    ],
                                    "includes": [
                                        "*"
                                    ]
                                },
                                query: {
                                    bool: {
                                        should: queryStrings
                                    }
                                },
                                size: 500,
                            })
                        ).then((res) => {
                            const curedDatasets = res.body.responses[0].hits.hits.map((hit) => {
                                return hit._source;
                            }).filter(item => userFavDatasets.includes(item.name));
                            setFetchedFavDatasets(curedDatasets);
                        });
                } catch (error: any) {
                    notificationService!.addNotification(
                        NOTIFICATION_ID,
                        Status.Error,
                        `Could not fetch user's favorite projects.`,
                        error.message
                    );
                }
            }
        };
        fetchFavProject();
        fetchFavDataset();
    }, [userFavProjects, userFavDatasets, notificationService]);

    const renderFetchedFavProject = () => {
        if (fetchedFavProjects && fetchedFavProjects.length > 0) {
            return fetchedFavProjects.map(
                (el, i) => <ProjectResultCard project={el} isCachedFav={true} fromFavList={true} key={`${el.name}-${i}`} />
            )
        }
        return renderEmptyFavProjectMessage();
    };

    const renderEmptyFavProjectMessage = () => {
        if (!fetchedFavProjects || (fetchedFavProjects && fetchedFavProjects.length === 0)) {
            return (
                <StyledEmptyMessageDiv>
                    <EmptyUserFavPage isProject={true} />
                </StyledEmptyMessageDiv>
            )
        }
        return null;
    };

    const renderFetchedFavDataset = () => {
        if (fetchedFavDatasets && fetchedFavDatasets.length > 0) {
            return fetchedFavDatasets.map(
                (el, i) => <DatasetResultCard datasetDocument={el} isCachedFav={true} fromFavList={true} key={`${el.name}-${i}`} />
            )
        }
        return renderEmptyFavDatasetMessage();
    };

    const renderEmptyFavDatasetMessage = () => {
        if (!fetchedFavDatasets || (fetchedFavDatasets && fetchedFavDatasets.length === 0)) {
            return (
                <StyledEmptyMessageDiv>
                    <EmptyUserFavPage isProject={false} />
                </StyledEmptyMessageDiv>
            )
        }
        return null;
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return (
        <Box>
            <Box>
                <StyledTabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    aria-label="favorite tabs"
                >
                    <Tab value={0} label={`Favorite Projects (${fetchedFavProjects.length})`} />
                    <Tab value={1} label={`Favorite Datasets (${fetchedFavDatasets.length})`} />
                </StyledTabs>
            </Box>
            <UserFavoriteProjectTab value={tabIndex} index={0} userFavProjects={userFavProjects} height={height}>
                {renderFetchedFavProject()}
            </UserFavoriteProjectTab>
            <UserFavoriteDatasetTab value={tabIndex} index={1} userFavDatasets={userFavDatasets} height={height}>
                {renderFetchedFavDataset()}
            </UserFavoriteDatasetTab>
        </Box>
    )
}

export default UserFavorites;
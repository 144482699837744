import React from 'react';

import {
  styled,
  Typography
} from '@mui/material';
import MarkdownText from '@extensions/components/core/MarkdownText';
import DescriptionPanel from '@extensions/components/core/DescriptionPanel';
import DatasetModel from '@extensions/models/Dataset';

interface DataQualityProps {
  dataset: DatasetModel;
}

const StyledHeaderTag = styled(Typography)(() => ({
  fontWeight: 'normal',
  marginBlockStart: '0',
  marginBlockEnd: '12px',
  fontSize: '1.25em',
  color: '#000000',
}));

const StyledTextTag = styled(Typography)(() => ({
  fontWeight: 'normal',
  marginBlockStart: '0',
  marginBlockEnd: '12px',
  fontSize: '1em',
  color: '#4a4d50',
}));

const DataQuality: React.FC<DataQualityProps> = ({ dataset }) => {
  if (!dataset.dataQualityDescription) {
    return null;
  }

  return (
    <DescriptionPanel>
      <StyledHeaderTag>Data Quality</StyledHeaderTag>
      <MarkdownText
        rawMarkdown={dataset.dataQualityDescription}
        components={{
          p: (props) => <StyledTextTag variant="body1" {...props} />,
        }}
      />
    </DescriptionPanel>
  )
}

export default DataQuality;
import {
  Grid,
  ScopedCssBaseline
} from '@mui/material';

import SearchResults from '@extensions/components/search-core/SearchResults';
import CodeFilters from '@extensions/components/code-search/code/CodeFilters';
import { getStatsDisplayString } from '@extensions/components/code-search/utils';
import CodeResultHit from '@extensions/components/code-search/code/CodeResultHit';
import { SearchProvider, SEARCH_BAR_REACTIVE_ID } from '@extensions/components/search-core';
import { StyledSearchBar, StyledSelectedFilters } from '@extensions/components/code-search/code/codeStyles';

import theme from '@extensions/services/Theme';

const REACTIVE_FILE_TYPE_ID = 'FileTypeFilter';
const REACTIVE_FILE_EXT_ID = 'FileExtFilter';
const REACTIVE_REPO_ID = 'RepoFilter';
const REACTIVE_CONTRIB_ID = 'ContributorFilter';

const REACTIVE_IDS = [
  SEARCH_BAR_REACTIVE_ID,
  REACTIVE_FILE_TYPE_ID,
  REACTIVE_FILE_EXT_ID,
  REACTIVE_REPO_ID,
  REACTIVE_CONTRIB_ID
];

const Code: React.FC = () => {
  return (
    <ScopedCssBaseline sx={{ backgroundColor: theme.palette.grey[50] }}>
      <SearchProvider elasticIndex="codehub/code" apiPrefix="api" theme={{ colors: { primaryColor: '#53b03f' } }}>
        <StyledSearchBar
          placeholder="Search for Code"
          dataField={['file_path']}
          autosuggest={false}
          URLParams={true}
          queryFormat="and"
          customQuery={(value) => {
            const query = value
            value = value.toLowerCase()
            if (!value) {
              return {};
            }
            return {
              query: {
                bool: {
                  should: [
                    { wildcard: { 'file_path.keyword': { value, boost: 15 } } },
                    { wildcard: { 'file_name': { value, boost: 15 } } },
                    { wildcard: { 'repo_name': { value, boost: 15 } } },
                    {
                      match_phrase: {
                        'attachment.content': {
                          query,
                          analyzer: 'special_char_analyzer',
                          boost: 5,
                        },
                      },
                    },
                  ],
                  minimum_should_match: 1,
                },
              },
            };
          }}
          highlight
          customHighlight={() => ({
            highlight: {
              fields: {
                'attachment.content': {
                  type: 'unified',
                  fragment_size: 0,
                  number_of_fragments: 100,
                },
              },
            },
          })}
        />
        <StyledSelectedFilters />
        <Grid container spacing={2} sx={{ paddingTop: '10px' }}>
          <Grid item xs={12} sm={4} md={5} lg={3} xl={3}>
            <CodeFilters />
          </Grid>
          <Grid item xs={12} sm={8} md={7} lg={9} xl={9}>
            <SearchResults dataField="name.keyword" sortBy="asc" react={{ and: [SEARCH_BAR_REACTIVE_ID, ...REACTIVE_IDS] }}
              renderItem={(hit) => (
                <CodeResultHit key={hit.node_id} data={hit} />
              )}
              renderNoResults={() => {
                return "No results found."
              }}
              renderResultStats={(stats) => (
                <div style={{ fontSize: '90%', color: '#999' }}>
                  {getStatsDisplayString(stats)}
                </div>
              )}
              sortOptions={[
                { label: 'Relevance', dataField: '_score', sortBy: 'desc' },
                { label: 'Repository', dataField: 'repo_name.keyword', sortBy: 'asc' },
                { label: 'File Name', dataField: 'file_name.keyword', sortBy: 'asc' },
              ]}
              excludeFields={['attachment', 'file_content']}
            />
          </Grid>
        </Grid>
      </SearchProvider>
    </ScopedCssBaseline>
  );
};

export default Code;

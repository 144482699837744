import React from 'react';
import {
  Card,
  CardContent,
  styled,
  Typography,
  Button,
  Divider,
  Stack,
  CardActionArea,
  CardActions,
} from '@mui/material';

import ContactPoint from '@extensions/models/ContactPoint';
import { useContactUsService } from '@extensions/hooks/useService';
import Organization from '@extensions/models/Organization';

export interface IContactsPanelProps {
  contacts: ContactPoint[];
  name?: string;
  title?: string;
  orgs?: Organization[];
}

const StyledContactCard = styled(Card)(() => ({
  marginTop: '10px',
  minHeight: '40px',
}));

const StyledTitle = styled(Typography)(() => ({
  color: '#000000',
  fontSize: '1.1em',
}));

const StyledContact = styled('p')(() => ({
  margin: '0.25rem 0',
  fontSize: '0.95em',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: '1rem -1rem',
}));

const StyledMessageButton = styled(Button)(({ theme }) => ({
  display: 'inline-block',
  cursor: 'pointer',
  color: theme.palette.secondary.main,
  margin: '-2em auto 0',
  fontSize: '0.9em',
  textTransform: 'capitalize',
  letterSpacing: 'normal',
}));

const ContactsPanel: React.FC<IContactsPanelProps> = (props) => {
  const { contacts, name, title, orgs } = props;
  const contactUsService = useContactUsService();

  if (!contacts?.length) {
    return (
      <Card>
        <CardContent>
          <Typography sx={{ padding: '0 1rem' }}>No Contacts Found</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <StyledContactCard variant='outlined'>
      <CardContent>
        <Stack>
          <div>
            <StyledTitle>Points of Contact</StyledTitle>
            {contacts.map((contact, index) => (
              <StyledContact title={contact.role} key={index}>
                {contact.displayName}
              </StyledContact>
            ))}
            <StyledDivider />
          </div>

          {orgs && orgs.length > 0 && (
            <>
              <div>
                <StyledTitle>Participating Organizations</StyledTitle>
                {orgs.map((org, index) => (
                  <StyledContact title={org.name} key={index}>
                    {org.shortName || org.name}
                  </StyledContact>
                ))}
                <StyledDivider />
              </div>
            </>
          )}
        </Stack>
      </CardContent>

      {contactUsService && (
        <CardActions>
          <StyledMessageButton onClick={() => contactUsService.openModal(name, title)}>
            Send Message
          </StyledMessageButton>
        </CardActions>
      )}
    </StyledContactCard>
  );
};

export default ContactsPanel;

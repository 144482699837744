import React from 'react';
import { Typography, styled } from '@mui/material';

import theme from '@extensions/services/Theme';

const StyledLabel = styled('span')(({
  fontWeight: theme.typography.fontWeightBold,
}));

interface IFileSummaryItemProps {
  label: string;
  value: string | number;
}

const FileSummaryItem: React.FC<IFileSummaryItemProps> = ({ label, value }) => {
  return (
    <Typography>
      <StyledLabel>{label}: </StyledLabel> {value}
    </Typography>
  )
}

export default FileSummaryItem;

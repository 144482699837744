import React from 'react';
import IHasMetrics from '@extensions/models/IHasMetrics';
import Stat from '@extensions/components/core/Stat';
import {
  ResponsiveContainer,
  YAxis,
  Tooltip,
  XAxis,
  LineChart,
  Line,
} from 'recharts';
import { styled, Typography } from '@mui/material';
import filesize from 'filesize';
import MetricsUtils from '@extensions/utils/MetricsUtils';

const StyledHeader = styled(Typography)(() => ({
  margin: '0',
  fontSize: '1em',
  marginBottom: '0 !important',
}));

const renderStat = (value: string | number | null, description: string) => (
  <Stat
    value={value !== undefined && value !== null ? value.toLocaleString() : '-'}
    description={description}
  />
);

export const renderViewStats: React.FC<{ item: IHasMetrics }> = ({ item }) => (
  renderStat(item.getViews(), 'Visitors')
);

export const renderTotalDynamoSizesStats: React.FC<{ item: IHasMetrics }> = ({ item }) => {
  const files = item.getDynamoFileCount();
  const sizes = item.getDynamoTotalFileSize();
  const childCount = item.getChildCount();

  return (
    <>
      {renderStat(childCount, 'Datasets')}
      {renderStat(files, 'Files Stored')}
      {renderStat(sizes ? filesize(sizes) : '-', 'Bytes Stored')}
    </>
  );
};

export const renderDownloadHistoryCharts: React.FC<{ item: IHasMetrics }> = ({ item }) => {
  const stats = item.getDownloadStats();
  const totalFileCount = stats.reduce((total, stat) => total + (stat.file_count || 0), 0);

  if (!stats.length) return null;

  const xAxisInterval = stats.length < 12 ? 0 : stats.length < 36 ? 2 : 5;
  const downloadDateRange = `(${MetricsUtils.formatDownloadsTooltipLabel(stats[0].month)} - ${MetricsUtils.formatDownloadsTooltipLabel(stats[stats.length - 1].month)})`;

  return (
    <div>
      <div style={{ margin: '0' }}>
        <StyledHeader>Files Requested</StyledHeader>
        <Typography variant="caption">Downloads Between {downloadDateRange}</Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '25px' }}>
        <Typography variant="h2">{totalFileCount}</Typography>
        <ResponsiveContainer width={150} height={50}>
          <LineChart data={stats}>
            <XAxis
              mirror
              hide
              dataKey="month"
              tickFormatter={(value) => MetricsUtils.formatXAxisTick(value, xAxisInterval)}
              tick={false}
              interval={xAxisInterval}
            />
            <YAxis
              mirror
              hide
              tickFormatter={MetricsUtils.formatYAxisTick}
              scale={stats.length > 1 ? 'log' : 'auto'}
              domain={['auto', 'auto']}
            />
            <Tooltip
              labelFormatter={MetricsUtils.formatDownloadsTooltipLabel}
              formatter={MetricsUtils.formatFileCountsTooltip}
            />
            <Line dataKey="file_count" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <hr />
    </div>
  );
};

export const getOrderStat: React.FC<{ item: IHasMetrics }> = ({ item }) => {
  const orderCount = item.getOrderCount();
  return orderCount ? renderStat(orderCount, 'Order Requests') : null;
};

export const getByteRequestStat: React.FC<{ item: IHasMetrics }> = ({ item }) => {
  const byteCount = item.getOrderByteCount();
  return byteCount ? renderStat(byteCount, 'Bytes Requested') : null;
};

export const getFileRequestStat: React.FC<{ item: IHasMetrics }> = ({ item }) => {
  const fileCount = item.getOrderFileCount();
  return fileCount ? renderStat(fileCount, 'Files Requested') : null;
};
import React from 'react';
import { Divider, Typography, Box, styled } from '@mui/material';
import { ReactiveList, ReactiveBase } from '@appbaseio/reactivesearch';

import PopularProjectList from '@extensions/components/page/home-page/PopularProjectList';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

import { useTokenService } from '@extensions/hooks/useService';

interface HomePagePopularProjectsProps { };

const StyledDivider = styled(Divider)(({
    borderWidth: 1,
    marginBottom: '1rem'
}));

const HomePagePopularProjects: React.FC<HomePagePopularProjectsProps> = () => {
    const tokenService = useTokenService();
    const apiPrefix = 'api';
    const port = window.location.port ? `:${window.location.port}` : '';
    const prefix = apiPrefix ? `/${apiPrefix}` : '';
    const url = `${window.location.protocol}//${window.location.hostname}${port}${prefix}`;

    if (!tokenService) {
        return null;
    }

    const popularProjectQuery = {
        query: {
            match_all: {},
        },
        size: 10,
        sort: [
            {
                "dapOrderStats.uniqueUserCount": {
                    order: "desc"
                }
            }
        ],
        _source: {
            includes: ["*"],
            excludes: [],
        }
    }

    const customRequest = (req) => {
        req.body = req.body.replace('{"query":{"match_all":{}},"size":10,"_source":{"includes":["*"],"excludes":[]}}',
            JSON.stringify(popularProjectQuery)
        )
        return req;
    }

    return (
        <Box>
            <Typography variant='subtitle1' sx={{ color: '#000 !important' }}>
                Popular Projects
            </Typography>
            <StyledDivider orientation="horizontal" />
            <ReactiveBase
                app='projects'
                url={url}
                headers={{
                    'x-csrf-token': tokenService.dapToken,
                }}
                transformRequest={(req) => customRequest(req)}
            >
                <ReactiveList
                    infiniteScroll={false}
                    showResultStats={false}
                    dataField="title.keyword"
                    componentId="SearchResult"
                    render={({ loading, error, data }) => {
                        if (loading) {
                            return <CenteredCircularProgress />;
                        }
                        if (error) {
                            return (
                                <div>
                                    Something went wrong! Please email{' '}
                                    <a href={process.env.REACT_APP_TEAM_EMAIL}>our team</a> if
                                    this issue persists.
                                </div>
                            );
                        }
                        return (<PopularProjectList projects={data} />);
                    }}
                >
                </ReactiveList>
            </ReactiveBase>
        </Box>
    )
}

export default HomePagePopularProjects;
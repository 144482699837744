import React, { useState } from 'react';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import OptionButton from '@extensions/components/order/options/OptionButton';
import FileLinksDialog from '@extensions/components/order/options/FileLinksDialog';

import AddIcon from '@extensions/utils/AddIcon';
import FileOrderGroup from '@extensions/models/FileOrderGroup';
import { DownloadOption } from '@extensions/models/FileOrder';

interface IFileLinksOptionProps {
  order: FileOrderGroup;
}

const FileLinksOption: React.FC<IFileLinksOptionProps> = ({ order }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const toggleShowDialog = () => {
    setShowDialog((prev) => !prev);
  };

  return (
    <>
      <OptionButton
        startIcon={<AddIcon icon={faLink} />}
        onClick={toggleShowDialog}
        order={order}
        option={DownloadOption.LINKS}
      >
        File Links
      </OptionButton>
      <FileLinksDialog
        open={showDialog}
        onClose={toggleShowDialog}
        order={order}
      />
    </>
  )
}

export default FileLinksOption


import React from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import HeaderMenuSR from '@extensions/components/nav/HeaderMenuSR';
import UserToolMenu from '@extensions/components/nav/UserToolMenu';
import ResponsiveMenu from '@extensions/components/nav/ResponsiveMenu';
import HeaderMenuItem, { IHeaderMenuItemProps } from '@extensions/components/nav/HeaderMenuItem';

import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material';
import theme from '@extensions/services/Theme';
import { useSecurityService } from '@extensions/hooks/useService';

const HEADER_ITEMS: IHeaderMenuItemProps[] = [
  { label: 'Projects', dst: '/projects/wind-energy', currentPathName: '' },
  { label: 'Data', dst: '/data/wind-energy', currentPathName: '' },
  { label: 'Publications', dst: '/publications', currentPathName: '' },
  { label: 'Code', dst: '/code', currentPathName: '' },
  { label: 'Metrics', dst: '/metrics', currentPathName: '' },
  { label: 'FAQ', dst: '/faq', currentPathName: '' },
];

const StyledTabs = styled(Tabs)(({
  minHeight: 'unset',
  '& .MuiTab-root': {
    height: 45,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
    minWidth: 'auto',
    padding: '0px 16px',
    opacity: 'unset',
    minHeight: 'unset',
    '&.Mui-selected': {
      background: theme.palette.secondary.main,
    },
  },
}));

const StyledMobileTabs = styled(Tabs)(({
  minHeight: '100%',
  background: theme.palette.primary.light,
  '& .MuiTab-root': {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.Mui-selected': {
      background: theme.palette.secondary.main,
    },
  },
  fontFamily: theme.typography.fontFamily,
}));

const StyledNavRoot = styled('nav')(({
  '@media (max-width: 959px)': {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
}));

const StyleUlItemList = styled('ul')(({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

interface IHeaderMenuProps {
  currentPathName: string;
}

const HeaderMenu: React.FC<IHeaderMenuProps> = ({ currentPathName }) => {
  const securityService = useSecurityService();
  const location = useLocation();

  const innerMenu = HEADER_ITEMS.map((headerItem) => (
    <HeaderMenuItem
      key={headerItem.dst}
      {...headerItem}
      currentPathName={currentPathName}
    />
  ));

  const innerMenuMobile = [
    <HeaderMenuSR key="headerMenu" />,
    ...HEADER_ITEMS.map((headerItem) => (
      <HeaderMenuItem
        key={headerItem.dst}
        {...headerItem}
        menuItemContentMobileStyle={{
          fontWeight: 1700,
          lineHeight: 2,
        }}
        currentPathName={currentPathName}
      />
    ))
  ];

  const webMenu = (
    <StyledTabs value={false} key={location.key}>
      {innerMenu}
    </StyledTabs>
  );

  const mobileMenu = (
    <StyledMobileTabs orientation="vertical" value={false}>
      {innerMenuMobile}
    </StyledMobileTabs>
  );

  return (
    <StyledNavRoot>
      <StyleUlItemList>
        <ResponsiveMenu desktop={webMenu} mobile={mobileMenu} />
        {securityService.user && securityService.user.authenticated && (
            <UserToolMenu
              tools={securityService.user.tools}
              isLight={false}
            />
          )}
      </StyleUlItemList>
    </StyledNavRoot>
  );
};

export default observer(HeaderMenu);
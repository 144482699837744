import { styled, Tab, Tabs, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, RouteComponentProps, useHistory, useLocation } from 'react-router-dom'

import Code from '@extensions/components/code-search/code/Code'
import Repo from '@extensions/components/code-search/repo/Repo'
import MarkdownText from '@extensions/components/core/MarkdownText'
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import theme from '@extensions/services/Theme';
import { useCachingService, useGlobalSearchService } from '@extensions/hooks/useService'

const StyledWrapper = styled('div')(({ theme }) => ({
  margin: '0 1em',
  [theme.breakpoints.down('xl')]: {
    margin: '0 1em',
  },
  [theme.breakpoints.down('xs')]: {
    margin: '0 0em',
  },
}));

interface ICodeHubProps extends RouteComponentProps<{ view: string }> {
}

const tabs = [
  {
    route: 'repo',
    label: 'Code Repository Search',
    component: Repo,
  },
  {
    route: 'search',
    label: 'Code Search',
    component: Code,
  },
]

const CodeHub: React.FC<ICodeHubProps> = ({ match }) => {
  const cache = useCachingService()
  const globalSearchService = useGlobalSearchService();
  const history = useHistory()
  const location = useLocation()
  const currentRoute = match.params['view']

  const [intro, setIntro] = useState('')

  const onTabChange = useCallback(route => {
    history.push(
      location.pathname.replace(
        new RegExp(`/${currentRoute}(/|$)`),
        `/${route}$1`
      )
    );
  }, [history, location, currentRoute])

  const tab = currentRoute ? tabs.find(
    (t, i) => t.route === currentRoute || (!currentRoute && i === 0)
  ) : tabs[0]

  useEffect(() => {
    if (!currentRoute && tab) {
      history.replace(`${location.pathname}/${tab.route}`)
    }
  })

  useEffect(() => {
    if (Boolean(intro)) {
      return
    }
    cache.getCodeHubIntro().then(md => setIntro(md))
  }, [intro, cache])

  if (!tab) {
    return (
      <Redirect to="/" />
    )
  }

  const renderContent = () => {
    return (
      <StyledWrapper>
        {Boolean(intro) && (
          <em>
            <MarkdownText
              rawMarkdown={intro}
              components={{
                p: ({ ...props }) => (
                  <Typography
                    variant="body1"
                    sx={{ color: (theme) => theme.palette.grey[700], lineHeight: 1.6 }}
                    {...props}
                  />
                ),
              }}
            />
          </em>
        )}
        <Tabs
          value={tab.route}
          onChange={(event, val) => onTabChange(val)}
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.grey[800],
            },
          }}
          sx={{
            '& .MuiTabs-indicator': {
              '@media (max-width: 500px)': {
                display: 'none'
              },
            },
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
              justifyContent: 'center',
            },
          }}
        >
          {tabs.map(t => (
            <Tab
              key={t.route}
              value={t.route}
              label={t.label}
              sx={{
                color: theme.palette.common.black,
                opacity: 0.7,
                '& .MuiTabs-flexContainer': {
                  flexWrap: 'wrap'
                },
                '&.Mui-selected': {
                  color: 'black',
                  '@media (max-width: 500px)': {
                    textDecoration: 'solid underline 2px',
                    textUnderlineOffset: '15px'
                  },
                },
              }}
            />
          ))}
        </Tabs>
        {React.createElement(tab.component, {})}
      </StyledWrapper>
    )
  }

  globalSearchService.setSearchContent('');
  globalSearchService.setSelectedCategory('');
  return <GlobalSearchBarWrap renderChildren={renderContent} />
}

export default CodeHub;

import React from 'react';
import { Card, CardContent, styled } from '@mui/material';

import { useSecurityService } from '@extensions/hooks/useService';
import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils';

import MultiList from '@extensions/components/core/MultiList';
import SortableMultiList from '@extensions/components/core/SortableMultiList';
import { SEARCH_BAR_REACTIVE_ID } from '@extensions/components/search-core';
import DateFilter from '@extensions/components/publications/DateFilter';

interface PublicationsSearchFiltersProps {};

const STATUS_ID = 'STATUS';
const PROJECT_ID = 'PROJECT';
const DATASET_ID = 'DATASET';
const DATE_ID = 'DATE';
const TYPE_ID = 'TYPE';
const KEYWORD_ID = 'KEYWORDS';
const AUTHOR_ID = 'AUTHORS';

export const FILTER_REACTIVE_IDS = [
    STATUS_ID,
    PROJECT_ID,
    DATE_ID,
    TYPE_ID,
    KEYWORD_ID,
    AUTHOR_ID,
    SEARCH_BAR_REACTIVE_ID,
    DATASET_ID,
];

const allReactiveIdsExcept = getAllReactiveIdsExcept([
    ...FILTER_REACTIVE_IDS,
    SEARCH_BAR_REACTIVE_ID,
]);

const StyledCardContent= styled(CardContent)(({ theme }) => ({
    '& .data-filter': {
      marginBottom: theme.spacing(2),
    },
}));

const PublicationsSearchFilters: React.FC<PublicationsSearchFiltersProps> = () => {
    const securityService = useSecurityService();
    const isAdminMode = securityService?.user?.canAdminPubs;
    return (
            <Card variant='outlined' sx={{ overflow: 'visible' }}>
            <StyledCardContent>
                <MultiList
                    dataField="referenceTypePretty.keyword"
                    componentId={TYPE_ID}
                    title="Publication Type"
                    react={{
                        and: allReactiveIdsExcept(TYPE_ID),
                    }}
                    className="data-filter"
                    URLParams
                    filterLabel="Type"
                />
            {isAdminMode && (
                    <MultiList
                        dataField="status.keyword"
                        componentId={STATUS_ID}
                        title="Status"
                        react={{
                            and: allReactiveIdsExcept(STATUS_ID),
                        }}
                        maxLabelWidth="none"
                        URLParams
                        filterLabel="Status"
                        className="data-filter"
                    />
                )}
                <DateFilter
                    componentId={DATE_ID}
                    react={{
                        and: allReactiveIdsExcept(DATE_ID),
                    }}
                    dataField="publicationDate"
                    URLParams
                    plainTitle={true}
                    className="data-filter"
                />
                <SortableMultiList
                    dataField="keywords.keyword"
                    componentId={KEYWORD_ID}
                    title="Keyword"
                    react={{
                        and: allReactiveIdsExcept(KEYWORD_ID),
                    }}
                    filterLabel="Keyword"
                    className="data-filter"
                />

                <SortableMultiList
                    dataField="authorNames.keyword"
                    componentId={AUTHOR_ID}
                    title="Author"
                    react={{
                        and: allReactiveIdsExcept(AUTHOR_ID),
                    }}
                    filterLabel="Author"
                />
                </StyledCardContent>
                </Card>
    )
}

export default PublicationsSearchFilters;
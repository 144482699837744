import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import theme from '@extensions/services/Theme';
import { useSecurityService } from '@extensions/hooks/useService';

interface IAddToFavoriteDatasetProps {
    dataset: any;
};

const AddToFavoriteDatasetButton: React.FC<IAddToFavoriteDatasetProps> = ({ dataset }) => {
    const [isFav, setIsFav] = useState(false);
    const securityService = useSecurityService();
    const { user } = securityService;

    useEffect(() => {
            if (securityService.userIsLoggedIn) {
                if (user && user['fav_ds'] && user['fav_ds'].length && user['fav_ds'].includes(dataset.name)) {
                    setIsFav(true)
                } else {
                    setIsFav(false)
                }
            }
        }, [user, dataset.name, securityService.userIsLoggedIn]);

    const handleClick = async () => {
        let statusCode = 0;
        if (isFav && user && user.fav_ds && user.fav_ds.length) {
            const updatedFavProject = user.fav_ds.filter(p => p !== dataset.name);
            statusCode = await securityService.updateFavorite({
                "fav_ds": updatedFavProject
            }, false);
            if (statusCode === 200) {
                setIsFav(prevIsFav => !prevIsFav);
                window.location.reload();
            }
        } else {
            if (user && (!user.hasOwnProperty('fav_ds') || !user.fav_ds)) {
                statusCode = await securityService.updateFavorite({
                    "fav_ds": [dataset.name]
                }, true);
                if (statusCode === 200) {
                    setIsFav(prevIsFav => !prevIsFav);
                }
            } else if (user && user.fav_ds && user.fav_ds.length === 0) {
                statusCode = await securityService.updateFavorite({
                    "fav_ds": [dataset.name]
                }, true);
                if (statusCode === 200) {
                    setIsFav(prevIsFav => !prevIsFav);
                }
            } else if (user && user.fav_ds && user.fav_ds.length > 0) {
                statusCode = await securityService.updateFavorite({
                    "fav_ds": [...user['fav_ds'], dataset.name]
                }, true);
                if (statusCode === 200) {
                    setIsFav(prevIsFav => !prevIsFav);
                }
            }
        }
    }

    if (securityService.userIsLoggedIn) {
        return (
            <Button
                fullWidth
                onClick={handleClick}
                endIcon={
                    isFav ? <FavoriteIcon
                        sx={{
                            '&.MuiSvgIcon-root': {
                                color: theme.palette.warning.main
                            }
                        }}
                    />
                        : <FavoriteBorderIcon sx={{
                            '&.MuiSvgIcon-root': {
                                color: theme.palette.warning.main
                            }
                        }}/>
                }
            >
                {
                    isFav
                        ? "Remove from Favorites"
                        : "Add to Favorites"
                }
            </Button>
        )
    }
}

export default AddToFavoriteDatasetButton;
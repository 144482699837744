import get from 'lodash/get';
import React, { useCallback, useEffect } from 'react';
import { Card, Divider, Typography } from '@mui/material';
import { react } from '@appbaseio/reactivesearch/lib/types';
import { ReactiveComponent } from '@appbaseio/reactivesearch';

import { CheckboxGrid } from '@dapclient/components/search-core';

import theme from '@extensions/services/Theme';

const NETCDF_LABEL = 'Standardized NetCDF';

type Bucket = {
  key: any;
  key_as_string: string;
  doc_count: number;
};

interface IStandardizationCheckboxProps {
  checked: boolean;
  setQuery: ({ value, query }: { value: string | null; query: any }) => void;
  numStandardized: number;
  hidden?: boolean;
}

const StandardizationCheckbox: React.FC<IStandardizationCheckboxProps> = ({
  checked,
  setQuery,
  numStandardized,
  hidden,
}) => {

  const setCheckedQuery = useCallback(() => {
    setQuery({
      query: {
        term: { isStandardized: true },
      },
      value: NETCDF_LABEL,
    });
  }, [setQuery]);

  useEffect(() => {
    if (checked) {
      setCheckedQuery();
    }
  }, [checked, setCheckedQuery]);

  const setUncheckedQuery = () => {
    setQuery({
      query: {
        match_all: {},
      },
      value: null,
    });
  };

  const setStandardization = (newChecked: boolean) => {
    if (newChecked) {
      setCheckedQuery();
    } else {
      setUncheckedQuery();
    }
  };

  if (hidden) {
    return null;
  }

  return (
    <div style={{ marginTop: '0.5rem' }}>
      <CheckboxGrid
        data={[{ key: NETCDF_LABEL, doc_count: `${numStandardized}` }]}
        value={{ [NETCDF_LABEL]: checked }}
        handleChange={() => setStandardization(!checked)}
        checkboxColumnHeader="Type"
        labeledBy="standardization-title"
      />
    </div>
  );
};

export const STANDARDIZATION_REACTIVE_ID = 'StandardizationFilter';

interface StandardizationFilterProps {
  react?: react;
}

const StandardizationFilter: React.FC<StandardizationFilterProps> = ({ react }) => {
  return (
    <ReactiveComponent
      componentId={STANDARDIZATION_REACTIVE_ID}
      showFilter={true}
      filterLabel="Standardization"
      defaultQuery={() => ({
        size: 0,
        aggs: {
          isStandardized: {
            terms: {
              field: 'isStandardized',
              size: 100,
            },
          },
        },
      })}
      URLParams={true}
      react={react}
      render={({ loading, error, aggregations, setQuery, value }) => {
        const buckets: Bucket[] = get(aggregations, 'isStandardized.buckets', []);

        let numNetcdf = 0;
        if (buckets.length > 0) {
          const netcdfBucket = buckets.find((bucket) => bucket.key === 1);
          if (netcdfBucket) {
            numNetcdf = netcdfBucket.doc_count;
          }
        }

        const hidden = loading || error || numNetcdf === 0;

        if (hidden) return null;

        return (
            <div className="data-filter">
              <Typography
                sx={{
                  mt: '0.5rem',
                  mb: '0.5rem !important',
                  color: theme.palette.text.secondary,
                }}
              >
                Standardization
              </Typography>
              <Divider sx={{ mx: -2 }} />
              <StandardizationCheckbox
                hidden={hidden}
                checked={!!value}
                numStandardized={numNetcdf}
                setQuery={setQuery}
              />
            </div>
        );
      }}
    />
  );
};

export default StandardizationFilter;

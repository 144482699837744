import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledTitle = styled(Typography)(() => ({
  color: '#000000',
  fontSize: '18px',
}));

export interface IPanelProps {
  ariaControls: string;
  id: string;
  title: string;
  body: React.ReactNode;
}

const Panel: React.FC<IPanelProps> = ({ ariaControls, id, title, body }) => {
  return (
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={ariaControls}
          id={id}
        >
          <StyledTitle variant='h3'>{title}</StyledTitle>
        </AccordionSummary>
        <AccordionDetails>{body}</AccordionDetails>
    </Accordion>
  )
}

export default Panel;
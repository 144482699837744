import React, { useState } from 'react';
import {
  faAddressCard,
  faCaretDown,
  faUsers,
  faShoppingBasket,
  faEdit,
  faCloudUploadAlt,
  faTasks,
  faExternalLinkAlt,
  faSignOutAlt,
  faHeart,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { inject, observer } from 'mobx-react';
import { Menu, MenuItem, Button, IconButton } from '@mui/material';

import Link from '@dapclient/components/core/Link';

import theme from '@extensions/services/Theme';
import AddIcon from '@extensions/utils/AddIcon';
import UserTool from '@extensions/models/UserTool';
import { useSecurityService } from '@extensions/hooks/useService';

export interface IUserToolMenuProps {
  tools: UserTool[] | null;
  isLight: boolean;
}

const ICON_MAP = {
  vcard: faAddressCard,
  users: faUsers,
  'shopping-basket': faShoppingBasket,
  edit: faEdit,
  'cloud-upload': faCloudUploadAlt,
  tasks: faTasks,
  heart: faHeart,
};

const UserToolMenu: React.FC<IUserToolMenuProps> = ({ tools, isLight }) => {
  const securityService = useSecurityService();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'user-tools-menu';

  const renderButton = () => {
    if (isLight) {
      return (
        <IconButton
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleOpen}
          aria-label="user tools"
          sx={{
            ml: '0.25rem',
            mt: '-0.25rem',
            fontSize: '.9rem',
            color: theme.palette.primary.main,
            '&:hover': {
              color: theme.palette.secondary.main,
              backgroundColor: 'transparent',
              textDecoration: 'none',
            },
          }}
        >
          <AddIcon icon={faCaretDown} />
        </IconButton>
      )
    }
    return (
      <Button
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleOpen}
        aria-label="user tools"
        sx={{
          borderRadius: 0,
          padding: '0.6rem 1.3rem',
          fontSize: '.9rem',
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            textDecoration: 'none',
          },
        }}
      >
        <AddIcon
          icon={faUser}
          style={{
            marginRight: '0.25rem',
          }}
        />
        <AddIcon icon={faCaretDown} />
      </Button>
    )
  }

  if (!tools) {
    return null;
  }

  return (
    <div>
      {renderButton()}
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{
          '& ul': {
            backgroundColor: theme.palette.secondary.light,
          },
        }}
      >
        {tools.map((tool) => (
          <Link
            to={tool.url}
            key={tool.url}
            target={tool.external ? '_blank' : undefined}
            ignoreTarget={true}
          >
            <MenuItem
              key={tool.url}
              onClick={handleClose}
              sx={{
                backgroundColor: 'inherit',
                color: theme.palette.common.white,
                textTransform: 'uppercase',
                fontWeight: theme.typography.fontWeightRegular,
                fontSize: theme.typography.pxToRem(14),
                '&:hover': {
                  color: theme.palette.common.white,
                  backgroundColor: theme.palette.secondary.main,
                },
                '&.Mui-focusVisible': {
                  backgroundColor: theme.palette.secondary.main,
                },
              }}
            >
              <AddIcon
                icon={ICON_MAP[tool.fontAwesomeIconName]}
                style={{
                  marginRight: '0.5rem',
                }}
              />
              {tool.label}
              {tool.external && (
                <AddIcon
                  icon={faExternalLinkAlt}
                  size="xs"
                  style={{
                    marginLeft: '0.5rem',
                  }}
                />
              )}
            </MenuItem>
          </Link>
        ))}
        <Link
          key={'signout'}
          onClick={async () => {
            await securityService!.logout();
            window.location.href = '/';
          }}
          ignoreTarget={true}
          sx={{ width: '100%' }}
        >
          <MenuItem
            key={'sign out'}
            onClick={async () => {
              await securityService!.logout();
              window.location.href = '/';
              handleClose();
            }}
            sx={{
              backgroundColor: 'inherit',
              color: theme.palette.common.white,
              textTransform: 'uppercase',
              fontWeight: theme.typography.fontWeightRegular,
              fontSize: theme.typography.pxToRem(14),
              '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.secondary.main,
              },
              '&.Mui-focusVisible': {
                backgroundColor: theme.palette.secondary.main,
              },
            }}
          >
            <AddIcon
              icon={faSignOutAlt}
              style={{
                marginRight: '0.5rem',
              }}
            />
            Sign Out
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
};

export default inject((store: any) => ({
  securityService: store.securityService,
}))(observer(UserToolMenu));

import { useMemo, useState, Fragment } from 'react';
import { escapeHtml } from '@extensions/utils/format';
import { CodeBlock } from '@extensions/components/code-search/code/codeStyles';

interface Highlight {
  'attachment.content'?: string[];
}

interface IHighlightProps {
  data: Highlight
}

interface Line {
  num: number
  txt: string
}

interface Section {
  lines: Line[]
  hl: boolean
  show: boolean
}

const HighlightViewer: React.FC<IHighlightProps> = ({ data }) => {
  const hl = data['attachment.content'] || null
  const lines = useMemo(() => {
    return (hl || []).map(b => b.split("\n")).flat()
      .map(line => {
        const m = line.match(/^(?:(\d+)\s?)?(.*)$/)
        return m ? { num: +m[1] || 0, txt: m[2] || '' } : { num: 0, txt: '' }
      })
  }, [hl])
  const sections = useMemo(() => {
    return lines.reduce((sections, line, i) => {
      if (line.num === 0) {
        if (lines.length > i + 1) {
          line.num = lines[i + 1].num - 1
        }
      }
      const hl = line.txt.search(/<em>[^<]+<\/em>/) >= 0
      const s = sections[sections.length - 1] || null
      if (s === null) {
        return [{
          lines: [line],
          show: hl,
          hl,
        }]
      }
      if (s.hl === hl) {
        s.lines.push(line)
        return sections
      }
      sections.push({
        lines: [line],
        show: hl,
        hl,
      })
      return sections
    }, [] as Section[])
  }, [lines])
  const renderGapIfRelevant = (section, lineIndex) => {
    const s = section
    const i = lineIndex
    const line = section.lines[i]
    if (i > 0 && s.lines[i - 1].num >= 0 && (line.num - s.lines[i - 1].num) > 1) {
      return (<div className="gap"></div>)
    }
    return null
  }
  const [toggled, setToggled] = useState([] as number[])
  return sections.length ? (
    <CodeBlock>
      <div className="gutter">
        {sections.map((s, si) => (
          <Fragment key={`${si}`}>
            {!s.show && toggled.indexOf(si) < 0 && (
              <button
                onClick={() => setToggled([...toggled, si])}
                title={`Show ${s.lines.length} hidden lines`}
              ><span>&harr;</span></button>
            )}
            {(s.show || toggled.indexOf(si) >= 0) && s.lines.map((line, i) => (
              <Fragment key={`${line.num}-${i}`}>
                {renderGapIfRelevant(s, i)}
                <div className="num">{line.num <= 0 ? '' : line.num}</div>
              </Fragment>
            ))}
          </Fragment>
        ))}
      </div>
      <div className="content">
        {sections.map((s, si) => (
          <Fragment key={`${si}`}>
            {!s.show && toggled.indexOf(si) < 0 && (
              <button className="spacer">&nbsp;</button>
            )}
            {(s.show || toggled.indexOf(si) >= 0) && s.lines.map((line, i) => (
              <Fragment key={`${i}-${line.num}`}>
                {renderGapIfRelevant(s, i)}
                <div
                  className="code"
                  dangerouslySetInnerHTML={{
                    __html: s.hl ? escapeHtml(line.txt).replace(
                      /&lt;em&gt;([^&]+)&lt;\/em&gt;/g,
                      '<em>$1</em>'
                    ) : escapeHtml(line.txt)
                  }}
                />
              </Fragment>
            ))}
          </Fragment>
        ))}
      </div>
    </CodeBlock>
  ) : null;
};

export default HighlightViewer;

import React from 'react';
import { faFileArchive } from '@fortawesome/free-solid-svg-icons';

import AddIcon from '@extensions/utils/AddIcon';
import { DownloadOption } from '@extensions/models/FileOrder';
import FileOrderGroup from '@extensions/models/FileOrderGroup';

import OptionButton from '@extensions/components/order/options/OptionButton';

interface IZipOptionProps {
  order: FileOrderGroup;
}

const ZipOption: React.FC<IZipOptionProps> = ({ order }) => {
  const startZipping = (): void => {
    order.downloadZip();
  }

  return (
    <OptionButton
      startIcon={<AddIcon icon={faFileArchive} />}
      onClick={startZipping}
      order={order}
      option={DownloadOption.ZIP}
    >
      ZIP
    </OptionButton>
  )
}
export default ZipOption;

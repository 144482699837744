import AccessLevel from '@dapclient/models/AccessLevel';
import Organization from '@dapclient/models/Organization';
import ContactPoint from '@dapclient/models/ContactPoint';
import Reference from '@dapclient/models/Reference';
import AccessRestriction from '@extensions/models/AccessRestriction';
import { find } from 'lodash';
import Permission from '@extensions/models/Permission';
import { observable, action, makeObservable } from 'mobx';
import IControlledAccess, { Type } from '@extensions/models/IControlledAccess';
import IHasMetrics from '@extensions/models/IHasMetrics';
import { DownloadStat } from '@extensions/models/Metrics';

export default class Project implements IControlledAccess, IHasMetrics {
  identifier: string;
  participatingOrganizations: Organization[];
  datasetCount: number;
  accessLevel: AccessLevel;
  contactPoint: ContactPoint[];
  description: string;
  title: string;
  restriction: AccessRestriction;
  permissions: Permission[] = [];
  permissionLabel: string;
  references: Reference[];
  hasTimeSeries: boolean;
  /** abbreviation for the project name */
  name: string;
  /**Human readable name */
  shortName: string;
  citation: string | null | undefined;
  keywords: string[] | null;
  doiName: string | null | undefined;
  @observable orderFileCount: number | null = null;
  @observable orderByteCount: number | null = null;
  @observable orderCount: number | null = null;
  @observable views: number = 0;
  @observable downloadStats: DownloadStat[] = [];
  @observable dynamoFileCount: number | null = null;
  @observable dynamoTotalFileSize: number | null = null;

  constructor(data, name) {
    makeObservable(this);
    this.identifier = data.identifier;
    this.participatingOrganizations = data.participatingOrganizations
      ? data.participatingOrganizations.map((org) => new Organization(org))
      : [];
    this.datasetCount = data.datasetCount;
    this.accessLevel = data.accessLevel;
    this.contactPoint = data.contactPoint.map(
      (contact) => new ContactPoint(contact)
    );
    this.description = data.description;
    this.title = data.title;

    if (data.accessRestriction) {
      this.restriction = find(
        AccessRestriction.restrictions,
        (res: AccessRestriction) =>
          res.restrictionType === data.accessRestriction
      ) as AccessRestriction; // Can't ever be undefined?
    } else {
      this.restriction = AccessRestriction.restrictions.none;
    }
    this.permissions = AccessRestriction.getPermissions(
      this.restriction,
      '',
      this.identifier
    );
    this.permissionLabel = AccessRestriction.getPermissionLabel(
      this.restriction,
      '',
      this.identifier
    );

    this.name = name;
    this.shortName = data.shortName;
    this.citation = data.citation;
    this.keywords = data.keywords || null;
    this.doiName = data.doiName;
    this.references = data.references || [];
    this.hasTimeSeries = (data.datasetType || [])
      .filter(dt => dt.isTimeSeries).length > 0;
  }

  getPermissionLabel(): string {
    return this.permissionLabel;
  }
  getIdentifier(): string {
    return this.identifier;
  }
  getRestriction(): AccessRestriction {
    return this.restriction;
  }

  getPermissions(): Permission[] {
    return this.permissions;
  }
  getType(): Type {
    return Type.PROJECT;
  }

  @action setViews(views: number): void {
    this.views = views;
  }

  @action setDynamoFileCount(count: number): void {
    this.dynamoFileCount = count;
  }
  @action setDynamoTotalFileSize(size: number): void {
    this.dynamoTotalFileSize = size;
  }
  @action setChildCount(count: number): void {
    this.datasetCount = count;
  }
  getChildCount(): number | null {
    return this.datasetCount;
  }

  getOrderFileCount(): number | null {
    return this.orderFileCount;
  }
  getDownloadStats(): DownloadStat[] {
    return this.downloadStats;
  }

  getDynamoFileCount(): number | null {
    return this.dynamoFileCount;
  }
  getDynamoTotalFileSize(): number | null {
    return this.dynamoTotalFileSize;
  }
  getOrderByteCount(): number | null {
    return this.orderByteCount;
  }
  getOrderCount(): number | null {
    return this.orderCount;
  }

  getViews(): number {
    return this.views;
  }

  getHasTimeSeries(): boolean {
    return this.hasTimeSeries;
  }
}

import {
  FiltersWrapper,
  SEARCH_BAR_REACTIVE_ID,
} from '@extensions/components/search-core';
import MultiList from '@extensions/components/core/MultiList';
import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils';
import { Card, CardContent, Stack, styled } from '@mui/material';

const REACTIVE_LICENSE_ID = 'LicenseFilter';
const REACTIVE_LANGUAGE_ID = 'LanguageFilter';
const REACTIVE_ORG_ID = 'OrgFilter';
const REACTIVE_IDS = [
  SEARCH_BAR_REACTIVE_ID,
  REACTIVE_LICENSE_ID,
  REACTIVE_LANGUAGE_ID,
  REACTIVE_ORG_ID,
];

const allReactiveIdsExcept = getAllReactiveIdsExcept(REACTIVE_IDS);

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '& .data-filter': {
    marginBottom: theme.spacing(2),
  },
}));

const RepoFilters: React.FC = () => {
  return (
    <Card variant='outlined'>
      <StyledCardContent>
        <MultiList
          componentId={REACTIVE_ORG_ID}
          title="Organization"
          dataField="parent.raw"
          react={{
            and: allReactiveIdsExcept(REACTIVE_ORG_ID),
          }}
          maxLabelWidth="none"
          URLParams
          filterLabel="Org"
          className="data-filter"
        />
        <MultiList
          componentId={REACTIVE_LICENSE_ID}
          title="License"
          dataField="license.keyword"
          react={{
            and: allReactiveIdsExcept(REACTIVE_LICENSE_ID),
          }}
          maxLabelWidth="none"
          URLParams
          filterLabel="License"
          className="data-filter"
        />
        <MultiList
          componentId={REACTIVE_LANGUAGE_ID}
          title="Language"
          dataField="languages.keyword"
          react={{
            and: allReactiveIdsExcept(REACTIVE_LANGUAGE_ID),
          }}
          maxLabelWidth="none"
          URLParams
          filterLabel="Language"
        />
      </StyledCardContent>
    </Card>
  );
};

export default RepoFilters;

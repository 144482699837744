import React from 'react';
import { Alert, Button, ButtonProps, Stack, Tooltip, styled } from '@mui/material';

import {
  DownloadOption,
  reasonCantUseOption,
} from '@extensions/models/FileOrder';
import FileOrderGroup from '@extensions/models/FileOrderGroup';

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '50.25px',
  paddingTop: '.75rem',
  paddingBottom: '.75rem',
  backgroundColor: '#e0e0e0 !important',
}));

interface IOptionButtonProps
  extends Omit<ButtonProps, 'classes' | 'disabled'> {
  option: DownloadOption;
  order: FileOrderGroup;
}

const OptionButton: React.FC<IOptionButtonProps> = ({ option, order, ...otherProps }) => {
  const notAllowedTooltip = reasonCantUseOption(option, order);
  const disabled = Boolean(notAllowedTooltip);

  return (
    <Stack spacing={1}>
      <Tooltip title={notAllowedTooltip || ''}>
        <span tabIndex={0}>
          <StyledButton
            sx={{ backgroundColor: '#e0e0e0' }}
            size="large"
            variant="contained"
            disabled={disabled}
            {...otherProps}
          >
            {otherProps.children}
          </StyledButton>
        </span>
      </Tooltip>
  
      {disabled && notAllowedTooltip && <Alert severity="info">{notAllowedTooltip}</Alert>}
    </Stack>
  );
  
}

export default OptionButton

import React from 'react';
import { Box } from '@mui/material';

interface IUserFavoriteDatasetTabProps {
    userFavDatasets: string[] | undefined;
    children?: React.ReactNode;
    index: number;
    value: number;
    height: number;
}

const UserFavoriteDatasetTab: React.FC<IUserFavoriteDatasetTabProps> = (props) => {
    const { children, value, index, userFavDatasets, height, ...other } = props;
    const adjustHeight = () => {
        const hasDatasets = userFavDatasets && userFavDatasets.length > 0;
        const numDatasets = userFavDatasets ? userFavDatasets.length : 0;
        if (!hasDatasets || numDatasets === 0) {
            return height * 0.3;
        }
        return height * 0.8;
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`user-fav-dataset-${index}`}
            aria-labelledby={`fav-dataset-tab-${index}`}
            style={{
                height: adjustHeight(),
                overflowY: 'auto'
            }}
            {...other}
        >
            {value === index && <Box sx={{ mt: 3 }}>{children}</Box>}
        </div>
    );
}

export default UserFavoriteDatasetTab;
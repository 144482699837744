import {
    Typography,
    Link,
    styled
} from '@mui/material';
import React from 'react';

import theme from '@extensions/services/Theme';

const StyledLink = styled(Link)(({
    textDecoration: 'none',
    '&: hover': {
        textDecoration: 'underline'
    }
}));

interface IEmptyUserFavPage {
    isProject?: boolean,
}

const EmptyUserFavPage: React.FC<IEmptyUserFavPage> = ({ isProject }) => {
    const renderLink = () => {
        return isProject ? <StyledLink href='/projects/wind-energy'>Projects</StyledLink> : <StyledLink href='/data'>Data</StyledLink>
    }
    return (
        <>
            <Typography variant='body1' sx={{ mt: '1rem', color: theme.palette.text.secondary }}>
                No favorite {isProject ? 'projects' : 'datasets'} found.
            </Typography>
            <Typography variant='body1' sx={{ color: theme.palette.text.secondary }}>
                To select a {isProject ? 'project as your favorite project' : 'dataset as your favorite dataset'}, go to the&nbsp;
                {renderLink()} page to browse {isProject ? 'projects' : 'datasets'} and click the "Add To Favorites" button.
            </Typography>
        </>
    )
};


export default EmptyUserFavPage;
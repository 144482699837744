import React, { useState } from 'react';
import * as yup from 'yup';
import { observable } from 'mobx';
import { Field, Form, Formik } from 'formik';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Link,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';
import SetupMFAModal from '@extensions/components/user/SetupMFAModal';
import {
  useSecurityService,
  useContactUsService
} from '@extensions/hooks/useService';
import User from '@extensions/models/User';
import theme from '@extensions/services/Theme';

const StyledTextField = styled(TextField)(({
  '& .MuiInputBase-multiline': {
      color: "#000"
  },
}));

const StyledButton = styled(Button)({
  color: '#FFFFFF',
  width: '100%',
});

const fieldProps = observable({
  variant: 'outlined',
  fullWidth: true,
  margin: 'normal',
  size: 'small',
});

interface IWdhUserProfileFormProps {
  resetToken?: any;
  email?: any;
}

const WdhUserProfileForm: React.FC<IWdhUserProfileFormProps> = ({ email, resetToken }) => {
  const securityService = useSecurityService();
  const contactUsService = useContactUsService();
  const [mfaModalVisible, setMfaModalVisible] = useState(false);

  const getUser = (): User => {
    if (securityService && securityService.user) {
      return securityService.user;
    } else {
      const user = User.getGuestUser();
      if (email) {
        user.setEmail(email);
      }
      return user;
    }
  };

  const isResettingPassword = (): boolean => !!resetToken;
  const isRegistering = (): boolean => !getUser().authenticated;
  const isUpdatingProfile = (): boolean => !isResettingPassword() && !isRegistering();

  const handleSignOut = async () => {
    await securityService.logout();
    window.location.href = '/';
  };

  const getExtensionSchema = (): Record<string, yup.AnySchema> => {
    return {};
  };

  const transformValues = (values) => {
    const { firstname, lastname, ...otherValues } = values;
    if (otherValues.sshkey) {
      otherValues.sshkey = otherValues.sshkey.trim().replace(/\++$/g, '').trim();
    }
    return {
      ...otherValues,
      name_given: firstname,
      name_family: lastname,
    };
  }

  const getValidationSchema = (): any => {
    const passwordSchema = yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters.')
      .matches(
        /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w]|[&.-]).*$/,
        'Password must have uppercase, lowercase, symbol, and numeric characters.'
      );
    const confirmSchema = yup
      .string()
      .oneOf([yup.ref('password'), undefined], 'Passwords must match')
      .required('Please confirm your password.');

    const extensionSchema = getExtensionSchema();
    let schema;

    if (isResettingPassword()) {
      schema = yup.object({
        password: passwordSchema,
        confirm: confirmSchema,
      });
    } else if (isRegistering()) {
      schema = yup.object({
        firstname: yup.string().required('First Name is required'),
        lastname: yup.string().required('Last Name is required'),
        justification: yup.string().required('Justification is required'),
        email: yup
          .string()
          .email('Enter a valid email')
          .required('Email is required'),
        password: passwordSchema,
        confirm: confirmSchema,
        agreement: yup.boolean().oneOf([true], 'Please read the agreement.'),
        ...extensionSchema,
      });
    } else if (isUpdatingProfile()) {
      schema = yup.object({
        firstname: yup.string().required('First Name is required'),
        lastname: yup.string().required('Last Name is required'),
        justification: yup.string().required('Justification is required'),
        ...extensionSchema,
      });
    } else {
      console.error(
        'error getting validation schema for profile form, unknown state'
      );
    }
    return schema;
  }

  const getInitialFormData = (): object => {
    const user = getUser();
    return {
      firstname: user.firstname || '',
      lastname: user.lastname || '',
      orcId: user.orcId || '',
      justification: user.justification || '',
      email: user.email || '',
      password: '',
      confirm: '',
      agreement: user.agreed,
    };
  }

  const onFormSubmit = (values, { setSubmitting }) => {
    values = transformValues(values);
    if (securityService) {
      if (isResettingPassword()) {
        securityService.resetPassword(
          email,
          values.password,
          values.confirm,
          resetToken
        );
      } else if (isUpdatingProfile()) {
        delete values['password'];
        delete values['confirm'];
        delete values['email'];
        securityService.updateProfile(values);
      } else if (isRegistering()) {
        securityService.register(values);
      } else {
        console.error('error submitting profile form, unknown state');
      }
    }
    setSubmitting(false);
  };

  const renderField = (name: string, label: string, type = "text", multiline = false, placeholder = '') => (
    <Field name={name}>
      {({ field, form }) => (
        <StyledTextField
          type={type}
          label={label}
          {...field}
          {...fieldProps}
          multiline={multiline}
          rows={multiline ? 3 : 1}
          error={form.touched[name] && Boolean(form.errors[name])}
          placeholder={placeholder}
          helperText={form.touched[name] && form.errors[name]}
        />
      )}
    </Field>
  );

  const renderMFAButton = (user: User, contactUsService) => {
    if (process.env.REACT_APP_MFA_ENABLED !== 'true') return null;

    return !user.mfaEnabled && user.emailVerified ? (
      <Stack spacing={1} my={2}>
        <Button fullWidth onClick={() => setMfaModalVisible(true)} variant="contained" color="secondary">
          Enable Multifactor Authentication (MFA)
        </Button>
        <Typography variant="body2">
          MFA is required in order to access proprietary data.
        </Typography>
      </Stack>
    ) : (
      <Alert severity="success">
        <AlertTitle>MFA Configured</AlertTitle>
        <Typography variant="body2">
          Multifactor authentication is enabled for your account.
          {user.mfaNew && (
            <strong>
              You will need to log out and log back in using your newly configured MFA token in order to access resources that require a multifactor login.
            </strong>
          )}
          If you need to reset your MFA token, please{' '}
          <Link onClick={() => contactUsService?.openModal()}>
            contact us
          </Link>
          .
        </Typography>
      </Alert>
    );
  };

  const renderUsername = (user: User) => {
    return (
      <Typography sx={{ overflowWrap: 'break-word', color: theme.palette.grey[700] }}>Username: {user.username}</Typography>
    );
  };

  const renderEmail = (user: User) => {
    return (
      <Typography sx={{ overflowWrap: 'break-word', color: theme.palette.grey[700] }}>Email: {user.email}</Typography>
    );
  };

  const renderSshKeyField = () => {
    return (
        <Field
          component={TextField}
          name="sshkey"
          type="text"
          label="SSH Public Key"
          id="sshkey-input"
          multiline
          rows={3}
          placeholder={"SSH Public Key"}
          {...fieldProps}
        />
    );
  };

  const renderSubmitButton = (user: User, submitForm: () => void) => (
    <StyledButton fullWidth onClick={submitForm} variant="contained" sx={{backgroundColor: theme.palette.primary.main}}>
      {isResettingPassword() ? 'Reset Password' : user.authenticated && user.agreed ? 'Save Profile' : 'Register'}
    </StyledButton>
  );

  const renderSignOutButton = () => (
    <StyledButton fullWidth variant="contained" onClick={handleSignOut} sx={{backgroundColor: theme.palette.primary.main}}>
      Sign Out
    </StyledButton>
  );

  return (
    <Box>
      <SetupMFAModal visible={mfaModalVisible} onCancel={() => setMfaModalVisible(false)} />
      
      <Formik
        initialValues={getInitialFormData()}
        validationSchema={getValidationSchema()}
        onSubmit={onFormSubmit}
      >
        {({ submitForm }) => (
          <Form>
            {renderMFAButton(getUser(), contactUsService)}
            {renderUsername(getUser())}  
            {renderEmail(getUser())}
            {renderField("firstname", "First Name*")}
            {renderField("lastname", "Last Name*")}
            {renderField("orcId", "ORCID")}
            {renderField("justification", "Justification*", "text", true, "Identify which EEMS project you are working on.")}
            {renderSshKeyField()}
            <Typography variant='body2'>* indicates a required field</Typography>
            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="center" mt={2}>
              {renderSubmitButton(getUser(), submitForm)}
              {renderSignOutButton()}
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default WdhUserProfileForm;

import React from 'react';
import { Box } from '@mui/material';

import Project from '@extensions/models/Project';
import ProjectResultCard from '@extensions/components/project-search/ProjectResultCard';

interface IPopularProjectListProps {
    projects: Project[];
};

export const PopularProjectList: React.FC<IPopularProjectListProps> = ({ projects }) => {

    return (
        <Box>
            {
                projects.map((project, i) => <ProjectResultCard project={project} key={i}/>)
            }
        </Box>
    );
};

export default PopularProjectList;
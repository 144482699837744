import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    CardActions,
    Divider,
    Link,
    Chip,
    Stack
} from '@mui/material';
import {
    faHandshake,
    faNewspaper,
    faChartBar
} from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

import AddIcon from '@extensions/utils/AddIcon';
import { useMetricsService } from '@extensions/hooks/useService';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

const StyledLink = styled(Link)(({
    textDecoration: 'none',
    '&: hover': {
        textDecoration: 'underline'
    },
    zIndex: 999
}));

const StyledAddIcon = styled(AddIcon)(({ theme }) => (({
    color: theme.palette.primary.dark,
    height: theme.spacing(5.5),
})));

const StyledBox = styled(Box)(({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '-4rem'
}));

interface IHomeMetricsProps { };

const HomeMetrics: React.FC<IHomeMetricsProps> = () => {

    const [projectsCount, setProjectsCount] = useState(0);
    const [datasetsCount, setDatasetsCount] = useState(0);
    const [publicationsCount, setPublicationsCount] = useState(0);
    const [fileCount, setFileCount] = useState(0);
    const [fileSizeStat, setFileSizeStat] = useState({
        symbol: '',
        value: 0
    });
    const [loading, setLoading] = useState(true);

    const metricsService = useMetricsService();

    useEffect(() => {
        const loadMetrics = async () => {
            if (metricsService) {
                await metricsService.loadHomeMetrics();
                const { metrics } = metricsService;

                if (metrics) {
                    setProjectsCount(metrics.projectCount || 0);
                    setDatasetsCount(metrics.datasetCount || 0);
                    setPublicationsCount(metrics.publicationStats?.totalNumPubs || 0);
                    setFileCount(metrics.fileCount || 0);
                    setFileSizeStat({
                        symbol: metrics.fileSizeStat.symbol,
                        value: metrics.fileSizeStat.value,
                    });
                } else {
                    console.error('Metrics not available');
                }
                setLoading(false);
            }
        };
        loadMetrics();
    }, [metricsService]);

    const formatNumber = (inputNum: number): string => {
        const thresholds = [
            { value: 1e9, suffix: 'b+' },
            { value: 1e6, suffix: 'm+' },
            { value: 1e3, suffix: 'k+' },
        ];
    
        for (const { value, suffix } of thresholds) {
            if (inputNum >= value) {
                return `${(inputNum / value).toFixed(1)}${suffix}`;
            }
        }
    
        return inputNum.toString();
    };

    const renderLoadingNumber = (loading, numberValue, faIcon) => {
        if (loading) {
            return (
                <div>
                    <CenteredCircularProgress />
                    <Typography variant='body1'
                        sx={{
                            fontSize: '4.5rem',
                            mt: '-1rem',
                            visibility: 'hidden',
                            mb: '-2rem'
                        }}
                    >{formatNumber(numberValue)}</Typography>
                </div>
            )
        } else {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <StyledAddIcon icon={faIcon} />
                    <Typography
                        variant='body1'
                        sx={{
                            fontSize: '5rem',
                            mt: '-1rem',
                            mb: '-2rem'
                        }}
                    >{formatNumber(numberValue)}</Typography>
                    <div style={{ visibility: 'hidden' }}>
                        <CenteredCircularProgress />
                    </div>
                </div>
            )
        }
    }

    const formatNumberToMillion = (input) => {
        if (input >= 1000 && input <= 999999) {
            return `${Math.round(parseFloat(input) / 1e3)}k`
        } else if (input >= 1000000) {
            return `${Math.round(parseFloat(input) / 1e6)} Million`
        } else {
            return `${Math.round(parseFloat(input))} `
        }
    }

    return (
        <Box sx={{ flexGrow: 1, mt: '2rem', mb: '1rem' }}>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12} md={4}>
                    <Card variant='outlined'>
                        <CardContent>
                            <Typography sx={{ color: '#000' }}>Projects</Typography>
                            <StyledBox>
                                {renderLoadingNumber(loading, projectsCount, faHandshake)}
                            </StyledBox>
                        </CardContent>
                        <Divider orientation="horizontal" sx={{ borderRightWidth: 2, borderColor: 'grey', mt: '-1rem' }}></Divider>
                        <CardActions sx={{ display: 'flex', justifyContent: 'center', pt: '1rem', pb: '1rem' }}>
                            <StyledLink href='/projects/wind-energy'>Browse Projects</StyledLink>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card variant='outlined'>
                        <CardContent sx={{ position: 'relative' }}>
                            <Typography sx={{ color: '#000' }}>Datasets</Typography>
                            <Stack
                                direction="column"
                                spacing={0.5}
                                sx={{
                                    justifyContent: "flex-start",
                                    alignItems: "flex-end",
                                    position: 'absolute',
                                    top: '0.5rem',
                                    right: '0.5rem'
                                }}
                            >
                                <Chip
                                    size="small"
                                    label={`${formatNumberToMillion(fileCount)} Files`}
                                    sx={{ bgcolor: '#80c44c', color: '#fff' }}
                                />
                                <Chip
                                    size="small"
                                    label={`${fileSizeStat.value.toFixed(0)} ${fileSizeStat.symbol} Stored`}
                                    sx={{ bgcolor: '#80c44c', color: '#fff' }}
                                />
                            </Stack>
                            <StyledBox>
                                {renderLoadingNumber(loading, datasetsCount, faChartBar)}
                            </StyledBox>
                        </CardContent>
                        <Divider orientation="horizontal" sx={{ borderRightWidth: 2, borderColor: 'grey', mt: '-1rem' }}></Divider>
                        <CardActions sx={{ display: 'flex', justifyContent: 'center', pt: '1rem', pb: '1rem' }}>
                            <StyledLink href='/data'>Browse Datasets</StyledLink>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card variant='outlined'>
                        <CardContent>
                            <Typography sx={{ color: '#000' }}>Publications</Typography>
                            <StyledBox>
                                {renderLoadingNumber(loading, publicationsCount, faNewspaper)}
                            </StyledBox>
                        </CardContent>
                        <Divider orientation="horizontal" sx={{ borderRightWidth: 2, borderColor: 'grey', mt: '-1rem' }}></Divider>
                        <CardActions sx={{ display: 'flex', justifyContent: 'center', pt: '1rem', pb: '1rem' }}>
                            <StyledLink href='/publications'>Browse Publications</StyledLink>
                        </CardActions>
                    </Card>
                </Grid>

            </Grid>
        </Box>
    )
}

export default HomeMetrics;
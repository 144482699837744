import React from 'react';
import { Typography } from '@mui/material';

import Link from '@extensions/components/core/Link';

const WDHNotificationText: React.FC = () => {
    return (
        <Typography
            variant='body1'
            sx={{
                fontWeight: 800,
                color: 'rgb(86, 85, 85)',
            }}
        >
            The Wind Data Hub (WDH), also known as the Data Archive and Portal (DAP),
            was established under the Atmosphere to Electrons Initiative and has since
            expanded to offer more observational and model data, supporting both the
            user community and WETO’s mission. Learn more about WDH <Link to="/about/wdh">here</Link>.
        </Typography>
    )
}

export default WDHNotificationText;
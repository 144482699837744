import {
  TextField,
  Typography,
  Rating
} from '@mui/material';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import React, { useState, MouseEvent } from 'react';
import { useContactUsService, useSecurityService } from '@extensions/hooks/useService';

const StyledDiv = styled('div')(({
  justifyContent: 'center',
  width: '25rem',
}));

const StyledTypography = styled(Typography)({
  fontSize: '1.25rem',
});

const StyledButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto',
  backgroundColor: theme.palette.primary.main,
  width: '90px',
  height: '40px',
  borderRadius: '10px',
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  "&:active": {
    outline: 'none',
    border: 'none',
  }
}));

const StyledTextField = styled(TextField)({
  marginBottom: '1rem',
});

const StyledWarning = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

const StyledText = styled('div')(({ theme }) => ({
  color: 'white',
  fontSize: theme.spacing(2),
}));

const FeedbackForm: React.FC = observer(() => {
  const contactUsService = useContactUsService();
  const securityService = useSecurityService()
  const [feedbackMsgA, setFeedbackMsgA] = useState('');
  const [feedbackMsgB, setFeedbackMsgB] = useState('');
  const [rating, setRating] = useState(-1);
  const [submitted, setSubmitted] = useState(false);
  const [required, setRequired] = useState(false);

  const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (rating === -1) {
      setRequired(true);
    } else {
      setSubmitted(true);
      setRequired(false);
      if (contactUsService && securityService) {
        contactUsService.submitMessage({
          emailAddress: securityService?.user ? securityService?.user.email : '',
          subject: 'Order Page User Feedback',
          message: `Rating: ${rating}
          \n\n ## Tell us how this data will impact your work: 
          \n${feedbackMsgA}
          \n\n ## Tell us how to improve your experience: 
          \n${feedbackMsgB}`,
          showSuccessNotification: true,
        });
      }
    }
  };

  return (
    <StyledDiv>
      {submitted ? (
        <StyledTypography>Thank you for your feedback!</StyledTypography>
      ) : (
        <>
          <StyledTypography>
            Rate your experience with discovering and ordering data
          </StyledTypography>
          <Rating
            onChange={(event, value) => setRating(value ?? -1)}
            style={{ fontSize: '3rem' }}
            name="rating"
            defaultValue={0}
            precision={1}
          />
          {required && <StyledWarning>*Rating Required</StyledWarning>}
          <StyledTextField
            onChange={(event) => setFeedbackMsgA(event.target.value)}
            type="text"
            label="Tell us how this data will impact your work."
            multiline
            minRows={3}
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 500 }}
          />
          <StyledTextField
            onChange={(event) => setFeedbackMsgB(event.target.value)}
            type="text"
            label="Tell us how to improve your experience."
            multiline
            minRows={3}
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 500 }}
          />
          <StyledButton onClick={handleMouseEvent}>
            <StyledText>Submit</StyledText>
          </StyledButton>
        </>
      )}
    </StyledDiv>
  );
});

export default FeedbackForm;

import React from 'react';
import { Card } from '@mui/material';
import { react } from '@appbaseio/reactivesearch/lib/types';

import MultiList from '@extensions/components/core/MultiList';

export const FILE_TYPE_REACTIVE_ID = 'FileTypeFilter';

interface FileTypeFilterProps {
  react?: react;
}

const FileTypeFilter: React.FC<FileTypeFilterProps> = ({ react }) => {
  return (
    <MultiList
      componentId={FILE_TYPE_REACTIVE_ID}
      dataField="dapFileSummary.types.keyword"
      title="File Type"
      filterLabel="File Type"
      showSearch={false}
      react={react}
      URLParams={true}
    />
  );
};

export default FileTypeFilter;

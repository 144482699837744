import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  styled,
  Box,
  Modal,
  Typography,
  Stack,
  IconButton,
  Slide,
} from '@mui/material';
import { faLockOpen, faLock, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import theme from '@extensions/services/Theme';
import AddIcon from '@extensions/utils/AddIcon';
import Link from '@dapclient/components/core/Link';
import CartLink from '@extensions/components/nav/CartLink';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSecurityService } from '@extensions/hooks/useService';
import SignInForm from '@extensions/components/nav/SignInForm';

const StyledRootDiv = styled('div')(({
  display: 'flex',
  flexDirection: 'column',
  padding: '0.5rem 1.3rem',
  lineHeight: 2.5,
  background: theme.palette.secondary.light,
  color: 'rgb(238, 238, 238)',
  alignItems: 'left',
  fontSize: '20px',
}));

const StyledModalContainer = styled(Box)({
  position: 'fixed',
  bottom: '0',
  left: '0',
  right: '0',
  borderRadius: '10px 10px 0px 0px',
  backgroundColor: '#fff',
  padding: 'clamp(1rem, 5vw, 2rem)',
  boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
  maxHeight: '90%',
  overflowY: 'auto',
});

const StyledLinks = styled('a')(({ theme }) => ({
  textDecoration: 'underline',
  fontSize: '1.1rem',
  fontFamily: 'sans-serif',
  cursor: 'pointer',
  color: theme.palette.secondary.main,
  marginTop: '0.5em'
}));

export interface IHeaderMenuSRProps {
  className?: string;
}

const HeaderMenuSR:React.FC = () => {
  const securityService = useSecurityService();

  const { user } = securityService;

  const loggedIn = Boolean(user && user.authenticated);
  const withMfa = Boolean(loggedIn && user?.authenticatedMfa);

  const firstName = user !== null ? user.firstname : "";
  const lastName = user !== null ? user.lastname : "";

  const [openModal, setOpenModal] = useState(false);

  const LinkStyleObject = {
    display: 'flex',
    alignItems: 'left',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
    },
    '&:visited': {
      color: '#eee',
    },
  };

  const handleLoginSuccess = () => {
    setOpenModal(false);
  };

  const handleContinueAsGuest = () => {
    setOpenModal(false);
  }

  const handleSignOut = async () => {
    await securityService.logout();
    window.location.href = '/'; 
  };

  return (
    <>
      <StyledRootDiv>
        {loggedIn ? (
          <>
            <Link to="/profile" sx={LinkStyleObject}>
              {`${firstName} ${lastName} `}
              <AddIcon
                icon={withMfa ? faLock : faLockOpen}
                title={
                  withMfa
                    ? 'Logged in with MFA token'
                    : 'NOT logged in with MFA token'
                }
              />
            </Link>
            <Box
              sx={{
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.common.white,
                cursor: 'pointer',
                textAlign: 'left',
                display: 'flex',
                alignItems: 'center',
                mt: 1,
              }}
              onClick={handleSignOut}
            >
              Sign Out 
              <AddIcon icon={faSignOutAlt} />
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                ...LinkStyleObject,
                cursor: 'pointer',
                mb: 1,
              }}
              onClick={() => setOpenModal(true)}
            >
              Sign In
            </Box>
            <Link to="/register" sx={LinkStyleObject}>
              Register
            </Link>
          </>
        )}
        <Box sx={{ mt: 2 }}>
          <CartLink styleObject={{ fontSize: '2.5rem', color: theme.palette.common.white }} />
        </Box>
      </StyledRootDiv>
      
      <Modal open={openModal} onClose={() => setOpenModal(false)} closeAfterTransition>
        <Slide direction="up" in={openModal} timeout={300}>
          <StyledModalContainer>
            <Stack spacing={1} sx={{ px: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <IconButton
                  onClick={() => setOpenModal(false)}
                >
                  <ArrowBackIcon fontSize="medium" />
                </IconButton>
              </Box>
              <SignInForm onLoginSuccess={handleLoginSuccess} />

              <Box>
                <StyledLinks
                  onClick={() => {
                    setOpenModal(false);
                    window.location.href = '/register';
                  }}
                >
                  Sign up here
                </StyledLinks>
              </Box>
              <Typography variant="caption">
                It’s quick and easy, and we can provide a better experience when we know your
                interests.
              </Typography>
              
              <Box>
                <StyledLinks
                  color="primary"
                  onClick={handleContinueAsGuest}
                  sx={{ display: 'flex' }}
                >
                  Continue as a guest*
                </StyledLinks>
              </Box>
              <Typography variant="caption" sx={{ mt: 1 }}>
                *Some datasets won’t be downloadable without an account
              </Typography>
            </Stack>
          </StyledModalContainer>
        </Slide>
      </Modal>
    </>
  );
};

export default observer(HeaderMenuSR);

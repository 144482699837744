import React from 'react';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
}))

export interface IStatProps {
  value: React.ReactNode;
  description: React.ReactNode;
}

const Stat: React.FC<IStatProps> = ({ value, description }) => {
  return (
    <>
      <StyledDiv>
        <div>
          {description}
        </div>
        <div>
          {value}
        </div>
      </StyledDiv>
      <hr />
    </>
  );
}

export default Stat;

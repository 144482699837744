import React from 'react';

import { styled, useMediaQuery } from '@mui/material';

import theme from '@extensions/services/Theme';
import WdhHeaderLogo from '@extensions/resources/WDH Logo.png';

import HeaderMenu from '@extensions/components/nav/HeaderMenu';
import ResponsiveSR from '@extensions/components/nav/ResponsiveSR';
import SignInAndRegister from '@extensions/components/nav/SignInAndRegister';

const StyledTopBarDiv = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
	width: '100%',
	display: 'flex',
	justifyContent: isMobile ? 'center' : 'space-between',
}));

const StyledMenuWrapperDiv = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	background: theme.palette.primary.main,
	marginTop: isMobile ? '2rem' : '0',
	boxShadow: '0 5px 10px rgba(0, 0, 0, 0.225)'
}));

const StyledRootDiv = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	background: '#fff',
}));

const StyledHeaderMenuDiv = styled('div')(({}));

const StyledWDHTag = styled('a')(({
	maxWidth: '100%',
	height: '100px',
}));

const StyledSRDiv = styled('div')(({
	marginTop: '0.7rem',
	marginRight: '1.5rem'
}));

const StyledImg = styled('img')(({
	marginTop: '1.5rem',
	marginLeft: '1.5rem',
	marginBottom: '0.85rem',
	width: '220px',
	height: '50px',
}));

interface IHeaderProps {
	currentPathName: string;
}

const Header: React.FC<IHeaderProps> = ({ currentPathName }) => {

	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<StyledRootDiv>
			<StyledTopBarDiv isMobile={isMobile}>
				<StyledWDHTag href="/">
					<StyledImg alt="WDH Logo" src={WdhHeaderLogo} />
				</StyledWDHTag>
				<StyledSRDiv>
					<ResponsiveSR desktop={<SignInAndRegister />} mobile={<></>} />
				</StyledSRDiv>
			</StyledTopBarDiv>
			<StyledMenuWrapperDiv isMobile={isMobile}>
				<StyledHeaderMenuDiv>
					<HeaderMenu currentPathName={currentPathName} />
				</StyledHeaderMenuDiv>
			</StyledMenuWrapperDiv>
		</StyledRootDiv>
	)
}

export default Header;
import React from 'react';
import * as Yup from 'yup';
import { 
  useSecurityService, 
  useContactUsService, 
} from '@extensions/hooks/useService';
import { 
  Button, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  styled, 
  Typography 
} from '@mui/material';
import { observer } from 'mobx-react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';

const StyledModalTitle = styled(Typography)(({
  fontSize: '1.75rem',
}));

const StyledButtonDiv = styled('div')(({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}))

const StyledEmailField = styled(TextField)(({
  margin: '0.75rem 0',
}))

const StyledMessageField = styled(TextField)(({
  marginBottom: '1rem',
  '& .MuiOutlinedInput-root': {
    padding: '1rem',
  }
}))

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#FFFFF'
}));

const StyledSubmitText = styled('div')(({
  color: 'white'
}))

const FeedbackSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Required'),
  message: Yup.string().required('Required'),
});

const ContactModal: React.FC = observer(() => {
  const securityService = useSecurityService();
  const contactUsService = useContactUsService();

  return (
    <Dialog
      open={contactUsService.showModal}
      onClose={contactUsService.closeModal}  
      aria-labelledby="form-dialog-title"
    >

      <DialogTitle id="form-dialog-title">
        <StyledModalTitle>{contactUsService.modalTitle}</StyledModalTitle>
        <Typography variant="subtitle2">
          {contactUsService.modalSubTitle || ''}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            email: securityService.user ? securityService.user.email : '',
            message: '',
          }}
          validationSchema={FeedbackSchema}
          onSubmit={(values, { setSubmitting }) => {
            contactUsService.closeModal();
            contactUsService.submitMessage({
              emailAddress: values.email,
              message: values.message,
            });
            setSubmitting(false);
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Field
                component={StyledEmailField}
                name="email"
                type="email"
                label="Email"
                variant="outlined"
                fullWidth
                id="email-input"
                autoComplete="email" 
              />
              <Field
                component={StyledMessageField}
                name="message"
                type="text"
                id="message-input"
                label="Message"
                multiline
                rows={10}
                variant="outlined"
                autoComplete="off"
                fullWidth
              />
              <StyledButtonDiv>
                <Button
                  onClick={contactUsService.closeModal}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <StyledSubmitButton
                  onClick={() => !isSubmitting && submitForm()}
                  variant="contained"
                >
                  <StyledSubmitText>Submit</StyledSubmitText>
                </StyledSubmitButton>
              </StyledButtonDiv>
            </Form>
          )}
        </Formik>
      </DialogContent> 

    </Dialog>
  );
});

export default ContactModal;

import React from 'react';
import { inject, observer } from 'mobx-react';
import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ICartService } from '@extensions/services/ICartService';
import { FilterType } from '@dapclient/models/Dataset';
import DefaultExtensionFilter from '@extensions/components/cart/DefaultExtensionFilter';
import AutocompleteExtensionFilter from '@extensions/components/cart/AutocompleteExtensionFilter';

const StyledAutocompleteExtensionFilter = styled(AutocompleteExtensionFilter)(() => ({
  marginTop: '0.5rem',
}));

export interface IExtensionsFiltersProps {
  className?: string;
  cartService?: ICartService;
  styleObject?: any;
  label?: string;
}

@inject('cartService')
@observer
class ExtensionsFilters extends React.Component<IExtensionsFiltersProps> {
  render() {
    const { cartService, styleObject, label } = this.props;
    if (!cartService || !cartService.datasets || !label) {
      return null;
    }

    let filterType: FilterType | undefined;
    const valuesSet = new Set<string>();

    for (const dataset of cartService.datasets) {
      for (const fileExtension of dataset.dynamoFileExtensions) {
        if (fileExtension.label === label) {
          if (fileExtension.filterType === FilterType.HIDE) continue;
          if (!filterType && fileExtension.filterType) {
            filterType = fileExtension.filterType;
          }
          for (const val of fileExtension.valuesInUse) {
            valuesSet.add(val);
          }
        }
      }
    }

    if (valuesSet.size === 0) {
      return null;
    }

    const valuesArray = Array.from(valuesSet).sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true })
    );

    const filterProps = {
      toggle: (value: string) => cartService.toggleExtensionValue({ label, value }),
      selected: cartService.extensionsFilter.get(label) || new Set(),
      values: valuesArray,
    };

    let filterElement: React.ReactNode;
    switch (filterType) {
      case FilterType.AUTOCOMPLETE:
        filterElement = (
          <StyledAutocompleteExtensionFilter
            {...filterProps}
            label={label}
          />
        );
        break;
      case FilterType.DEFAULT:
      default:
        filterElement = (
          <DefaultExtensionFilter
            {...filterProps}
            styleObject={styleObject?.optionsList || {}}
          />
        );
        break;
    }

    return (
      <FormControl
        component="div"
        sx={
          styleObject?.filterBlock
            ? { ...styleObject.filterBlock, width: '100%' }
            : { width: '100%' }
        }
      >
        {filterElement}
      </FormControl>
    );
  }
}

export default ExtensionsFilters;

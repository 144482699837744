import { Link, styled } from '@mui/material';
import { SearchBar, SelectedFilters, MultiList } from '@extensions/components/search-core';

export const StyledSearchBar = styled(SearchBar)(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
}));

export const StyledSelectedFilters = styled(SelectedFilters)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const StyledMultiList = styled(MultiList)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const HitWrapper = styled('li')`
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const StyledLink = styled(Link)({
  textDecoration: 'none',
});

export const CodeBlock = styled('div')`
  font-family: monospace;
  display: grid;
  grid-template-columns: minmax(0, min-content) auto;
  border: 1px solid #ddd;
  border-radius: 3px;
  max-height: 500px;
  overflow: auto;

  .gutter {
    text-align: right;
    background-color: #eee;
    border-right: 1px solid #ccc;
    color: #777;
  }

  .content {
    overflow-x: auto;
    background-color: #f4f4f4;

    .code {
      white-space: pre;
    }

    em {
      font-weight: bold;
      font-style: normal;
      background-color: rgba(255, 255, 0, 0.5);
    }
  }

  .gutter, .content {
    padding: 3px 5px;
    font-size: 10px;

    & > div {
      min-height: 16px;
    }
  }

  .gap {
    min-height: 10px !important;
    height: 10px !important;
    margin: 3px -5px;
  }

  .content .gap {
    background-color: #fff;
    border: 0 solid #ccc;
    border-width: 1px 0;
  }

  button {
    text-align: center;
    cursor: pointer;
    border-radius: 15px;
    border: none;
    background-color: #fff;
    padding: 2px 5px;
  }
  button:hover {
    background-color: #aaa;
    color: #fff;
  }
  button.spacer {
    visibility: hidden;
  }
  button > span {
    display: inline-block;
    rotate: 90deg;
  }
`

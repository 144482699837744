import { styled } from '@mui/material/styles';
import { Menu, MenuItem } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';
import React, { useState, useCallback } from 'react';
import { react } from '@appbaseio/reactivesearch/lib/types';

import MultiList from '@extensions/components/core/MultiList';

import theme from '@extensions/services/Theme';

interface SortableMultiListProps {
    fixedValue?: string;
    plainTitle?: boolean;
    react?: react;
    componentId: string;
    title: string;
    dataField: string;
    filterLabel: string;
    className?: string;
}

const StyledTitleDiv = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '30px',
});

const SortableMultiList: React.FC<SortableMultiListProps> = (
    { title, fixedValue, componentId, dataField, react, filterLabel }
) => {
    const [sort, setSort] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const handleMenuOpen = (event: React.MouseEvent<SVGSVGElement>) => {
        setAnchorEl(event.currentTarget as unknown as HTMLElement);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleSelectionChange = (selectedValues: string[]) => {
        setSelectedItems(selectedValues)
    }

    const sortedItems = (items) => {
        if (sort) {
            return items.sort((a, b) => a.key.localeCompare(b.key));
        } else {
            return items.sort((a, b) => b.doc_count - a.doc_count);
        }
    }

    const renderTitle = useCallback(() => (
        <StyledTitleDiv>
            <p>{title}</p>
            <FilterAlt onClick={(e) => handleMenuOpen(e)} sx={{ cursor: 'pointer', fontSize: '1.5rem' }} />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem
                    onClick={() => {
                        setSort(false);
                        handleMenuClose();
                    }}
                >
                    Sort by Count
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setSort(true);
                        handleMenuClose();
                    }}
                >
                    Sort Alphabetically
                </MenuItem>
            </Menu>
        </StyledTitleDiv>
    ), [anchorEl, title]);

    return (
        <div style={fixedValue ? { 'display': 'none' } : { marginBottom: theme.spacing(2) }}>
            <MultiList
                componentId={componentId}
                title={renderTitle()}
                dataField={dataField}
                react={react}
                maxLabelWidth="none"
                URLParams={fixedValue ? !Boolean(fixedValue) : true}
                showFilter={fixedValue ? !Boolean(fixedValue) : true}
                filterLabel={filterLabel}
                sortBy={sort ? 'asc' : 'count'}
                size={1000}
                onChange={(values) => handleSelectionChange(values)}
                transformData={sortedItems}
                value={selectedItems}
                defaultValue={fixedValue ? [fixedValue] : undefined}
            />
        </div>
    )
}

export default SortableMultiList;
import HighlightViewer from '@extensions/components/code-search/code/HighlightViewer';
import { HitWrapper, StyledLink } from '@extensions/components/code-search/code/codeStyles';

interface CodeResult {
  file_name: string;
  file_path: string;
  file_url: string;
  repo_full_name: string;
  repo_url: string;
  highlight: { 'attachment.content'?: string[] };
  node_id: string;
}

interface ICodeResultHitProps {
  data: CodeResult
}

const CodeResultHit: React.FC<ICodeResultHitProps> = ({ data }) => {
  const dir = data.file_path.split('/').slice(0, -1).join(' / ')

  return (
    <HitWrapper>
      <StyledLink className="path" href={data.file_url} target="_blank" rel="noreferrer">
        {data.file_name}
      </StyledLink>
      <HighlightViewer data={data.highlight} />
      <div className="path">
        <small>
          Path:&nbsp;
        </small>
        <small>
          /&nbsp;{dir}
        </small>
      </div>
      <div className="repo">
        <small>
          Repository:&nbsp;
        </small>
        <small>
          <StyledLink href={data.repo_url} target="_blank" rel="noreferrer">
            {data.repo_full_name}
          </StyledLink>
        </small>
      </div>
    </HitWrapper>
  );
};

export default CodeResultHit;

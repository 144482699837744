import {
  Alert,
  AlertTitle,
  Box,
  Modal,
  Typography,
  Button,
  styled,
  useMediaQuery
} from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Redirect, RouteComponentProps, useLocation, withRouter } from 'react-router-dom';

import SignInForm from '@extensions/components/nav/SignInForm';
import WDHHeaderLogo from '@extensions/resources/WDH Logo.png';

import User from '@extensions/models/User';
import theme from '@extensions/services/Theme';
import { IHistoryService } from '@extensions/services/IHistoryService';
import { ISecurityService } from '@extensions/services/ISecurityService';

const StyledSignUpLink = styled('a')(() => ({
  color: '#fff',
  cursor: 'pointer',
  textDecoration: 'underline',
  fontSize: '1.20rem',
  fontFamily: 'sans-serif',
}));

const StyledModalContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => {
  if (!isMobile) {
    return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '900px',
      minHeight: '500px',
      display: 'flex',
      boxShadow: '0 6px 18px rgba(0,0,0,0.3)',
      borderRadius: '12px',
      overflow: 'hidden',
    }
  }
  return {
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    borderRadius: '10px 10px 0px 0px',
    backgroundColor: '#fff',
    boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
    maxHeight: '70%',
    overflowY: 'auto',
  }
}
);
const StyledLeftColumn = styled(Box)(() => ({
  flex: 1,
  backgroundColor: '#fff',
  padding: '3rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledWDHATag = styled('a')({
  maxWidth: '100%',
  height: '100px',
});

const StyledImg = styled('img')({
  marginTop: '1.5rem',
  marginLeft: '1.5rem',
  marginBottom: '0.85rem',
  width: '220px',
  height: '50px',
});

const StyledRightColumn = styled(Box)(() => ({
  flex: 1,
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  padding: '3rem',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledTitle = styled(Typography)(() => ({
  fontFamily: 'sans-serif',
  fontSize: '2rem',
  fontStyle: 'italic',
  fontWeight: 'bold',
  marginBottom: '1.5rem',
  textAlign: 'center',
}));

const StyledSubTitle = styled(Typography)(() => ({
  fontFamily: 'sans-serif',
  fontSize: '1.1rem',
  fontStyle: 'italic',
  lineHeight: 1.4,
  marginBottom: '2.5rem',
  textAlign: 'center',
}));

export interface ISignInProps extends RouteComponentProps {
  securityService?: ISecurityService;
  historyService?: IHistoryService;
  location: any;
}

const SignIn: React.FC<ISignInProps> = ({ securityService, historyService }) => {
  const [openModal, setOpenModal] = useState(true);
  const [willRedirect, setWillRedirect] = useState(false);
  
  const currentLocation = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const user: User | null | undefined = securityService?.user;
  const loggedIn = Boolean(user && user.authenticated);

  useEffect(() => {
    if (securityService && historyService) {
      setWillRedirect(securityService.checkForCallback(currentLocation.search));
      if (currentLocation.state && currentLocation.state['pathname']) {
        historyService.setRedirectPath(currentLocation.state['pathname']);
        if (currentLocation.state['search']) {
          historyService.setRedirectFilter(currentLocation.state['search']);
        }
        if (currentLocation.state['hash']) {
          historyService.setRedirectHash(currentLocation.state['hash']);
        }
      }
    }
  }, [securityService, historyService, currentLocation]);

  useEffect(() => {
    if (openModal === false && !loggedIn) {
      window.location.href = '/';
    }
  }, [openModal, loggedIn])

  const handleSignUp = () => {
    window.location.href = '/register';
  };

  const handleContinueAsGuest = () => {
    setOpenModal(false);
    window.location.href = '/';
  }

  if (user && user.authenticated) {
    if (willRedirect) {
      return (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          You have successfully signed in. Redirecting...
        </Alert>
      );
    }
    return (
      <Redirect
        to={{
          pathname: historyService?.redirectPath || '/profile',
          search: historyService?.redirectFilter || '',
          hash: historyService?.redirectHash || '',
        }}
      />
    );
  }

  return (
    <>
      <Modal
        open={openModal}
        disableEnforceFocus={true}
        onClose={() => setOpenModal(false)}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0,0,0,0.3)',
            backdropFilter: 'blur(6px)',
          },
        }}
      >
        <StyledModalContainer isMobile={isMobile}>
          <StyledLeftColumn>
            <Box sx={{ mb: 3, textAlign: 'center' }}>
              <StyledWDHATag href="/">
                <StyledImg alt="WDH Logo" src={WDHHeaderLogo} />
              </StyledWDHATag>
            </Box>
            <SignInForm onLoginSuccess={() => setOpenModal(false)} />
          </StyledLeftColumn>
          <StyledRightColumn>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <StyledTitle>Don’t have a login?</StyledTitle>
              <Box sx={{ mb: 3 }}>
                <StyledSignUpLink onClick={handleSignUp}>
                  Sign up here!
                </StyledSignUpLink>
              </Box>
              <StyledSubTitle>
                It’s quick and easy and we can provide a better experience when
                we know your interests.
              </StyledSubTitle>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                variant="text"
                onClick={handleContinueAsGuest}
                sx={{
                  color: '#fff',
                  textTransform: 'none',
                  fontSize: '1rem',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                Continue as guest
                <Box component="span" sx={{ fontWeight: 'bold', mt: 1 }}>
                  <ChevronRightIcon />
                </Box>
              </Button>
              <Typography
                variant="caption"
                sx={{
                  mt: 1,
                  fontSize: '0.85rem',
                  whiteSpace: 'nowrap',
                  display: 'block',
                }}
              >
                *Some datasets won’t be downloadable without an account
              </Typography>
            </Box>
          </StyledRightColumn>
        </StyledModalContainer>
      </Modal>
    </>
  );
};

export default inject('securityService', 'historyService')(observer(withRouter(SignIn)));

import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Typography, styled } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';

import Link from '@extensions/components/core/Link';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import { Status } from '@dapclient/services/INotificationService';
import { useCachingService, useNotificationService, useGlobalSearchService } from '@extensions/hooks/useService';

const StyledRootDiv = styled('div')(({
  margin: '0 1em',
  '& > textarea': {
    width: '100%',
    height: 'auto',
    minHeight: '80px'
  }
}));

const StyledWrapper = styled('div')(({}));

const InfoTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontFamily: theme.typography.fontFamily,
  lineHeight: 1.43,
}));

const StyledTextArea = styled('textarea')(({ theme }) => ({
  whiteSpace: 'unset',
  '&&': {
    width: '99%',
    height: '105px',
    minHeight: '105px',
    color: theme.palette.grey.A700,
    fontSize: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 'inherit',
    resize: 'vertical'
  }
}));

const SiteDOITypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontFamily: theme.typography.fontFamily,
  lineHeight: 1.43,
}));

const Acknowledge: React.FC = observer(() => {
  const [siteDoi, setSiteDoi] = useState<string | null | undefined>(null);
  const [copied, setCopied] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const cachingService = useCachingService();
  const globalSearchService = useGlobalSearchService();
  const notificationService = useNotificationService();

  useEffect(() => {
    const fetchSiteDoi = async () => {
      try {
        const siteDoi = await cachingService?.getSiteDoi();
        runInAction(() => setSiteDoi(siteDoi));
      } catch (error: any) {
        console.log('error when fetching site DOI.');
      }
    };

    fetchSiteDoi();
  }, [cachingService]);

  useEffect(() => {
    if (copied) {
      notificationService?.addNotification(
        'copy',
        Status.Success,
        'Copied to clipboard!',
        '',
        true
      );
      setCopied(false);
    }
  }, [copied, notificationService]);

  const handleClick = (e: React.MouseEvent<HTMLTextAreaElement>) => {
    if (textareaRef.current) {
      textareaRef.current.select();
      document.execCommand('copy');
      setCopied(true);
    }
  };

  const renderSiteDOI = () => (
    <StyledWrapper>
      {siteDoi && (
        <SiteDOITypography variant="body1">
          Site DOI: <Link to={`https://www.osti.gov/search/semantic:${siteDoi}`}>{siteDoi}</Link>
        </SiteDOITypography>
      )}
    </StyledWrapper>
  );

  const renderTextArea = () => (
    <StyledWrapper>
      <Typography variant="h1" gutterBottom>
        Acknowledge
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        To acknowledge general use of WDH, select:
      </Typography>
      <InfoTypography variant="body1" gutterBottom>
        Click to copy to clipboard:
      </InfoTypography>
      <StyledTextArea
        ref={textareaRef}
        readOnly
        onClick={handleClick}
        value={`Data were obtained from the Wind Data Hub funded by U.S. 
          Department of Energy, Office of Energy Efficiency and Renewable 
          Energy's Wind Energy Technologies Office operated and maintained 
          by Pacific Northwest National Laboratory at https://wdh.energy.gov. 
          Please be sure to cite individual datasets used in your work. 
          Recommended citations can be found on specific dataset records.`}
      />
    </StyledWrapper>
  );

  const renderPageContent = () => {
    return (
      <StyledRootDiv>
        {renderTextArea()}
        {renderSiteDOI()}
      </StyledRootDiv>
    );
  }

  globalSearchService.setSearchContent('');
  globalSearchService.setSelectedCategory('');
  return <GlobalSearchBarWrap renderChildren={() => renderPageContent()}/>

});

export default Acknowledge;

import React from 'react';
import moment from 'moment';
import find from 'lodash/find';
import filesize from 'filesize';
import { reaction } from 'mobx';
import { css } from '@emotion/css';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import startsWith from 'lodash/startsWith';
import { inject, observer } from 'mobx-react';

import {
  Tooltip,
  Alert,
  Popover,
  Button,
  Input,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';

import { IDatasetService } from '@extensions/services/IDatasetService';
import { INotificationService } from '@extensions/services/INotificationService';

import FileUpload from '@extensions/models/FileUpload';
import FileMetadataSchema from '@extensions/models/FileMetadataSchema';

const manageStyle = css`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 6px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 5px;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0px;
  }

  th.columnFileDetails,
  td.columnFileDetails {
    text-align: right !important;
  }
`;

interface IEditableTableProps {
  datasetService: IDatasetService;
  notificationService: INotificationService;
}

interface IEditableTableState {
  dataSource: FileUpload[];
  selectedRowKeys: string[];
  selectedRows: any[];
  isDeleteOpen: boolean;
  deleteAnchorEl: HTMLButtonElement | null;
  isSubmitOpen: boolean;
  submitAnchorEl: HTMLButtonElement | null;
  rowModesModel: GridRowModesModel;
  hasValidationError: boolean;
  error: string;
}

// tslint:disable-next-line: max-classes-per-file
@inject('datasetService', 'notificationService')
@observer
export default class EditUploadedFilesTable extends React.Component<
  IEditableTableProps,
  IEditableTableState
> {
  static defaultProps = {
    datasetService: null,
    notificationService: null,
  };

  populateTableReaction: any;
  columns: any;

  constructor(props: IEditableTableProps) {
    super(props);

    this.columns = [
      {
        headerName: 'File Name',
        field: 'name',
        editable: true,
        width: 450,
        flex: 1,
        renderCell: this.renderFormattedFilename,
        renderEditCell: (params) => {
          return (
            <div style={{ width: '100%' }}>
              <Input
                fullWidth
                type="text"
                value={params.value || ''}
                onChange={(event) => {
                  params.api.setEditCellValue(
                    {
                      id: params.id,
                      field: params.field,
                      value: event.target.value,
                    },
                    event
                  );
                }}
              />
              {this.state.hasValidationError && (
                <span style={{ color: 'red', display: 'block' }}>
                  {this.state.error}
                </span>
              )}
            </div>
          );
        },
        preProcessEditCellProps: (params) => {
          const callback = (message) => {
            if (message) {
              this.setState({ error: message, hasValidationError: true });
            }
          };

          this.validateFilename(
            params.props.field,
            params.props.value,
            callback
          );
        },
      },
      {
        headerName: 'Size • Upload Date',
        field: 'details',
        className: 'columnFileDetails',
        editable: false,
        width: 170,
        maxWidth: 200,
        align: 'right',
        renderCell: this.renderFileDetails,
      },
      {
        headerName: 'Actions',
        field: 'actions',
        type: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <Tooltip title="Save">
                <GridActionsCellItem
                  icon={<SaveIcon />}
                  label="Save"
                  onClick={() => this.handleSaveClick(id)}
                />
              </Tooltip>,
              <Tooltip title="Cancel">
                <GridActionsCellItem
                  icon={<ClearIcon />}
                  label="Cancel"
                  onClick={() => this.handleCancelClick(id)}
                />
              </Tooltip>,
            ];
          }

          return [
            <Tooltip title="Edit">
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={() => this.handleEditClick(id)}
              />
            </Tooltip>,
          ];
        },
      },
    ];

    this.state = {
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      isDeleteOpen: false,
      deleteAnchorEl: null,
      isSubmitOpen: false,
      submitAnchorEl: null,
      rowModesModel: {},
      hasValidationError: false,
      error: '',
    };

    // populate the table with data once the file records have been retrieved,
    // and again every time they change (i.e. user uploads another file)
    this.populateTableReaction = reaction(
      () => this.props.datasetService.uploads,
      (uploads: any | null) => {
        if (uploads) {
          // sort by date before setting the state
          const sortedUploads = sortBy(uploads, [
            (record) => {
              return record.created;
            },
          ]);
          this.setState({
            dataSource: sortedUploads,
          });
        }
      }
    );
  }

  handleEditClick = (id: GridRowId) => {
    this.setState((prevState) => ({
      rowModesModel: {
        ...prevState.rowModesModel,
        [id]: { mode: GridRowModes.Edit },
      },
    }));
  };

  handleSaveClick = (id: GridRowId) => {
    this.setState((prevState) => ({
      rowModesModel: {
        ...prevState.rowModesModel,
        [id]: { mode: GridRowModes.View },
      },
    }));
  };

  handleCancelClick = (id: GridRowId) => {
    this.setState((prevState) => ({
      rowModesModel: {
        ...prevState.rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      },
    }));
  };

  handleRowUpdate = (row: FileUpload, oldName: string, newName: string) => {
    if (oldName === newName) {
      // name didnt change, do nothing
      return;
    }
    this.props.datasetService.renameFile(row.owner, oldName, newName);

    // get new list of files from server, reaction will trigger table to reload
    const loadFiles = () => this.props.datasetService.loadUploadedFiles();

    this.setState((prevState) => {
      const newSelectedRowKeys = prevState.selectedRowKeys.map(key =>
        key === oldName ? newName : key
      );

      const newSelectedRows = prevState.selectedRows.map(row =>
        row.name === oldName ? { ...row, name: newName } : row
      );

      return {
        selectedRowKeys: newSelectedRowKeys,
        selectedRows: newSelectedRows,
      };
    });

    // some of the time right after doing a rename the just renamed file's old name is still returned...this is a workaround
    setTimeout(() => {
      loadFiles();
    }, 1500);
  };

  handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    this.setState({ rowModesModel: newRowModesModel });
  };

  sortDataSource = (uploads: any) => {
    const sortedUploads = sortBy(uploads, [
      (record) => {
        return record.created;
      },
    ]);
    return sortedUploads;
  };

  renderFileDetails = (params: GridRenderCellParams) => {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <span>
        {filesize(params.row.size)} &bull;{' '}
        {moment(moment.unix(params.row.created), dateFormat).format(
          'MM/DD/YYYY'
        )}
      </span>
    );
  };

  renderFormattedFilename = (params: GridRenderCellParams) => {
    let filename: any = params.row.name;

    if (this.props.datasetService.dataset) {
      const datasetName =
        this.props.datasetService.dataset.getDatasetOnlyName();
      const startsWithDatasetName =
        this.filenameStartsWithDatasetName(filename);

      // first parse off the datasetname from the filename, its not part of the filename validation/color coding
      filename = startsWithDatasetName
        ? filename.substring(datasetName.length + 1)
        : filename;
      const nameSegments: string[] = filename.split('.');

      const isRightSegmentLength = this.filenameisRightSegmentLength(filename);

      if (filename && startsWithDatasetName && isRightSegmentLength) {
        const formatCodeString: any[] = [];
        formatCodeString.push(
          <code key={`${filename}datasetname`} className="datasetname" style={{ fontSize: "1em" }}>
            {datasetName}.
          </code>
        );
        const messages: string[] =
          this.getInvalidFilenameSegmentsMessage(filename);
        nameSegments.forEach((segment: string, index: number) => {
          const error = messages[index] ? true : false;
          if (error) {
            formatCodeString.push(
              <Tooltip key={`${filename}code${index}`} title={messages[index]}>
                <code key={`${filename}code${index}`} className="colorError" style={{ fontSize: "1em" }}>
                  <CloseIcon
                    style={{ color: 'red', fontSize: '11px', padding: '3px' }}
                  />
                  {segment}
                </code>
              </Tooltip>
            );
          } else {
            formatCodeString.push(
              <code key={`${filename}code${index}`} className={`color${index}`} style={{ fontSize: "1em" }}>
                {segment}
              </code>
            );
          }

          if (index < nameSegments.length - 1) {
            formatCodeString.push(
              <span key={`${filename}dot${index}`} className="dot" style={{ fontSize: "1em" }}>
                .
              </span>
            );
          }
        });
        return <span>{formatCodeString}</span>;
      } else {
        if (startsWithDatasetName) {
          return (
            <Tooltip
              key="invalidfilenamepattern"
              title="Invalid File Name Pattern"
              placement="bottom-end"
            >
              <span>
                <code key={`${filename}datasetname`} className="datasetname" style={{ fontSize: "1em" }}>
                  {datasetName}.
                </code>
                <Alert
                  severity="error"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.9em',
                    padding: '5px',
                    margin: '0'
                  }}
                >
                  Invalid File Name Pattern
                </Alert>
              </span>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              key="filenamepattern"
              title="Filename must follow the pattern instructed above."
              placement="bottom-end"
            >
              <span>
                <Alert
                  severity="error"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.9em',
                    padding: '5px',
                    margin: '0'
                  }}
                >
                  Invalid File Name Pattern
                </Alert>
              </span>
            </Tooltip>
          );
        }
      }
    } else {
      return filename;
    }
  };

  componentDidMount() {
    this.props.datasetService.loadUploadedFilesIfNeeded();
    if (this.props.datasetService.uploads) {
      this.setState({
        dataSource: this.sortDataSource(this.props.datasetService.uploads),
      });
    }
  }

  componentWillUnmount() {
    // dispose of the reactions, new ones will be created when another dataset's data order modal loads
    this.populateTableReaction();
  }

  getSelectedFiles = () => {
    const files: FileUpload[] = filter(
      this.state.dataSource,
      (row: FileUpload) =>
        find(this.state.selectedRowKeys, (key) => row.name === key) !==
        undefined
    );
    return files;
  };

  processSelectedFiles = (refreshFileList, callback: CallableFunction) => {
    const files = this.getSelectedFiles();

    files.forEach((row: FileUpload) => callback(row.owner, row.name));

    if (refreshFileList) {
      const loadFiles = () => this.props.datasetService.loadUploadedFiles();

      // some of the time right after doing an API call that modifies files the old file state is still returned...this is a workaround
      setTimeout(() => {
        loadFiles();
      }, 1500);
    }
  };

  handleSubmit = () => {
    const callback = (owner, name) =>
      this.props.datasetService.submitFile(owner, name);
    this.processSelectedFiles(true, callback);
    this.setState({
      isSubmitOpen: false,
      dataSource: []
    });
  };

  handleDownload = () => {
    const callback = (owner, name) =>
      this.props.datasetService.downloadFile(owner, name);
    this.processSelectedFiles(false, callback);
  };

  handleDelete = () => {
    const callback = (owner, name) =>
      this.props.datasetService.deleteFile(owner, name);
    this.processSelectedFiles(true, callback);
    this.setState({
      isDeleteOpen: false,
      dataSource: [],
    });
  };

  getInvalidFilenameSegmentsMessage = (filename) => {
    const messages: string[] = [];
    if (this.props.datasetService.dataset) {
      const downloadDist =
        this.props.datasetService.dataset.getDownloadDistribution();
      const nameSegments: string[] = filename.split('.');
      // for each segment, validate againts it's regex
      downloadDist.fileMetadataSchema.forEach(
        (attr: FileMetadataSchema, index: number) => {
          if (attr.fieldFormat) {
            try {
              if (nameSegments[index].match(attr.fieldFormat.regex) === null) {
                messages.push(
                  `${nameSegments[index]} does not meet requirement: ${attr.fieldFormat.label} (regex: ${attr.fieldFormat.regex})`
                );
              }
            } catch (e) {
              if (e instanceof SyntaxError) {
                console.error(`Invalid regular expression: ${attr.fieldFormat.regex}. Error: ${e.message}`);
                messages.push(
                  `${nameSegments[index]} has an invalid regular expression: ${attr.fieldFormat.regex}. Please check the format.`
                );
              } else {
                throw e;
              }
            }
            
          } else {
            console.log(
              'unable to verify filename format, no format defined in metadata'
            );
            messages.push('');
          }
        }
      );
    }
    return messages;
  };

  filenameStartsWithDatasetName = (filename) => {
    let startsWithDatasetName = false;
    if (this.props.datasetService.dataset) {
      const datasetName =
        this.props.datasetService.dataset.getDatasetOnlyName();
      startsWithDatasetName = startsWith(filename, datasetName);
    }
    return startsWithDatasetName;
  };

  filenameisRightSegmentLength = (filename) => {
    let isRightSegmentLength = false;
    if (this.props.datasetService.dataset) {
      const nameSegments: string[] = filename.split('.');
      const downloadDist =
        this.props.datasetService.dataset.getDownloadDistribution();

      isRightSegmentLength =
        nameSegments.length === downloadDist.fileMetadataSchema.length;
    }
    return isRightSegmentLength;
  };

  validateFilename = (rule, value, callback) => {
    if (this.props.datasetService.dataset) {
      // parse off the dataset name, and validate that its there first
      const datasetName =
        this.props.datasetService.dataset.getDatasetOnlyName();
      if (!this.filenameStartsWithDatasetName(value)) {
        callback(`Filename must start with dataset name (${datasetName})`);
        // can't validate further, just return one message to say it needs to start with the datasetname
      } else {
        // remove dataset name from filename and validate rest
        const filename = value.substring(datasetName.length + 1);
        if (!this.filenameisRightSegmentLength(filename)) {
          const downloadDist =
            this.props.datasetService.dataset.getDownloadDistribution();
          callback(
            `Does not have correct number of segments (${downloadDist.fileMetadataSchema.length}).`
          );
        } else if (find(this.props.datasetService.uploads, { name: value })) {
          callback(`This filename is already in use ('${value}).`);
        } else {
          callback(this.getFilenameErrors(filename));
        }
      }
    }
    callback();
  };

  getFilenameErrors = (filename) => {
    const messages: string[] = this.getInvalidFilenameSegmentsMessage(filename);
    const errors: string[] = [];
    messages.forEach((message: string) => {
      if (message) {
        errors.push(message);
      }
    });
    return errors;
  };

  handleClick = (event, popoverType) => {
    if (popoverType === 'delete') {
      this.setState({
        deleteAnchorEl: event.currentTarget,
        isDeleteOpen: true,
      });
    } else if (popoverType === 'submit') {
      this.setState({
        submitAnchorEl: event.currentTarget,
        isSubmitOpen: true,
      });
    }
  };

  render() {
    const { dataSource, selectedRowKeys } = this.state;

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          validateFilename: this.validateFilename,
        }),
      };
    });

    const selectionHasInvalidFilenames = () => {
      const files = this.getSelectedFiles();
      const invalidFound = find(files, (row: FileUpload) => {
        const datasetName = this.props.datasetService.dataset
          ? this.props.datasetService.dataset.getDatasetOnlyName()
          : undefined;
        const filename = datasetName
          ? row.name.substring(datasetName.length + 1)
          : undefined;
        if (
          !this.filenameStartsWithDatasetName(row.name) ||
          !this.filenameisRightSegmentLength(filename) ||
          this.getFilenameErrors(filename).length > 0
        ) {
          return true;
        }
        return false;
      });
      return invalidFound !== undefined;
    };

    const dataSourceWithIdRow = dataSource.map((row, index) => ({
      ...row,
      id: index,
    }));

    const dataSourceWithIdColumn = columns.map((col, index) => ({
      ...col,
      id: index,
    }));

    let content = <div>no data</div>;
    if (this.props.datasetService.uploads) {
      content = (
        <div className={manageStyle}>
          <Popover
            open={this.state.isDeleteOpen}
            anchorEl={this.state.deleteAnchorEl}
          >
            <div style={{ padding: 10 }}>
              <Typography style={{ marginTop: 5 }}>
                {selectedRowKeys.length > 1
                  ? `Are you sure you want to delete all ${selectedRowKeys.length} selected files?`
                  : `Are you sure you want to delete the selected file?`}
              </Typography>

              <Button
                style={{ marginBottom: 10, textTransform: "none" }}
                disabled={selectedRowKeys.length === 0}
                onClick={() => this.setState({ isDeleteOpen: false })}
                variant='outlined'
              >
                Cancel
              </Button>
              <Button
                style={{
                  marginBottom: 10,
                  marginLeft: 15,
                  textTransform: 'none',
                }}
                disabled={selectedRowKeys.length === 0}
                onClick={this.handleDelete}
                variant="contained"
                color="secondary"
              >
                Delete
              </Button>
            </div>
          </Popover>
          <Button
            sx={{
              textTransform: 'none',
              marginLeft: '10px',
            }}
            disabled={selectedRowKeys.length === 0}
            onClick={(event) => this.handleClick(event, 'delete')}
            variant="outlined"
          >
            Delete
          </Button>

          <Button
            style={{ marginBottom: 10, marginLeft: 5, textTransform: 'none', marginTop: 10 }}
            disabled={selectedRowKeys.length === 0}
            onClick={this.handleDownload}
            variant="outlined"
          >
            Download
          </Button>

          <Popover
            open={this.state.isSubmitOpen}
            anchorEl={this.state.submitAnchorEl}
          >
            <div style={{ padding: 10 }}>
              <Typography style={{ marginTop: 5 }}>
                {selectedRowKeys.length > 1
                  ? `Are you sure you want to submit all ${selectedRowKeys.length} selected files?`
                  : `Are you sure you want to submit the selected file?`}
              </Typography>

              <Button
                onClick={() => this.setState({ isSubmitOpen: false })}
                style={{ textTransform: 'none', marginRight: 15 }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                disabled={
                  this.getSelectedFiles().length === 0 ||
                  selectionHasInvalidFilenames()
                }
                color="secondary"
                onClick={this.handleSubmit}
                style={{ textTransform: 'none' }}
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </Popover>
          <Button
            color="secondary"
            style={{
              marginBottom: 10,
              marginRight: 10,
              textTransform: 'none',
              float: 'right',
              marginTop: 10
            }}
            disabled={
              this.getSelectedFiles().length === 0 ||
              selectionHasInvalidFilenames()
            }
            onClick={(event) => this.handleClick(event, 'submit')}
            variant="contained"
          >
            Submit
          </Button>
          <div style={{ padding: '0 10px 10px 10px' }}>
            <DataGrid
              rows={dataSourceWithIdRow}
              columns={dataSourceWithIdColumn}
              checkboxSelection
              disableColumnMenu
              getRowClassName={() => '.editable-row'}
              rowHeight={100}
              autoHeight
              onRowSelectionModelChange={(rowSelectionModel) => {
                this.setState({
                  selectedRowKeys: rowSelectionModel.map(
                    (idx) => dataSource[idx].name
                  ),
                  selectedRows: rowSelectionModel.map((idx) => dataSource[idx]),
                });
              }}
              processRowUpdate={(newRow, oldRow) => {
                this.handleRowUpdate(newRow, oldRow.name, newRow.name);
                return newRow;
              }}
              rowModesModel={this.state.rowModesModel}
              onRowModesModelChange={this.handleRowModesModelChange}
              sx={{
                '.MuiTablePagination-displayedRows': {
                  marginTop: '1em',
                  marginBottom: '1em',
                },
                '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel':
                {
                  marginTop: '1em',
                  marginBottom: '1em',
                },
              }}
            />
          </div>
        </div>
      );
    }
    return content;
  }
}

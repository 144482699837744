import React from 'react';

import { 
  Card, 
  CardContent, 
  Typography,
  styled
} from '@mui/material';

import Link from '@dapclient/components/core/Link';
import { PubDataset } from '@extensions/models/Publication';

const StyledDatasetCard = styled(Card)(() => ({
  marginTop: '10px',
  height: '200px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px', 
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555', 
  },
}));

const StyledTitle = styled(Typography)(() => ({
  position: 'sticky',
  top: 0, 
  backgroundColor: '#ffffff', 
  zIndex: 1,
  padding: '10px 0',
  fontSize: '1.2em',
}));

export interface IDatasetListProps {
  datasets: PubDataset[];
}

const DatasetList: React.FC<IDatasetListProps> = ({ datasets }) => {
  return (
    <StyledDatasetCard variant='outlined'>
      <CardContent>
        <StyledTitle>Datasets</StyledTitle>
        {datasets.length > 0 ? (
          datasets.map((dataset: PubDataset, index: number) => (
            <Link to={`/ds/${dataset.name}`} key={index}>
              <Typography>{dataset.name}</Typography>
            </Link>
          ))
        ) : (
          <Typography>No Datasets Provided</Typography>
        )}
      </CardContent>
    </StyledDatasetCard>
  );
};

export default DatasetList;

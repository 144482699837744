import removeMd from 'remove-markdown';
import difference from 'lodash/difference';
export const getAllReactiveIdsExcept = allReactiveIds => excluded =>
  difference(allReactiveIds, [excluded]);

const collapseWhtespace = str => {
  const collapsed = str.trim();
  return collapsed.replace(/\s+/g, ' ');
};
export const roughlyIncludes = ({ item, query }) => {
  const normalize = str => collapseWhtespace(str).toLowerCase();
  return normalize(item).includes(normalize(query));
};

export const removeHtml = (html: string) => {
  return html.replace(/<[^>]*>/g, '')
};

export const removeHighlight = (html: string, tag = 'mark') => {
  return html.replace(new RegExp(`</?${tag}>`, 'g'), '');
}

export const removeMdExcept = (md: string, keepTags: string[]) => {
  md = keepTags.reduce(
    (txt, tag) => {
      if (txt) {
        return txt.replace(
          new RegExp(`<(/)?${tag}>`, 'g'), `&lt;$1${tag}&gt;`
        )
      } else {
        return ''
      }
    }, md
  );
  return keepTags.reduce((txt, tag) => txt.replace(
    new RegExp(`&lt;(/)?${tag}&gt;`, 'g'), `<$1${tag}>`
  ), removeMd(md));
};

import React from 'react';

import {
  Card,
  CardContent,
  styled,
  Typography
} from '@mui/material';

import { PubAuthor } from '@extensions/models/Publication';

const StyledAuthorsCard = styled(Card)(() => ({
  marginTop: '10px', 
  minHeight: '40px'  
}))

const StyledTitle = styled(Typography)(() => ({
  color: '#000000',
  fontSize: '1.2em',
}))

const StyledName = styled('p')(() => ({
  margin: '0 auto',
}));

export interface IAuthorsListProps {
  className?: string;
  authors: PubAuthor[];
}

const PublicationAuthors: React.FC<IAuthorsListProps> = ({ authors }) => {
  return (
    <StyledAuthorsCard variant='outlined'>
      <CardContent>
        <StyledTitle>Authors</StyledTitle>
        {authors.map((author, index) => (
          <div key={index}>
            <StyledName>{author.firstName}{' '}{author.lastName}</StyledName>
            <Typography variant='caption'>{author.affiliation?.name}</Typography>
            <hr />
          </div>
        ))}
      </CardContent>
    </StyledAuthorsCard>
  )
}

export default PublicationAuthors

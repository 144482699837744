import find from 'lodash/find';
import { inject } from 'mobx-react';
import { Helmet } from 'react-helmet';
import GroupIcon from '@mui/icons-material/Group';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Typography, Tabs, Tab, Box, styled } from '@mui/material';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

import {
  Dataset as DapDataset,
} from '@dapclient/components/page/Dataset';
import Link from '@extensions/components/core/Link';
import DatasetSummary from '@extensions/components/dataset/DatasetSummary';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import User from '@extensions/models/User';
import Project from '@extensions/models/Project';
import DatasetModel from '@extensions/models/Dataset';
import DistributionType from '@extensions/models/DistributionType';

import theme from '@extensions/services/Theme';
import { ABILITIES } from '@extensions/services/ISecurityService';
import { IGlobalSearchService } from '@extensions/services/IGlobalSearchService';

const ContentWrapper = styled('div')(({ theme }) => ({
  margin: '0 20em',
  [theme.breakpoints.down('xl')]: {
    margin: '0 1em',
  },
  [theme.breakpoints.down('xs')]: {
    margin: '0 0em',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  '&.MuiTab-root': {
    color: theme.palette.text.primary,
  },
  '&.Mui-selected': {
    color: '#000',
  }
}));

const StyledTabBox = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.grey[700],
}));

interface IDatasetProps {
  globalSearchService?: IGlobalSearchService;
}
export class Dataset extends DapDataset<IDatasetProps> {
  UNSAFE_componentWillMount(): void {
    this.props.globalSearchService?.setSelectedCategory('');
    this.props.globalSearchService?.setSearchContent('');
  }
  createWrapper = (content: React.ReactNode) => {
    return <ContentWrapper>{content}</ContentWrapper>
  }

  getDatasetSummary = (): React.ReactNode => {
    return <DatasetSummary showApiTab={(e) => this.onTabsChange(e, 'api')} />
  };
  getBrowseDataButton = () => null;
  renderDataOrderModal = () => null;

  renderPageContent(project, dataset, hasApi, showUploadTab, showManageTab, browseDataButton) {
    return (
      <>
        {this.renderDataOrderModal()}
        <Link to={`/project/${project.identifier}`}>{project.title}</Link>
        <Typography
          variant="h2"
          sx={{
            marginTop: theme.spacing(1.5),
            color: theme.palette.secondary.main,
            fontWeight: 400,
          }}
        >
          {dataset.title}
        </Typography>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            position: "relative",
            display: "flex",
            flex: "none",
            alignItems: "center"
          }}
        >
          <Tabs
            value={this.state.activeTab}
            onChange={this.onTabsChange}
            sx={{ flex: 'auto' }}
          >
            <StyledTab
              value="summary"
              label={
                <StyledTabBox display="flex" alignItems="center">
                  <ListAltIcon style={{ marginRight: 8 }} fontSize='small' />
                  Summary
                </StyledTabBox>
              }
            />
            {hasApi && (
              <StyledTab
                value="api"
                label={
                  <StyledTabBox display="flex" alignItems="center">
                    <SettingsEthernetIcon style={{ marginRight: 8 }} fontSize='small' />
                    API Details
                  </StyledTabBox>
                }
                key="api-help"
              />
            )}
            {hasApi && (
              <StyledTab
                value="api-help"
                label={
                  <StyledTabBox display="flex" alignItems="center">
                    <HelpOutlineIcon style={{ marginRight: 8 }} fontSize='small' />
                    API Help
                  </StyledTabBox>
                }
                key="api"
              />
            )}
            {showUploadTab && (
              <StyledTab
                value="upload"
                label={
                  <StyledTabBox display="flex" alignItems="center">
                    <CloudUploadIcon style={{ marginRight: 8 }} fontSize='small' />
                    Upload
                  </StyledTabBox>
                }
                key="upload"
              />
            )}
            {showManageTab && (
              <StyledTab
                value="manage"
                label={
                  <StyledTabBox display="flex" alignItems="center">
                    <GroupIcon style={{ marginRight: 8 }} fontSize='small' />
                    Permissions
                  </StyledTabBox>
                }
                key="manage"
              />
            )}
          </Tabs>
          {browseDataButton}
        </Box>
        <div style={{ marginTop: '1rem' }}>
          {this.renderTabContent(this.state.activeTab)}
        </div>
      </>
    )
  }

  renderContent() {
    let content = <div />;
    const loggedInUser: User | null = this.props.securityService.user;
    const dataset: DatasetModel | null = this.props.datasetService.dataset;
    const project: Project | null = this.props.datasetService.project;
    const userAlert = this.getUserAlert(loggedInUser);
    if (userAlert) {
      content = userAlert;
    } else if (dataset && project) {
      const abilities: string[] | null = dataset.abilities;
      const hasApi =
        find(dataset.distribution, {
          distributionType: DistributionType.API,
        }) !== undefined;

      const hasFiles = dataset.getDownloadDistribution() !== undefined;
      const showManageTab = abilities && abilities.includes(ABILITIES.ADMIN);
      const showUploadTab =
        hasFiles && abilities && abilities.includes(ABILITIES.UPLOAD);
      const browseDataButton =
        hasFiles && this.state.activeTab === 'summary'
          ? this.getBrowseDataButton()
          : null;
      content = (
        <div>
          <Helmet>
            <title>{dataset.title}</title>
            <meta name="description" content={dataset.description}></meta>
          </Helmet>
          <GlobalSearchBarWrap
            renderChildren={
              () => this.renderPageContent(project, dataset, hasApi, showUploadTab, showManageTab, browseDataButton)
            }
          />
        </div>
      );
    }
    return content;
  }
}

export default inject((store: any) => ({
  datasetService: store.datasetService,
  securityService: store.securityService,
  notificationService: store.notificationService,
  historyService: store.historyService,
  projectService: store.projectService,
  globalSearchService: store.globalSearchService,
}))(Dataset);

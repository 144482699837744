import React from 'react';
import { observer } from 'mobx-react';
import { ReactiveBase, StateProvider } from '@appbaseio/reactivesearch';
import ITokenService from '@extensions/services/ITokenService';
import { useTokenService } from '@extensions/hooks/useService';

import {
    getHashValues,
    setHashValues,
    stringify,
    parse,
} from '@dapclient/utils/url';
import {
    SEARCH_BAR_REACTIVE_ID,
} from '@dapclient/components/search-core';

import {
    RecommendedFilter,
    DataLevelFilter,
    InstrumentFilter,
    MeasurementFilter,
    ModelFilter,
    StandardizationFilter,
    FileTypeFilter,
    DateRangeFilter,
    ProjectFilter,
    ResultsList,
    RECOMMENDED_REACTIVE_ID,
    DATA_LEVEL_REACTIVE_ID,
    INSTRUMENT_REACTIVE_ID,
    MEASUREMENT_REACTIVE_ID,
    MODEL_REACTIVE_ID,
    STANDARDIZATION_REACTIVE_ID,
    FILE_TYPE_REACTIVE_ID,
    DATE_RANGE_REACTIVE_ID,
    PROJECT_REACTIVE_ID,
    AREA_FILTER_REACTIVE_ID,
} from '@extensions/components/page/search-components';

import { ELASTIC_APP } from '@extensions/components/global-search/SearchCategories';
import { CATEGORY_INDEX_ID } from '@extensions/components/page/home-page/HomeSearchResultContainer';

export interface IGlobalSearchProviderProps {
    children: React.ReactNode;
    tokenService?: ITokenService;
}

const REACTIVE_IDS = [
    SEARCH_BAR_REACTIVE_ID,
    DATA_LEVEL_REACTIVE_ID,
    RECOMMENDED_REACTIVE_ID,
    INSTRUMENT_REACTIVE_ID,
    MEASUREMENT_REACTIVE_ID,
    MODEL_REACTIVE_ID,
    STANDARDIZATION_REACTIVE_ID,
    FILE_TYPE_REACTIVE_ID,
    DATE_RANGE_REACTIVE_ID,
    PROJECT_REACTIVE_ID,
    AREA_FILTER_REACTIVE_ID,
    CATEGORY_INDEX_ID
];


const GlobalSearchProvider: React.FC<IGlobalSearchProviderProps> = ({
    children,
}) => {
    const tokenService = useTokenService();

    if (!tokenService) {
        console.log('tokenService is null');
        return null;
    }

    return (
        <ReactiveBase
            app={ELASTIC_APP.ALL}
            url={`${window.location.protocol}//${window.location.host}`}
            headers={{ 'x-csrf-token': tokenService.dapToken }}
            getSearchParams={() => {
                let params: { [key: string]: string } = {};
                const hash = getHashValues();
                for (const key in hash) {
                    if (REACTIVE_IDS.indexOf(key) < 0) {
                        continue;
                    }
                    params[key] = hash[key] as string;
                }
                return stringify(params);
            }}
            setSearchParams={(url)=>{
                const params = parse(url.split('?')[1]) as { [key: string]: string };
                const hash = getHashValues();
                let keep: { [key: string]: string } = {};
                for (const key in params) {
                    if (params[key]) {
                        keep[key] = params[key] as string;
                    }
                }
                for (const key in hash) {
                    if (REACTIVE_IDS.indexOf(key) < 0) {
                        keep[key] = hash[key] as string;
                    }
                }
                setHashValues(keep);
            }}
            transformRequest={(req) => {
                return req;
            }}
        >
            <StateProvider
                componentIds={[PROJECT_REACTIVE_ID, DATE_RANGE_REACTIVE_ID]}
                includeKeys={['value']}
                onChange={(prev, next) => {
                    const value = next[PROJECT_REACTIVE_ID].value;
                    if (value === undefined || value === null) {
                        let hash = getHashValues();
                        if (PROJECT_REACTIVE_ID in hash) {
                            delete hash[PROJECT_REACTIVE_ID];
                        }
                        if (DATE_RANGE_REACTIVE_ID in hash) {
                            delete hash[DATE_RANGE_REACTIVE_ID];
                        }
                        setHashValues(hash);
                    }
                    return null;
                }}
            />
            {children}
        </ReactiveBase>
    );
};

export default observer(GlobalSearchProvider);

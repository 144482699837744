import rehypeRaw from 'rehype-raw';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Typography, styled } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import MarkdownLink from '@extensions/components/core/MarkdownLink';
import MarkdownImage from '@extensions/components/core/MarkdownImage';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import { useCachingService, useGlobalSearchService } from '@extensions/hooks/useService';

const StyledH1HeaderTag = styled(Typography)(() => ({
    fontWeight: 300,
    marginBlockStart: '0',
    marginBlockEnd: '12px',
    fontSize: '3em',
    color: '#424242',
}))

const StyledH2HeaderTag = styled(Typography)(() => ({
    fontWeight: 'normal',
    marginBlockStart: '0',
    marginBlockEnd: '12px',
    fontSize: '1.25em',
    color: '#000000',
}))

const StyledTextTag = styled(Typography)(() => ({
    fontWeight: 'normal',
    marginBlockStart: '0',
    marginBlockEnd: '12px',
    fontSize: '1em',
    color: '#5C5C5C',
}))

const StyledLiTag = styled('li')(() => ({
    color: '#5C5C5C',
    fontSize: '16px',
    lineHeight: '22px',
}))

const StyledRootDiv = styled('div')(({
    margin: '-0.5em 1em',
}))

export interface IStaticPageProps extends RouteComponentProps { }

const Static: React.FC<IStaticPageProps> = () => {
    const [content, setContent] = useState<string | null>(null);
    const [show404, setShow404] = useState<boolean>(false);
    const location = useLocation();
    const cachingService = useCachingService();
    const globalSearchService = useGlobalSearchService();

    const fetchContent = useCallback(async () => {
        try {
            const staticPage = await cachingService.getStaticPage(location.pathname);
            runInAction(() => setContent(staticPage));
        } catch (error: any) {
            if (error.response && error.response.notFound) {
                runInAction(() => setShow404(true));
            } else {
                throw error;
            }
        }
    }, [cachingService, location.pathname]);

    useEffect(() => {
        fetchContent();
    }, [location.pathname, fetchContent]);

    let body: React.ReactNode;
    if (show404) {
        body = (
            <>
                <Typography variant="h1">Page Not Found</Typography>
                <Typography>Please contact us if you require assistance.</Typography>
            </>
        );
    }

    const headerH1TagHandler = (props: React.ComponentProps<typeof Typography>) => (
        <StyledH1HeaderTag {...props} />
    );

    const headerH2TagHandler = (props: React.ComponentProps<typeof Typography>) => (
        <StyledH2HeaderTag {...props} />
    );

    if (content) {
        body = (
            <ReactMarkdown
                children={content}
                linkTarget={(url) => (url.startsWith('http') ? '_blank' : '')}
                components={{
                    image: MarkdownImage,
                    a: MarkdownLink,
                    h1: headerH1TagHandler,
                    h2: headerH2TagHandler,
                    p: (props) => <StyledTextTag variant="body1" {...props} />,
                    li: (props) => <StyledLiTag {...props} />,
                }}
                rehypePlugins={[rehypeRaw]}
            />
        );
    }

    const renderContent = () => <StyledRootDiv>{body}</StyledRootDiv>;

    globalSearchService.setSearchContent('');
    globalSearchService.setSelectedCategory('');
    return <GlobalSearchBarWrap renderChildren={() => renderContent()} />
};

export default observer(Static);

import React from 'react';
import { Box, styled } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { observable, action, reaction, makeObservable } from 'mobx';

import {
  ICartService,
  CompleteDateRange,
} from '@extensions/services/ICartService';

import DatePicker, { DateRange } from '@extensions/components/core/date-picker';

const StyledDatePickerWrapper = styled(Box)({
  width: '100%', // Ensures it scales with the card width
  '& .DateRangePicker': {
    width: '100%', // Ensures the DatePicker input fields fill the container
  },
});

export interface IDateFilterProps {
  className?: string;
  cartService?: ICartService;
}

@inject('cartService')
@observer
class DateFilter extends React.Component<IDateFilterProps> {
  componentDidMount = () => {
    // Pull the selection from the service
    reaction(
      () => {
        if (this.props.cartService === undefined) {
          return null;
        }
        return this.props.cartService.dateRangeFilter;
      },
      dateRangeFilter => {
        if (this.areDifferent(dateRangeFilter, this.currentDateRange)) {
          if (dateRangeFilter === null) {
            this.setCurrentDateRange({
              startDate: null,
              endDate: null,
            });
          } else {
            this.setCurrentDateRange(dateRangeFilter);
          }
        }
      },
      {
        fireImmediately: true,
      }
    );
    reaction(
      () => this.currentDateRange,
      currentDateRange => {
        if (
          this.props.cartService &&
          currentDateRange.startDate !== null &&
          currentDateRange.endDate !== null &&
          this.areDifferent(
            this.props.cartService.dateRangeFilter,
            currentDateRange
          )
        ) {
          this.props.cartService.setDateRangeFilter({
            startDate: currentDateRange.startDate,
            endDate: currentDateRange.endDate,
          });
        }
      }
    );
  };
  @observable
  currentDateRange: DateRange = {
    startDate: null,
    endDate: null,
  };

  areDifferent = (
    cartDateRange: CompleteDateRange | null,
    thisDateRange: DateRange
  ) => {
    const cartIsNullThisIsNot =
      cartDateRange === null &&
      (thisDateRange.startDate !== null || thisDateRange.endDate !== null);
    const nonNullMismatch =
      cartDateRange !== null &&
      (thisDateRange.startDate === null ||
        !thisDateRange.startDate.isSame(cartDateRange.startDate) ||
        thisDateRange.endDate === null ||
        !thisDateRange.endDate.isSame(cartDateRange.endDate));
    return cartIsNullThisIsNot || nonNullMismatch;
  };

  @action
  setCurrentDateRange = (newRange: DateRange): void => {
    this.currentDateRange = newRange;
  };

  constructor(props: IDateFilterProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { cartService } = this.props;
    if (!cartService) {
      return null;
    }

    return (
      <StyledDatePickerWrapper>
        <DatePicker
          startDate={this.currentDateRange.startDate}
          startDateId="date-range-filter-start"
          endDate={this.currentDateRange.endDate}
          endDateId="date-range-filter-end"
          oldestAllowed={cartService.firstDateWithData}
          newestAllowed={cartService.lastDateWithData}
          onDatesChange={this.setCurrentDateRange}
        />
      </StyledDatePickerWrapper>
    );
  }
}

export default DateFilter;

import {
  Card,
  CardContent,
  CardActions,
  styled,
  Typography,
  Button,
  Divider
} from '@mui/material';
import React from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { Status } from '@extensions/services/INotificationService';
import { useNotificationService } from '@extensions/hooks/useService';

const StyledCitationCard = styled(Card)(() => ({
  marginTop: '10px',
  minHeight: '40px',
  marginBottom: '10px',
}));

const StyledTitle = styled(Typography)(() => ({
  color: '#000000',
  fontSize: '1.20em',
}));

const StyledCitationText = styled(Typography)(() => ({
  fontStyle: 'italic',
  fontSize: '0.8em',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
}));

const StyledDOIText = styled(Typography)(() => ({
  fontSize: '0.8em',
}));

const StyledCopyButton = styled(Button)(({ theme }) => ({
  display: 'inline-block',
  cursor: 'pointer',
  color: theme.palette.secondary.main,
  margin: 'auto',
  fontSize: '0.9em',
  textTransform: 'capitalize',
  letterSpacing: 'normal',
}));

export interface ICitationProps {
  projectTitle?: string,
  doiName?: string | null,
  givenCitation?: string,
}

const CitationPanel: React.FC<ICitationProps> = ({ projectTitle, doiName, givenCitation }) => {

  const notifcationService = useNotificationService();
  let citation: string = '';

  if (givenCitation) {
    citation = givenCitation;
  } else if (projectTitle) {
    citation =
      `
      This ${projectTitle} data were obtained from the Wind Data Hub funded by 
      U.S. Department of Energy, Office of Energy Efficiency and Renewable Energy's Wind Energy Technologies Office 
      operated and maintained by Pacific Northwest National Laboratory 
      at https://wdh.energy.gov.
    `;
  } else {
    return '';
  }

  const handleClick = () => {
    navigator.clipboard.writeText(citation).then(() => {
      notifcationService?.addNotification(
        'copy',
        Status.Success,
        'Copied to clipboard!',
        '',
        true
      )
    }).catch(err => {
      console.error('Failed to copy citation:', err);
    });
  };

  return (
    <StyledCitationCard variant='outlined'>
      <CardContent>
        <StyledTitle>Citation</StyledTitle>
        {doiName
          ? <StyledDOIText>
            <strong>DOI: {' '}</strong>
            <a
              href={"https://www.osti.gov/search/semantic:" + doiName}
              target='blank'
              rel='noopener noreferrer'
            >
              {doiName}
              <OpenInNewIcon sx={{ width: 15 }} />
            </a>
          </StyledDOIText>
          : null
        }
        <div id='citation-text'>
          <StyledCitationText>{citation}</StyledCitationText>
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <StyledCopyButton
          onClick={handleClick}
        >
          Copy Citation
        </StyledCopyButton>
      </CardActions>
    </StyledCitationCard>
  )
}


export default CitationPanel;

import React from 'react';
import { observer } from 'mobx-react';
import { styled, useMediaQuery } from '@mui/material';
import { DataSearchProps } from '@appbaseio/reactivesearch/lib/components/search/DataSearch';

import theme from '@extensions/services/Theme';
import ITokenService from '@extensions/services/ITokenService';
import { useTokenService } from '@extensions/hooks/useService';

import SearchBar from '@extensions/components/global-search/SearchBar';

export const REACTIVE_ID = 'SearchBar';

const StyledDiv = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
    paddingBottom: '8px',
    marginTop: isMobile ? '1.25rem' : '0',
}));

interface ExtraDataSearchProps extends DataSearchProps {
    projectContext?: string;
    tokenService?: ITokenService;
    className?: string;
    isRedirecting?: boolean;
}

const HomePageSearchBarDataField = ['*'];

const HomePageSearchBarContainer: React.FC<
    Omit<ExtraDataSearchProps, 'componentId' | 'innerClass'>
> = (props) => {
    const tokenService = useTokenService();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    if (!tokenService) {
        return null;
    }

    return (
        <StyledDiv isMobile={isMobile}>
            <SearchBar
                dataField={HomePageSearchBarDataField}
                highlight={true}
                customHighlight={(d) => ({
                    highlight: {
                        pre_tags: ['<mark>'],
                        post_tags: ['</mark>'],
                        fields: {
                            ...d.dataField.reduce((carry, field) => {
                                return {
                                    ...carry,
                                    [field]: field === 'description' || field === 'title'
                                        ? { number_of_fragments: 0 }
                                        : {},
                                };
                            }, {}),
                        },
                    },
                })}
                filterLabel="Search"
                queryString={true}
                autosuggest={false}
                queryFormat="and"
                URLParams={true}
                debounce={500}
                {...props}
            />
        </StyledDiv>
    );
};

export default observer(HomePageSearchBarContainer);
import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import theme from '@extensions/services/Theme';
import { useCartService } from '@extensions/hooks/useService';

import Link from '@dapclient/components/core/Link';

const StyledRootDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const StyledCountDiv = styled('div')({
  display: 'flex',
  fontSize: '.75rem',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: theme.typography.fontWeightBold,
  position: 'absolute',
  top: '-0.5rem',
  left: '1.3rem',
  borderRadius: '50%',
  width: '1rem',
  height: '1rem',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
});

const StyledTextSpan = styled('span')({
  marginLeft: '1rem',
});

export interface ICartLinkProps {
  className?: string;
  children?: any;
  styleObject?: any;
}

const CartLink: React.FC<ICartLinkProps> = ({ styleObject, children }) => {
  const cartService = useCartService();

  if (!cartService) {
    return null;
  }

  const count = cartService.orderItems.length;

  return (
    <StyledRootDiv>
      <Link
        aria-label={`Cart (${count} datasets)`}
        to="/profile/cart"
        underline='hover'
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ShoppingCartOutlinedIcon sx={styleObject} />
        <StyledCountDiv aria-hidden>
          <span>{count}</span>
        </StyledCountDiv>
        <StyledTextSpan>
          {children}
        </StyledTextSpan>
      </Link>
    </StyledRootDiv>
  );
};

export default inject('cartService')(observer(CartLink));

import { Helmet } from 'react-helmet';

interface IHeadManagerProps {
    title: string;
    description: string;
}

const HeadManager: React.FC<IHeadManagerProps> = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default HeadManager;
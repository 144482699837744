import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import Project from '@extensions/models/Project';

import theme from '@extensions/services/Theme';
import { useSecurityService } from '@extensions/hooks/useService';

interface _IAddToFavoriteProjectProps {
    project: Project;
};

const AddToFavoriteProjectButton: React.FC<_IAddToFavoriteProjectProps> = ({ project }) => {
    const [isFav, setIsFav] = useState(false);
    const securityService = useSecurityService();
    const { user } = securityService;

    useEffect(() => {
            if (securityService.userIsLoggedIn) {
                if (user && user['fav_project'] && user['fav_project'].length && user['fav_project'].includes(project.identifier)) {
                    setIsFav(true)
                } else {
                    setIsFav(false)
                }
            }
        }, [user, project.identifier, securityService.userIsLoggedIn]);

    const handleClick = async () => {
        let statusCode = 0;
        if (isFav && user && user.fav_project && user.fav_project.length) {
            const updatedFavProject = user.fav_project.filter(p => p !== project.identifier);
            statusCode = await securityService.updateFavorite({
                "fav_project": updatedFavProject
            }, false);
            if (statusCode === 200) {
                setIsFav(prevIsFav => !prevIsFav);
                window.location.reload();
            }
        } else {
            if (user && (!user.hasOwnProperty('fav_project') || !user.fav_project)) {
                statusCode = await securityService.updateFavorite({
                    "fav_project": [project.identifier]
                }, true);
                if (statusCode === 200) {
                    setIsFav(prevIsFav => !prevIsFav);
                }
            } else if (user && user.fav_project && user.fav_project.length === 0) {
                statusCode = await securityService.updateFavorite({
                    "fav_project": [project.identifier]
                }, true);
                if (statusCode === 200) {
                    setIsFav(prevIsFav => !prevIsFav);
                }
            } else if (user && user.fav_project && user.fav_project.length > 0) {
                statusCode = await securityService.updateFavorite({
                    "fav_project": [...user['fav_project'], project.identifier]
                }, true);
                if (statusCode === 200) {
                    setIsFav(prevIsFav => !prevIsFav);
                }
            }
        }
    }

    if (securityService.userIsLoggedIn) {
        return (
            <Button
                fullWidth
                onClick={handleClick}
                endIcon={
                    isFav ? <FavoriteIcon
                        sx={{
                            '&.MuiSvgIcon-root': {
                                color: theme.palette.warning.main
                            }
                        }}
                    />
                        : <FavoriteBorderIcon sx={{
                            '&.MuiSvgIcon-root': {
                                color: theme.palette.warning.main
                            }
                        }}/>
                }
            >
                {
                    isFav
                        ? "Remove from Favorites"
                        : "Add to Favorites"
                }
            </Button>
        )
    }
}

export default AddToFavoriteProjectButton;
import React from 'react';
import { inject, observer } from 'mobx-react';
import { styled, useMediaQuery } from '@mui/material';

import HomeAfterLogin from '@extensions/components/page/home-page/HomeAfterLogin';
import HomeBeforeLogin from '@extensions/components/page/home-page/HomeBeforeLogin';
import GlobalSearchProvider from '@extensions/components/global-search/GlobalSearchProvider';
import HomeSearchResultContainer from '@extensions/components/page/home-page/HomeSearchResultContainer';
import HomePageSearchBarContainer from '@extensions/components/global-search/HomePageSearchBarContainer';

import theme from '@extensions/services/Theme';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { IGlobalSearchService } from '@extensions/services/IGlobalSearchService';

export interface IHomeProps {
  className?: string;
  globalSearchService?: IGlobalSearchService
  securityService?: ISecurityService
}

const StyledDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
  position: 'absolute',
  top: isMobile ? '3.8rem' : '0.8rem',
  left: '50%',
  transform: 'translateX(-50%)',
}));

const Home: React.FC<IHomeProps> = ({ globalSearchService, securityService }) => {

  const isLoggedIn = securityService?.userIsLoggedIn;
  const user = securityService?.user;
  const userFavProjects = user?.fav_project;
  const userFavDatasets = user?.fav_ds;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const searchContent = globalSearchService?.searchContent ? globalSearchService.searchContent : '';

  const clickedButton = globalSearchService?.clickedButton;
  const needStay = globalSearchService?.needStay;

  const renderHomeResultPage = () => {
    if ((searchContent === '' && !clickedButton && !needStay) || (searchContent !== '' && clickedButton && !needStay) || (clickedButton)) {
      return null;
    } else if ((searchContent !== '' && !clickedButton && needStay) || (searchContent === '' && !clickedButton && needStay)) {
      return <HomeSearchResultContainer />
    }
    return <HomeSearchResultContainer />
  };

  const renderHomeStaticSection = () => {
    if (
      (searchContent === '' && !clickedButton && !needStay)
      || (searchContent === '' && clickedButton && !needStay)
      || (searchContent !== '' && clickedButton && !needStay)
    ) {
      globalSearchService?.setClickedButton(false);
      if (isLoggedIn) {
        if (userFavProjects?.length || userFavDatasets?.length) {
          return <HomeAfterLogin />
        }
        return <HomeBeforeLogin showBanner={false}/>
      } else {
        return <HomeBeforeLogin showBanner={true}/>
      }
    } else {
      return null;
    }
  };

  return (
    <div>
      <div>
        <GlobalSearchProvider >
          <StyledDiv isMobile={isMobile}>
            <HomePageSearchBarContainer isRedirecting={true}/>
          </StyledDiv>
          {renderHomeResultPage()}
        </GlobalSearchProvider>
      </div>
      {renderHomeStaticSection()}
    </div>
  )
};

export default inject(
  (store: any) => ({
    globalSearchService: store.globalSearchService,
    securityService: store.securityService
  })
)(observer(Home));
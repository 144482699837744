import { css } from '@emotion/css';
import { inject } from 'mobx-react';
import groupBy from 'lodash/groupBy';
import moment, { Moment } from 'moment';
import { react } from '@appbaseio/reactivesearch/lib/types';
import React, { useState, useEffect, useCallback } from 'react';
import { ReactiveList, StateProvider } from '@appbaseio/reactivesearch';

import {
  DATE_RANGE_REACTIVE_ID,
  FILE_TYPE_REACTIVE_ID,
} from '@extensions/components/page/search-components';
import TimelineContainer from '@extensions/components/dataset/TimelineContainer';
import TimelineToggle from '@extensions/components/page/search-components/TimelineToggle';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';
import SearchPagination from '@extensions/components/page/search-components/SearchPagination';
import DatasetResultCard from '@extensions/components/page/search-components/DatasetResultCard';

import Dataset from '@extensions/models/Dataset';
import { useHashStateKey } from '@extensions/hooks/useUrl';
import ILayoutService from '@extensions/services/ILayoutService';

export interface IResultsListProps {
  react?: react;
  pageSize: number;
  layoutService?: ILayoutService;
  vizByDefault?: boolean;
  searchFieldLabels?: { string: string };
}

interface ResultStats {
  numberOfResults: number;
  numberOfPages: number;
  currentPage: number;
  time: number;
  displayedResults: number;
  hidden: number;
  promoted: number;
}

const getStatsDisplayString = (stats?: ResultStats) => {
  if (stats) {
    const { currentPage, numberOfPages, numberOfResults } = stats;
    return `${numberOfResults} dataset${
      numberOfResults === 1 ? '' : 's'
    } found (page ${currentPage + 1} of ${numberOfPages})`;
  }
  return '';
};

const parseRawDateRangeSearch = (raw: string) => {
  const [beg, end] = raw.split(' to ');
  const format = 'YYYY-MM-DD';
  return [moment(beg, format), moment(end, format)] as [Moment, Moment];
};

const parseRawCategorySearch = (raw: string) => {
  return JSON.parse(raw) as string[];
};

const ResultsList = ({ pageSize, react, layoutService, vizByDefault, searchFieldLabels }: IResultsListProps) => {
  const [urlDateRange] = useHashStateKey(DATE_RANGE_REACTIVE_ID, undefined);
  const [urlFileTypes] = useHashStateKey(FILE_TYPE_REACTIVE_ID, undefined);

  const [currentStats, setCurrentStats] = useState(
    undefined as ResultStats | undefined
  );
  const [currentFileTypes, setCurrentFileTypes] = useState(
    urlFileTypes ? parseRawCategorySearch(urlFileTypes) : undefined
  );
  const [currentRange, setCurrentRange] = useState(
    urlDateRange ? parseRawDateRangeSearch(urlDateRange) : undefined
  );
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  const [initialFullWidth] = useState(
    layoutService ? layoutService.fullWidth : false
  );
  const [tl, setTl] = useHashStateKey('tl', vizByDefault || false);
  const tlEnabled = tl === true || tl === 'true';

  useEffect(() => {
    const sortOptionsElement = document.querySelector(
      'select[name="sort-options"]'
    );
    if (sortOptionsElement) {
      sortOptionsElement.setAttribute('aria-label', 'Sort By');
    }
  });

  useEffect(() => {
    if (layoutService === undefined) {
      return;
    }
    layoutService.setFullWidth(tlEnabled);
    return () => {
      if (initialFullWidth !== tlEnabled) {
        layoutService.setFullWidth(initialFullWidth);
      }
    };
  }, [layoutService, initialFullWidth, tlEnabled]);

  const stateListener = useCallback(
    (prev, next) => {
      const range = next[DATE_RANGE_REACTIVE_ID].value;
      setCurrentRange(
        range === null || range === undefined
          ? undefined
          : parseRawDateRangeSearch(range)
      );
      setCurrentFileTypes(next[FILE_TYPE_REACTIVE_ID].value || []);
    },
    [setCurrentRange]
  );

  return (
    <>
      <p
        className={css`
          height: 0;
          margin: 0;
          padding: 0;
          color: rgba(0, 0, 0, 0);
        `}
        aria-live="assertive"
      >
        <span>{getStatsDisplayString(currentStats)}</span>
      </p>
      <StateProvider
        componentIds={[DATE_RANGE_REACTIVE_ID, FILE_TYPE_REACTIVE_ID]}
        includeKeys={['value']}
        onChange={stateListener}
      />
      <ReactiveList
        componentId="SearchResult"
        className={css`
          border-top: 1px solid #eeeeee;
          padding-top: 0.25rem;
          display: flex;
          flex-direction: column;
          flex: 1;
        `}
        dataField="identifier"
        renderItem={(response: any) => {
          return (
          <DatasetResultCard key={response.name} datasetDocument={response} searchFieldLabels={searchFieldLabels} />
        )
      }}
        react={react}
        innerClass={{
          resultsInfo: css`
            && {
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
            }
          `,
        }}
        size={currentPageSize}
        pagination={true}
        infiniteScroll={false}
        URLParams={true}
        render={({ loading, error, data }) => {
          let results: React.ReactNode;
          if (loading) {
            results = (
              <div
                className={css`
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                  justify-content: flex-start;
                `}
              >
                <CenteredCircularProgress
                  className={css`
                    margin-bottom: 8rem;
                  `}
                  aria-label="loading results"
                />
              </div>
            );
          } else if (error) {
            results = (
              <div>
                Something went wrong! Please email{' '}
                <a href="mailto:dapteam@pnnl.gov">our team</a> if this issue
                persists.
              </div>
            );
          } else if (tlEnabled) {
            enum DataAvailability {
              HAS_DATA = 'hasData',
              NO_DATA = 'noData',
            }
            const getDataAvailability = (hit) => {
              const hasData =
                hit.dapFileSummary &&
                hit.dapFileSummary.count &&
                hit.dapFileSummary.count > 0;
              return hasData
                ? DataAvailability.HAS_DATA
                : DataAvailability.NO_DATA;
            };
            const hitsByDataAvailability = groupBy(data, getDataAvailability);
            const hitsWithData =
              hitsByDataAvailability[DataAvailability.HAS_DATA] ?? [];
            const hitsWithoutData =
              hitsByDataAvailability[DataAvailability.NO_DATA] ?? [];
            results = (
              <TimelineContainer
                datasets={hitsWithData.map((hit) => {
                  return new Dataset(hit);
                })}
                datalessDatasetIds={hitsWithoutData.map(
                  (hit) => { return hit }
                )}
                fileTypes={currentFileTypes}
                defaultZoom={currentRange}
              />
            );
          } else {
            results = (
              <ul
                className={css`
                  list-style: none;
                  margin: 2rem 0 0 0;
                  padding: 0;
                  flex: 1;
                `}
                aria-label="matching datasets"
              >
                {data.map((hit) => {
                  return (
                  <li key={hit.name}>
                    <DatasetResultCard
                      datasetDocument={hit}
                      fileTypes={currentFileTypes}
                      searchFieldLabels={searchFieldLabels}
                    />
                  </li>
                )})}
              </ul>
            );
          }
          return (
            <div role="region" aria-label="search results">
              {results}
            </div>
          );
        }}
        renderPagination={({
          totalPages,
          currentPage,
          setPage,
          fragmentName,
        }) => {
          if (!Number.isFinite(totalPages)) {
            return null;
          }
          return (
            <SearchPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setPage={setPage}
              fragmentName={fragmentName}
              pageSize={currentPageSize}
              getPageNumbers={(currentPageNumber, pageSize) => setCurrentPageSize(pageSize)}
              className={css`
                flex: 0 0 auto;
              `}
            />
          );
        }}
        renderNoResults={() => "No results found."}
        renderResultStats={(stats: ResultStats) => {
          return (
            <div
              aria-hidden
              className={css`
                && {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  margin-right: 0.5rem;
                  align-items: center;
                }
              `}
            >
              <TimelineToggle
                defaultChecked={tlEnabled}
                onChange={(on: boolean) => setTl(on)}
              />
              {getStatsDisplayString(stats)}
            </div>
          );
        }}
        onData={({
          data,
          resultStats,
        }: {
          data: any;
          resultStats: ResultStats;
        }) => {
          setCurrentStats(resultStats);
        }}
        // includeFields={[
        //   'title',
        //   'identifier',
        //   'project_name',
        //   'dapAccessGroup',
        //   'generalUseReady',
        //   'dapFileSummary.count',
        //   'dapFileSummary.size',
        //   'dapFileSummary.types',
        //   'dapFileSummary.extensions',
        //   'dapFileSummary.ends',
        //   'dapFileSummary.begins',
        //   'dapFileSummary.updated',
        //   'imagesDataset',
        // ]}
        sortOptions={[
          {
            label: 'Last Updated',
            dataField: 'dapFileSummary.updated',
            sortBy: 'desc',
          },
          {
            label: 'Best Match',
            dataField: '_score',
            sortBy: 'desc',
          },
          {
            label: 'Name A-Z',
            dataField: 'identifier.keyword',
            sortBy: 'asc',
          },
          {
            label: 'Name Z-A',
            dataField: 'identifier.keyword',
            sortBy: 'desc',
          },
          {
            label: 'Volume Ascending',
            dataField: 'dapFileSummary.size',
            sortBy: 'asc',
          },
          {
            label: 'Volume Descending',
            dataField: 'dapFileSummary.size',
            sortBy: 'desc',
          },
          {
            label: 'File Count Ascending',
            dataField: 'dapFileSummary.count',
            sortBy: 'asc',
          },
          {
            label: 'File Count Descending',
            dataField: 'dapFileSummary.count',
            sortBy: 'desc',
          },
        ]}
      />
    </>
  );
};

export default inject((store: any) => ({
  layoutService: store.layoutService,
}))(ResultsList);

import { 
  Typography, 
  styled, 
  FormControl, 
  MenuItem, 
  Select, 
  Button 
} from '@mui/material';
import React from 'react';
import { inject } from 'mobx-react';

import {
  DocumentLanding as DapDocumentLanding
} from '@dapclient/components/page/DocumentLanding';
import Link from '@extensions/components/core/Link';
import MetaDocument from '@extensions/models/MetaDocument';
import SearchBox from '@extensions/components/core/SearchBox';
import HeadManager from '@extensions/components/page/HeadManager';
import MetadataGuard from '@extensions/components/metadata/MetadataGuard';
import DocumentsTable from '@extensions/components/metadata/DocumentsTable';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import theme from '@extensions/services/Theme';
import { IGlobalSearchService } from '@extensions/services/IGlobalSearchService';

const PUBLISHED = 'published';
const PENDING_AND_DRAFTS = 'pendingdraft';

const StyledProjectSection = styled('div')(({
  marginBottom: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledSplitBarDiv = styled('div')(({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
}));

const StyledSearchBox = styled(SearchBox)(({
  flex: '1 0 auto',
  marginRight: '.5rem',
  width: '80%',
}));

const StyledSelect = styled(Select)(({
  marginRight: '0rem',
  width: '100%'
}));

const StyledContentWrapperDiv = styled('div')(({
  marginTop: '1rem',
}));

const StyledRootDiv = styled('div')(({
  margin: '0 1em',
}));

interface IDocumentLandingProps {
  globalSearchService?: IGlobalSearchService;
}
export class DocumentLanding extends DapDocumentLanding<IDocumentLandingProps> {

  UNSAFE_componentWillMount(): void {
    this.props.globalSearchService?.setSelectedCategory('');
    this.props.globalSearchService?.setSearchContent('');
  }

  getLinkToUpload = (): React.ReactNode => {
    return (
      <>
        <HeadManager
          title="WDH: Wind Data Hub Metadata"
          description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
            Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
        />
        <Typography
          sx={{
            color: theme.palette.grey[700]
          }}
        >
          Need to upload data? Go to the{' '}
          <Link to="/upload">data upload page</Link> to get started.
        </Typography>
      </>
    );
  };

  renderPageContent = () => {
    const { metadataService } = this.props;
    if (!metadataService) {
      return null;
    }

    let contents: React.ReactNode;
    if (metadataService.loaded) {
      const docs =
        this.searchType === PENDING_AND_DRAFTS
          ? metadataService.docsByProjectName
          : metadataService.publishedDocsByProjectName;
      const matchingEntries = Object.entries(docs)
        .map(([projectName, documents]) => {
          const filteredDocs = documents.filter(this.docMatches);
          return [projectName, filteredDocs];
        })
        .filter(([projectName, documents]) => {
          return documents.length > 0;
        }) as [string, MetaDocument[]][];
      if (matchingEntries.length > 0) {
        contents = matchingEntries.map(([projectName, documents]) => (
          <StyledProjectSection key={projectName}>
            <Link
              variant="h2"
              to={`/project/${projectName}`}
              sx={{
                textTransform: 'uppercase',
                marginBottom: '0.75rem',
              }}
            >
              {projectName}
            </Link>
            <DocumentsTable
              isPublishedSearchType={this.searchType === PUBLISHED}
              documents={documents}
              searchQuery={this.searchQuery}
            />
          </StyledProjectSection>
        ));
      } else {
        contents = <Typography>No Matching Results</Typography>;
      }
    }
    return (
      <MetadataGuard>
        <StyledRootDiv>
          <Typography variant="h1">
            Metadata Submissions
          </Typography>
          {this.getLinkToUpload()}

          <StyledSplitBarDiv>
            <StyledSearchBox
              value={this.searchQuery}
              onChange={this.setSearchQuery}
              label="Search Submissions"
            />
            <FormControl>
              <StyledSelect
                value={this.searchType}
                onChange={(event) => this.setSearchType(event.target.value)}
                variant='standard'
                sx={{
                  marginLeft: '0.7rem',
                  marginRight: '0.7rem',
                  width: '150px',
                  paddingBottom: '0.2rem',
                  ".MuiSelect-select": {
                    paddingTop: '0.1rem',
                  },
                  ".MuiSvgIcon-root": {
                    marginTop: '-0.25rem',
                  },
                }}
              >
                <MenuItem
                  key={PENDING_AND_DRAFTS}
                  value={PENDING_AND_DRAFTS}
                >
                  <em>Pending &amp; Draft</em>
                </MenuItem>
                <MenuItem key={PUBLISHED} value={PUBLISHED} sx={{ justifyContent: "flex-start" }}>
                  <em>Published</em>
                </MenuItem>
              </StyledSelect>
            </FormControl>
            <Button
              variant="contained"
              sx={{
                backgroundColor: `${theme.palette.primary.main} !important`,
                fontSize: '13px',
                fontWeight: 500,
                whiteSpace: 'nowrap',
                height: theme.spacing(5),
                padding: `${theme.spacing(0.5)} ${theme.spacing(3.375)}`
              }}
              size="small"
              onClick={this.newDocument}
            >
              Add New Metadata
            </Button>
          </StyledSplitBarDiv>
          <StyledContentWrapperDiv>{contents}</StyledContentWrapperDiv>
        </StyledRootDiv>
      </MetadataGuard>
    );
  };

  renderContent = () => {
    return <GlobalSearchBarWrap renderChildren={() => this.renderPageContent()} />
  }
}

export default inject((store: any) => ({
  metadataService: store.metadataService,
  historyService: store.historyService,
  globalSearchService: store.globalSearchService,
}))(DocumentLanding);

import { 
  Alert, 
  AlertTitle, 
  Grid, 
  styled, 
  Typography 
} from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';

import ZipOption from '@extensions/components/order/options/ZipOption';
import ApiOption from '@extensions/components/order/options/ApiOption';
import ScriptOption from '@extensions/components/order/options/ScriptOption';
import FileLinksOption from '@extensions/components/order/options/FileLinksOption';

import { 
  DownloadOption, 
  OrderStatus, 
  reasonCantUseOption 
} from '@extensions/models/FileOrder';
import FileOrderGroup from '@extensions/models/FileOrderGroup';

const StyledHeader = styled(Typography)(({
  fontSize: '1.25rem'
}))

interface IOrderOptionsProps {
  order: FileOrderGroup;
}

const OrderOptions: React.FC<IOrderOptionsProps> = observer(({ order }) => {
  const orderNotRead = order.status === OrderStatus.PENDING;
  
  return (
    <>
      {orderNotRead ? (
        <Alert>
          <AlertTitle>Order Pending</AlertTitle>
          <StyledHeader>
            Your order is being retrieved from long-term storage. You will be emailed when it is ready to download.
          </StyledHeader>
        </Alert>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledHeader>How would you like to download the data?</StyledHeader>
          </Grid>

          <Grid item xs={12} lg={3}>
            <FileLinksOption order={order} />
          </Grid>

          <Grid item xs={12} lg={3}>
            <ZipOption order={order} />
          </Grid>

          <Grid item xs={12} lg={3}>
            <ScriptOption order={order} />
            {reasonCantUseOption(DownloadOption.SCRIPT, order) && (
              <Alert severity="info">{reasonCantUseOption(DownloadOption.SCRIPT, order)}</Alert>
            )}
          </Grid>

          <Grid item xs={12} lg={3}>
            <ApiOption />
          </Grid>

        </Grid>
      )}
    </>
  );
});

export default OrderOptions;

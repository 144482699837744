import {
  Card,
  CardContent,
  CardActions,
  Collapse,
  Typography,
  styled,
  Button
} from '@mui/material';
import React, { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {
  renderViewStats,
  renderTotalDynamoSizesStats,
  renderDownloadHistoryCharts,
  getOrderStat,
  getByteRequestStat,
} from '@extensions/components/core/getMetrics';

import theme from '@extensions/services/Theme';
import IHasMetrics from '@extensions/models/IHasMetrics';

interface IStatsBarProps {
  item: IHasMetrics;
}

const StyledStatsCard = styled(Card)(() => ({
  marginTop: '10px', 
  minHeight: '40px',
  marginBottom: '10px', 
  backgroundColor: '#F9FAFB',
}));

const StyledTitle = styled(Typography)(() => ({
  color: '#000000',
  fontSize: '1.20em',
}));

const StyledShowAll = styled(Button)(() => ({
  display: 'flex',
  cursor: 'pointer',
  color: theme.palette.secondary.main,
  margin: 'auto',
  fontSize: '0.9em',
  textTransform: 'capitalize',
  letterSpacing: 'normal',
}));

const ProjectStatsBar: React.FC<IStatsBarProps> = ({ item }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledStatsCard variant='outlined'>
      <CardContent>
        <StyledTitle>Metrics</StyledTitle>
        {renderDownloadHistoryCharts({ item })}
        {renderViewStats({ item })}
      </CardContent>

      <CardActions>
        <StyledShowAll
          onClick={handleExpandClick}
        >
          <Typography variant="body2" style={{ marginRight: 5, color: theme.palette.secondary.main }}>Show All</Typography>
          <ArrowDropDownIcon
            style={{
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease-in-out',
              color: theme.palette.secondary.main,
            }}
          />
        </StyledShowAll>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {renderTotalDynamoSizesStats({ item })}
          {getOrderStat({ item })}
          {getByteRequestStat({ item })}
        </CardContent>
      </Collapse>
    </StyledStatsCard>
  )
}

export default ProjectStatsBar;

import { SelectedFilters } from "@appbaseio/reactivesearch";
import { SearchBar } from "@extensions/components/search-core";
import { Card, Link, styled } from "@mui/material";

export const StyledSearchBar = styled(SearchBar)(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
}));

export const StyledSelectedFilters = styled(SelectedFilters)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const HitWrapper = styled(Card)`
  margin: 1rem 0;

  .details {
    padding: 1rem;
  }

  .details .title {
    font-weight: bold;
  }

  .description, .license {
    font-size: 80%;
    line-height: 1;
  }

  .stats {
    border-radius: 2px;
    padding: 1rem;

    .title {
      padding: 0.3rem 0.55rem;
      border-bottom: 1px solid #ddd;
    }

    .body {
      padding: 0.5rem;

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      a > svg {
        margin-right: 0.25rem;
      }
    }
  }
`

export const StyledLink = styled(Link)({
  textDecoration: 'none',
});
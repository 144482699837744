
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled
} from '@mui/material';
import { reaction } from 'mobx';
import React, { useEffect, useState } from 'react';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

import {
  DownloadOption,
  canDownloadUsingOption,
} from '@extensions/models/FileOrder';
import AddIcon from '@extensions/utils/AddIcon';
import FileOrderGroup from '@extensions/models/FileOrderGroup';

const StyledLi = styled('li')(({ theme }) => ({
  '&:hover': {
    color: theme.palette.text.primary,
  },
}));

const StyledIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 'auto',
  marginRight: theme.spacing(2),
}));

interface IFileLinksDialogProps {
  open: boolean;
  onClose: () => void;
  order: FileOrderGroup;
}

const FileLinksDialog: React.FC<IFileLinksDialogProps> = ({ open, onClose, order }) => {
  const [downloadUrls, setDownloadUrls] = useState(order.downloadUrls);

  useEffect(() => {
    if (canDownloadUsingOption(DownloadOption.LINKS, order)) {
      order.loadDownloadUrls();
  
      const dispose = reaction(
        () => order.downloadUrls,
        (urls) => setDownloadUrls(urls)
      );
  
      return () => dispose(); 
    }
  }, [order]);
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="file-links-dialog"
      scroll="paper"
    >
      <DialogTitle id="file-links-dialog">File Links</DialogTitle>
      <DialogContent dividers>
        {downloadUrls ? (
          <List aria-label="file download links">
            {downloadUrls.map(({ url, name }) => (
              <StyledLi key={url}>
                <ListItem component="a" href={url} download={name}>
                  <StyledIcon>
                    <AddIcon icon={faFileDownload} size="lg" />
                  </StyledIcon>
                  <ListItemText primary={name} />
                </ListItem>
              </StyledLi>
            ))}
          </List>
        ) : (
          <CenteredCircularProgress />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default FileLinksDialog

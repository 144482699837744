import React from 'react';
import { Card } from '@mui/material';
import { react } from '@appbaseio/reactivesearch/lib/types';

import MultiList from '@extensions/components/core/MultiList';

export const MODEL_REACTIVE_ID = 'ModelFilter';

interface ModelFilterProps {
  react?: react;
}

const ModelFilter: React.FC<ModelFilterProps> = ({ react }) => {
  return (
    <div>
      <MultiList
        className="data-filter"
        componentId={MODEL_REACTIVE_ID}
        dataField="model.keyword"
        title="Model"
        filterLabel="Model"
        showSearch={false}
        react={react}
        URLParams={true}
      />
    </div>
  );
};

export default ModelFilter;

import { observer } from 'mobx-react';
import { Typography, styled } from '@mui/material';

import FaqSection from '@extensions/models/FaqSection';

import QandAnswerAccordion from '@extensions/components/faq/QandAnswerAccordion';

const StyledHeader = styled(Typography)(() => ({
  fontSize: '1.5rem',
}));

const StyledSection = styled('div')(() => ({
  margin: '1rem 0',
}));

interface FaqListProps {
  faqSections: FaqSection[];
  searchText: string;
}

const FaqList: React.FC<FaqListProps> = observer(({ faqSections, searchText }) => {
  const collapseWhitespace = (str: string) => str.trim().replace(/\s+/g, ' ');

  const roughlyIncludes = (searchString: string, subString: string) => {
    const normalize = (str: string) => collapseWhitespace(str).toLowerCase();
    return normalize(searchString).includes(normalize(subString));
  };

  return (
    <>
      {faqSections.map((section, index) => (
        <StyledSection key={index}>
          <StyledHeader>{section.label}</StyledHeader>
          <div>
            {section.questionAndAnswers.map((qAndA) => {
              const match =
                roughlyIncludes(qAndA.answerMarkdown, searchText) ||
                roughlyIncludes(qAndA.question, searchText);
              return (
                <QandAnswerAccordion
                  key={qAndA.id}
                  qAndA={qAndA}
                  searchText={searchText}
                  searchmatch={match}
                />
              );
            })}
          </div>
        </StyledSection>
      ))}
    </>
  );
});

export default FaqList;

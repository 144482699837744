import React from 'react';
import { Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import HeadManager from '@extensions/components/page/HeadManager';

const StyledRootDiv = styled('div')(({}))

export interface IExternalAppProps extends RouteComponentProps {
  className?: string;
}

export interface IExternalAppState { }

@observer
export class ExternalApp extends React.Component<
  IExternalAppProps,
  IExternalAppState
> {
  render() {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const encodedExternalUrl = searchParams.get('external-url');
    const encodedTitle = searchParams.get('title');
    let body: React.ReactNode;
    let helmet;
    if (!encodedExternalUrl) {
      helmet = (
        <HeadManager
          title="WDH: Wind Data Hub Malformed URL"
          description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
            Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
        />
      );
      body = <Typography>Malformed URL</Typography>;
    } else {
      helmet = (
        <HeadManager
          title={"WDH: Wind Data Hub" + (encodedTitle ? decodeURIComponent(encodedTitle) : 'External App')}
          description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
            Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
        />
      )
      body = (
        <iframe
          title={
            encodedTitle ? decodeURIComponent(encodedTitle) : 'External App'
          }
          id="external-app"
          src={decodeURI(encodedExternalUrl)}
          scrolling="no"
          style={{
            overflow: 'hidden',
            height: '2236px',
            width: '100%',
            boxShadow: 'none',
            border: 'none',
          }}
        />
      );
    }
    return (
      <StyledRootDiv>
        {helmet}
        {body}
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(withRouter(ExternalApp));

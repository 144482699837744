import { observable, action, computed, makeObservable } from 'mobx';

export default class User {
  @computed get fullName() {
    if (this.firstname && this.lastname) {
      return `${this.firstname} ${this.lastname}`;
    } else if (this.firstname) {
      return this.firstname;
    } else if (this.lastname) {
      return this.lastname;
    }
    return '';
  }

  static getGuestUser(): User {
    return new User({
      username: '',
      name_given: '',
      name_family: '',
      email: '',
      orcId: '',
      justification: '',
      api_umbrella_key: '',
      agreed: false,
      approved: false,
      verified: false,
      mfa: false,
      mfa_enabled: false,
    });
  }

  username: string;
  @observable firstname: string;
  @observable lastname: string;
  @observable email: string;
  @observable orcId: string;
  @observable justification: string;
  @observable apiKey: string;
  @observable authenticated: boolean;
  @observable authenticatedMfa: boolean;
  @observable agreed: boolean;
  @observable approved: boolean;
  @observable approvalPending: boolean;
  @observable emailVerified: boolean;
  @observable memberOf: string[];
  @observable mfaEnabled: boolean;
  @observable mfaRequiresEmailVerification: boolean;
  @observable mfaNew: boolean;
  @observable mfaAccessKey: string;
  @observable mfaToken: string;
  @observable mfaQRCode: string;
  @observable resourceNamesRequested: string[];
  @observable canEdit: boolean;
  @observable canAdminPubs: boolean;
  @observable fav_ds: string[];
  @observable fav_project: string[];
  constructor(data) {
    makeObservable(this);
    this.username = data.username;
    this.firstname = data.name_given;
    this.lastname = data.name_family;
    this.email = data.email;
    this.orcId = data.orcId;
    this.justification = data.justification;
    this.apiKey = data.api_umbrella_key;
    this.agreed = data.agreed;
    this.approved = data.approved;
    this.approvalPending =
      data.approval_pending === undefined
        ? !data.approved
        : data.approval_pending;
    this.emailVerified = data.verified;

    this.authenticated = false;

    // mfa might not be an option for all DAP deployments
    this.authenticatedMfa = data.mfa ? data.mfa : false;
    this.mfaEnabled = data.mfa_enabled ? data.mfa_enabled : false;
    this.mfaRequiresEmailVerification = data.mfa_requires_email_verification;
    this.mfaNew = false;
    this.mfaAccessKey = '';
    this.mfaToken = '';
    this.mfaQRCode = '';
    this.resourceNamesRequested = data.access_requests || [];
    this.memberOf = data.member_of || [];
    this.canEdit = data.can_edit || false;
    this.canAdminPubs = data.can_admin_pubs || false;
    this.fav_ds = data.fav_ds || [];
    this.fav_project = data.fav_project || [];
  }

  /**
   * Setter $firstname
   * @param {string} value
   */
  @action setFirstname(value: string) {
    this.firstname = value;
  }

  /**
   * Setter $lastname
   * @param {string} value
   */
  @action setLastname(value: string) {
    this.lastname = value;
  }

  /**
   * Setter $email
   * @param {string} value
   */
  @action setEmail(value: string) {
    this.email = value;
  }

  /**
   * Setter $orcId
   * @param {string} value
   */
  @action setOrcId(value: string) {
    this.orcId = value;
  }

  /**
   * Setter $apiKey
   * @param {string} value
   */
  @action setApiKey(value: string) {
    this.apiKey = value;
  }

  /**
   * Setter $justification
   * @param {string} value
   */
  @action setJustification(value: string) {
    this.justification = value;
  }

  /**
   * Setter $authenticated
   * @param {boolean} value
   */
  @action setAuthenticated(value: boolean) {
    this.authenticated = value;
  }

  /**
   * Setter $authenticatedMfa
   * @param {boolean} value
   */
  @action setAuthenticatedMfa(value: boolean) {
    this.authenticatedMfa = value;
  }

  /**
   * Setter $agreed
   * @param {boolean} value
   */
  @action setAgreed(value: boolean) {
    this.agreed = value;
  }

  /**
   * Setter $emailVerified
   * @param {boolean} value
   */
  @action setEmailVerified(value: boolean) {
    this.emailVerified = value;
  }
  /**
   * Setter $approved
   * @param {boolean} value
   */
  @action setApproved(value: boolean) {
    this.approved = value;
    if (this.approved && this.approvalPending) {
      this.approvalPending = false;
    }
  }

  /**
   * Setter $mfaEnabled
   * @param {boolean} value
   */
  @action setMfaEnabled(value: boolean) {
    this.mfaEnabled = value;
  }

  /**
   * Setter $mfaNew
   * @param {boolean} value
   */
  @action setMfaNew(value: boolean) {
    this.mfaNew = value;
  }

  /**
   * Setter $mfaAccessKey
   * @param {string} value
   */
  @action setMfaAccessKey(value: string) {
    this.mfaAccessKey = value;
  }

  /**
   * Setter $mfaToken
   * @param {string} value
   */
  @action setMfaToken(value: string) {
    this.mfaToken = value;
  }

  /**
   * Setter $mfaQRCode
   * @param {string} value
   */
  @action setMfaQRCode(value: string) {
    this.mfaQRCode = value;
  }
}

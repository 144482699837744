import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ChevronRight from '@mui/icons-material/ChevronRight';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { styled, Typography, Button, TextField, Link, Alert } from '@mui/material';

import VirtualizedAutocomplete from '@extensions/components/core/VirtualizedAutocomplete';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import theme from '@extensions/services/Theme';
import { Status } from '@extensions/services/INotificationService';
import { useContactUsService, useNotificationService, useUploadService, useGlobalSearchService } from '@extensions/hooks/useService';

const StyledRootDiv = styled('div')({
  marginBottom: '2rem',
  width: '100%',
  padding: '0 1rem', 
});

const StyledTitle = styled(Typography)({
  fontSize: '2rem', 
  '@media (max-width: 800px)': {
    fontSize: '1.5rem',
  },
});

const StyledChoicesDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginTop: '2rem',
  '@media (max-width: 800px)': {
    flexDirection: 'column',
    width: '80%',
  },
});

const StyledChoiceContainerDiv = styled('div')({
  display: 'flex',
  width: '50%',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem 4rem',
  '@media (max-width: 800px)': {
    width: '100%', 
    padding: '1rem 2rem',
  },
});

const StyledChoiceExplanation = styled('div')({
  backgroundColor: '#eee',
  borderRadius: '5px',
  padding: '1rem',
  width: '91%',
  '@media (max-width: 800px)': {
    width: '100%',
  },
});

const StyledTextField = styled(TextField)({
  '& > div': { backgroundColor: '#fff' },
  width: '100%',
});

const StyledDescription = styled(Typography)({
  color: theme.palette.grey[700],
  '@media (max-width: 800px)': {
    fontSize: '0.875rem',
  },
});

const StyledListItem = styled('li')({
  color: theme.palette.grey[700],
  fontSize: '1rem',
});

const StyledIcon = styled(OpenInBrowserIcon)({
  fontSize: '150px !important',
  color: theme.palette.grey[700],
  '@media (max-width: 800px)': {
    fontSize: '120px',
  },
});

const StyledSettingsIcon = styled(SettingsApplicationsIcon)({
  fontSize: '150px !important',
  color: theme.palette.grey[700],
  '@media (max-width: 800px)': {
    fontSize: '120px',
  },
});

const StyledButton = styled(Button)({
  width: '100%',
  marginTop: '1rem',
  '@media (max-width: 800px)': {
    fontSize: '1rem',
  },
});

const StyledForm = styled('form')({
  width: '100%',
});

const StyledBorderContainer = styled(StyledChoiceContainerDiv)({
  borderRight: '1px solid #ccc',
  '@media (max-width: 800px)': {
    borderRight: 'none',
  },
});

const StyledStrongText = styled(Typography)({
  fontWeight: 'bold',
  color: theme.palette.grey[800],
});

const StyledLink = styled(Link)({
  cursor: 'pointer',
});

const StyledAlert = styled(Alert)({
  marginBottom: '1rem',
});

const NOTIFICATION_ID = 'upload-page';

const Upload: React.FC = observer(() => {
  const uploadService = useUploadService();
  const contactUsService = useContactUsService();
  const notificationService = useNotificationService();
  const globalSearchService = useGlobalSearchService();

  const history = useHistory();
  const [selectedDataset, setSelectedDataset] = useState<string | null>(null);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);

  useEffect(() => {
    uploadService?.load();
  }, [uploadService]);

  const handleBeginConfigure = (event: React.FormEvent) => {
    event.preventDefault();
    if (!selectedProject) {
      notificationService?.addNotification(NOTIFICATION_ID, Status.Error, 'Project Name Required', 'Please Select a Project Name', true, true);
    } else {
      history.push(`/uploaders/${selectedProject}`);
    }
  };

  const handleBeginUpload = (event: React.FormEvent) => {
    event.preventDefault();
    if (!selectedDataset) {
      notificationService?.addNotification(NOTIFICATION_ID, Status.Error, 'Dataset Name Required', 'Please Select a Dataset Name', true, true);
    } else {
      history.push(`/ds/${selectedDataset}/upload`);
    }
  };

  const renderPageContent = () => {
    return (
      <StyledRootDiv>
      <Typography variant='h1'>Submit Data</Typography>
      <StyledDescription>Use one of the options below to upload data.</StyledDescription>
      <StyledDescription>
        <em>
          If the project or dataset you are looking for is not in the list, please{' '}
          <StyledLink underline='hover' onClick={() => contactUsService?.openModal()}>
            let us know
          </StyledLink>
          .
        </em>
      </StyledDescription>
      <StyledChoicesDiv>
        <StyledBorderContainer>
          <StyledIcon />
          <StyledTitle>Drag and Drop</StyledTitle>
          <StyledChoiceExplanation>
            <StyledStrongText>Ideal for files that are:</StyledStrongText>
            <ul>
              <StyledListItem>small (in size <em>and</em> quantity),</StyledListItem>
              <StyledListItem>on this computer, <em>and</em></StyledListItem>
              <StyledListItem>a static set.</StyledListItem>
            </ul>
            {uploadService.datasetNames.length === 0 && (
              <StyledAlert severity='warning'>
                It looks like you don't have upload access to any datasets. Please{' '}
                <StyledLink onClick={() => contactUsService?.openModal()}>
                  let us know
                </StyledLink>
                {' '} what you need.
              </StyledAlert>
            )}
            <StyledForm onSubmit={handleBeginUpload}>
              <VirtualizedAutocomplete
                options={uploadService.datasetNames}
                value={selectedDataset}
                onChange={(_, newSelection) => setSelectedDataset(newSelection)}
                renderInput={params => <StyledTextField {...params} label='Select Dataset' variant='outlined' size='small' />}
              />
              <StyledButton variant='contained' color='primary' type='submit' disableElevation disabled={!selectedDataset} startIcon={<ChevronRight />}>Get Started</StyledButton>
            </StyledForm>
          </StyledChoiceExplanation>
        </StyledBorderContainer>
        <StyledChoiceContainerDiv>
          <StyledSettingsIcon />
          <StyledTitle>Uploader Client</StyledTitle>
          <StyledChoiceExplanation>
            <StyledStrongText>Ideal for files that are:</StyledStrongText>
            <ul>
              <StyledListItem>large (in size <em>or</em> quantity),</StyledListItem>
              <StyledListItem>remote, <em>or</em></StyledListItem>
              <StyledListItem>routine / ongoing.</StyledListItem>
            </ul>
            {uploadService.projectNames.length === 0 && (
              <StyledAlert severity='warning'>
                It looks like you don't have upload access to any projects. Please{' '}
                <StyledLink onClick={() => contactUsService?.openModal()}>
                  let us know
                </StyledLink>
                {' '} what you need.
              </StyledAlert>
            )}
            <StyledForm onSubmit={handleBeginConfigure}>
              <VirtualizedAutocomplete
                options={uploadService.projectNames}
                value={selectedProject}
                onChange={(_, newSelection) => setSelectedProject(newSelection)}
                renderInput={params => <StyledTextField {...params} label='Select Project' variant='outlined' size='small' />}
              />
              <StyledButton variant='contained' color='primary' type='submit' disableElevation disabled={!selectedProject} startIcon={<ChevronRight />}>Get Started</StyledButton>
            </StyledForm>
          </StyledChoiceExplanation>
        </StyledChoiceContainerDiv>
      </StyledChoicesDiv>
    </StyledRootDiv>
    )
  }

  globalSearchService.setSearchContent('');
  globalSearchService.setSelectedCategory('');
  return <GlobalSearchBarWrap renderChildren={() => renderPageContent()} />
});

export default Upload;

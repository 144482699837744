import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Menu, Card, MenuItem } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { react } from '@appbaseio/reactivesearch/lib/types';

import MultiList from '@extensions/components/core/MultiList';

export const PROJECT_REACTIVE_ID = 'ProjectFilter';

interface ProjectFilterProps {
  fixedValue?: string;
  react?: react;
}

const ProjectFilter: React.FC<ProjectFilterProps> = ({ fixedValue, react }) => {
  const [sortAlphabetically, setSortAlphabetically] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (sortAlphabetically: boolean) => {
    setSortAlphabetically(sortAlphabetically);
    handleMenuClose();
  };

  const handleSelectionChange = (selectedValues: string[]) => {
    setSelectedItems(selectedValues);
  };

  const sortedItems = (items: any) => {
    return items.sort((a: any, b: any) => {
      if (sortAlphabetically) {
        return a.key.localeCompare(b.key);
      } else {
        return b.doc_count - a.doc_count;
      }
    });
  };

  const filterHeader = (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <span style={{ textTransform: 'capitalize', fontSize: '1rem' }}>
        Project
      </span>
      <div onClick={handleMenuOpen} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        <FilterAltIcon />
      </div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => handleSort(false)}>Sort by Count</MenuItem>
        <MenuItem onClick={() => handleSort(true)}>Sort Alphabetically</MenuItem>
      </Menu>
    </div>
  );

  return (
    <div style={fixedValue ? { display: 'none' } : {}}>
      <MultiList
        className="data-filter"
        componentId={PROJECT_REACTIVE_ID}
        dataField="project_name.keyword"
        title={filterHeader}
        filterLabel="Project"
        showSearch={false}
        react={react}
        URLParams={!Boolean(fixedValue)}
        showFilter={!Boolean(fixedValue)}
        sortBy={sortAlphabetically ? 'asc' : 'count'}
        onChange={handleSelectionChange}
        value={selectedItems}
        transformData={sortedItems}
        defaultValue={fixedValue ? [fixedValue] : undefined}
      />
    </div>
  );
};

export default observer(ProjectFilter);

import { reaction } from 'mobx';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';

import CitationPanel from '@extensions/components/core/CitationPanel';
import ProjectContacts from '@extensions/components/core/ContactsPanel';
import ReferencesPanel from '@extensions/components/core/ReferencesPanel';
import KeywordsPanel from '@extensions/components/core/KeywordsPanel';
import ProjectDescription from '@extensions/components/project/ProjectDescription'
import AddToFavoriteProjectButton from '@extensions/components/core/AddToFavoriteProjectButton';
import ProjectStatsBar from '@extensions/components/project/project-details-components/ProjectStatsBar';

import { useMetricsService, useProjectService } from '@extensions/hooks/useService';

const ProjectOverview: React.FC = () => {
  const projectService = useProjectService();
  const metricsService = useMetricsService();
  const project = projectService.project;

  useEffect(() => {
    const disposer = reaction(
      () => projectService.project,
      (project) => {
        if (project) {
          metricsService.getGAMetrics(project);
        }
      }
    );

    return () => disposer();
  }, [projectService.project, metricsService]);

  return (
    <>
      {project && ( 
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <ProjectDescription project={project}/>
            <ReferencesPanel project={project} isSmallText={true}/>
          </Grid>
          <Grid item xs={12} md={4}>
            <ProjectContacts contacts={project.contactPoint} name={project.identifier} title={project.title} orgs={project.participatingOrganizations} />
            <CitationPanel  projectTitle={project.title} doiName={project.doiName} />
            <KeywordsPanel keywords={project.keywords}/>
            <ProjectStatsBar item={project} />
            <AddToFavoriteProjectButton project={project} />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ProjectOverview;

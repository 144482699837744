import chroma from 'chroma-js';
import { createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const overriddenDefaultPalette = {
  primary: {
    main: '#00AEE6', // teal
    light: '#42a5fa',
    dark: 'rgb(1, 63, 91)',
    contrastText: 'rgb(0, 0, 0)',
  },
  secondary: {
    light: 'rgba(1, 130, 177, 1)',
    main: 'rgb(2, 91, 130)', // darker blue for fonts
  },
  common: {
    black: '#000',
    white: '#fff',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgb(117, 117, 117)',
    hint: 'rgb(238, 238, 238)',
  },
  metrics: {
    main: green[800],
  },
  timeline: {
    primary: 'rgb(108, 176, 70)',
    secondary: 'rgb(0, 169, 224)',
    chromaGradientScale: chroma.scale('Oranges'),
  },
  highlight: {
    main: 'rgb(255, 255, 0)',
  },
  warning: { 
    main: 'rgb(255, 0, 0)', 
  },
};

const theme = createTheme({
  palette: overriddenDefaultPalette,
  typography: {
    fontFamily: "'Open Sans',sans-serif",
  },
  contentWidth: {
    min: '994px',
    max: '650px',
  },
  FilterTitle: {
    root: {},
    titleText: {},
  },
  linkIcon: {
    marginBottom: 0,
  },
  FullPageForm: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      marginBottom: 15,
      maxWidth: 450,
    },
    formFooter: {
      marginBottom: 15,
      textAlign: 'center',
    },
  },
  Stat: {
    statValue: {
      fontSize: '2rem',
      lineHeight: 1,
      // color: theme.palette.secondary.main,
      fontFamily: "'Oswald',sans-serif",
    },
    statDescription: {
      fontSize: 10,
    },
  },
  GreenButton: {
    root: {
      backgroundColor: green[800],
      '&:hover': {
        backgroundColor: green[900],
      },
    },
  },
  openSansFontFamily: "'Open Sans', sans-serif !important",
  MuiAlert: {
    outlinedSuccess: {
      border: '1px solid #b7eb8f',
      backgroundColor: '#f6ffed',
      color: 'rgba(0,0,0,0.65)',
    },
    outlinedInfo: {
      border: '1px solid #91d5ff',
      backgroundColor: '#e6f7ff',
      color: 'rgba(0,0,0,0.65)',
    },
    outlinedWarning: {
      border: '1px solid #ffe58f',
      backgroundColor: '#fffbe6',
      color: 'rgba(0,0,0,0.65)',
    },
    outlinedError: {
      border: '1px solid #ffccc7',
      backgroundColor: '#fff2f0',
      color: 'rgba(0,0,0,0.65)',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: overriddenDefaultPalette.secondary.main,
        },
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&&:before': {
            opacity: '0',
          },
          border: '1px solid rgba(0, 0, 0, .125)',
          borderBottom: 'none',
          '&.Mui-expanded': {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&&': {
            outline: 0,
          },
          '&&&:focus': {
            outline: 0,
          },
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: '0.1em',
          fontSize: '1rem',
        },
        textPrimary: {
          color: overriddenDefaultPalette.secondary.main,
        },
        containedPrimary: {
          color: overriddenDefaultPalette.common.black,
          backgroundColor: overriddenDefaultPalette.primary.light,
          '&:hover': {
            backgroundColor: overriddenDefaultPalette.primary.main,
          },
        },
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#586581',
          "&.Mui-completed": {
            "&&": {
              color: overriddenDefaultPalette.secondary.main,
            }
          },
          "&.Mui-active": {
            "&&": {
              color: overriddenDefaultPalette.secondary.main,
            }
          }
        },
        text: {
          fill: overriddenDefaultPalette.common.white,
        },
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          color: '#586581',
          "&.Mui-completed": {
            "&&": {
              color: overriddenDefaultPalette.secondary.main,
            }
          },
          "&.Mui-active": {
            "&&": {
              color: overriddenDefaultPalette.secondary.main,
            }
          }
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: overriddenDefaultPalette.secondary.main,
          fontWeight: 500,
          fontSize: '1rem',
        },
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '&&': {
              color: overriddenDefaultPalette.text.primary,
            },
            '&&:hover': {
            // backgroundColor: theme.palette.primary.main,
          },
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&&&': {
            outline: 0,
          },
          '&&&:focus': {
            outline: 0,
          },
        },
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input': {
            height: 'fit-content',
          },
        },
      },
    },
  }
});
if (theme.GreenButton) {
  theme.GreenButton.root.color = theme.palette.getContrastText(green[800]);
}
theme.Stat.statValue.color = theme.palette.metrics
  ? theme.palette.metrics.main
  : theme.palette.secondary.main;

theme.FilterTitle = {
  root: {
    backgroundColor: theme.palette.secondary.main,
    padding: '0.25rem 0.5rem',
    minWidth: '300px',
    marginBottom: '0.5rem',
  },
  titleText: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1rem',
  },
};

theme.typography.h1.fontWeight = theme.typography.fontWeightLight;
theme.typography.h1.fontSize = '3rem';
theme.typography.h1.marginBottom = '0.5rem';
theme.typography.h2.fontWeight = theme.typography.fontWeightLight;
theme.typography.h2.fontSize = '1.75rem';
theme.typography.h3.fontWeight = theme.typography.fontWeightRegular;
theme.typography.h3.fontSize = '1.25rem';

theme.typography.body1 = {
  ...theme.typography.body1,
  'p&': {
    marginBottom: '1rem',
  },
};

export default theme;

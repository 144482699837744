import React from 'react';
import { Card } from '@mui/material';
import { react } from '@appbaseio/reactivesearch/lib/types';

import MultiList from '@extensions/components/core/MultiList';

export const RECOMMENDED_REACTIVE_ID = 'RecommendedFilter';

interface RecommendedFilterProps {
  react?: react;
}

const RecommendedFilter: React.FC<RecommendedFilterProps> = ({ react }) => {
  const transformData = (data: { key: any; doc_count: number }[]) => {
    return data
      .filter((d) => d.key === 1)
      .map(({ key, doc_count }) => {
        const prettyKey = {
          1: 'Yes',
        }[key];
        return {
          key: prettyKey,
          doc_count,
        };
      });
  };

  const customQuery = (yesNoValue: string[], props: any) => {
    if (!yesNoValue) {
      return {};
    }

    const trueFalseValueArr = yesNoValue.map((v) => {
      if (v === 'Yes') {
        return true;
      }
      return {};
    });

    if (trueFalseValueArr && trueFalseValueArr.length > 0) {
      return {
        query: {
          terms: {
            generalUseReady: trueFalseValueArr,
          },
        },
      };
    } else {
      return {
        query: {
          match_all: {},
        },
      };
    }
  };

  return (
    <div>
      <MultiList
        className="data-filter"
        componentId={RECOMMENDED_REACTIVE_ID}
        dataField="generalUseReady"
        title="Preferred for General Use"
        filterLabel="Preferred"
        showSearch={false}
        react={react}
        URLParams={true}
        transformData={transformData}
        customQuery={customQuery}
      />
    </div>
  );
};

export default RecommendedFilter;

import React from 'react';
import { observer } from 'mobx-react';
import { Button, Tooltip, styled } from '@mui/material';
import { faPlug } from '@fortawesome/free-solid-svg-icons';

import AddIcon from '@extensions/utils/AddIcon';

const StyledButton = styled(Button)(({
  width: '100%',
  height: '50.25px',
  paddingTop: '.75rem',
  paddingBottom: '.75rem',
  backgroundColor: '#e0e0e0 !important',
  color: 'black !important',
}));

const ApiOption: React.FC = observer(() => {
  const handleOpenWindow = () => {
    window.open(
      'https://github.com/DAP-platform/dap-py/blob/master/docs/doe_dap_dl.md', 
      '_blank', 
      'noopener,noreferrer'
    );
  }
  return (
    <>
      <Tooltip title={'Use API to download data'}>
        <StyledButton
          onClick={handleOpenWindow}
          size="large"
          variant="contained"
          startIcon={<AddIcon icon={faPlug} />}
        >
          API
        </StyledButton>
      </Tooltip>
    </>
  )
});

export default ApiOption;

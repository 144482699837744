import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils';
import MultiList from '@extensions/components/core/MultiList';
import { SEARCH_BAR_REACTIVE_ID } from '@extensions/components/search-core';
import { Card, CardContent, Stack, styled } from '@mui/material';

const REACTIVE_FILE_TYPE_ID = 'FileTypeFilter';
const REACTIVE_FILE_EXT_ID = 'FileExtFilter';
const REACTIVE_REPO_ID = 'RepoFilter';
const REACTIVE_CONTRIB_ID = 'ContributorFilter';

const REACTIVE_IDS = [
  SEARCH_BAR_REACTIVE_ID, 
  REACTIVE_FILE_TYPE_ID, 
  REACTIVE_FILE_EXT_ID, 
  REACTIVE_REPO_ID, 
  REACTIVE_CONTRIB_ID
];

const allReactiveIdsExcept = getAllReactiveIdsExcept(REACTIVE_IDS);

const StyledCardContent= styled(CardContent)(({ theme }) => ({
  '& .data-filter': {
    marginBottom: theme.spacing(2),
  },
}));

const CodeFilters: React.FC = () => {
  return (
    <Card variant='outlined'>
      <StyledCardContent>
        <MultiList 
          componentId={REACTIVE_FILE_TYPE_ID} 
          title="File Type" 
          dataField="file_type.keyword" 
          react={{ and: allReactiveIdsExcept(REACTIVE_FILE_TYPE_ID) }} 
          URLParams
          className="data-filter"
        />
        <MultiList 
          componentId={REACTIVE_FILE_EXT_ID} 
          title="File Extension" 
          dataField="file_extension.keyword" 
          react={{ and: allReactiveIdsExcept(REACTIVE_FILE_EXT_ID) }} 
          URLParams 
          className="data-filter"
        />
        <MultiList 
          componentId={REACTIVE_REPO_ID} 
          title="Repository" 
          dataField="repo_name.keyword" 
          react={{ and: allReactiveIdsExcept(REACTIVE_REPO_ID) }} 
          URLParams 
          className="data-filter"
        />
        <MultiList 
          componentId={REACTIVE_CONTRIB_ID} 
          title="Contributor" 
          dataField="contributors.keyword" 
          react={{ and: allReactiveIdsExcept(REACTIVE_CONTRIB_ID) }} 
          URLParams 
        />
      </StyledCardContent>
    </Card>
  );
};

export default CodeFilters;

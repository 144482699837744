import {
    styled,
    Select,
    MenuItem,
    FormControl,
    ListSubheader
} from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { SEARCH_CATEGORIES } from '@extensions/components/global-search/SearchCategories';

import { IGlobalSearchService } from '@extensions/services/IGlobalSearchService';

const StyledSpan = styled('span')(({
    color: '#424242'
}));

interface SearchCategoryMenuProps {
    globalSearchService?: IGlobalSearchService;
    isRedirecting?: boolean;
}

const SearchCategoryMenu: React.FC<SearchCategoryMenuProps> = ({ globalSearchService, isRedirecting }) => {
    const history = useHistory();

    const selectedCategory = globalSearchService?.selectedCategory ? globalSearchService?.selectedCategory : SEARCH_CATEGORIES.ALL;
    const handleChange = (event) => {
        if (isRedirecting || isRedirecting === undefined) {
            switch (event.target.value) {
                case SEARCH_CATEGORIES.ENV_PROJECT:
                    globalSearchService?.setSelectedCategory(SEARCH_CATEGORIES.ENV_PROJECT);
                    history.push('/projects/env-project')
                    break;
                case SEARCH_CATEGORIES.WDH_PROJECT:
                    globalSearchService?.setSelectedCategory(SEARCH_CATEGORIES.WDH_PROJECT);
                    history.push('/projects/wind-energy')
                    break;
                case SEARCH_CATEGORIES.ENV_DATA:
                    globalSearchService?.setSelectedCategory(SEARCH_CATEGORIES.ENV_DATA);
                    history.push('/data/env-data')
                    break;
                case SEARCH_CATEGORIES.WDH_DATA:
                    globalSearchService?.setSelectedCategory(SEARCH_CATEGORIES.WDH_DATA);
                    history.push('/data/wind-energy')
                    break;
                case SEARCH_CATEGORIES.PUBLICATIONS:
                    globalSearchService?.setSelectedCategory(SEARCH_CATEGORIES.PUBLICATIONS);
                    history.push('/publications')
                    break;
                case SEARCH_CATEGORIES.ALL:
                    globalSearchService?.setSelectedCategory(SEARCH_CATEGORIES.ALL);
                    history.push('/')
                    break;
                default:
                    break;
            }
        } else {
            globalSearchService?.setSelectedCategory(event.target.value);
        }
    };

    return (
        <div>
            <FormControl>
                <Select
                    defaultValue="All Categories"
                    id="category-select"
                    displayEmpty
                    renderValue={() => {
                        if (!selectedCategory) {
                            return <StyledSpan>All Categories</StyledSpan>;
                        }
                        return selectedCategory;
                    }}
                    value={selectedCategory}
                    onChange={handleChange}
                    sx={{
                        pl: 1.5,
                        mr: 1,
                        '& .MuiInputBase-input': {
                            color: '#636262',
                            fontSize: '0.9rem',
                        },
                    }}
                >
                    <MenuItem value={SEARCH_CATEGORIES.ALL}>{SEARCH_CATEGORIES.ALL}</MenuItem>
                    <MenuItem value={SEARCH_CATEGORIES.WDH_PROJECT}>{SEARCH_CATEGORIES.WDH_PROJECT}</MenuItem>
                    <MenuItem value={SEARCH_CATEGORIES.ENV_PROJECT}>{SEARCH_CATEGORIES.ENV_PROJECT}</MenuItem>
                    <MenuItem value={SEARCH_CATEGORIES.WDH_DATA}>{SEARCH_CATEGORIES.WDH_DATA}</MenuItem>
                    {/* <MenuItem value={SEARCH_CATEGORIES.ENV_DATA}>{SEARCH_CATEGORIES.ENV_DATA}</MenuItem> */}
                    <MenuItem value={SEARCH_CATEGORIES.PUBLICATIONS}>{SEARCH_CATEGORIES.PUBLICATIONS}</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}

export default inject('globalSearchService')(observer(SearchCategoryMenu));
import { Moment } from 'moment';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { Typography, Divider, Card, CardContent, styled } from '@mui/material';

import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

import theme from '@extensions/services/Theme';
import FileOrderGroup from '@extensions/models/FileOrderGroup';
import { Status } from '@extensions/services/INotificationService';
import { useNotificationService } from '@extensions/hooks/useService';

const StyledHeading = styled(Typography)({
  fontSize: '1.5rem',
  marginBottom: theme.spacing(2),
});

const StyledDiv = styled('div')({
  maxHeight: '28rem',
  overflow: 'auto',
});

const StyledText = styled(Typography)({
  width: '100%',
  fontSize: '16px',
  cursor: 'pointer',
  userSelect: 'none',
});

const StyledDivider = styled(Divider)({
  margin: `${theme.spacing(1)}px 0`,
});

interface ICitationProps {
  order: FileOrderGroup;
}

const Citation: React.FC<ICitationProps> = observer(({ order }) => {
  const notificationServce = useNotificationService();

  useEffect(() => {
    order.loadDatasets();
  }, [order]);

  const dateRange =
    order.dateRange.startDate && order.dateRange.endDate
      ? ([order.dateRange.startDate, order.dateRange.endDate] as [Moment, Moment])
      : undefined;

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    notificationServce.addNotification(
      'copy',
      Status.Success,
      'Copied to clipboard!',
      '',
      true
    )
  };

  return (
    <Card>
      <CardContent>
        <StyledHeading>Citation</StyledHeading>
        {order.datasets ? (
          <StyledDiv>
            {order.datasets.map((dataset, index) => {
              const citationText = dataset.getCitationWithAccessDate(order.created, dateRange);
              return (
                <div key={dataset.name}>
                  {index > 0 && <StyledDivider />}
                    <StyledText variant="body1" onClick={() => handleCopy(citationText)}>
                      {citationText}
                    </StyledText>
                </div>
              );
            })}
          </StyledDiv>
        ) : (
          <CenteredCircularProgress />
        )}
      </CardContent>
    </Card>
  );
});

export default Citation;

import { observer } from 'mobx-react';
import difference from 'lodash/difference';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect, useState, useCallback } from 'react';
import { ReactiveBase, StateProvider } from '@appbaseio/reactivesearch';
import { faMapMarkedAlt, faList } from '@fortawesome/free-solid-svg-icons';
import { Button, Alert, useMediaQuery, Grid, Box, Collapse, IconButton, styled } from '@mui/material';

import theme from '@extensions/services/Theme';
import ITokenService from '@extensions/services/ITokenService';
import { ICachingService } from '@extensions/services/ICachingService';
import { ISecurityService } from '@extensions/services/ISecurityService';

import AddIcon from '@extensions/utils/AddIcon';
import {
  ResultsList,
  RECOMMENDED_REACTIVE_ID,
  DATA_LEVEL_REACTIVE_ID,
  INSTRUMENT_REACTIVE_ID,
  MEASUREMENT_REACTIVE_ID,
  MODEL_REACTIVE_ID,
  STANDARDIZATION_REACTIVE_ID,
  FILE_TYPE_REACTIVE_ID,
  DATE_RANGE_REACTIVE_ID,
  PROJECT_REACTIVE_ID,
  AREA_FILTER_REACTIVE_ID,
} from '@extensions/components/page/search-components';
import HeadManager from '@extensions/components/page/HeadManager';
import { SelectedFilters } from '@extensions/components/search-core';
import SearchBar from '@extensions/components/global-search/SearchBar';
import AreaFilter from '@extensions/components/dataset-search/AreaFilter';
import { SEARCH_BAR_REACTIVE_ID } from '@dapclient/components/search-core';
import { getHashValues, setHashValues, stringify, parse } from '@dapclient/utils/url';
import { SEARCH_CATEGORIES } from '@extensions/components/global-search/SearchCategories';
import DatasetSearchFilters from '@extensions/components/page/datasetSearch/DatasetSearchFilters';

import {
  useCachingService,
  useSecurityService,
  useTokenService,
  useGlobalSearchService
} from '@extensions/hooks/useService';

const REACTIVE_IDS = [
  SEARCH_BAR_REACTIVE_ID,
  DATA_LEVEL_REACTIVE_ID,
  RECOMMENDED_REACTIVE_ID,
  INSTRUMENT_REACTIVE_ID,
  MEASUREMENT_REACTIVE_ID,
  MODEL_REACTIVE_ID,
  STANDARDIZATION_REACTIVE_ID,
  FILE_TYPE_REACTIVE_ID,
  DATE_RANGE_REACTIVE_ID,
  PROJECT_REACTIVE_ID,
  AREA_FILTER_REACTIVE_ID,
];

const StyledWrapper = styled('div')(({ theme }) => ({
  margin: '0 1em',
  [theme.breakpoints.down('xl')]: {
      margin: '0 1em',
  },
  [theme.breakpoints.down('xs')]: {
      margin: '0 0em',
  },
}));

const StyledFiltersAndMapToggleDiv = styled('div')({
  display: 'flex',
  marginTop: '2rem',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  flexDirection: 'row',

  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'stretch',
  },
});

const StyledSelectedFilters = styled(SelectedFilters)({
  flex: '1',
});

const StyledMapToggleButton = styled(Button)({
  flex: '0 0 auto',
  flexDirection: 'row-reverse',
});

const StyledMapToggleButtonTextSpan = styled('span')({
  marginLeft: '0.25rem',
});

const StyledDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
  position: 'absolute',
  top: isMobile ? '3.8rem' : '0.8rem',
  left: '50%',
  transform: 'translateX(-50%)',
}));

const StyledAlert = styled(Alert)({
  marginTop: '1rem',
  backgroundColor: '#f1c40f',
  color: '#FFF',
  fontWeight: 800,
  '& .MuiAlert-message': {
      margin: 'auto',
  },
  '& .MuiAlert-action': {
      marginLeft: 0,
      paddingLeft: 0,
  }
});

export interface ISearchProps {
  projectContext?: string;
  cachingService?: ICachingService;
  securityService?: ISecurityService;
  tokenService?: ITokenService;
  className?: string;
  selectedCategory?: string;
}

const searchFields: any = {
  'name': 'Dataset Name',
  'title': 'Title',
  'description': 'Description',
  'instrument.keyword': 'Instrument Keyword',
  'instance.keyword': 'Instance Keyword',
  'dataLevel.keyword': 'Data Level Keyword',
  'contactPoint.fn': 'Contact Name',
  'contactPoint.hasEmail': 'Contact Email',
  'contactPoint.hasOrg': 'Contact Organization',
  'measurements.description': 'Measurements Description',
  'measurements.standardName.description': 'Measurements StandardName Description',
  'measurements.specificName': 'Measurements SpecificName'
};

const DatasetSearch: React.FC<ISearchProps> = observer((props) => {
  const { projectContext, selectedCategory } = props;

  const tokenService = useTokenService();
  const cachingService = useCachingService();
  const securityService = useSecurityService();
  const globalSearchService = useGlobalSearchService();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [showMap, setShowMap] = useState(true);
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [defaultViz, setDefaultViz] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (selectedCategory) {
      globalSearchService?.setSelectedCategory(selectedCategory);
    } else if (globalSearchService.selectedCategory) {
      globalSearchService?.setSelectedCategory(globalSearchService.selectedCategory);
    } else {
      globalSearchService?.setSelectedCategory(SEARCH_CATEGORIES.WDH_DATA);
    }
  }, [globalSearchService, selectedCategory]);

  // Toggle map visibility
  const toggleMap = useCallback(() => {
    setShowMap((prev) => !prev);
  }, []);

  // Set default visualization based on project context
  useEffect(() => {
    if (!projectContext) {
      setDefaultViz(false);
      return;
    }
    if (projectContext && cachingService) {
      cachingService.getProjectLite(projectContext).then((project) => {
        setDefaultViz(project.hasTimeSeries ?? false);
      });
    }
  }, [projectContext, cachingService]);

  if (!tokenService || !securityService) {
    return null;
  }

  return (
    <StyledWrapper>
      <HeadManager
        title="WDH: Wind Data Hub"
        description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
      />
      {securityService.user &&
        securityService.user.authenticated &&
        securityService.user.mfaEnabled &&
        !securityService.user.authenticatedMfa &&
        <Box>
          <Collapse in={isShowAlert}>
            <StyledAlert
              icon={false}
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsShowAlert(false);
                  }}
                >
                  <CancelIcon fontSize="inherit" />
                </IconButton>
              }
            >
              You have signed in without your second-factor token. You will
              NOT see any proprietary datasets.
            </StyledAlert>
          </Collapse>
        </Box>
      }
      <ReactiveBase
        app="api/datasets"
        url={`${window.location.protocol}//${window.location.host}`}
        headers={{ 'x-csrf-token': tokenService.dapToken }}
        getSearchParams={() => {
          let params: { [key: string]: string } = {};
          const hash = getHashValues();
          for (const key in hash) {
            if (REACTIVE_IDS.indexOf(key) < 0) {
              continue;
            }
            params[key] = hash[key] as string;
          }
          return stringify(params);
        }}
        setSearchParams={(url) => {
          const params = parse(url.split('?')[1]) as {
            [key: string]: string;
          };
          const hash = getHashValues();
          let keep: { [key: string]: string } = {};
          for (const key in params) {
            if (params[key]) {
              keep[key] = params[key] as string;
            }
          }
          for (const key in hash) {
            if (REACTIVE_IDS.indexOf(key) < 0) {
              keep[key] = hash[key] as string;
            }
          }
          setHashValues(keep);
        }}
        transformRequest={(req) => {
          req.body = req.body.replace(
            '"sort":[{"dapFileSummary.updated":{"order":"desc"}}]',
            '"sort":' +
            JSON.stringify([
              {
                'dapFileSummary.updated': {
                  missing: '_last',
                  order: 'desc',
                },
              },
              { generalUseReady: { order: 'desc' } },
            ])
          );
          return req;
        }}
      >
        <StateProvider
          componentIds={[PROJECT_REACTIVE_ID, DATE_RANGE_REACTIVE_ID]}
          includeKeys={['value']}
          onChange={(prev, next) => {
            const value = next[PROJECT_REACTIVE_ID].value;
            if (value === undefined || value === null) {
              let hash = getHashValues();
              if (PROJECT_REACTIVE_ID in hash) {
                delete hash[PROJECT_REACTIVE_ID];
              }
              if (DATE_RANGE_REACTIVE_ID in hash) {
                delete hash[DATE_RANGE_REACTIVE_ID];
              }
              setHashValues(hash);
            }
            return null;
          }}
        />
        <StyledDiv isMobile={isMobile}>
          <div
            role="search"
            style={{ paddingBottom: '8px' }}
          >
            <SearchBar
              dataField={[
                '*',
                'instrument.keyword',
                'dataLevel.keyword',
                'instance.keyword',
                'contactPoint:fn',
                'contactPoint:hasEmail',
                'contactPoint:hasOrg',
                'measurements:description',
                'measurements:standardName.description',
                'measurements:specificName',
              ]}
              highlight={true}
              customHighlight={(d) => ({
                highlight: {
                  pre_tags: ['<mark>'],
                  post_tags: ['</mark>'],
                  fields: {
                    ...d.dataField.reduce((carry, field) => {
                      return {
                        ...carry,
                        [field]: field === 'description' || field === 'title'
                          ? { number_of_fragments: 0 }
                          : {},
                      };
                    }, {}),
                  },
                },
              })}
              filterLabel="Search"
              queryFormat="and"
              queryString={true}
              URLParams={true}
              debounce={500}
              autosuggest={false}
            />
          </div>
        </StyledDiv>
        <StyledFiltersAndMapToggleDiv>
          <StyledSelectedFilters />
        </StyledFiltersAndMapToggleDiv>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={5} lg={3} xl={3}>
              <DatasetSearchFilters projectContext={projectContext} />
          </Grid>
          <Grid item xs={12} sm={8} md={7} lg={9} xl={9}>
            <StyledMapToggleButton onClick={toggleMap} aria-hidden>
              <>
                <AddIcon icon={showMap ? faList : faMapMarkedAlt} />
                <StyledMapToggleButtonTextSpan>
                  {showMap ? 'Hide Map' : 'Show Map'}
                </StyledMapToggleButtonTextSpan>
              </>
            </StyledMapToggleButton>
            <AreaFilter
              showMap={showMap}
              react={{
                and: difference(REACTIVE_IDS, [AREA_FILTER_REACTIVE_ID]),
              }}
            />
            {defaultViz !== undefined && (
              <ResultsList
                react={{ and: REACTIVE_IDS }}
                pageSize={20}
                vizByDefault={defaultViz}
                searchFieldLabels={searchFields}
              />
            )}
          </Grid>
        </Grid>
      </ReactiveBase>
    </StyledWrapper>
  );
});

export default DatasetSearch;
import {
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
import moment from 'moment';

import Publication from '@extensions/models/Publication';
import { PublicationType } from '@extensions/models/Publication';
import { useSecurityService } from '@extensions/hooks/useService';

import Link from '@extensions/components/core/Link';
import {
  PublicationJournalArticle,
  PublicationTechnicalReport,
  PublicationAuthors,
  PublicationSponsors,
  DatasetsList,
  ProjectsList
} from '@extensions/components/publications/components';
import KeywordsPanel from '@extensions/components/core/KeywordsPanel';
import MustSignInAlert from '@extensions/components/core/MustSignInAlert';
import AdminAccordion from '@extensions/components/publications/AdminAccordion';

export interface IPublicationSummaryProps {
  className?: string;
  publication: Publication;
  editMode: boolean;
}

const StyledAdminAccordionDiv = styled('div')(() => ({
  margin: '1.5rem 0',
}));

const StyledHeader = styled(Typography)(() => ({
  fontWeight: 'normal',
  marginBlockStart: '0',
  marginBlockEnd: '12px',
  fontSize: '1.25em',
  color: '#000000',
}));

const StyledLink = styled(Link)(() => ({
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
}));

const PublicationSummary: React.FC<IPublicationSummaryProps> = ({ editMode, publication }) => {
  const securityService = useSecurityService();
  let typeSpecificFields: React.ReactNode;
  let linkUrl: string | undefined = publication.url === '' || !publication.url ? publication.uploadedFileUrl : publication.url;

  switch (publication.type) {
    case PublicationType.JOURNAL_ARTICLE:
      typeSpecificFields = <PublicationJournalArticle article={publication} />;
      break;
    case PublicationType.TECHNICAL_REPORT:
      typeSpecificFields = <PublicationTechnicalReport report={publication} editMode={editMode} />;
      break;
  }

  return (
    <div>
      {!editMode && <Typography variant="h2">{publication.title}</Typography>}

      {!editMode &&
        securityService &&
        securityService.user &&
        securityService.user.canAdminPubs && (
          <StyledAdminAccordionDiv>
            <AdminAccordion />
          </StyledAdminAccordionDiv>
        )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop="5px"
            spacing={2}
          >
            {publication.publicationDate && (
              <Grid item>
                <Typography variant="body1">
                  Publication Date: {moment(publication.publicationDate).format('YYYY-MM-DD')}
                </Typography>
              </Grid>
            )}
            {publication.doi && (
              <Grid item>
                <Typography variant="body1">
                  DOI:&nbsp;
                  <Link
                    to={`https://doi.org/${publication.doi}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {publication.doi}
                  </Link>
                </Typography>
              </Grid>
            )}
          </Grid>

          <Card variant='outlined'>
            <CardContent>
              <StyledHeader>Abstract</StyledHeader>
              <Typography>{publication.abstract && publication.abstract}</Typography>
            </CardContent>
          </Card>

          {publication.sponsorOrganizations && (
            <PublicationSponsors sponsors={publication.sponsorOrganizations} />
          )}
          <Grid container spacing={2}>
            {securityService && (!securityService.user || !securityService.user.authenticated) ? (
              <Grid item marginTop="20px">
                <MustSignInAlert actionDescription="view or request access to dataset(s)" />
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <DatasetsList datasets={publication.datasets} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ProjectsList projects={publication.projects} />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4} marginTop={{ xs: '20px', sm: '0px' }}>
          {linkUrl && (
            <Card variant='outlined'>
              <CardContent>
                <StyledHeader>Reference URL</StyledHeader>
                <StyledLink
                  to={linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {linkUrl}
                </StyledLink>
              </CardContent>
            </Card>
          )}
          {typeSpecificFields}
          {publication.authors && <PublicationAuthors authors={publication.authors} />}
          {publication.keywords && publication.keywords.length > 0 && <KeywordsPanel keywords={publication.keywords} />}
        </Grid>
      </Grid>
    </div>
  );
};

export default PublicationSummary;

import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Link,
    Box,
    Divider,
    styled
} from '@mui/material';
import React from 'react';
import moment from 'moment';

import theme from '@extensions/services/Theme';
import { PublicationType } from '@extensions/models/Publication';
import { useSecurityService } from '@extensions/hooks/useService';

interface INewPublicationsListProps {
    publications: any[];
};

const StyledLink = styled(Link)(() => ({
    marginBottom: theme.spacing(1),
    display: 'block',
    color: '#005b82',
    textTransform: 'none',
    fontSize: '1rem'
}));

const StyledCard = styled(Card)(({
    marginBottom: '1rem',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
    marginLeft: '-1rem'
}));

const StyledCardContent = styled(CardContent)(({
    paddingBottom: '0px !important',
    paddingTop: '0px !important'
}));

const StyledTypographyBodyOne = styled(Typography)(({
    marginBottom: '0px !important'
}));

const StyledTypographyBodyTwo = styled(Typography)(({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '0.5rem',
    color: theme.palette.text.secondary
}));

export const NewPublicationsList: React.FC<INewPublicationsListProps> = ({ publications }) => {
    const securityService = useSecurityService();

    const checkIsMember = (publication: any) => {
        const userMembers = securityService?.user?.memberOf;
        var result = false;
        if (publication['datasets'] && publication['datasets'].length) {
            publication['datasets'].forEach(dataset => {
                if (userMembers?.includes(dataset.name)) {
                    result = true;
                }
            });
        } else if (publication['projects'] && publication['projects'].length) {
            publication['projects'].forEach(project => {
                if (userMembers?.includes(project.name)) {
                    result = true;
                }
            });
        }
        return result;
    }

    const renderPublicationTitle = (publication: any) => {
        if (publication.type !== PublicationType.JOURNAL_ARTICLE
            && publication.type !== PublicationType.TECHNICAL_REPORT
        ) {
            if (securityService
                && securityService.user
                && (securityService.user.canAdminPubs || checkIsMember(publication))) {
                return (
                    <StyledLink href={`/publication/${publication.id}`} underline='hover'>
                        {publication.title}
                    </StyledLink>
                )
            } else {
                return (
                    <StyledLink
                        href={
                            publication.url.startsWith('/api/')
                                ? `${window.location.origin}${publication.url}`
                                : publication.url} underline='hover'>
                        {publication.title}
                    </StyledLink>
                )
            }
        } else {
            return (
                <StyledLink href={`/publication/${publication.id}`} underline='hover'>
                    {publication.title}
                </StyledLink>
            )
        }
    }

    const getTruncatedText = (description: string) => {
        if (description && description.length > 200) {
            return description.slice(0, 200) + '...';
        } else if (description) {
            return description;
        }
        return '';
    };

    const getUpdatedTime = (publicationDate) => {
        if (publicationDate) {
            const statsLastUpdated = moment(publicationDate);
            const durationSinceUpdate = moment.duration(
                moment().diff(statsLastUpdated)
            );
            return (`Updated ${durationSinceUpdate.humanize()} ago`);
        }
    };

    return (
        <Box>
            {
                publications.map((publication, i) => (
                    <StyledCard key={i}>
                        <CardHeader
                            title={
                                renderPublicationTitle(publication)
                            }
                            sx={{ mt: '0rem' }}
                        >
                        </CardHeader>
                        <StyledCardContent>
                            <StyledTypographyBodyOne variant='body1'>
                                {getTruncatedText(publication.abstract)}
                            </StyledTypographyBodyOne>
                            <StyledTypographyBodyTwo variant='body2'>
                                {getUpdatedTime(publication.publicationDate)}
                            </StyledTypographyBodyTwo>
                        </StyledCardContent>
                        <Divider />
                    </StyledCard>
                ))
            }
        </Box>
    )
}

export default NewPublicationsList;
import {  
  Chip, 
  Grid, 
  Tooltip 
} from '@mui/material';
import React from 'react';
import moment from 'moment';
import {
  CallSplit,
  CheckCircle,
  Error,
  GetApp,
  LocalOffer,
  People
} from '@mui/icons-material';
import { Line, LineChart, ResponsiveContainer } from 'recharts';

import { HitWrapper, StyledLink } from '@extensions/components/code-search/repo/repoStyles';

interface Badge {
  image_url: string;
  image_link: string;
}

interface RepoResult {
  id: number;
  name: string;
  full_name: string;
  description: string;
  updated_at: string;
  homepage: string | null;
  stargazers_count: number;
  forks_count: number;
  default_branch: string;
  parent: string;
  url: string;
  license: string;
  license_url: string;
  commit_activity: number[];
  languages: string[];
  badges: Badge[];
  contributors_count: number;
  watchers_count: number;
  current_release: string | null;
  current_release_date: string | null;
  releases_count: number;
  issues_open_count: number;
  issues_closed_count: number;
  pull_requests_open_count: number;
  pull_requests_closed_count: number;
  downloads_last_day: number | undefined;
  downloads_last_week: number | undefined;
  downloads_last_month: number | undefined;
  _indexed: string;
}

interface IRepoResultHitProps {
  data: RepoResult
}

const RepoResultHit: React.FC<IRepoResultHitProps> = ({ data }) => {
  const chartData = data.commit_activity.map(v => ({
    val: v,
  }))
  return (
    <HitWrapper variant='outlined'>
      <Grid container direction="row">
        <Grid item className="details" lg={4}>
          <Grid container direction="column" spacing={1}>
            <Grid item className="title">
              {data.parent} /&nbsp;
              <StyledLink href={data.url} target="_blank" rel="noreferrer">
                {data.name}
              </StyledLink>
            </Grid>
            <Grid item className="description">
              {data.description || <em className="none">[ No description ]</em>}
            </Grid>
            <Grid item className="license">
              <strong>License:</strong>&nbsp;
              {Boolean(data.license_url) && (
                <StyledLink href={data.license_url} target="_blank" rel="noreferrer">
                  {data.license}
                </StyledLink>
              )}
              {!Boolean(data.license_url) && data.license}
            </Grid>
            <Grid item className="languages">
              <Grid container spacing={1}>
                {data.languages.map(lang => (
                  <Grid item key={lang}>
                    <Chip size="small" key={lang} label={lang} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="stats" lg={8}>
          <Grid container direction="column">
            <Grid item className="title">
              <Grid container>
                <Grid item xs={3}>
                  <StyledLink href={`${data.url}/pulse`} target="_blank" rel="noreferrer">
                    Activity
                  </StyledLink>
                </Grid>
                <Grid item style={{ textAlign: 'right' }} xs={9}>
                  <small>Updated: {moment(data.updated_at).fromNow()}</small>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="body">
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  Issues
                  <Grid container spacing={1}>
                    <Grid item>
                      <Tooltip title="Open Issues">
                        <StyledLink href={`${data.url}/issues`} target="_blank" rel="noreferrer">
                          <Error fontSize="small" />
                          {data.issues_open_count}
                        </StyledLink>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Closed Issues">
                        <StyledLink href={`${data.url}/issues?q=is%3Aissue+is%3Aclosed`} target="_blank" rel="noreferrer">
                          <CheckCircle fontSize="small" />
                          {data.issues_closed_count}
                        </StyledLink>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  Pull Requests
                  <Grid container spacing={1}>
                    <Grid item>
                      <Tooltip title="Open Pull Requests">
                        <StyledLink href={`${data.url}/pulls`} target="_blank" rel="noreferrer">
                          <Error fontSize="small" />
                          {data.pull_requests_open_count}
                        </StyledLink>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Closed Pull Requests">
                        <StyledLink href={`${data.url}/pulls?q=is%3Apr+is%3Aclosed`} target="_blank" rel="noreferrer">
                          <CheckCircle fontSize="small" />
                          {data.pull_requests_closed_count}
                        </StyledLink>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  Contributors<br />
                  <StyledLink href={`${data.url}/contributors`} target="_blank" rel="noreferrer">
                    <People fontSize="small" />
                    {data.contributors_count}
                  </StyledLink>
                </Grid>
                <Grid item xs={8}>
                  Releases
                  <Grid container spacing={1}>
                    {Boolean(data.current_release) && (
                      <Grid item>
                        <StyledLink href={`${data.url}/releases`} target="_blank" rel="noreferrer">
                          <LocalOffer fontSize="small" />
                          {data.current_release} ({moment(data.current_release_date).fromNow()})
                        </StyledLink>
                      </Grid>
                    )}
                    <Grid item>
                      <StyledLink href={`${data.url}/releases`} target="_blank" rel="noreferrer">
                        <LocalOffer fontSize="small" />
                        {data.releases_count}
                      </StyledLink>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  Forks
                  <StyledLink href={`${data.url}/forks`} target="_blank" rel="noreferrer">
                    <CallSplit fontSize="small" />
                    {data.forks_count}
                  </StyledLink>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title="Past Year of Commit Activity">
                    <div>
                      <ResponsiveContainer height={50}>
                        <LineChart data={chartData}>
                          <Line
                            type="monotone"
                            dataKey="val"
                            stroke="#888"
                            strokeWidth={2}
                            dot={false}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </Tooltip>
                </Grid>
                {data.downloads_last_month !== undefined && (
                  <Grid item xs={12}>
                    PyPI Downloads
                    <StyledLink href={`https://pypistats.org/packages/${data.name.toLowerCase()}`}>
                      <GetApp fontSize="small" />
                      {data.downloads_last_day || 0} (day),&nbsp;
                      {data.downloads_last_week || 0} (week),&nbsp;
                      {data.downloads_last_month || 0} (month)
                    </StyledLink>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {data.badges.map(b => (
                      <Grid key={`${b.image_link}${b.image_url}`} item>
                        <a href={b.image_link} target="_blank" rel="noreferrer">
                          <img src={b.image_url} alt="Badge" />
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HitWrapper>
  );
};

export default RepoResultHit;
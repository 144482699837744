import React from 'react';
import { inject } from 'mobx-react';
import { ReactElement } from 'react';
import { observable, action, makeObservable } from 'mobx';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';

import {
  Tooltip,
  Link,
  TableCell,
  TableRow,
  LinkProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import theme from '@extensions/services/Theme';
import { ICartService } from '@extensions/services/ICartService';

import AddIcon from '@extensions/utils/AddIcon';
import DatasetModel from '@extensions/models/Dataset';
import { formatBytes } from '@extensions/utils/format';
import {
  DistributionTable as DapDistributionTable
} from '@dapclient/components/dataset/DistributionTable';
import CartLink from '@extensions/components/nav/CartLink';
import Distribution from '@extensions/models/Distribution';
import ExpandableText from '@extensions/components/core/ExpandableText';
import DistributionType, { typeToLabel } from '@extensions/models/DistributionType';
import getInstructions from '@extensions/components/dataset/externalSourceInstructions';

const StyledLink = styled(Link)(({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center'
}));

const StyledExitToAppIcon = styled(ExitToAppIcon)(() => ({
  ...theme.linkIcon
}));

interface CustomLinkProps extends LinkProps {
  empty?: boolean;
  disabled?: boolean;
  component?: React.ElementType
}

const StyledEmptyLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'empty' && prop !== 'component' && prop !== 'disabled',
})<CustomLinkProps>(({ empty }) => {
  if (empty) {
    return {
      '&&&': {
        color: theme.palette.text.disabled,
        textDecoration: 'underline',
        cursor: 'inherit',
      },
    };
  }
  return {};
});

const StyledTableFirstColumnDiv = styled('div')(({
  '& > p': {
    marginBottom: '0 !important'
  }
}));

const StyledExpandTextDiv = styled('div')(({
  '& > p': {
    color: 'gray',
    fontStyle: 'italic',
    fontSize: '0.9rem',
    marginBottom: 0,
    marginTop: '1rem'
  }
}));

interface IWdhDistributionTableProps {
  className?: string;
  cartService?: ICartService;
}
export class DistributionTable extends DapDistributionTable<IWdhDistributionTableProps> {

  @observable
  instructions: ReactElement | null = null;

  loading: boolean = false;
  loaded: boolean = false;

  @observable
  xReady: boolean = false;

  constructor(props: IWdhDistributionTableProps) {
    super(props);
    makeObservable(this);
  }

  addToCart = () => {
    const { cartService, datasetService } = this.props;
    if (cartService && datasetService && datasetService.dataset) {
      cartService.addToCart(datasetService.dataset.name);
    }
  };

  handleFullFileList() { }

  @action
  xCallback = (ready: boolean) => {
    const { securityService, datasetService } = this.props;
    const user = securityService?.user;
    const dataset = datasetService?.dataset;
    this.xReady = ready;
    this.instructions = ready || !user || !dataset?.externalSource
      ? null
      : getInstructions(user, dataset.externalSource);
    this.loading = false;
    this.loaded = true;
  }

  @action
  xCheck = () => {
    const { securityService, cartService, datasetService } = this.props;
    const user = securityService?.user;
    const dataset = datasetService?.dataset;
    if (user && cartService && dataset?.externalSource && !this.loading && !this.loaded) {
      this.loading = true;
      cartService.isExternalSourceReady(dataset.externalSource, this.xCallback);
    }
  }

  renderInternalDistroLink(distribution: Distribution) {
    const { cartService } = this.props;
    const dataset = this.props.datasetService?.dataset;
    const empty = !Boolean(dataset && dataset.dynamoFileCount);
    const notReady = Boolean(
      cartService &&
      dataset &&
      dataset.externalSource &&
      !this.xReady
    );
    this.xCheck();
    if (this.instructions) {
      return this.instructions;
    }
    if (dataset && cartService && cartService.isInCart(dataset.name)) {
      return (
        <CartLink>
          View in Cart
        </CartLink>
      )
    }
    const link = (
      <StyledEmptyLink
        onClick={this.addToCart}
        component="button"
        disabled={empty || notReady}
        empty={empty}
        underline='hover'
      >
        <AddIcon icon={faCartPlus} aria-hidden size="lg" /> Add to Cart
      </StyledEmptyLink>
    );
    if (empty) {
      return (
        <Tooltip title="Can't add empty dataset to cart">
          <span>{link}</span>
        </Tooltip>
      );
    }
    if (notReady) {
      return (
        <Tooltip title="Can't add external dataset yet ... checking readiness first">
          <span>{link}</span>
        </Tooltip>
      )
    }
    return link;
  }

  renderExternalDistroLink(distribution: Distribution) {
    if (distribution.downloadURL) {
      return (
        <StyledLink
          color="primary"
          href={distribution.downloadURL}
          onClick={(e) =>
            this.captureDownloadLinkClickMetric(distribution.downloadURL)
          }
        >
          <StyledExitToAppIcon /> External Link
        </StyledLink>
      );
    }

    if (distribution.accessURL) {
      return (
        <StyledLink
          color="primary"
          href={distribution.accessURL}
          target="_blank"
          onClick={(e) =>
            this.captureDownloadLinkClickMetric(distribution.accessURL)
          }
        >
          <StyledExitToAppIcon /> External Link
        </StyledLink>
      );
    }
  }

  renderTitleAndDescription = (distribution: Distribution) => {
    let externalDatasetTitle = '';
    let externalResourceDescription = '';

    if (distribution.title) {
      externalDatasetTitle = distribution.title;
    } else if (distribution.shortName) {
      externalDatasetTitle = typeToLabel(distribution.shortName);
    } else if (distribution.identifier) {
      externalDatasetTitle = distribution.identifier;
    }

    if (distribution.description) {
      externalResourceDescription = distribution.description;
    }

    return (
      <StyledTableFirstColumnDiv>
        <p>{externalDatasetTitle}</p>
        {externalResourceDescription &&
          <StyledExpandTextDiv>
            <ExpandableText>{externalResourceDescription}</ExpandableText>
          </StyledExpandTextDiv>
        }
      </StyledTableFirstColumnDiv>
    )
  }

  renderTableRow = (distribution: Distribution, dataset: DatasetModel, idx: number) => {
    return (
      <TableRow key={`${distribution.identifier}-${idx}`}>
        <TableCell component="th" scope="row" style={{ width: '80%' }}>
          {(
            distribution.distributionType === DistributionType.DownloadDap ||
            (dataset.dynamoFileCount !== null && dataset.dynamoFileCount > 0)
          ) ? (
            <>
              <strong>
                {(dataset.dynamoFileCount || 0).toLocaleString()}
              </strong> files,&nbsp;
              <strong>
                {formatBytes(dataset.dynamoTotalFileSize || 0)}
              </strong>
            </>
          ) : this.renderTitleAndDescription(distribution)}
        </TableCell>
        <TableCell>{this.renderDistroLink(distribution)}</TableCell>
      </TableRow>
    )
  }
}

export default inject((store: any) => ({
  datasetService: store.datasetService,
  securityService: store.securityService,
  notificationService: store.notificationService,
  cartService: store.cartService,
}))(DistributionTable);

import {
    Grid,
    Box,
    Divider,
    Link,
    Card,
    CardContent,
    CardHeader,
    Typography,
    useMediaQuery,
    styled,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';

import FileOrder from '@extensions/models/FileOrder';
import DapApiAgent from '@extensions/utils/DapApiAgent';

import UserFavorites from '@extensions/components/page/home-page/UserFavorites';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';
import WDHNotificationText from '@extensions/components/page/home-page/WDHNotificationText';

import theme from '@extensions/services/Theme';
import { useDatasetService } from '@extensions/hooks/useService';

const StyledCard = styled(Card)(({
    marginBottom: '1rem',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
    marginLeft: '-1rem'
}));

const StyledCardContent = styled(CardContent)(({
    paddingBottom: '0px !important',
    paddingTop: '0px !important'
}));

const StyledTypographyHTwo = styled(Typography)(() => ({
    fontSize: '1rem',
    fontWeight: 400,
}));

const StyledTypographyBodyOne = styled(Typography)(({
    marginBottom: '0px !important'
}));

const StyledTypographyBodyTwo = styled(Typography)(({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '0.5rem',
    color: theme.palette.text.secondary
}));

const StyledDiv = styled('div')(({ theme }) => ({
    margin: '0em 1em',
    [theme.breakpoints.down('xs')]: {
        margin: '0 0em',
    },
}));

interface IHomeAfterLoginProps { }

const HomeAfterLogin: React.FC<IHomeAfterLoginProps> = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [ordersList, setOrdersList] = useState<FileOrder[]>([]);
    const [visibleCount, setVisibleCount] = useState<number>(3);

    const isSmallScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const datasetService = useDatasetService();

    const fetchOrdersAndDatasets = useCallback(async () => {
        const fetchOrdersMfaInfo = async (datasetNameFromOrdersList: string[], ordersList: FileOrder[]): Promise<any> => {
            const queryStrings = datasetNameFromOrdersList.map((dataset) => {
                return {
                    query_string: {
                        query: dataset.slice(dataset.indexOf('/') + 1),
                        fields: ["name"],
                        default_operator: "and"
                    }
                }
            });
            try {
                await DapApiAgent.agent
                    .post('/api/datasets/_msearch?')
                    .send(
                        JSON.stringify({ preference: "SearchResult" }) + "\n" +
                        JSON.stringify({
                            query: {
                                bool: {
                                    should: queryStrings
                                }
                            },
                            size: 500,
                        })
                    ).then((res) => {
                        const ordersIsMfa: { [key: string]: boolean } = {};
                        const resList = res.body.responses[0].hits.hits.map(order => order._source);
                        resList.forEach(order => {
                            ordersIsMfa[order.name] = order.dapAccessRequiresMfa;
                        });
                        ordersList.forEach(order => {
                            order['description'] = resList.filter((rl) => rl.name === order.datasetName)[0]['description'];
                            order['project_name'] = resList.filter((rl) => rl.name === order.datasetName)[0]['project_name'];
                            order.isMfaRestricted = ordersIsMfa[order.datasetName];
                        });
                        setOrdersList(ordersList);
                    });
            } catch (er) {
                console.log('er:', er);
            }
        };

        await datasetService.getFileOrders();
        if (datasetService.orders !== null) {
            const ordersList = datasetService.orders;
            const datasetNames: string[] = ordersList.map(order => order.datasetName);
            await fetchOrdersMfaInfo(datasetNames, ordersList);
            setIsLoading(false);
        }
    }, [datasetService]);

    useEffect(() => {
        fetchOrdersAndDatasets();
    }, [fetchOrdersAndDatasets]);

    const getTruncatedText = (description: string) => {
        if (description.length > 200) {
            return description.slice(0, 200) + '...';
        }
        return description;
    };

    const getCreatedContent = (order: FileOrder): React.ReactNode =>
        moment.unix(order.created).fromNow();

    const handleViewMore = () => {
        setVisibleCount(prevCount => prevCount + 3);
    };

    return (
        <StyledDiv>
            <Grid
                container
                spacing={3}
            >
                <Grid item xs={12} sm={12} md={12} lg={8}>
                    <Box>
                        <WDHNotificationText />
                    </Box>
                    <UserFavorites />
                </Grid>
                <Grid item lg={0.3}>
                    {isSmallScreen && <Divider orientation="vertical" sx={{ height: '100%', borderRightWidth: 2, borderColor: 'grey' }} />}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3.7}>
                    <Typography variant='h3' gutterBottom>Recent Orders</Typography>
                    {isLoading ? <CenteredCircularProgress />
                        : ordersList.length ? ordersList.slice(0, visibleCount).map((order, i) => (
                            <StyledCard key={i}>
                                <CardHeader
                                    title={
                                        <StyledTypographyHTwo variant="h2">
                                            <Link
                                                href={`/data/${order.datasetName}`}
                                                underline='hover'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                {order['project_name'].toUpperCase()} - {order.datasetName}
                                            </Link>
                                        </StyledTypographyHTwo>
                                    }
                                    sx={{ mt: '0rem' }}
                                >
                                </CardHeader>
                                <StyledCardContent>
                                    <StyledTypographyBodyOne
                                        variant='body1'
                                    >
                                        {getTruncatedText(order['description'])}
                                    </StyledTypographyBodyOne>
                                    <StyledTypographyBodyTwo
                                        variant='body2'
                                    >
                                        {getCreatedContent(order)}
                                    </StyledTypographyBodyTwo>
                                </StyledCardContent>
                                <Divider />
                            </StyledCard>
                        ))
                            : <Typography variant='body2' sx={{ color: theme.palette.text.secondary }}>No Recent Order</Typography>
                    }
                    {ordersList.length
                        ? visibleCount < ordersList.length && (
                            <Link
                                onClick={handleViewMore}
                                underline='hover'
                                sx={{
                                    float: 'right',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                View More...
                            </Link>)
                        : null
                    }
                </Grid>
            </Grid>
        </StyledDiv>
    );
};

export default HomeAfterLogin;
import React from 'react';
import { Button, Typography } from '@mui/material';
import { ABILITIES } from '@extensions/services/ISecurityService';
import MustSignInAlert from '@extensions/components/core/MustSignInAlert';
import MustVerifyEmailAlert from '@extensions/components/core/MustVerifyEmailAlert';
import AwaitApprovalAlert from '@extensions/components/core/AwaitApprovalAlert';
import DistributionTable from '@extensions/components/dataset/DistributionTable';
import { useSecurityService } from '@extensions/hooks/useService';

interface IDataFilesSectionProps {
  datasetAbilities: string[] | null;
  openRequestAccessModal: () => void;
}

const DataFilesSection: React.FC<IDataFilesSectionProps> = ({ datasetAbilities, openRequestAccessModal }) => {
  const { user } = useSecurityService();
  
  if (!user || !user.authenticated) {
    return <MustSignInAlert actionDescription="download data" />;
  }
  if (!user.emailVerified) {
    return <MustVerifyEmailAlert />;
  }
  if (user.approvalPending) {
    return <AwaitApprovalAlert />;
  }
  if (datasetAbilities) {
    if (datasetAbilities.includes(ABILITIES.DOWNLOAD_PENDING) && !datasetAbilities.includes(ABILITIES.DOWNLOAD)) {
      return <Typography>Your access request is pending approval.</Typography>;
    }
    if (!datasetAbilities.includes(ABILITIES.DOWNLOAD)) {
      return (
        <Button variant="contained" color="primary" onClick={openRequestAccessModal} sx={{marginTop: '10px'}}>
          Request Access
        </Button>
      );
    }
    if (datasetAbilities.includes(ABILITIES.DOWNLOAD)) {
      return <DistributionTable />;
    }
  }

  return null;
};

export default DataFilesSection;

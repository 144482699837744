import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Grid, useMediaQuery, styled } from '@mui/material';

import {
    SEARCH_BAR_REACTIVE_ID,
    SearchProvider,
    SearchResults,
    SelectedFilters,
} from '@extensions/components/search-core';
import Project from '@extensions/models/Project';
import {
    REACTIVE_IDS as FILTER_REACTIVE_IDS,
} from '@extensions/components/project-search/ProjectFilters';
import SearchBar from '@extensions/components/global-search/SearchBar';
import ProjectFilters from '@extensions/components/project-search/ProjectFilters';
import ProjectResultCard from '@extensions/components/project-search/ProjectResultCard';
import { SEARCH_CATEGORIES } from '@extensions/components/global-search/SearchCategories';

import theme from '@extensions/services/Theme';
import { useGlobalSearchService } from '@extensions/hooks/useService';

const StyledSelectedFilters = styled(SelectedFilters)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StyledWrapper = styled('div')(({ theme }) => ({
    margin: '0 1em',
    [theme.breakpoints.down('xl')]: {
        margin: '0 1em',
    },
    [theme.breakpoints.down('xs')]: {
        margin: '0 0em',
    },
}));

const StyledDiv = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
    position: 'absolute',
    top: isMobile ? '3.8rem' : '0.8rem',
    left: '50%',
    transform: 'translateX(-50%)',
}));

export interface IProjectSearchWindEnergyProps {
    selectedCategory?: string;
}

const searchFields: any = {
    'name': 'Project Name',
    'title': 'Title',
    'description': 'Description',
    'participatingOrganizations.name': 'Participating Organization',
    'dataset.distributionType': 'Dataset Distribution Type',
    'dataset.keyword': 'Dataset Keyword',
    'contactPoint.fn': 'Contact Name',
    'contactPoint.hasOrg': 'Contact Organization',
    'projectCategory': 'Project Category'
};

const ProjectSearchWindEnergy: React.FC<IProjectSearchWindEnergyProps> = observer(({ selectedCategory }) => {
    const globalSearchService = useGlobalSearchService();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const elasticHitToProject = (hit) => {
        return new Project(hit, hit.name);
    };

    useEffect(() => {
        if (selectedCategory) {
            globalSearchService?.setSelectedCategory(selectedCategory);
        } else if (globalSearchService.selectedCategory) {
            globalSearchService?.setSelectedCategory(globalSearchService.selectedCategory);
        } else {
            globalSearchService?.setSelectedCategory(SEARCH_CATEGORIES.WDH_PROJECT);
        }
    }, [selectedCategory, globalSearchService.selectedCategory, globalSearchService]);

    const customTransformRequest = (req) => {
        let bodyString = req.body;
        const parts = bodyString.split('\n');
        const partLength = parts.length;
        if (partLength && partLength > 0) {
            for (let i = 0; i < partLength; i++) {
                if (i % 2 !== 0) {
                    let queryBody;
                    queryBody = JSON.parse(parts[i]);
                    if (!queryBody) return req;
                    if (queryBody.query) {
                        if (queryBody.query.bool && queryBody.query.bool.must) {
                            queryBody.query.bool.must.push({
                                match: {
                                    projectCategory: "Wind energy"
                                }
                            });
                        } else {
                            queryBody.query = {
                                bool: {
                                    must: [
                                        queryBody.query,
                                        { match: { projectCategory: "Wind energy" } }
                                    ]
                                }
                            };
                        }
                    } else {
                        queryBody.query = {
                            bool: {
                                must: [
                                    { match: { projectCategory: "Wind energy" } }
                                ]
                            }
                        };
                    }
                    parts[i] = JSON.stringify(queryBody);
                }
            }
            req.body = parts.join('\n');
            return req;
        }
        return req;
    }

    return (
        <SearchProvider
            elasticIndex="projects"
            apiPrefix="api"
            customTransformRequest={(req) => customTransformRequest(req)}
        >
            <StyledDiv isMobile={isMobile}>
                <SearchBar
                    dataField={Object.keys(searchFields)}
                    highlight={true}
                    customHighlight={(d) => ({
                        highlight: {
                            pre_tags: ['<mark>'],
                            post_tags: ['</mark>'],
                            fields: {
                                ...d.dataField.reduce((carry, field) => {
                                    return {
                                        ...carry,
                                        [field]: field === 'description' || field === 'title'
                                            ? { number_of_fragments: 0 }
                                            : {},
                                    };
                                }, {}),
                            },
                        },
                    })}
                    filterLabel="Search"
                    queryFormat="and"
                    queryString={true}
                    URLParams={true}
                    debounce={500}
                    autosuggest={false}
                />
            </StyledDiv>
            <StyledWrapper>
                <StyledSelectedFilters />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={5} lg={3} xl={3}>
                        <ProjectFilters />
                    </Grid>
                    <Grid item xs={12} sm={8} md={7} lg={9} xl={9}>
                        <SearchResults
                            resultsText="projects"
                            dataField="title.keyword"
                            sortBy="asc"
                            react={{
                                and: [SEARCH_BAR_REACTIVE_ID, ...FILTER_REACTIVE_IDS],
                            }}
                            renderItem={(hit) => {
                                const project = elasticHitToProject(hit);
                                return (
                                    <ProjectResultCard
                                        key={`${project.identifier}`}
                                        project={hit}
                                        highlight={hit.highlight}
                                        searchFieldLabels={searchFields}
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </StyledWrapper>
        </SearchProvider>
    );
});

export default ProjectSearchWindEnergy;

import { Component } from 'react';
import {
  faMapMarkedAlt,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import difference from 'lodash/difference';
import { observer, inject } from 'mobx-react';
import CancelIcon from '@mui/icons-material/Cancel';
import { observable, action, makeObservable } from 'mobx';
import { ReactiveBase, StateProvider } from '@appbaseio/reactivesearch';
import { Button, Grid, Box, Collapse, IconButton, Alert, styled } from '@mui/material';

import HeadManager from '@extensions/components/page/HeadManager';
import DatasetSearchFilters from '@extensions/components/page/datasetSearch/DatasetSearchFilters';

import { ICachingService } from '@extensions/services/ICachingService';
import { ISecurityService } from '@extensions/services/ISecurityService';

import {
  getHashValues,
  setHashValues,
  stringify,
  parse,
} from '@dapclient/utils/url';
import {
  SearchBar,
  SEARCH_BAR_REACTIVE_ID,
} from '@dapclient/components/search-core';
import AddIcon from '@extensions/utils/AddIcon';
import {
  ResultsList,
  RECOMMENDED_REACTIVE_ID,
  DATA_LEVEL_REACTIVE_ID,
  INSTRUMENT_REACTIVE_ID,
  MEASUREMENT_REACTIVE_ID,
  MODEL_REACTIVE_ID,
  STANDARDIZATION_REACTIVE_ID,
  FILE_TYPE_REACTIVE_ID,
  DATE_RANGE_REACTIVE_ID,
  PROJECT_REACTIVE_ID,
  AREA_FILTER_REACTIVE_ID,
} from '@extensions/components/page/search-components';
import AreaFilter from '@extensions/components/dataset-search/AreaFilter';
import ITokenService from '@extensions/services/ITokenService';
import SelectedFilters from '@extensions/components/page/search-components/SelectedFilters';

const REACTIVE_IDS = [
  SEARCH_BAR_REACTIVE_ID,
  DATA_LEVEL_REACTIVE_ID,
  RECOMMENDED_REACTIVE_ID,
  INSTRUMENT_REACTIVE_ID,
  MEASUREMENT_REACTIVE_ID,
  MODEL_REACTIVE_ID,
  STANDARDIZATION_REACTIVE_ID,
  FILE_TYPE_REACTIVE_ID,
  DATE_RANGE_REACTIVE_ID,
  PROJECT_REACTIVE_ID,
  AREA_FILTER_REACTIVE_ID,
];

export interface ISearchProps {
  projectContext?: string;
  cachingService?: ICachingService;
  securityService?: ISecurityService;
  tokenService?: ITokenService;
  className?: string;
}

const StyledFiltersAndMapToggleDiv = styled('div')({
  display: 'flex',
  marginTop: '-1rem',
  marginBottom: '0.5rem',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
});

const StyledSelectedFilters = styled(SelectedFilters)({
  flex: '1',
  marginRight: '0.5rem',
});

const StyledMapToggleButton = styled(Button)({
  flex: '0 0 auto',
  flexDirection: 'row-reverse',
});

const StyledMapToggleButtonTextSpan = styled('span')({
  marginLeft: '0.25rem',
});

const StyledAlert = styled(Alert)({
  marginTop: '1rem',
  backgroundColor: '#f1c40f',
  color: '#FFF',
  fontWeight: 800,
  '& .MuiAlert-message': {
    margin: 'auto',
  },
  '& .MuiAlert-action': {
    marginLeft: 0,
    paddingLeft: 0,
  }
});

@inject('cachingService', 'securityService', 'tokenService')
@observer
class OriginalDatasetSearch extends Component<ISearchProps> {
  @observable
  showMap: boolean = true;
  @observable
  showMapToggleButton: boolean = false;
  @observable
  defaultViz: boolean | undefined;
  @observable
  isShowAlert: boolean = true;

  @action
  toggleMap = () => (this.showMap = !this.showMap);

  @action
  onInitialMapLoad = () => (this.showMapToggleButton = true);

  @action
  toggleIsShowAlert = () => (this.isShowAlert = !this.isShowAlert);

  allReactiveIdsExcept = (excluded) => difference(REACTIVE_IDS, [excluded]);

  constructor(props: ISearchProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const { projectContext, cachingService } = this.props;
    if (!projectContext) {
      this.defaultViz = false;
      return;
    }
    if (projectContext && cachingService) {
      cachingService.getProjectLite(projectContext).then((project) => {
        this.defaultViz = project.hasTimeSeries ?? false;
      });
    }
  }

  render() {
    const { projectContext, securityService, tokenService } = this.props;
    if (!tokenService || !securityService) {
      return null;
    }

    return (
      <div>
        <HeadManager
          title="A2e: Atmosphere to Electrons Datasets"
          description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
            Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
        />
        {securityService.user &&
          securityService.user.authenticated &&
          securityService.user.mfaEnabled &&
          !securityService.user.authenticatedMfa &&
          <Box>
            <Collapse in={this.isShowAlert}>
              <StyledAlert
                icon={false}
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.toggleIsShowAlert();
                    }}
                  >
                    <CancelIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                You have signed in without your second-factor token. You will
                NOT see any proprietary datasets.
              </StyledAlert>
            </Collapse>
          </Box>
        }
        <ReactiveBase
          app="api/datasets"
          url={`${window.location.protocol}//${window.location.host}`}
          headers={{ 'x-csrf-token': tokenService.dapToken }}
          getSearchParams={() => {
            // Uses hash instead of query string and allows for non-native (to
            // Reactivesearch) pieces to be in the URL
            let params: { [key: string]: string } = {};
            const hash = getHashValues();
            for (const key in hash) {
              if (REACTIVE_IDS.indexOf(key) < 0) {
                continue;
              }
              params[key] = hash[key] as string;
            }
            return stringify(params);
          }}
          setSearchParams={(url) => {
            // Uses hash instead of query string and allows for non-native (to
            // Reactivesearch) pieces to be in the URL
            const params = parse(url.split('?')[1]) as {
              [key: string]: string;
            };
            const hash = getHashValues();
            let keep: { [key: string]: string } = {};
            for (const key in params) {
              if (params[key]) {
                keep[key] = params[key] as string;
              }
            }
            for (const key in hash) {
              if (REACTIVE_IDS.indexOf(key) < 0) {
                keep[key] = hash[key] as string;
              }
            }
            setHashValues(keep);
          }}
          transformRequest={(req) => {
            req.body = req.body.replace(
              '"sort":[{"dapFileSummary.updated":{"order":"desc"}}]',
              '"sort":' +
              JSON.stringify([
                {
                  'dapFileSummary.updated': {
                    missing: '_last',
                    order: 'desc',
                  },
                },
                { generalUseReady: { order: 'desc' } },
              ])
            ).replace(/\//g, '\\\\/');
            return req;
          }}
        >
          <StateProvider
            componentIds={[PROJECT_REACTIVE_ID, DATE_RANGE_REACTIVE_ID]}
            includeKeys={['value']}
            onChange={(prev, next) => {
              // This is to hack around this issue:
              // https://github.com/appbaseio/reactivesearch/issues/1460
              // ...only affects the project filter and date range filter
              const value = next[PROJECT_REACTIVE_ID].value;
              if (value === undefined || value === null) {
                let hash = getHashValues();
                if (PROJECT_REACTIVE_ID in hash) {
                  delete hash[PROJECT_REACTIVE_ID];
                }
                if (DATE_RANGE_REACTIVE_ID in hash) {
                  delete hash[DATE_RANGE_REACTIVE_ID];
                }
                setHashValues(hash);
              }
              return null;
            }}
          />
          <div role="search" style={{ paddingBottom: '8px' }}>
            <SearchBar
              dataField={[
                '*',
                'instrument.keyword',
                'dataLevel.keyword',
                'instance.keyword',
                'contactPoint:fn',
                'contactPoint:hasEmail',
                'contactPoint:hasOrg',
                'measurements:description',
                'measurements:standardName.description',
                'measurements:specificName',
              ]}
              filterLabel="Search"
              queryFormat="and"
              queryString={true}
              URLParams={true}
              debounce={500}
              autosuggest={false}
            />
          </div>
          <StyledFiltersAndMapToggleDiv>
            <StyledSelectedFilters />
          </StyledFiltersAndMapToggleDiv>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4} xl={3}>
              <DatasetSearchFilters projectContext={projectContext} />
            </Grid>
            <Grid item xs={12} lg={8} xl={9}>
              <StyledMapToggleButton onClick={this.toggleMap} aria-hidden>
                <>
                  <AddIcon icon={this.showMap ? faList : faMapMarkedAlt} />
                  <StyledMapToggleButtonTextSpan>
                    {this.showMap ? 'Hide Map' : 'Show Map'}
                  </StyledMapToggleButtonTextSpan>
                </>
              </StyledMapToggleButton>
              <AreaFilter
                showMap={this.showMap}
                react={{
                  and: difference(REACTIVE_IDS, [AREA_FILTER_REACTIVE_ID]),
                }}
              />
              {this.defaultViz !== undefined && (
                <ResultsList
                  react={{ and: REACTIVE_IDS }}
                  pageSize={20}
                  vizByDefault={this.defaultViz}
                />
              )}
            </Grid>
          </Grid>
        </ReactiveBase>
      </div>
    );
  }
}

export default OriginalDatasetSearch;

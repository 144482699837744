import {
  Grid,
  styled,
  Typography
} from '@mui/material';
import moment from 'moment';
import { reaction } from 'mobx';
import React, { useState, useEffect } from 'react';

import {
  useDatasetService,
  useMembershipService,
  useMetricsService,
  useNotificationService,
} from '@extensions/hooks/useService';

import {
  IDs,
  Status,
} from '@extensions/services/INotificationService';
import DatasetModel from '@extensions/models/Dataset';

import Panel from '@extensions/components/core/Panel';
import MarkdownText from '@extensions/components/core/MarkdownText';
import ContactsPanel from '@extensions/components/core/ContactsPanel';
import CitationPanel from '@extensions/components/core/CitationPanel';
import ReferencesPanel from '@extensions/components/core/ReferencesPanel';
import DimensionsList from '@extensions/components/dataset/DimensionsList';
import DatasetMap from '@extensions/components/dataset/components/DatasetMap';
import MeasurementsList from '@extensions/components/dataset/MeasurementsList';
import DataQuality from '@extensions/components/dataset/components/DataQuality';
import DataSummary from '@extensions/components/dataset/components/DataSummary';
import RequestAccessModal from '@extensions/components/membership/RequestAccessModal';
import DatasetStatsBar from '@extensions/components/dataset/components/DatasetStatsBar';
import DataFilesSection from '@extensions/components/dataset/components/DataFilesSection';
import DataRelevantEventsList from '@extensions/components/dataset/DataRelevantEventsList';
import TimelineContainerSingle from '@extensions/components/dataset/TimelineContainerSingle';
import DatasetDescription from '@extensions/components/dataset/components/DatasetDescription';
import AddToFavoriteDatasetButton from '@extensions/components/core/AddToFavoriteDatasetButton';

export interface IDatasetSummaryProps {
  showApiTab: (e) => void;
}

const StyledMarkdown = styled(Typography)(() => ({
  fontWeight: 'normal',
  marginBlockStart: '0',
  marginBlockEnd: '12px',
  color: '#000',
  fontSize: '14px',
}));

const DatasetSummary: React.FC<IDatasetSummaryProps> = ({ showApiTab }) => {
  const [requestAccessModalVisible, setRequestAccessModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const datasetService = useDatasetService()
  const membershipService = useMembershipService()
  const notificationService = useNotificationService();
  const metricsService = useMetricsService();

  const dataset: DatasetModel | null = datasetService.dataset;

  useEffect(() => {
    const requestSentReaction = reaction(
      () => notificationService.getNotification(IDs.REQUEST_SENT),
      (requestStatus) => {
        if (requestStatus && requestStatus.status === Status.Success) {
          setRequestAccessModalVisible(false);
        }
      }
    );

    if (dataset) {
      updateActiveTab(dataset);
      metricsService.getGAMetrics(dataset);
    }

    return () => {
      requestSentReaction();
    };
  }, [dataset, notificationService, metricsService]);

  const updateActiveTab = (dataset: DatasetModel) => {
    if (dataset.viewData && dataset.viewData.isViewDataOn) {
      setActiveTab("viewData");
    } else if (dataset.references?.some((ref) => ref.preview === true)) {
      setActiveTab("previewFiles");
    } else if (dataset.realtimeData?.viewDataPlot) {
      setActiveTab("viewPlot");
    }
  };

  if (!dataset) {
    return (
      <div>
        <Typography variant="h6" color="textSecondary">
          No dataset available.
        </Typography>
      </div>
    );
  }

  const openRequestAccessModal = () => {
    setRequestAccessModalVisible(true);
  };

  const closeRequestAccessModal = () => {
    setRequestAccessModalVisible(false)
  };

  const requestAccess = (justification) => {
    const accessSvc = membershipService;
    const datasetSvc = datasetService;
    if (accessSvc && datasetSvc && datasetSvc.dataset) {
      accessSvc.requestDatasetAccess(datasetSvc.dataset.name, justification);
      closeRequestAccessModal();
    }
  };

  return (
    <Grid container spacing={2}>
      <RequestAccessModal
        resourceName={dataset.name}
        resourceLabel={dataset.title}
        open={requestAccessModalVisible}
        handleClose={closeRequestAccessModal}
        request={requestAccess}
      />
      <Grid item xs={12} md={8}>
        <DatasetDescription dataset={dataset} />
        <DatasetMap dataset={dataset} />
        <ReferencesPanel dataset={dataset} isSmallText={true}/>
        {dataset.uncertaintyDescription &&
          <Panel
            ariaControls='uncertainty-description-content'
            id='uncertainty-description'
            title='Uncertainty'
            body={
              <MarkdownText
                rawMarkdown={dataset.uncertaintyDescription}
                components={{
                  p: (props) => <StyledMarkdown variant='body1' {...props} />,
                }}
              />
            }
          />
        }
        {dataset.constraintsDescription &&
          <Panel
            ariaControls='constraints-description-content'
            id='constraints-description'
            title='Constraints'
            body={
              <MarkdownText
                rawMarkdown={dataset.constraintsDescription}
                components={{
                  p: (props) => <StyledMarkdown variant='body1' {...props} />,
                }}
              />
            }
          />
        }
        {dataset.dataDetails &&
          <Panel
            ariaControls='data-details-content'
            id='data-details'
            title='Details'
            body={
              <MarkdownText
                rawMarkdown={dataset.dataDetails}
                components={{
                  p: (props) => <StyledMarkdown variant='body1' {...props} />,
                }}
              />
            }
          />
        }
        {dataset.measurements != null && dataset.measurements.length > 0 &&
          <Panel
            ariaControls='measurements-list-content'
            id='measurements-list'
            title={`Measurements (${dataset.measurements.length})`}
            body={<MeasurementsList measurements={dataset.measurements} />}
          />
        }
        {dataset.dimensions != null && dataset.dimensions.length > 0 &&
          <Panel
            ariaControls='dimensions-list-content'
            id='dimensions-list'
            title={`Dimensions (${dataset.dimensions.length})`}
            body={<DimensionsList dimensions={dataset.dimensions} />}
          />
        }
        {dataset.events && <DataRelevantEventsList events={dataset.events} />}
        {dataset.dataQualityDescription && <DataQuality dataset={dataset} />}
      </Grid>

      <Grid item xs={12} md={4}>
        {dataset.contacts && <ContactsPanel contacts={dataset.contacts} name={dataset.name} title={dataset.title} />}
        <DataSummary dataset={dataset} />
        <CitationPanel givenCitation={dataset.getCitationWithAccessDate(moment())} />
        <DatasetStatsBar item={dataset} />
        <AddToFavoriteDatasetButton dataset={dataset} />
      </Grid>

      <Grid item xs={12}>
        <DataFilesSection datasetAbilities={dataset.abilities} openRequestAccessModal={openRequestAccessModal} />
        {(dataset.dynamoFileCount ?? 0) > 0 && dataset.dynamoDataBegins &&
          <TimelineContainerSingle dataset={dataset} />
        }
      </Grid>
    </Grid>
  )
}

export default DatasetSummary;
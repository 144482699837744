import React from 'react';
import qs from 'query-string';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import { Link, Redirect, useLocation } from 'react-router-dom';

import User from '@extensions/models/User';
import UserProfileForm from '@extensions/components/user/UserProfileForm';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import theme from '@extensions/services/Theme';
import { ISecurityService } from '@extensions/services/ISecurityService';

const StyledFullPageFormDiv = styled('div')({
    ...theme.FullPageForm.root
});

const StyledFormDiv = styled('div')({
    ...theme.FullPageForm.form,
});

const StyledFormFooterDiv = styled('div')(({ theme }) => ({
    ...theme.FullPageForm.formFooter,
    color: theme.palette.grey[700]
}));

const StyledLink = styled(Link)({
    textDecoration: 'none'
});

export interface IRegisterProps {
    securityService?: ISecurityService;
}

const Register: React.FC<IRegisterProps> = ({ securityService }) => {
    const user: User | null = securityService ? securityService.user : null;
    const location = useLocation();

    // These will only be set on a reset password URL sent via email
    const resetToken = qs.parse(location.search).token;
    const email = qs.parse(location.search).email;

    const renderContent = () => {
        if (user && user.authenticated) {
            return <Redirect to={{ pathname: '/profile' }} />;
        } else {
            return (
                <StyledFullPageFormDiv>
                    <StyledFormDiv>
                        {/* Adding a unique key will force the form to recreate itself every time this page is rendered */}
                        <UserProfileForm
                            key={new Date().getMilliseconds()}
                            resetToken={resetToken}
                            email={email}
                        />
                    </StyledFormDiv>
                    <StyledFormFooterDiv>
                        Already have an account? <StyledLink to="/signIn">Sign In</StyledLink>
                    </StyledFormFooterDiv>
                </StyledFullPageFormDiv>
            );
        }
    }
    
    return <GlobalSearchBarWrap renderChildren={() => renderContent()}/>
};

export default inject('securityService')(observer(Register));

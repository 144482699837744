/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import Project from '@extensions/models/Project';

import {
  styled,
  Typography
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import MarkdownImage from '@extensions/components/core/MarkdownImage';
import MarkdownLink from '@extensions/components/core/MarkdownLink';
import DescriptionPanel from '@extensions/components/core/DescriptionPanel';

interface IProjectDescriptionProps {
  project: Project;
}

const StyledHeaderTag = styled(Typography)(() => ({
  fontWeight: 'normal',
  marginBlockStart: '0',
  marginBlockEnd: '12px',
  fontSize: '1.25em',
  color: '#000000',
}))

const StyledTextTag = styled(Typography)(() => ({
  fontWeight: 'normal',
  marginBlockStart: '0',
  marginBlockEnd: '12px',
  fontSize: '1em',
  color: '#4a4d50',
}))

const StyledLiTag = styled('li')(() => ({
  color: '#00000',
  fontSize: '14px',
  lineHeight: '22px',
}))

const ProjectDescription: React.FC<IProjectDescriptionProps> = ({ project }) => {
  const convertImageUri = (imageUri: string) => {
    return `/api/content/${project.identifier}/${imageUri}`;
  };

  const convertDocumentUri = (uri: string) => {
    if (uri.search(/^(mailto:|\/|(ht|f)tps?:\/\/)/) < 0) {
      return `/api/content/${project.identifier}/${uri}`;
    }
    return uri;
  }

  const headerTagHandler = (props: React.ComponentProps<typeof Typography>) => (
    <StyledHeaderTag {...props} />
  );

  return (
    <DescriptionPanel>
      <ReactMarkdown
        children={project.description}
        transformImageUri={convertImageUri}
        transformLinkUri={convertDocumentUri}
        linkTarget={(url) =>
          url.startsWith('http') || url.startsWith('documents') ? '_blank' : ''
        }
        components={{
          img: MarkdownImage,
          a: MarkdownLink,
          h1: headerTagHandler,
          h2: headerTagHandler,
          h3: headerTagHandler,
          h4: headerTagHandler,
          h5: headerTagHandler,
          p: (props) => <StyledTextTag variant="body1" {...props} />,
          li: (props) => <StyledLiTag {...props} />,
        }}
        rehypePlugins={[rehypeRaw]}
      />
    </DescriptionPanel>
  )
}

export default ProjectDescription

import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';
import { Button, Grid, Tab, Tabs, Typography, styled } from '@mui/material';

import Link from '@extensions/components/core/Link';
import ProjectOverview from '@extensions/components/project/ProjectOverview';
import OriginalDatasetSearch from '@extensions/components/page/OriginalDatasetSearch';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import theme from '@extensions/services/Theme';
import ProjectModel from '@extensions/models/Project';
import { ABILITIES } from '@extensions/services/ISecurityService';
import { useHistoryService, useProjectService, useSecurityService } from '@extensions/hooks/useService';

const StyledButton = styled(Button)(({ theme }) => ({
    float: 'right',
    backgroundColor: theme.palette.primary.main
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    '&.MuiTab-root': {
        color: theme.palette.text.primary,
    },
    '&.Mui-selected': {
        color: '#000',
    }
}));

const StyledWrapper = styled('div')({
    margin: '0 20em',
    [theme.breakpoints.down('xl')]: {
        margin: '0 1em',
    },
    [theme.breakpoints.down('xs')]: {
        margin: '0 0em',
    },
});
interface IProjectContentProps {
    project: ProjectModel;
}

const ProjectContent: React.FC<IProjectContentProps> = ({ project }) => {
    const projectService = useProjectService();
    const historyService = useHistoryService();
    const securityService = useSecurityService();

    const renderProjectDetail = (project, securityService, abilities, tabIndex, historyService, datasetsPathSuffix): React.ReactNode => {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item sm={10} xs={12}>
                        <Typography
                            variant="h2"
                            gutterBottom
                            sx={{
                                color: theme.palette.secondary.main,
                                fontWeight: 400,
                            }}
                        >
                            {project.title}
                        </Typography>
                    </Grid>

                    <Grid item xs={11}>
                        <Tabs
                            value={tabIndex}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="project tabs"
                            onChange={(event, value) => {
                                if (value === tabIndex) {
                                    return;
                                }
                                switch (value) {
                                    case 0:
                                        return historyService.redirect(
                                            historyService.history.location.pathname.replace(datasetsPathSuffix, '')
                                        );
                                    case 1:
                                        return historyService.redirect(
                                            historyService.history.location.pathname + datasetsPathSuffix
                                        );
                                }
                            }}
                        >
                            <StyledTab value={0} label="Project" />
                            <StyledTab value={1} label={`Datasets (${project.datasetCount})`} />
                        </Tabs>
                    </Grid>

                    <Grid item xs={1}>
                        {securityService.userIsLoggedIn &&
                            abilities &&
                            abilities.includes(ABILITIES.ADMIN) && (
                                <Link to={`/project/${project.name}/manage`}>
                                    <StyledButton
                                        variant="contained"
                                    >
                                        Manage
                                    </StyledButton>
                                </Link>
                            )}
                    </Grid>
                </Grid>
                <div>
                    {tabIndex === 0 && (
                        <ProjectOverview />
                    )}
                    {tabIndex === 1 && (
                        <OriginalDatasetSearch projectContext={project.name} />
                    )}
                </div>
            </div>
        )
    }

    const renderContent = (): React.ReactNode | null => {
        const abilities: string[] | null = projectService.abilities;
        const datasetsPathSuffix = '/data';
        const tabIndex = historyService.history.location.pathname.endsWith(datasetsPathSuffix) ? 1 : 0;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{project.title}</title>
                    <meta name="description" content={project.description}></meta>
                </Helmet>
                <GlobalSearchBarWrap renderChildren={() => renderProjectDetail(project, securityService, abilities, tabIndex, historyService, datasetsPathSuffix)} />
            </React.Fragment>
        );
    }

    return (
        <StyledWrapper>{renderContent()}</StyledWrapper>
    )
}

export default observer(ProjectContent);
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Link,
    Chip,
    CardActions,
    Grid,
    styled,
} from '@mui/material';
import React from 'react';
import omit from 'lodash/omit';
import removeMd from 'remove-markdown';
import {
    faHandshake,
    faChartBar,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import AddIcon from '@extensions/utils/AddIcon';

import ExpandableText from '@extensions/components/core/ExpandableText';
import SearchHighlight from '@extensions/components/core/SearchHighlight';

import theme from '@extensions/services/Theme';
import { PublicationType } from '@extensions/models/Publication';
import { useSecurityService } from '@extensions/hooks/useService';

const StyledAddIcon = styled(AddIcon)(({ theme }) => (({
    marginLeft: '0.5rem',
    color: theme.palette.primary.dark,
    height: theme.spacing(2.3),
})));

const StyledChip = styled(Chip)(({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    height: 'initial'
}));

const StyledTypographyHTwo = styled(Typography)(() => ({
    fontSize: `${theme.typography.pxToRem(20.8)}`,
    fontWeight: `${theme.typography.fontWeightRegular}`,
}));

const StyledLink = styled(Link)(() => ({
    marginBottom: theme.spacing(1),
    display: 'block',
    color: '#005b82',
    textTransform: 'none',
    width: 'fit-content',
}));

const StyledGridItem = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const StyledTypographyBodyOneTitle = styled(Typography)(() => ({
    fontWeight: 800,
    fontSize: '0.8rem',
    color: theme.palette.text.secondary
}));

const StyledTypographyBodyOne = styled(Typography)(() => ({
    fontWeight: 800,
    fontSize: '1rem',
    color: theme.palette.text.secondary,
}));

interface PublicationResultCardProps {
    publication: Record<string, any>;
    searchFieldLabels?: { string: string };
}

const PublicationResultCard: React.FC<PublicationResultCardProps> = ({ publication, searchFieldLabels }) => {
    const hlField = (f) => {
        return publication.highlight && publication.highlight.hasOwnProperty(f)
            ? publication.highlight[f].join(' ')
            : publication[f]
    };

    const securityService = useSecurityService();

    const checkIsMember = (publication: any) => {
        const userMembers = securityService?.user?.memberOf;
        var result = false;
        if (publication['datasets'] && publication['datasets'].length) {
            publication['datasets'].forEach(dataset => {
                if (userMembers?.includes(dataset.name)) {
                    result = true;
                }
            });
        } else if (publication['projects'] && publication['projects'].length) {
            publication['projects'].forEach(project => {
                if (userMembers?.includes(project.name)) {
                    result = true;
                }
            });
        }
        return result;
    }

    const renderPublicationTitle = (publication: any) => {
        if (publication.type !== PublicationType.JOURNAL_ARTICLE && publication.type !== PublicationType.TECHNICAL_REPORT) {
            if (securityService && securityService.user && (securityService.user.canAdminPubs || checkIsMember(publication))) {
                return (
                    <StyledLink href={`/publication/${publication.id}`} underline='hover'>
                        <span dangerouslySetInnerHTML={{ __html: hlField('title') }} />
                    </StyledLink>
                )
            } else {
                return (
                    <StyledLink href={publication.url.startsWith('/api/') ? `${window.location.origin}${publication.url}` : publication.url} underline='hover'>
                        <span dangerouslySetInnerHTML={{ __html: hlField('title') }} />
                    </StyledLink>
                )
            }
        } else {
            return (
                <StyledLink href={`/publication/${publication.id}`} underline='hover'>
                    <span dangerouslySetInnerHTML={{ __html: hlField('title') }} />
                </StyledLink>
            )
        }
    }

    const renderLabel = (publication) => {
        switch (publication.referenceType) {
            case "JournalArticle":
                return "Publication - Journal Article"
            case "web":
                return "Publication - Web"
            case "TechnicalReport":
                return "Publication - Technical Report"
            default:
                break;
        }
    }

    const getExpandableText = (hit:  Record<string, any>): React.ReactNode => {
        const abstract = removeMd(hlField('abstract'), ['mark'])
                    .replace(/^[^<>]+(\s[^<>]{300,}<mark>)/, '...$1');
        const fields = omit(searchFieldLabels, [
                    'title',
                    'abstract',
                ]) as { string: string };
        return (
            <>
                <ExpandableText dangerous>
                    {abstract}
                </ExpandableText>
                {Boolean(publication.highlight) && Boolean(searchFieldLabels) && (
                    <SearchHighlight
                        highlight={publication.highlight as { string: string[] }}
                        searchFieldLabels={fields}
                    />
                )}
            </>
        )
    }

    return (
        <Card variant='outlined' key={publication.id} sx={{ mb: '1rem' }}>
            <CardHeader
                sx={{ p: 0, ml: '1rem', mt: '0.5rem' }}
                title={
                    <>
                        <StyledChip
                            size="small"
                            label={renderLabel(publication)}
                        />
                        <StyledTypographyHTwo
                            variant="h2"
                        >
                            {renderPublicationTitle(publication)}
                        </StyledTypographyHTwo>
                    </>
                }
            >
            </CardHeader>
            <CardContent sx={{ pb: 0 }}>
                {publication.abstract && getExpandableText(publication)}
            </CardContent>
            <CardActions disableSpacing sx={{ ml: '0.5rem', mt: 0, pb: 0 }}>
                <Grid container spacing={4}>
                <StyledGridItem item>
                        <StyledTypographyBodyOneTitle variant='body1'>Associated Projects</StyledTypographyBodyOneTitle>
                        <StyledTypographyBodyOne variant='body1'>
                            {publication.projects.length}<StyledAddIcon icon={faHandshake} />
                        </StyledTypographyBodyOne>
                    </StyledGridItem>
                    <StyledGridItem item>
                        <StyledTypographyBodyOneTitle variant='body1'>Associated Datasets</StyledTypographyBodyOneTitle>
                        <StyledTypographyBodyOne variant='body1'>
                            {publication.datasets.length}<StyledAddIcon icon={faChartBar} />
                        </StyledTypographyBodyOne>
                    </StyledGridItem>
                    <StyledGridItem item>
                        <StyledTypographyBodyOneTitle variant='body1'>Authors</StyledTypographyBodyOneTitle>
                        <StyledTypographyBodyOne variant='body1'>
                            {publication.authorNames.length}<StyledAddIcon icon={faUser} />
                        </StyledTypographyBodyOne>
                    </StyledGridItem>
                </Grid>
            </CardActions>
        </Card>
    )
};

export default PublicationResultCard;
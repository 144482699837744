import React from 'react';
import { inject, observer } from 'mobx-react';

import {
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  styled
} from '@mui/material';
import map from 'lodash/fp/map';
import uniq from 'lodash/fp/uniq';
import filter from 'lodash/fp/filter';
import flatten from 'lodash/fp/flatten';
import compose from 'lodash/fp/compose';
import theme from '@extensions/services/Theme';

import { ICartService } from '@extensions/services/ICartService';

import Dataset from '@extensions/models/Dataset';

const FileTypeRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

export interface IFileTypesFilterProps {
  className?: string;
  cartService?: ICartService;
  styleObject?: any;
}

@inject('cartService')
@observer
class FileTypesFilter extends React.Component<IFileTypesFilterProps> {
  state = {
    showAll: false,
  };

  private getFileTypeCounts(fileTypesArr: string[]): Record<string, number> {
    const { cartService } = this.props;
    const result: Record<string, number> = {};

    if (!cartService || !cartService.datasets) {
      return result;
    }

    fileTypesArr.forEach((ft) => {
      result[ft] = 0;
    });

    cartService.datasets.forEach((dataset) => {
      if (dataset.dynamoFileTypes) {
        const setOfTypes = new Set(dataset.dynamoFileTypes);
        fileTypesArr.forEach((ft) => {
          if (setOfTypes.has(ft)) {
            result[ft] += 1;
          }
        });
      }
    });

    return result;
  }

  render() {
    const { cartService, styleObject } = this.props;
    if (
      !cartService ||
      cartService.datasets === null ||
      cartService.datasets.length === 0
    ) {
      return null;
    }

    const uniqueFileTypes = compose(
      uniq,
      flatten,
      filter((fileTypes: string[] | null) => fileTypes !== null),
      map((dataset: Dataset) => dataset.dynamoFileTypes)
    )(cartService.datasets);

    if (uniqueFileTypes.length === 0) {
      return null;
    }

    const fileTypeCounts = this.getFileTypeCounts(uniqueFileTypes);
    return (
      <FormControl
        component="div"
        sx={
          styleObject?.root
            ? { ...styleObject.root }
            : { width: '100%' }
        }
      >
        <FormGroup
          sx={
            styleObject?.filterOptionList
              ? { ...styleObject.filterOptionList, flexWrap: 'nowrap' }
              : { flexWrap: 'nowrap' }
          }
        >
          {uniqueFileTypes.map((fileType) => (
            <FileTypeRow key={fileType}>
              <FormControlLabel
                sx={{
                  marginLeft: '-11px',
                  '& .MuiTypography-root': {
                    color: theme.palette.grey[700],
                  },
                }}
                control={
                  <Checkbox
                    checked={cartService.fileTypesFilter.has(fileType)}
                    onChange={() => {
                      cartService.toggleFileType(fileType);
                    }}
                    name={fileType}
                  />
                }
                label={`${fileType} (${fileTypeCounts[fileType]})`}
              />
            </FileTypeRow>
          ))}
        </FormGroup>
      </FormControl>
    );
  }
}

export default FileTypesFilter;

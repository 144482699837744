import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardContent,
    Typography,
    Card,
    Box,
    styled,
} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

import AddIcon from '@extensions/utils/AddIcon';

const StyledBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '-1rem',
});

const StyledAddIcon = styled(AddIcon)(({ theme }) => ({
    color: theme.palette.primary.dark,
    height: theme.spacing(6),
}));

const StyledNumberTypography = styled(Typography)({
    fontSize: '4.5rem',
});

const StyledCardTitleTypography = styled(Typography)({
    color: '#000',
});

const StyledDetailsTypography = styled(Typography)({
    color: '#025B82',
    fontSize: '1rem',
    margin: '0.25rem auto',
});

const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
    color: '#025B82',
});

const StyledDiv = styled('div')(({
    display: 'flex',
    flexDirection: 'column'
}));

interface IMetricCardProps {
    cardTitle: string;
    icon: FontAwesomeIconProps['icon'];
    count: number;
    children?: React.ReactNode;
}

const formatNumber = (inputNum: number): string => {
    const thresholds = [
        { value: 1e9, suffix: 'b+' },
        { value: 1e6, suffix: 'm+' },
        { value: 1e3, suffix: 'k+' },
    ];

    for (const { value, suffix } of thresholds) {
        if (inputNum >= value) {
            return `${(inputNum / value).toFixed(1)}${suffix}`;
        }
    }

    return inputNum.toString();
};

const MetricsCard: React.FC<IMetricCardProps> = ({ cardTitle, icon, count, children }) => {
    const renderLoadingNumber = (numberValue: number, faIcon: FontAwesomeIconProps['icon']) => (
        <StyledDiv>
            <StyledAddIcon icon={faIcon} />
            <StyledNumberTypography variant="body1">
                {formatNumber(numberValue)}
            </StyledNumberTypography>
        </StyledDiv>
    );

    return (
        <Card variant="outlined">
            <CardContent>
                <StyledCardTitleTypography>{cardTitle}</StyledCardTitleTypography>
                <StyledBox>{renderLoadingNumber(count, icon)}</StyledBox>
            </CardContent>
            {children && (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<StyledExpandMoreIcon />}
                        sx={{
                            '&.MuiAccordionSummary-root': { background: '#ffffff' }
                        }}
                    >
                        <StyledDetailsTypography variant="body2">Details</StyledDetailsTypography>
                    </AccordionSummary>
                    <AccordionDetails>{children}</AccordionDetails>
                </Accordion>
            )}
        </Card>
    );
};

export default MetricsCard;
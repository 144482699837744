import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Link,
    Chip,
    CardActions,
    Grid,
    IconButton,
    styled,
} from '@mui/material';
import omit from 'lodash/omit';
import {
    faNewspaper,
    faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import AddIcon from '@extensions/utils/AddIcon';
import React, { useState, useEffect } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { removeMdExcept } from '@extensions/utils/SearchUtils';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import ExpandableText from '@extensions/components/core/ExpandableText';
import SearchHighlight from '@extensions/components/core/SearchHighlight';
import ContactMenu from '@extensions/components/page/home-page/ContactMenu';

import Project from '@extensions/models/Project';

import theme from '@extensions/services/Theme';
import { useSecurityService } from '@extensions/hooks/useService';

const StyledAddIcon = styled(AddIcon)(({ theme }) => (({
    marginLeft: '0.5rem',
    color: theme.palette.primary.dark,
    height: theme.spacing(2.4),
})));

const StyledChip = styled(Chip)(({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    height: 'initial'
}));

const StyledTypographyHTwo = styled(Typography)(() => ({
    fontSize: `${theme.typography.pxToRem(20.8)}`,
    fontWeight: `${theme.typography.fontWeightRegular}`,
}));

const StyledGridItem = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const StyledTypographyBodyOneTitle = styled(Typography)(() => ({
    fontWeight: 800,
    fontSize: '0.8rem',
    color: theme.palette.text.secondary
}));

const StyledTypographyBodyOne = styled(Typography)(() => ({
    fontWeight: 800,
    fontSize: '1rem',
    color: theme.palette.text.secondary,
}));

interface IProjectResultCardProps {
    project: Project;
    highlight?: { string: string[] };
    searchFieldLabels?: { string: string };
    isCachedFav?: boolean;
    fromFavList?: boolean;
};

const ProjectResultCard: React.FC<IProjectResultCardProps> = ({ project, highlight, searchFieldLabels, isCachedFav, fromFavList }) => {
    const [isFav, setIsFav] = useState(false);

    const securityService = useSecurityService();
    const { user } = securityService;

    useEffect(() => {
        if (securityService.userIsLoggedIn) {
            if (user && user['fav_project'] && user['fav_project'].length && user['fav_project'].includes(project.identifier)) {
                setIsFav(true)
            } else {
                setIsFav(false)
            }
        }
    }, [user, project.identifier, securityService.userIsLoggedIn]);

    const hlField = (f) => {
        return highlight && highlight.hasOwnProperty(f)
            ? highlight[f][0]
            : project[f]
    };

    const getExpandableText = (hit: Project): React.ReactNode => {

        const desc = removeMdExcept(hlField('description'), ['mark'])
            .replace(/^[^<>]+(\s[^<>]{300,}<mark>)/, '...$1');

        const fields = omit(searchFieldLabels, [
            'name',
            'title',
            'description',
        ]) as { string: string };

        return (
            <>
                <ExpandableText dangerous>
                    {desc}
                </ExpandableText>

                {Boolean(highlight) && Boolean(searchFieldLabels) && (
                    <SearchHighlight
                        highlight={highlight as { string: string[] }}
                        searchFieldLabels={fields}
                    />
                )}
            </>
        );
    };

    const renderValue = (data: any) => {
        if (typeof data === 'number') {
            return data;
        }

        if (Array.isArray(data)) {
            return data.length
        }

        if (!data) {
            return 0
        }
        return 0;
    };

    const handleClick = async () => {
        let statusCode = 0;
        if ((isCachedFav || isFav) && user && user.fav_project && user.fav_project.length) {
            const updatedFavProject = user.fav_project.filter(p => p !== project.identifier);
            statusCode = await securityService.updateFavorite({
                "fav_project": updatedFavProject
            }, false);
            if (statusCode === 200) {
                setIsFav(prevIsFav => !prevIsFav);
            }
            if (
                (isCachedFav && !isFav && user.fav_project.length === 1) 
                || (fromFavList && user.fav_project.length === 1)) {
                window.location.reload()
            }
        } else {
            if (user && (!user.hasOwnProperty('fav_project') || !user.fav_project)) {
                statusCode = await securityService.updateFavorite({
                    "fav_project": [project.identifier]
                }, true);
                if (statusCode === 200) {
                    setIsFav(prevIsFav => !prevIsFav);
                }
            } else if (user && user.fav_project && user.fav_project.length === 0) {
                statusCode = await securityService.updateFavorite({
                    "fav_project": [project.identifier]
                }, true);
                if (statusCode === 200) {
                    setIsFav(prevIsFav => !prevIsFav);
                }
            } else if (user && user.fav_project && user.fav_project.length > 0) {
                statusCode = await securityService.updateFavorite({
                    "fav_project": [...user['fav_project'], project.identifier]
                }, true);
                if (statusCode === 200) {
                    setIsFav(prevIsFav => !prevIsFav);
                }
            }
        }
    }

    return (
        <Card variant='outlined' key={`${project.identifier}-card`} sx={{ mb: '1rem' }}>
            <CardHeader
                sx={{ p: 0, ml: '1rem', mt: '0.5rem' }}
                title={
                    <>
                        <StyledChip
                            size="small"
                            label="PROJECT"
                        />
                        <StyledTypographyHTwo
                            variant="h2"
                        >
                            <Link href={`/project/${project.identifier}`} underline='hover'>
                                <span dangerouslySetInnerHTML={{ __html: hlField('title') }} />
                            </Link>
                        </StyledTypographyHTwo>
                    </>
                }
            >
                <Typography></Typography>
            </CardHeader>
            <CardContent sx={{ pb: 0 }}>
                <Typography
                    variant='body1'
                    component={'div'}
                >{getExpandableText(project)}</Typography>
            </CardContent>
            <CardActions disableSpacing sx={{ ml: '0.5rem', mt: 0, pb: 0 }}>
                <Grid container spacing={4}>
                    <StyledGridItem item>
                        <StyledTypographyBodyOneTitle variant='body1'>DATASETS</StyledTypographyBodyOneTitle>
                        <StyledTypographyBodyOne variant='body1'>
                            {renderValue(project.datasetCount)}<StyledAddIcon icon={faChartBar} />
                        </StyledTypographyBodyOne>
                    </StyledGridItem>
                    <StyledGridItem item>
                        <StyledTypographyBodyOneTitle variant='body1'>REFERENCES</StyledTypographyBodyOneTitle>
                        <StyledTypographyBodyOne variant='body1'>
                            {renderValue(project.references)}<StyledAddIcon icon={faNewspaper} />
                        </StyledTypographyBodyOne>
                    </StyledGridItem>
                    <StyledGridItem item>
                        <StyledTypographyBodyOneTitle variant='body1'>CONTACT</StyledTypographyBodyOneTitle>
                        <ContactMenu project={project} />
                    </StyledGridItem>
                    {
                        securityService.userIsLoggedIn
                        &&
                        <StyledGridItem item>
                            <StyledTypographyBodyOneTitle variant='body1'>
                                {
                                    (isFav || isCachedFav)
                                        ? "Remove from Favorites"
                                        : "Add to Favorites"
                                }
                            </StyledTypographyBodyOneTitle>
                            <StyledTypographyBodyOne variant='body1'>
                                <IconButton
                                    onClick={handleClick}
                                    sx={{
                                        marginTop: '-0.5rem'
                                    }}
                                >
                                    {
                                        (isFav || isCachedFav)
                                            ? <FavoriteIcon
                                                sx={{
                                                    '&.MuiSvgIcon-root': {
                                                        color: theme.palette.warning.main
                                                    }
                                                }}
                                            />
                                            : <FavoriteBorderIcon />
                                    }
                                </IconButton>
                            </StyledTypographyBodyOne>
                        </StyledGridItem>
                    }
                </Grid>
            </CardActions>
        </Card>
    )
}

export default ProjectResultCard;
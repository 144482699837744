import React, { useState } from 'react';
import { Menu, Card, MenuItem } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { react } from '@appbaseio/reactivesearch/lib/types';

import MultiList from '@extensions/components/core/MultiList';

export const INSTRUMENT_REACTIVE_ID = 'InstrumentFilter';

interface InstrumentFilterProps {
  react?: react;
}

const InstrumentFilter: React.FC<InstrumentFilterProps> = ({ react }) => {
  const [sortAlphabetically, setSortAlphabetically] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (sortAlphabetically: boolean) => {
    setSortAlphabetically(sortAlphabetically);
    handleMenuClose();
  };

  const handleSelectionChange = (selectedValues: string[]) => {
    setSelectedItems(selectedValues);
  };

  const sortedItems = (items: any[]) => {
    if (sortAlphabetically) {
      return items.sort((a, b) => a.key.localeCompare(b.key));
    } else {
      return items.sort((a, b) => b.doc_count - a.doc_count);
    }
  };

  const filterHeader = (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <span style={{ textTransform: 'capitalize', fontSize: '1rem' }}>
        Instrument
      </span>
      <div onClick={handleMenuOpen} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        <FilterAltIcon />
      </div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => handleSort(false)}>Sort by Count</MenuItem>
        <MenuItem onClick={() => handleSort(true)}>Sort Alphabetically</MenuItem>
      </Menu>
    </div>
  );

  return (
    <div>
      <MultiList
        className="data-filter"
        componentId={INSTRUMENT_REACTIVE_ID}
        dataField="instrument.keyword"
        title={filterHeader}
        filterLabel="Instrument"
        showSearch={false}
        react={react}
        URLParams={true}
        sortBy={sortAlphabetically ? 'asc' : 'count'}
        onChange={handleSelectionChange}
        value={selectedItems}
        transformData={sortedItems}
      />
    </div>
  );
};

export default InstrumentFilter;
import { observer } from 'mobx-react';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    AppBar,
    Button,
    Menu,
    MenuItem,
    StepIcon,
    Tab,
    Tabs,
    Toolbar,
    Typography,
} from '@mui/material';

import theme from '@extensions/services/Theme';
import { useUploadService, useGlobalSearchService } from '@extensions/hooks/useService';

import { useHashStateKey } from '@extensions/hooks/useUrl';
import StepManage from '@extensions/components/data/uploaders/StepManage';
import StepDownload from '@extensions/components/data/uploaders/StepDownload';
import StepConfigure from '@extensions/components/data/uploaders/StepConfigure';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

const StyledHeadingTypography = styled(Typography)({
    marginBottom: '0.5rem',
});

const StyledStepDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.5rem',
});

const StyledDiv = styled('div')({
    margin: '0 1em',
});

const TabStep = ({ children, icon }) => {
    return (
        <StyledStepDiv>
            <StepIcon icon={icon} sx={{ mr: '0.25rem' }}></StepIcon>
            {children}
        </StyledStepDiv>
    );
};

interface IUploadersProps extends RouteComponentProps<{ project: string }> { }

const Uploaders = ({ match }: IUploadersProps) => {
    const uploadService = useUploadService();
    const globalSearchService = useGlobalSearchService();

    useEffect(() => {
        uploadService.load();
    });

    const history = useHistory();
    const location = useLocation();

    const projectName = match.params['project'];

    useEffect(() => {
        uploadService.loadProject(projectName, false);
    }, [uploadService, projectName]);

    const onProjectChange = useCallback(
        (name) => {
            const pathname = location.pathname.replace(
                new RegExp(`/${projectName}(/|$)`),
                `/${name}$1`
            );
            const search = window.location.search;
            const hash = window.location.hash;
            history.push(`${pathname}${search}${hash}`);
        },
        [history, location, projectName]
    );

    const [menuAnchorEl, setMenuAnchorEl] = useState(null as null | HTMLElement);

    const steps = [
        {
            comp: StepConfigure,
            label: 'Configure Datasets',
        },
        {
            comp: StepDownload,
            label: 'Download & Run Exe',
        },
        {
            comp: StepManage,
            label: 'Manage Clients',
        },
    ];

    const [step, setStep] = useHashStateKey('step', 1);
    const stepIndex = Math.max(0, Math.min(steps.length - 1, +step - 1));

    useEffect(() => {
        if (+step - 1 !== stepIndex) {
            setStep(stepIndex + 1);
        }
    }, [step, stepIndex, setStep]);

    const Step = steps[stepIndex].comp;


    const renderContent = () => {
        return (
            <StyledDiv>
                <StyledHeadingTypography variant="h1">
                    Uploader Clients
                </StyledHeadingTypography>
                <Typography sx={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                    An uploader client is a downloadable executable intended to be run on a
                    remote machine where data files reside. Follow the steps below to
                    configure remote machines to submit data using uploader clients.
                </Typography>
                <AppBar position="sticky">
                    <Toolbar sx={{ backgroundColor: theme.palette.primary.main }}>
                        <Button
                            variant="text"
                            sx={{ color: theme.palette.common.black }}
                            disableElevation
                            onClick={(event: MouseEvent<HTMLElement>) =>
                                setMenuAnchorEl(event.currentTarget)
                            }
                        >
                            <ArrowDropDownIcon />
                            {projectName}
                        </Button>
                        <Menu
                            anchorEl={menuAnchorEl}
                            keepMounted
                            open={Boolean(menuAnchorEl)}
                            onClose={() => setMenuAnchorEl(null)}
                            sx={{ maxHeight: 500 }}
                        >
                            {uploadService.projectNames.map((name) => (
                                <MenuItem
                                    key={name}
                                    selected={projectName === name}
                                    onClick={() => {
                                        setMenuAnchorEl(null);
                                        onProjectChange(name);
                                    }}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Menu>
                        <div style={{ flexGrow: 1 }}></div>
                        <Tabs
                            value={stepIndex + 1}
                            onChange={(event, val) => setStep(val)}
                            indicatorColor="secondary"
                        >
                            {steps.map((s, i) => (
                                <Tab
                                    sx={{
                                        color: theme.palette.text.disabled,
                                        '&.Mui-selected': {
                                            color: theme.palette.common.black,
                                        },
                                    }}
                                    key={s.label}
                                    value={i + 1}
                                    label={<TabStep icon={i + 1}>{s.label}</TabStep>}
                                />
                            ))}
                        </Tabs>
                    </Toolbar>
                </AppBar>
                <Step
                    number={step}
                    next={
                        stepIndex < steps.length - 1 ? steps[stepIndex + 1].label : undefined
                    }
                    prev={stepIndex > 0 ? steps[stepIndex - 1].label : undefined}
                    onNext={() => setStep(stepIndex + 2)}
                    onPrev={() => setStep(stepIndex)}
                />
            </StyledDiv>
        );
    }

    globalSearchService.setSearchContent('');
    globalSearchService.setSelectedCategory('');
    return <GlobalSearchBarWrap renderChildren={() => renderContent()} />
    
};

export default observer(Uploaders);

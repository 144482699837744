import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Checkbox,
} from '@mui/material';
import React from 'react';
import filesize from 'filesize';
import { Moment } from 'moment';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import { faTrash, faLock } from '@fortawesome/free-solid-svg-icons';

import theme from '@extensions/services/Theme';
import { ICartService, OrderItem } from '@extensions/services/ICartService';

import AddIcon from '@extensions/utils/AddIcon';
import Link from '@extensions/components/core/Link';
import FileOrder from '@extensions/models/FileOrder';
import QualityWarning from '@extensions/components/dataset/QualityWarning';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

const StyledRootDiv = styled('div')({});

function formatDate(d: Moment): string {
  return d.format('YYYY-MM-DD');
}

export interface IOrderSummaryProps {
  className?: string;
  cartService?: ICartService;
}

interface IOrderSummaryState {
  selectedItems: string[];
}

@inject('cartService')
@observer
class OrderSummary extends React.Component<
  IOrderSummaryProps,
  IOrderSummaryState
> {
  constructor(props: IOrderSummaryProps) {
    super(props);
    this.state = {
      selectedItems: [],
    };
  }

  getColumns = () => [
    {
      statsKey: 'fileCount',
      heading: 'File Count',
      align: 'center' as const,
      format: (count: number) => count.toLocaleString(),
    },
    {
      statsKey: 'byteCount',
      heading: 'Total Size',
      align: 'center' as const,
      format: (byteCount: number) => {
        const pretty = filesize(byteCount, { round: 0 });
        if (byteCount > FileOrder.maxSizeWithoutApproval) {
          return (
            <span style={{ color: theme.palette.error.main }}>
              {pretty}
              <sup>*</sup>
            </span>
          );
        }
        return pretty;
      },
    },
    {
      statsKey: 'startDate',
      heading: 'Start Date',
      align: 'center' as const,
      format: (val: Moment) => formatDate(val),
    },
    {
      statsKey: 'endDate',
      heading: 'End Date',
      align: 'center' as const,
      format: (val: Moment) => formatDate(val),
    },
  ];

  buildItemKey(item: OrderItem) {
    const dateRange = item.dateRange
      ? `[${item.dateRange.startDate.format(
          'YYYYMMDD'
        )}-${item.dateRange.endDate.format('YYYYMMDD')}]`
      : '';
    return `${item.datasetName}${dateRange}`;
  }

  handleToggleItem = (itemKey: string) => {
    this.setState((prev) => {
      const alreadySelected = prev.selectedItems.includes(itemKey);
      let updated;
      if (alreadySelected) {
        updated = prev.selectedItems.filter((k) => k !== itemKey);
      } else {
        updated = [...prev.selectedItems, itemKey];
      }

      if (this.props.cartService) {
        this.props.cartService.setSelectedItemKeys(updated);
      }
      return { selectedItems: updated };
    });
  };

  render() {
    const { cartService } = this.props;
    const { selectedItems } = this.state;
    if (!cartService) {
      return null;
    }
    if (!cartService.currentStats) {
      return <CenteredCircularProgress />;
    }

    if (cartService.orderItems.length === 0) {
      return (
        <Typography>
          No datasets in cart. Add datasets from the{' '}
          <Link to="/data">search page</Link> or view{' '}
          <Link to="/profile/orders">previous orders</Link>.
        </Typography>
      );
    }
    const datasetByName = cartService.datasetsByName;
    const tableColumns = this.getColumns();
    return (
      <StyledRootDiv>
        <TableContainer component={Paper}>
          <Table aria-label="datasets being ordered">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: theme.palette.common.black }} />
                <TableCell sx={{ color: theme.palette.common.black }}>
                  Dataset
                </TableCell>
                {tableColumns.map((col) => (
                  <TableCell
                    key={col.statsKey}
                    align={col.align}
                    sx={{ color: theme.palette.common.black }}
                  >
                    {col.heading}
                  </TableCell>
                ))}
                <TableCell sx={{ color: theme.palette.common.black }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {cartService.orderItems.map((item) => {
                const itemKey = this.buildItemKey(item);
                const checked = selectedItems.includes(itemKey);
                const statsKey = cartService.buildOrderItemKey(item);
                const stats = cartService.currentStats?.get(statsKey);

                return (
                  <TableRow key={itemKey}>
                    <TableCell>
                      <Checkbox
                        checked={checked}
                        onChange={() => this.handleToggleItem(itemKey)}
                      />
                    </TableCell>
                    <TableCell>
                      <Link to={`/ds/${item.datasetName}`}>
                        {item.datasetName}
                      </Link>
                      {(datasetByName[item.datasetName].events || []).some(
                        (e) => e.affectsDataQuality
                      ) && <QualityWarning datasetName={item.datasetName} />}
                      {datasetByName[item.datasetName].isMfaRestricted && (
                        <AddIcon icon={faLock} title="Data restricted" />
                      )}
                    </TableCell>
                    {tableColumns.map((col) => {
                      const value = stats ? stats[col.statsKey] : undefined;
                      const displayValue =
                        value !== null && value !== undefined
                          ? col.format(value)
                          : '—';
                      return (
                        <TableCell key={col.statsKey} align={col.align}>
                          {displayValue}
                        </TableCell>
                      );
                    })}
                    <TableCell align="right">
                      <Tooltip title="Remove from cart">
                        <IconButton
                          onClick={() =>
                            cartService.removeFromCart(
                              item.datasetName,
                              item.dateRange
                                ? [
                                    item.dateRange.startDate,
                                    item.dateRange.endDate,
                                  ]
                                : undefined
                            )
                          }
                        >
                          <AddIcon icon={faTrash} size="xs" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledRootDiv>
    );
  }
}

export default OrderSummary;
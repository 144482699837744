import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import HeadManager from '@extensions/components/page/HeadManager';
import PublicationForm from '@extensions/components/publications/PublicationForm';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';
interface INewPublicationProps { }

const StyledPublicationForm = styled(PublicationForm)(({
    marginTop: '1rem'
}));

const StyledWrapper = styled('div')(({ theme }) => ({
    margin: '0 1em',
    [theme.breakpoints.down('xl')]: {
        margin: '0 1em',
    },
    [theme.breakpoints.down('xs')]: {
        margin: '0 1em',
    },
}));

const NewPublication: React.FC<INewPublicationProps> = () => {

    const renderTitle = () => {
        return (
            <>
                <HeadManager
                    title="WDH - Wind Data Hub - Add Publication"
                    description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
                                    Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
                />
                <Typography variant="h1">Add a Publication</Typography>
            </>
        )
    }

    const renderContent = () => {
        return (
            <StyledWrapper>
                {renderTitle()}
                <StyledPublicationForm isEdit={false} />
            </StyledWrapper>
        )
    }

    return <GlobalSearchBarWrap renderChildren={renderContent} />
}

export default NewPublication;
import React from 'react'

import {
  Card,
  CardContent,
  Chip,
  styled,
  Typography
} from '@mui/material';

const StyledKeywordsCard = styled(Card)(() => ({
  marginTop: '10px', 
  minHeight: '40px',
  marginBottom: '10px', 
  backgroundColor: '#F9FAFB'
}));

const StyledTitle = styled(Typography)(() => ({
  color: '#000000',
  fontSize: '1.20em',
}));

const StyledKeywordBubble = styled(Chip)(() => ({
  marginBottom: '7px',
  marginRight: '5px',
  '&:hover': {
    backgroundColor: '#c0c0c0',
  },
}));

export interface KeywordPanelProps {
  keywords: string[] | null;
}

const KeywordsPanel: React.FC<KeywordPanelProps> = ({ keywords }) => {
  if (keywords && keywords.length) {
    return (
      <StyledKeywordsCard variant='outlined'>
        <CardContent>
          <StyledTitle>Keywords</StyledTitle>
          {keywords.map((keyword, index) => {
            return (
              <StyledKeywordBubble key={index} label={keyword} />
            )
          })}
        </CardContent>
      </StyledKeywordsCard>
    )
  }
  return null;
}

export default KeywordsPanel

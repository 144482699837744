import {
  Card,
  CardContent,
  Typography,
  Chip,
  CardHeader,
  CardActions,
  IconButton,
  Grid
} from '@mui/material';
import moment from 'moment';
import omit from 'lodash/omit';
import filesize from 'filesize';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import theme from '@extensions/services/Theme';
import { useCartService, useSecurityService } from '@extensions/hooks/useService';

import Dataset from '@extensions/models/Dataset';
import AccessLevel from '@extensions/models/AccessLevel';
import DistributionType from '@extensions/models/DistributionType';

import { removeMdExcept } from '@extensions/utils/SearchUtils';

import ExpandableText from '@extensions/components/core/ExpandableText';
import SearchHighlight from '@extensions/components/core/SearchHighlight';
import AddToCartIconButton from '@extensions/components/dataset/AddToCartIconButton';
import DatasetResultIcons from '@dapclient/components/dataset-search/DatasetResultIcons';

const MAX_DESCRIPTION_LENGTH = 200;

export interface IDatasetResultCardProps {
  datasetDocument: any;
  fileTypes?: string[];
  searchFieldLabels?: { string: string };
  isCachedFav?: boolean;
  fromFavList?: boolean;
}

const StyledChip = styled(Chip)(({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  height: 'initial'
}));

const StyledDatasetLink = styled('a')(({
  display: 'flex',
  flexDirection: 'column-reverse',
  textDecoration: 'none',
  width: 'fit-content',
  marginBottom: '0.5rem'
}));

const StyledTitleTypography = styled(Typography)({
  fontSize: '1.25rem',
  lineHeight: '20px !important',
  fontWeight: '500 !important',
  marginTop: '0.5rem',
  color: theme.palette.secondary.main,
  '&:hover': {
    textDecoration: 'underline',
  },
});

const StyledNameCode = styled('code')(() => ({
  fontSize: '0.875rem',
  lineHeight: 1,
  fontWeight: theme.typography.fontWeightMedium,
  color: 'rgb(60, 64, 67)',
  fontStyle: 'normal',
  marginBottom: theme.spacing(1),
  marginRight: '1rem'
}));

const StyledStatSpan = styled('span')(() => ({
  color: theme.palette.text.secondary,
  margin: 0,
  marginTop: '0.25rem',
  float: 'right',
  fontSize: '1rem'
}));

const StyledCommentP = styled('p')(({
  fontSize: '80%',
  fontStyle: 'italic',
  backgroundColor: '#efefef',
  borderRadius: '3px',
  padding: '0.25rem 0.5rem',
}));

const StyledExitToAppIcon = styled(ExitToAppIcon)(({
  marginBottom: '-5px',
  fontSize: '1.4rem'
}));

const StyledGridItem = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

const StyledTypographyBodyOneTitle = styled(Typography)(() => ({
  fontWeight: 800,
  fontSize: '0.8rem',
  color: theme.palette.text.secondary,
}));

const StyledTypographyBodyOne = styled(Typography)(() => ({
  fontWeight: 800,
  fontSize: '1rem',
  color: theme.palette.text.secondary,
}));

const StyledDescriptionDiv = styled('div')(() => ({
  marginBottom: 0,
  marginBlockStart: '0',
  color: theme.palette.text.secondary
}));

const DatasetResultCard: React.FC<IDatasetResultCardProps> = observer(({ datasetDocument, fileTypes, searchFieldLabels, isCachedFav, fromFavList }) => {
  const [isFav, setIsFav] = useState(false);
  const cartService = useCartService();
  const securityService = useSecurityService();
  const { user } = securityService;

  useEffect(() => {
    if (securityService.userIsLoggedIn) {
      if (user && user['fav_ds'] && user['fav_ds'].length && user['fav_ds'].includes(datasetDocument.identifier)) {
        setIsFav(true)
      } else {
        setIsFav(false)
      }
    }
  }, [datasetDocument.identifier, securityService.userIsLoggedIn, user]);

  const hasAffectedDataQuality = (dataset: any): boolean => {
    return dataset.events
      && dataset.events.length > 0
      && dataset.events.some((event: any) => event.affectsDataQuality);
  };

  if (!cartService) {
    return null;
  }

  let datasetTitle = datasetDocument.title;
  if (!datasetTitle) {
    const datasetNameNoProject = datasetDocument.identifier.split('/')[1];
    const datasetNameParts = datasetNameNoProject.split('.');
    const titleParts = datasetNameParts
      .slice(0, 3)
      .map((part) => part.toLocaleUpperCase());
    datasetTitle = titleParts.join(' • ');
  }

  const hlField = (f) => {
    if (datasetDocument && datasetDocument.highlight) {
      return datasetDocument && datasetDocument.highlight.hasOwnProperty(f)
        ? datasetDocument.highlight[f].join(' ')
        : datasetDocument[f]
    } else {
      return datasetDocument[f];
    }
  };

  const hasExternalLink = (datasetDocument.distributionType && datasetDocument.distributionType[0]) ? datasetDocument.distributionType[0] === DistributionType.ExternalLink : false;
  const completeTitle = <span dangerouslySetInnerHTML={{ __html: `${hlField('project_name').toLocaleUpperCase()} - ${hlField('title')}` }} />;

  const { count, size, types } = datasetDocument.dapFileSummary;
  let fileCount: string = '0';
  let fileType: string = '';
  let fileSize: string = '0';
  let datasetStatus: string = '';

  if (count) {
    let fileString = `${count.toLocaleString()} files`;
    if (types) {
      fileType += ` ${types.join(', ')}`;
    } else {
      fileType = 'N/A'
    }
    fileCount = fileString;
  } else {
    fileType = 'N/A'
  }

  if (size) {
    fileSize = `${filesize(size, { round: 0 })}`;
  }

  if (datasetDocument.dapFileSummary.updated) {
    const statsLastUpdated = moment(datasetDocument.dapFileSummary.updated);
    const durationSinceUpdate = moment.duration(
      moment().diff(statsLastUpdated)
    );
    datasetStatus = `Updated ${durationSinceUpdate.humanize()} ago`;
  }

  let descriptionContent = datasetDocument.description ? hlField('description') : 'No description provided';

  if (descriptionContent.length > MAX_DESCRIPTION_LENGTH) {
    descriptionContent = `${descriptionContent.slice(0, MAX_DESCRIPTION_LENGTH)}...`;
  }

  const renderDescription = () => {
    const desc = removeMdExcept(hlField('description'), ['mark'])
      .replace(/^[^<>]+(\s[^<>]{300,}<mark>)/, '...$1');
    const fields = omit(searchFieldLabels, [
      'name',
      'title',
      'description',
    ]) as { string: string };
    return (
      <>
        <ExpandableText dangerous>
          {desc}
        </ExpandableText>

        {Boolean(datasetDocument.highlight) && Boolean(searchFieldLabels) && (
          <SearchHighlight
            highlight={datasetDocument.highlight as { string: string[] }}
            searchFieldLabels={fields}
          />
        )}
      </>
    );
  }
  const filteredOut = fileTypes !== undefined &&
    fileTypes.length > 0 &&
    datasetDocument.dapFileSummary.extensions !== undefined &&
    datasetDocument.imagesDataset &&
    datasetDocument.dapFileSummary.extensions.filter(
      (ext) => fileTypes.indexOf(ext.split('.').pop() || '') >= 0
    ).length === 0;

  const removeHtmlTags = (input) => {
    return input.replace(/<\/?mark>/g, '');
  }

  const handleClick = async () => {
    let statusCode = 0;
    if ((isCachedFav || isFav) && user && user.fav_ds && user.fav_ds.length) {
      const updatedFavDS = user.fav_ds.filter(ds => ds !== datasetDocument.identifier);
      statusCode = await securityService.updateFavorite({
        "fav_ds": updatedFavDS
      }, false);
      if (statusCode === 200) {
        setIsFav(prevIsFav => !prevIsFav);
      }
      if (
        (isCachedFav && !isFav && user.fav_ds.length === 1)
        || (fromFavList && user.fav_ds.length === 1)) {
        window.location.reload()
      }
    } else {
      if (user && (!user.hasOwnProperty('fav_ds') || !user.fav_ds)) {
        statusCode = await securityService.updateFavorite({
          "fav_ds": [datasetDocument.identifier]
        }, true);
        if (statusCode === 200) {
          setIsFav(prevIsFav => !prevIsFav);
        }
      } else if (user && user.fav_ds && user.fav_ds.length === 0) {
        statusCode = await securityService.updateFavorite({
          "fav_ds": [datasetDocument.identifier]
        }, true);
        if (statusCode === 200) {
          setIsFav(prevIsFav => !prevIsFav);
        }
      } else if (user && user.fav_ds && user.fav_ds.length > 0) {
        statusCode = await securityService.updateFavorite({
          "fav_ds": [...user['fav_ds'], datasetDocument.identifier]
        }, true);
        if (statusCode === 200) {
          setIsFav(prevIsFav => !prevIsFav);
        }
      }
    }
  }

  return (
    <div style={filteredOut ? { opacity: 0.5 } : {}}>
      <Card variant='outlined' sx={{ mb: '1rem', pb: '0rem' }}>
        <CardHeader
          sx={{ pt: '0rem', pb: '0rem' }}
          title={
            <div>
              <StyledChip
                size="small"
                label="DATASET"
              />
              <StyledStatSpan>{datasetStatus}</StyledStatSpan>
            </div>
          }
        />
        <CardContent sx={{ pt: '0rem', paddingBottom: '0rem !important' }}>
          <StyledNameCode><span dangerouslySetInnerHTML={{ __html: hlField('name') }} /></StyledNameCode>
          <DatasetResultIcons isPublic={datasetDocument.accessLevel === AccessLevel.Public} />
          <StyledDatasetLink href={`/data/${removeHtmlTags(datasetDocument.name)}`}>
            <StyledTitleTypography variant='h2'>
              {completeTitle}
            </StyledTitleTypography>
          </StyledDatasetLink>
          <StyledDescriptionDiv>{renderDescription()}</StyledDescriptionDiv>
          {filteredOut && (
            <StyledCommentP>
              <em>
                <strong>NOTE: </strong> This dataset is only shown because an
                otherwise hidden "images" dataset (
                {datasetDocument.imagesDataset}) is related to this one and
                matches your current file type filter. It does NOT directly
                contain files of the selected file type(s).
              </em>
            </StyledCommentP>
          )}
        </CardContent>
        <CardActions disableSpacing sx={{ ml: '0.5rem', mt: 0, pb: 0 }}>
          <Grid container spacing={4}>
            {datasetDocument.dapAccessRequiresMfa && (
              <StyledGridItem item>
                <StyledTypographyBodyOneTitle variant='body1'>MFA Restricted</StyledTypographyBodyOneTitle>
                <DatasetResultIcons isMfaRestricted={datasetDocument.dapAccessRequiresMfa} />
              </StyledGridItem>
            )}
            {hasAffectedDataQuality(datasetDocument) && (
              <StyledGridItem item>
                <StyledTypographyBodyOneTitle variant='body1'>Data Quality</StyledTypographyBodyOneTitle>
                <DatasetResultIcons isDataQualityAffected={hasAffectedDataQuality(datasetDocument)} datasetName={removeHtmlTags(datasetDocument.name)} />
              </StyledGridItem>
            )}
            {datasetDocument.dataLevel && (
              <StyledGridItem item>
                <StyledTypographyBodyOneTitle variant='body1'>Data Level</StyledTypographyBodyOneTitle>
                <DatasetResultIcons datasetLevel={datasetDocument.dataLevel} />
              </StyledGridItem>
            )}
            {datasetDocument.generalUseReady && (
              <StyledGridItem item>
                <StyledTypographyBodyOneTitle variant='body1'>General Use</StyledTypographyBodyOneTitle>
                <DatasetResultIcons isGeneralUseReady={datasetDocument.generalUseReady} />
              </StyledGridItem>
            )}
            <StyledGridItem item>
              <StyledTypographyBodyOneTitle variant='body1'>File Count</StyledTypographyBodyOneTitle>
              <StyledTypographyBodyOne variant='body1'>{fileCount}</StyledTypographyBodyOne>
            </StyledGridItem>
            <StyledGridItem item>
              <StyledTypographyBodyOneTitle variant='body1'>File Type</StyledTypographyBodyOneTitle>
              <StyledTypographyBodyOne variant='body1'>{fileType}</StyledTypographyBodyOne>
            </StyledGridItem>
            <StyledGridItem item>
              <StyledTypographyBodyOneTitle variant='body1'>File Size</StyledTypographyBodyOneTitle>
              <StyledTypographyBodyOne variant='body1'>{fileSize}</StyledTypographyBodyOne>
            </StyledGridItem>
            <StyledGridItem item>
              <StyledTypographyBodyOneTitle variant='body1'>Order Dataset</StyledTypographyBodyOneTitle>
              <StyledTypographyBodyOne variant='body1'>
                {hasExternalLink
                  ? <><StyledExitToAppIcon /> External Link</>
                  : <AddToCartIconButton
                    dataset={new Dataset(datasetDocument)}
                    fileCount={count}
                  />
                }
              </StyledTypographyBodyOne>
            </StyledGridItem>
            {
              securityService.userIsLoggedIn
              &&
              <StyledGridItem item>
                <StyledTypographyBodyOneTitle variant='body1'>
                  {
                    isFav
                      ? "Remove from Favorites"
                      : "Add to Favorites"
                  }
                </StyledTypographyBodyOneTitle>
                <StyledTypographyBodyOne variant='body1'>
                  <IconButton
                    onClick={handleClick}
                    sx={{
                      marginTop: '-0.5rem'
                    }}
                  >
                    {
                      isFav
                        ? <FavoriteIcon
                          sx={{
                            '&.MuiSvgIcon-root': {
                              color: theme.palette.warning.main
                            }
                          }}
                        />
                        : <FavoriteBorderIcon />
                    }
                  </IconButton>
                </StyledTypographyBodyOne>
              </StyledGridItem>
            }
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
});

export default DatasetResultCard;

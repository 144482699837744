import React from 'react';
import { Field } from 'formik';
import { inject } from 'mobx-react';
import { Button, TextField, styled} from '@mui/material';

import theme from '@extensions/services/Theme';

import {
    UserProfileForm as DapUserProfileForm,
} from '@dapclient/components/user/UserProfileForm';

const StyledTextField = styled(TextField)(({
    '& .MuiOutlinedInput-root': {
        borderRadius: 30,
    },
    input: {
        color: "#000"
    },
    label: {
        color: theme.palette.text.primary
    },
    '& .MuiInputBase-multiline': {
        color: "#000"
    },
}));

export class UserProfileForm extends DapUserProfileForm {
    renderPasswordField = () => {
        return (
            <Field name="password">
                {({ field, form }) => (
                    <StyledTextField
                        type="password"
                        label="Password"
                        id="password-input"
                        required
                        {...field}
                        {...this.fieldProps}
                        error={form.touched.password && Boolean(form.errors.password)}
                        helperText={form.touched.password && form.errors.password}
                    />
                )}
            </Field>
        );
    };
    renderFirstnameField = () => {
        return (
            <Field name="firstname">
                {({ field, form }) => (
                    <StyledTextField
                        type="text"
                        label="First Name"
                        id="first-name-input"
                        required
                        {...field}
                        {...this.fieldProps}
                        error={form.touched.firstname && Boolean(form.errors.firstname)}
                        helperText={form.touched.firstname && form.errors.firstname}
                    />
                )}
            </Field>
        )
    };
    renderLastnameField = () => {
        return (
            <Field name="lastname">
                {({ field, form }) => (
                    <StyledTextField
                        label="Last Name"
                        id="last-name-input"
                        type="text"
                        required
                        {...field}
                        {...this.fieldProps}
                        error={form.touched.lastname && Boolean(form.errors.lastname)}
                        helperText={form.touched.lastname && form.errors.lastname}
                    />
                )}
            </Field>
        )
    };
    renderConfirmPasswordField = () => {
        return (
            <Field name="confirm">
                {({ field, form }) => (
                    <StyledTextField
                        type="password"
                        label="Confirm Password"
                        id="confirm-password-input"
                        required
                        {...field}
                        {...this.fieldProps}
                        error={form.touched.confirm && Boolean(form.errors.confirm)}
                        helperText={form.touched.confirm && form.errors.confirm}
                    />
                )}
            </Field>
        );
    };
    renderEmailField = () => {
        return (
            <Field name="email">
                {({ field, form }) => (
                    <StyledTextField
                        type="email"
                        label="Email"
                        id="email-input"
                        required
                        {...field}
                        {...this.fieldProps}
                        error={form.touched.email && Boolean(form.errors.email)}
                        helperText={form.touched.email && form.errors.email}
                    />
                )}
            </Field>
        );
    };
    renderORCIDField = () => {
        return (
            <Field name="orcId">
                {({ field }) => (
                    <StyledTextField
                        type="text"
                        label="ORCID"
                        id="orcid-input"
                        {...field}
                        {...this.fieldProps}
                    />
                )}
            </Field>
        );
    };
    renderJustificationField = () => {
        return (
            <Field name="justification">
                {({ field, form }) => (
                    <StyledTextField
                        type="text"
                        label="Justification"
                        id="justification-input"
                        multiline
                        rows={3}
                        placeholder={this.getJustificationPlaceholder()}
                        required
                        {...field}
                        {...this.fieldProps}
                        error={form.touched.justification && Boolean(form.errors.justification)}
                        helperText={form.touched.justification && form.errors.justification}
                    />
                )}
            </Field>
        );
    };
    getInitialFormData(): object {
        const user = this.getUser();
        return {
            firstname: user.firstname || '',
            lastname: user.lastname || '',
            orcId: user.orcId || '',
            justification: user.justification || '',
            sshkey: user.sshkey || '',
            email: user.email || '',
            password: '',
            confirm: '',
            agreement: user.agreed,
        };
    };
    renderSshKeyField = () => {
        return (
            <Field
                component={TextField}
                name="sshkey"
                type="text"
                label="SSH Public Key"
                id="sshkey-input"
                multiline
                rows={3}
                placeholder={"SSH Public Key"}
                {...this.fieldProps}
            />
        );
    };

    renderSubmitButton(submitForm: () => void) {
        const user = this.getUser();

        return (
            <Button
                fullWidth
                onClick={submitForm}
                variant="contained"
                sx={{
                    borderRadius: 30,
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                }}
            >
                {this.isResettingPassword()
                ? 'Reset Password'
                : user.authenticated && user.agreed
                    ? 'Save Profile'
                    : 'Register'}
            </Button>
        );
    };
}

export default inject((store: any) => ({
    securityService: store.securityService,
    contactUsService: store.contactUsService,
}))(UserProfileForm);

export const ELASTIC_APP = {
    ALL: 'api',
    ENV_PROJECT: 'api/projects',
    WDH_PROJECT: 'api/projects',
    ENV_DATA: 'api/datasets',
    WDH_DATA: 'api/datasets',
    PUBLICATIONS: 'refs',
}

export const SEARCH_CATEGORIES = {
    ALL: 'All Categories',
    ENV_PROJECT: 'Environmental Projects',
    WDH_PROJECT: 'Wind Energy Projects',
    ENV_DATA: 'Environmental Data',
    WDH_DATA: 'Wind Energy Data',
    PUBLICATIONS: 'Publications',
}
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Grid, Box, styled, Card, IconButton, CardContent } from '@mui/material';

import {
    SelectedFilters,
    SEARCH_BAR_REACTIVE_ID,
    SearchResults,
} from '@extensions/components/search-core';
import {
    DATA_LEVEL_REACTIVE_ID,
    RECOMMENDED_REACTIVE_ID,
    INSTRUMENT_REACTIVE_ID,
    MEASUREMENT_REACTIVE_ID,
    MODEL_REACTIVE_ID,
    STANDARDIZATION_REACTIVE_ID,
    FILE_TYPE_REACTIVE_ID,
    DATE_RANGE_REACTIVE_ID,
    PROJECT_REACTIVE_ID,
    AREA_FILTER_REACTIVE_ID,
} from '@extensions/components/page/search-components';
import MultiList from '@extensions/components/core/MultiList';
import HomeSearchResultCard from '@extensions/components/page/home-page/HomeSearchResultCard';

import AddIcon from '@extensions/utils/AddIcon';
import { useGlobalSearchService } from '@extensions/hooks/useService';
import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils';

export const CATEGORY_INDEX_ID = 'IndexSensor';

const REACTIVE_IDS = [
    SEARCH_BAR_REACTIVE_ID,
    DATA_LEVEL_REACTIVE_ID,
    RECOMMENDED_REACTIVE_ID,
    INSTRUMENT_REACTIVE_ID,
    MEASUREMENT_REACTIVE_ID,
    MODEL_REACTIVE_ID,
    STANDARDIZATION_REACTIVE_ID,
    FILE_TYPE_REACTIVE_ID,
    DATE_RANGE_REACTIVE_ID,
    PROJECT_REACTIVE_ID,
    AREA_FILTER_REACTIVE_ID,
    CATEGORY_INDEX_ID
];

const StyledSelectedFilters = styled(SelectedFilters)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StyledWrapper = styled('div')(({ theme }) => ({
    margin: '0 1em',
    [theme.breakpoints.down('xl')]: {
        margin: '0 1em',
    },
    [theme.breakpoints.down('xs')]: {
        margin: '0 0em',
    },
}));

const StyledAddIcon = styled(AddIcon)(({
    marginRight: '0.5rem',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    '& .data-filter': {
        marginBottom: theme.spacing(0),
    },
}));

interface IHomeSearchResultProps { };

const HomeSearchResultContainer: React.FC<IHomeSearchResultProps> = () => {

    const globalSearchService = useGlobalSearchService();
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        globalSearchService.setNeedStay(true);
    }, [globalSearchService]);

    const handleClick = () => {
        globalSearchService.setClickedButton(true);
        globalSearchService.setNeedStay(false);
        globalSearchService.setSearchContent('');
        history.push(location.pathname ?? '/');
    };

    const allReactiveIdsExcept = getAllReactiveIdsExcept([
        ...REACTIVE_IDS,
        SEARCH_BAR_REACTIVE_ID,
    ]);

    return (
        <StyledWrapper>
            <Box sx={{ flexGrow: 1 }}>
                <IconButton
                    onClick={handleClick}
                    color="secondary"
                    size="small"
                >
                    <StyledAddIcon icon={faChevronLeft} />Back
                </IconButton>
                <StyledSelectedFilters />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={5} lg={3} xl={3}>
                        <Card variant='outlined'>
                            <StyledCardContent>
                                <MultiList
                                    componentId={CATEGORY_INDEX_ID}
                                    dataField="_index"
                                    title="Categories"
                                    react={{
                                        and: allReactiveIdsExcept(CATEGORY_INDEX_ID),
                                    }}
                                    filterLabel="Categories"
                                    showSearch={true}
                                    URLParams={true}
                                    className="data-filter"
                                />
                            </StyledCardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={8} md={7} lg={9} xl={9}>
                        <SearchResults
                            resultsText="all"
                            dataField="title.keyword"
                            sortBy="asc"
                            react={{
                                and: REACTIVE_IDS,
                            }}
                            renderItem={(hit) => {
                                return <HomeSearchResultCard hit={hit} />
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </StyledWrapper>
    )
}

export default HomeSearchResultContainer;
import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography
} from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { inject } from 'mobx-react';
import { TextField } from 'formik-mui';
import { Field, Form, Formik } from 'formik';
import { styled } from '@mui/material/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import theme from '@extensions/services/Theme';
import { ISecurityService } from '@extensions/services/ISecurityService';

const StyledMFACheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

const StyledFormControlLabel = styled(FormControlLabel)(({theme}) => ({
  '.MuiFormControlLabel-label': {
    color: theme.palette.grey[700]
  },
}));

export interface RouteComponentPropsWithState extends RouteComponentProps {
  location: any;
}

export interface ISignInFormProps extends RouteComponentPropsWithState {
  securityService?: ISecurityService;
  onLoginSuccess?: () => void;
}

export interface ISignInFormState {
  forgot: boolean;
  mfaEnabled: boolean;
}

@inject('securityService')
class SignInForm extends React.Component<ISignInFormProps, ISignInFormState> {
  validationSchema: any;

  constructor(props: ISignInFormProps) {
    super(props);
    this.state = {
      forgot: false,
      mfaEnabled: false,
    };
  }

  onForgotPasswordSubmit = (values, { setSubmitting }) => {
    if (this.props.securityService) {
      this.props.securityService.forgotPassword(values.email);
    }
    setSubmitting(false);
  };

  onLoginSubmit = async (values, { setSubmitting }) => {
    const { onLoginSuccess } = this.props;
    if (this.props.securityService) {
      if (this.state.mfaEnabled) {
        await this.props.securityService.loginWithMfa(
          values.email,
          values.password,
          values.mfaCode
        );
      } else {
        await this.props.securityService.login(
          values.email,
          values.password
        );
      }

      if (this.props.securityService?.user?.authenticated) {
        if (onLoginSuccess) {
          onLoginSuccess();
        }
      } 

      setSubmitting(false);
      this.setState({
        forgot: false,
      });
    }
  };

  renderEmailField = () => {
    return (
      <Field
        component={TextField}
        name="email"
        type="email"
        label="Your Email"
        variant="outlined"
        fullWidth
        id="email-input"
        margin="normal"
        size="medium"
        InputProps={{
          style: {
            borderRadius: 30,
          },
        }}
      />
    );
  };

  renderForgotPassword() {
    return (
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={yup.object({
          email: yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
        })}
        onSubmit={this.onForgotPasswordSubmit}
      >
        {() => (
          <Form>
            {this.renderEmailField()}
            <Button
              color="primary"
              variant="contained"
              type="submit"
              sx={{
                borderRadius: 30,
                mt: 2,
                backgroundColor: theme.palette.primary.main,
                color: "white"
              }}
            >
              Send Reset Request
            </Button>
          </Form>
        )}
      </Formik>
    );
  }

  renderUseMfaCheckbox = () => {
    if (process.env.REACT_APP_MFA_ENABLED === 'true') {
      return (
        <StyledFormControlLabel
          control={
            <StyledMFACheckbox
              checked={this.state.mfaEnabled}
              onChange={e => this.setState({ mfaEnabled: e.target.checked })}
              name="useMfa"
            />
          }
          label="Use Multifactor Authentication (MFA)"
          sx={{ mt: 2 }}
        />
      );
    } else {
      return null;
    }
  };

  renderMfaCodeField = () => {
    if (this.state.mfaEnabled) {
      return (
        <Paper variant="outlined" style={{ padding: 15, marginTop: '1rem' }}>
          <Field
            component={TextField}
            name="mfaCode"
            type="text"
            label="Verification Code"
            id="verification-code-input"
            variant="outlined"
            fullWidth
            margin="normal"
            size="medium"
            InputProps={{
              style: {
                borderRadius: 30,
              },
            }}
          />
          <span style={{ fontFamily: 'sans-serif', lineHeight: 1.5 }}>
            <strong>NOTE:</strong> A second-factor, 6-digit verification code is
            required in order to access proprietary data and other sensitive
            information. This form of authentication can be set up in your user
            profile.
          </span>
        </Paper>
      );
    } else {
      return null;
    }
  };

  getValidationSchema(): any {
    const emailSchema = yup
      .string()
      .email('Enter a valid email')
      .required('Email is required');

    const passwordSchema = yup.string().required('Password is required');

    let schema;
    if (this.state.mfaEnabled) {
      schema = yup.object({
        email: emailSchema,
        password: passwordSchema,
        mfaCode: yup.string().required('MFA token is required'),
      });
    } else {
      schema = yup.object({
        email: emailSchema,
        password: passwordSchema,
      });
    }
    return schema;
  }

  renderSigninForm() {
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
          mfaCode: '',
        }}
        validationSchema={this.getValidationSchema()}
        onSubmit={this.onLoginSubmit}
      >
        {() => (
          <Form>
            {this.renderEmailField()}
            <Field
              component={TextField}
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              size="medium"
              InputProps={{
                style: {
                  borderRadius: 9999,
                },
              }}
            />
            {this.renderUseMfaCheckbox()}
            {this.renderMfaCodeField()}
            <Button
              color="primary"
              variant="contained"
              type="submit"
              sx={{
                mt: 3,
                px: 3,
                py: 1,
                display: 'block',
                mx: 'auto',
                color: "white",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              LOG IN
            </Button>
            <Typography
              variant="body2"
              sx={{
                mt: 2,
                textAlign: 'center',
                color: 'primary.main',
                cursor: 'pointer',
              }}
              onClick={e => this.setState({ forgot: true })}
            >
              Forgot Password?
            </Typography>
          </Form>
        )}
      </Formik>
    );
  }

  render() {
    if (this.state.forgot) {
      return this.renderForgotPassword();
    }
    return this.renderSigninForm();
  }
}

export default withRouter(SignInForm);

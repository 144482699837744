import React from 'react';

import { 
  Card, 
  CardContent, 
  Typography,
  styled
} from '@mui/material';

import { PubOrganization } from '@extensions/models/Publication';

const StyledSponsorsCard = styled(Card)(() => ({
  marginTop: '10px', 
  minHeight: '40px'  
}));

const StyledTitle = styled(Typography)(() => ({
  color: '#000000',
  fontSize: '1.25em',
}));

const StyledSponsorsText = styled(Typography)(() => ({
  fontSize: '1em'
}));

export interface ISponsorsListProps {
  className?: string;
  sponsors: PubOrganization[];
}

const PublicationSponsors: React.FC<ISponsorsListProps> = ({ sponsors }) => {
  return (
    <StyledSponsorsCard variant='outlined'>
      <CardContent>
        <StyledTitle>Sponsors</StyledTitle>
        {sponsors.length === 0 ? (
          <Typography>No Sponsors Provided</Typography>
        ) : (
          sponsors.map((sponsor, index) => (
            <StyledSponsorsText key={index}>{sponsor.name}</StyledSponsorsText>
          ))
        )}
      </CardContent>
    </StyledSponsorsCard>
  )
}

export default PublicationSponsors;

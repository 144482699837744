import {
  Grid,
  ScopedCssBaseline,
} from '@mui/material';
import { css } from '@emotion/css';

import {
  SEARCH_BAR_REACTIVE_ID,
  SearchProvider,
} from '@extensions/components/search-core';
import {
  StyledSearchBar,
  StyledSelectedFilters
} from '@extensions/components/code-search/repo/repoStyles';
import RepoResultHit from '@extensions/components/code-search/repo/RepoResultHit';
import RepoFilters from '@extensions/components/code-search/repo/RepoFilters';
import { getStatsDisplayString } from '@extensions/components/code-search/utils';
import SearchResults, { ResultStats } from '@extensions/components/search-core/SearchResults';

import theme from '@extensions/services/Theme';

const REACTIVE_LICENSE_ID = 'LicenseFilter';
const REACTIVE_LANGUAGE_ID = 'LanguageFilter';
const REACTIVE_ORG_ID = 'OrgFilter'
const REACTIVE_IDS = [
  SEARCH_BAR_REACTIVE_ID,
  REACTIVE_LICENSE_ID,
  REACTIVE_LANGUAGE_ID,
  REACTIVE_ORG_ID,
]

const Repo: React.FC = () => {
  return (
    <ScopedCssBaseline sx={{ backgroundColor: theme.palette.grey[50] }}>
      <SearchProvider
        elasticIndex="codehub/repo"
        apiPrefix="api"
        theme={{
          colors: {
            primaryColor: '#53b03f',
          },
        }}
      >
        <StyledSearchBar
          placeholder="Search for Code Repository"
          dataField={[
            'name',
            'full_name',
            'keyword',
            'description',
            'parent',
            'project_name',
            'languages',
            'url',
          ]}
          autosuggest={false}
          URLParams={true}
          queryFormat="and"
        />
        <StyledSelectedFilters />
        <Grid container spacing={2} sx={{ paddingTop: '10px' }}>
          <Grid item xs={12} sm={4} md={5} lg={3} xl={3}>
            <RepoFilters />
          </Grid>
          <Grid item xs={12} sm={8} md={7} lg={9} xl={9}>
            <SearchResults
              dataField="name.keyword"
              sortBy="asc"
              react={{
                and: [SEARCH_BAR_REACTIVE_ID, ...REACTIVE_IDS],
              }}
              renderItem={(hit) => (
                <RepoResultHit key={hit.id} data={hit} />
              )}
              renderNoResults={() => {
                return "No results found."
              }}
              renderResultStats={(stats: ResultStats) => (
                <div
                  aria-hidden
                  className={css`
                    && {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      margin-right: 0.5rem;
                      align-items: center;
                      font-size: 90%;
                      color: #999;
                    }
                  `}
                >
                  {getStatsDisplayString(stats)}
                </div>
              )}
              sortOptions={[
                {
                  label: 'Most Recently Updated',
                  dataField: 'updated_at',
                  sortBy: 'desc',
                },
                {
                  label: 'Repository (A-Z)',
                  dataField: 'name.keyword',
                  sortBy: 'asc',
                },
                {
                  label: 'Repository (Z-A)',
                  dataField: 'name.keyword',
                  sortBy: 'desc',
                },
                {
                  label: 'Organization (A-Z)',
                  dataField: 'full_name.keyword',
                  sortBy: 'asc',
                },
                {
                  label: 'Organization (Z-A)',
                  dataField: 'full_name.keyword',
                  sortBy: 'desc',
                },
                {
                  label: 'Open Issues Count (Asc)',
                  dataField: 'issues_open_count',
                  sortBy: 'asc',
                },
                {
                  label: 'Open Issues Count (Desc)',
                  dataField: 'issues_open_count',
                  sortBy: 'desc',
                },
                {
                  label: 'Contributor Count',
                  dataField: 'contributors_count',
                  sortBy: 'desc',
                },
                {
                  label: 'Fork Count',
                  dataField: 'forks_count',
                  sortBy: 'desc',
                },
                {
                  label: 'PyPI Downloads Count',
                  dataField: 'downloads_last_month',
                  sortBy: 'desc',
                },
              ]}
            />
          </Grid>
        </Grid>
      </SearchProvider>
    </ScopedCssBaseline>
  );
};

export default Repo

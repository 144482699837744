import React from 'react';
import { observer, inject } from 'mobx-react';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import ITokenService from '@extensions/services/ITokenService';

export interface ISearchProviderProps {
  /** Name of the elastic index to use */
  elasticIndex: string;
  /**
   * Will be appended to the hostname/port to form the URL.
   * @example
   * // Will make requests https://mysite.org/voom/_msearch
   * <SearchProvider
   *  elasticIndex="test"
   *  apiPrefix="voom"
   * >
   *   {search app goes here}
   * </SearchProvider>
   */
  apiPrefix?: string;
  /** Passed directly to ReactiveSearch's ReactiveBase */
  theme?: any;
  children: React.ReactNode;
  tokenService?: ITokenService;
  customTransformRequest?(req: any): any | undefined;
}

@inject('tokenService')
@observer
export default class SearchProvider extends React.Component<
  ISearchProviderProps
> {
  render() {
    const {
      elasticIndex,
      children,
      apiPrefix,
      theme,
      tokenService,
      customTransformRequest,
    } = this.props;
    const port = window.location.port ? `:${window.location.port}` : '';
    const prefix = apiPrefix ? `/${apiPrefix}` : '';
    const url = `${window.location.protocol}//${window.location.hostname}${port}${prefix}`;

    if (!tokenService) {
      return null;
    }

    return (
      <ReactiveBase
        app={elasticIndex}
        url={url}
        theme={theme}
        headers={{
          'x-csrf-token': tokenService.dapToken,
        }}
        transformRequest={
          customTransformRequest 
          ? (req) => customTransformRequest(req)
          : (req) => req
        }
      >
        {children}
      </ReactiveBase>
    );
  }
}

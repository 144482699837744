import React from 'react';

import ProjectResultCard from '@extensions/components/project-search/ProjectResultCard';
import PublicationResultCard from '@extensions/components/publications/PublicationResultCard';
import DatasetResultCard from '@extensions/components/page/search-components/DatasetResultCard';

interface IHomeSearchResultCardProps {
    hit: any;
};

const projectSearchFields: any = {
    'name': 'Project Name',
    'title': 'Title',
    'description': 'Description',
    'participatingOrganizations.name': 'Participating Organization',
    'dataset.distributionType': 'Dataset Distribution Type',
    'dataset.keyword': 'Dataset Keyword',
    'contactPoint.fn': 'Contact Name',
    'contactPoint.hasOrg': 'Contact Organization',
};

const publicationSearchFields: any = {
    'title': 'Publication Title',
    'abstract': 'Publication Abstract',
    'doi': 'Publication DOI',
    'keywords': 'Keywords',
    'authorNames': 'Author Name',
    'journalName': 'Journal Name',
    'authorInstitutions': 'Author Institutions',
    'sponsors': 'Sponsors'
};

const dataSetSearchFields: any = {
    'name': 'Dataset Name',
    'title': 'Title',
    'description': 'Description',
    'instrument.keyword': 'Instrument Keyword',
    'instance.keyword': 'Instance Keyword',
    'dataLevel.keyword': 'Data Level Keyword',
    'contactPoint.fn': 'Contact Name',
    'contactPoint.hasEmail': 'Contact Email',
    'contactPoint.hasOrg': 'Contact Organization',
    'measurements.description': 'Measurements Description',
    'measurements.standardName.description': 'Measurements StandardName Description',
    'measurements.specificName': 'Measurements SpecificName'
};

const HomeSearchResultCard: React.FC<IHomeSearchResultCardProps> = ({hit}) => {

    const renderResultCard = (hit) => {
        switch (hit._index) {
            case "datasets":
                return <DatasetResultCard 
                            datasetDocument={hit}
                            searchFieldLabels={dataSetSearchFields}
                        />;
            case "projects":
                return <ProjectResultCard 
                            project={hit} 
                            highlight={hit.highlight} 
                            searchFieldLabels={projectSearchFields}
                        />;
            case "references":
                return <PublicationResultCard 
                            publication={hit}
                            searchFieldLabels={publicationSearchFields}
                        />
            default:
                break;
        }
    }

    return (
        renderResultCard(hit)
    )
}

export default HomeSearchResultCard;
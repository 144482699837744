import React from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { ICartService } from '@extensions/services/ICartService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import theme from '@extensions/services/Theme';

const StyledRootDiv = styled('div')(({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export interface IOrderButtonProps {
  className?: string;
  securityService?: ISecurityService;
  cartService?: ICartService;
}

@inject('cartService', 'securityService')
@observer
class OrderButton extends React.Component<IOrderButtonProps> {
  render() {
    const { securityService, cartService } = this.props;

    if (!securityService || !cartService) {
      return null;
    }

    if (cartService.orderItems.length === 0) {
      return null;
    }

    if (securityService.userIsLoggedIn) {
      return (
        <StyledRootDiv>
          <Button
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              color: theme.palette.common.white,
              mt: 2,
              backgroundColor: theme.palette.primary.main,
            }}
            variant="contained"
            disabled={cartService.selectedItemKeys.length === 0}
            onClick={cartService.itemsToPlaceOrder}
          >
            Place Order
          </Button>
        </StyledRootDiv>
      );
    }

    return (
      <StyledRootDiv>
        <Button
          component="a"
          href="/signIn"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: theme.palette.primary.main,
            mt: 2,
            color: theme.palette.common.white,
          }}
          variant="contained"
        >
          Log in to Download
        </Button>
      </StyledRootDiv>
    );
  }
}

export default OrderButton;

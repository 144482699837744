import {
  INotificationService,
  Status,
} from '@dapclient/services/INotificationService';
import config from '@extensions/utils/ConfigUtil';
import type IHasMetrics from '@extensions/models/IHasMetrics';
import Metrics, { PublicationStats } from '@extensions/models/Metrics';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import {
  action,
  observable,
  runInAction,
  transaction,
  makeObservable,
} from 'mobx';
import moment from 'moment';
import * as superagent from 'superagent';

import { IMetricsService } from '@extensions/services/IMetricsService';
import { PublicationType } from '@extensions/models/Publication';

export default class MetricsService implements IMetricsService {
  @observable metrics: Metrics | null = null;
  /** Some deployments may not have Google's API configured */
  @observable gapiAccessToken: string | null = null;
  @observable gapiViewId: string | null = null;
  @observable gapiAvailable: boolean | null = null;
  gapiTokenExpires: number | null = null;
  notificationService: INotificationService;

  constructor(notificationService: INotificationService) {
    makeObservable(this);
    this.notificationService = notificationService;
  }

  isGaTokenExpired() {
    const now = moment().unix();
    let expired = true;
    if (this.gapiTokenExpires && now < this.gapiTokenExpires) {
      expired = false;
    }
    return expired;
  }

  @action async loadGATokenIfNeeded() {
    const { REACT_APP_GA_CONFIGURED } = process.env;
    if (typeof REACT_APP_GA_CONFIGURED === 'undefined' || REACT_APP_GA_CONFIGURED === 'true') {
      if (this.gapiAccessToken === null || this.isGaTokenExpired()) {
        await this.loadGAToken();
      }
    } else {
      if (REACT_APP_GA_CONFIGURED === 'false') {
        return;
      }
    }
    return;
  }

  @action async loadGAToken() {
    this.notificationService.addNotification(
      'loadGAToken',
      Status.Running,
      '',
      ''
    );
    try {
      let gapiTokenResponse;
      try {
        gapiTokenResponse = await DapApiAgent.agent.get(
          '/api/analytics-config'
        );
      } catch {
        gapiTokenResponse = null;
      }
      transaction(() => {
        if (gapiTokenResponse === null) {
          this.setGapiAvailable(false);
        } else {
          this.setGapiAvailable(true);
          const gapiTokenInfo = gapiTokenResponse.body;
          this.setGapiAccessToken(gapiTokenInfo.token.access_token);
          this.setGapiViewId(gapiTokenInfo.property);
          // not an observable as expires only used within service to test if new data is needed
          const expiresIn = gapiTokenInfo.token.expires_in;
          const createdEpoch = gapiTokenInfo.token.created;
          this.gapiTokenExpires = createdEpoch + expiresIn;
        }
      });
      this.notificationService.addNotification(
        'loadGAToken',
        Status.Success,
        '',
        ''
      );
    } catch (error) {
      this.notificationService.addNotification(
        'loadGAToken',
        Status.Error,
        'Failed to load GA token',
        error
      );
    }
  }

  @action async loadMetricsIfNeeded() {
    if (this.metrics === null) {
      this.loadMetrics();
    }
  }

  @action async loadMetrics() {
    this.notificationService.addNotification(
      'loadMetrics',
      Status.Running,
      '',
      ''
    );

    try {
      const dapApiResponse = await DapApiAgent.agent.get('/api/metrics');
      const filter = {};
      const lambdaApiUrl = config.getConfig().lambdaApi;
      const source = config.getConfig().reportTable;
      const cred = btoa('guest:guest');
      // don't want to use the labmdaApiAgent as we're sending guest:guest creds instead
      const searchesResponse = await superagent
        .post(`${lambdaApiUrl}/searches`) // http method
        .set('Accept', 'application/json') // headers go here
        .set('Authorization', `Basic ${cred}`) // headers go here
        .set('Content-Type', 'application/json') // headers go here
        .send({
          output: 'json',
          source,
          filter,
        });
      const ordersResponse = await superagent
        .get(`${lambdaApiUrl}/order-summary-timeline?entity=ALL`)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${cred}`)
        .set('Content-Type', 'application/json');
      const publicationStats = await this.fetchPubsStats();
      transaction(() => {
        this.notificationService.addNotification(
          'loadMetrics',
          Status.Success,
          '',
          ''
        );
        let metrics;
        if (publicationStats) {
          metrics = new Metrics(
            dapApiResponse.body,
            searchesResponse.body,
            ordersResponse.body,
            publicationStats
          );
        } else {
          metrics = new Metrics(
            dapApiResponse.body,
            searchesResponse.body,
            ordersResponse.body,
          );
        }
        this.setMetrics(metrics);
      });
    } catch (error) {
      this.notificationService.addNotification(
        'loadMetrics',
        Status.Error,
        'Failed to load metrics',
        error
      );
    }
  }

  @action async loadHomeMetrics() {

    try {
      const dapApiResponse = await DapApiAgent.agent.get('/api/metrics');
      const filter = {};
      const lambdaApiUrl = config.getConfig().lambdaApi;
      const source = config.getConfig().reportTable;
      const cred = btoa('guest:guest');
      // don't want to use the labmdaApiAgent as we're sending guest:guest creds instead
      const searchesResponse = await superagent
        .post(`${lambdaApiUrl}/searches`) // http method
        .set('Accept', 'application/json') // headers go here
        .set('Authorization', `Basic ${cred}`) // headers go here
        .set('Content-Type', 'application/json') // headers go here
        .send({
          output: 'json',
          source,
          filter,
        });
      const ordersResponse = await superagent
        .get(`${lambdaApiUrl}/order-summary-timeline?entity=ALL`)
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${cred}`)
        .set('Content-Type', 'application/json');
      const publicationStats = await this.fetchPubsStats();
      transaction(() => {
        let metrics;
        if (publicationStats) {
          metrics = new Metrics(
            dapApiResponse.body,
            searchesResponse.body,
            ordersResponse.body,
            publicationStats
          );
        } else {
          metrics = new Metrics(
            dapApiResponse.body,
            searchesResponse.body,
            ordersResponse.body,
          );
        }
        this.setMetrics(metrics);
      });
    } catch (error) {
      this.notificationService.addNotification(
        'loadMetrics',
        Status.Error,
        'Failed to load metrics',
        error
      );
    }
  }

  @action async getGAMetrics(item: IHasMetrics) {
    if (item.getViews() === 0) {
      await this.loadGATokenIfNeeded();
      if (!this.gapiAvailable) {
        return; // Nothing to do
      }

      const identifier = item.getIdentifier();
      const gaMetricsResult = await superagent
        .post(`https://analyticsdata.googleapis.com/v1beta/${this.gapiViewId}:runReport`)
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${this.gapiAccessToken}`)
        .send({
          dateRanges: [{ startDate: "2015-08-14", endDate: "today" }],
          metrics: [{ name: "screenPageViews" }],
          dimensions: [{ name: "pagePath" }],
          dimensionFilter: {
            orGroup: {
              expressions: [
                {
                  filter: {
                    fieldName: "pagePath",
                    stringFilter: {
                      matchType: "BEGINS_WITH",
                      value: `/project/${identifier}`
                    }
                  }
                },
                {
                  filter: {
                    fieldName: "pagePath",
                    stringFilter: {
                      matchType: "BEGINS_WITH",
                      value: `/ds/${identifier}`
                    }
                  }
                }
              ]
            }
          }
        });


      const viewsData = gaMetricsResult.body.rows.map(row => {
        const path = row.dimensionValues[0].value;
        const views = parseInt(row.metricValues[0].value, 10);
        return { path, views };
      });

      const totalViews = viewsData.reduce((sum, data) => {
        if (data.path.includes(item.getIdentifier())) {
          return sum + data.views;
        }
        return sum;
      }, 0);

      runInAction(() => {
        item.setViews(+totalViews);
      });

      return viewsData;
    }
  }

  @action setGapiAccessToken(gapiAccessToken: string): void {
    this.gapiAccessToken = gapiAccessToken;
  }

  @action setGapiViewId(gapiViewId: string): void {
    this.gapiViewId = gapiViewId;
  }

  @action setMetrics(metrics: Metrics): void {
    this.metrics = metrics;
  }

  @action setGapiAvailable(gapiAvailable: boolean): void {
    this.gapiAvailable = gapiAvailable;
  }

  async fetchPubsStats(): Promise<PublicationStats | void> {
    const elasticQuery = {
      aggs: {
        types: {
          terms: {
            field: 'referenceType.keyword',
          },
        },
      },
      size: 0,
    };
    const resp = await DapApiAgent.agent
      .post('/api/refs/_msearch')
      .send(`{}\n${JSON.stringify(elasticQuery)}`);

    const result = resp.body.responses[0];
    const totalNumPubs = result.hits.total.value;
    const pubsCountsByType = result.aggregations.types.buckets.reduce(
      (counts, { key, doc_count }) => ({
        ...counts,
        [key as PublicationType]: doc_count,
      }),
      {}
    );
    return {
      totalNumPubs,
      pubsCountsByType,
    };
  }
}

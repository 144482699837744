import React from 'react';
import * as queryString from 'query-string';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { action, observable, makeObservable } from 'mobx';

import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';
import { Typography, Button, MenuItem, Select, FormControl } from '@mui/material';

import { IHistoryService } from '@extensions/services/IHistoryService';
import { IMetadataService } from '@extensions/services/IMetadataService';
import { INotificationService } from '@extensions/services/INotificationService';

import Link from '@extensions/components/core/Link';
import MetaDocument from '@extensions/models/MetaDocument';
import SearchBox from '@extensions/components/core/SearchBox';
import { roughlyIncludes } from '@extensions/utils/SearchUtils';
import MetadataGuard from '@extensions/components/metadata/MetadataGuard';
import DocumentsTable from '@extensions/components/metadata/DocumentsTable';

const StyledSplitBarDiv = styled('div')(({
  marginTop: '1.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end'
}));

const StyledSearchBox = styled(SearchBox)(({
  flex: '1 0 auto',
  marginRight: '.5rem',
  width: '80%'
}));

const StyledSelect = styled(Select)(({
  marginRight: '5.5rem',
  width: '100%'
}));

const StyledProjectSection = styled('div')(({
  marginBottom: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledContentWrapperDiv = styled('div')(({
  marginTop: '1rem',
}));

export interface IDocumentLandingProps
  extends RouteComponentProps<{ view: string }> {
  className?: string;
  metadataService?: IMetadataService;
  historyService?: IHistoryService;
  notificationService?: INotificationService;
}

export interface IDocumentLandingState { }

const PENDING_AND_DRAFTS = 'pendingdraft';
const PUBLISHED = 'published';

@observer
export class DocumentLanding<
  ExtraProps extends object = {}
> extends React.Component<
  IDocumentLandingProps & ExtraProps,
  IDocumentLandingState
> {
  @observable
  searchQuery: string = '';

  @observable
  searchType: string = PENDING_AND_DRAFTS;

  @observable
  publishedDocuments: MetaDocument[] = [];

  constructor(props: IDocumentLandingProps & ExtraProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    if (this.props.metadataService) {
      this.props.metadataService.load();
    }
    let params = queryString.parse(this.props.location.search);
    if (params && params.view && PUBLISHED === params.view) {
      this.searchType = PUBLISHED;
    }
    if (params && params.query) {
      this.searchQuery = String(params.query);
    }
  }

  @action
  setSearchQuery = (newQuery: string) => {
    this.searchQuery = newQuery;
    if (this.props.historyService) {
      this.props.historyService.history.replace(
        `/metadata?view=${this.searchType}&query=${this.searchQuery}`
      );
    }
  };

  @action
  setSearchType = (newSearchType: any) => {
    this.searchType = newSearchType;
    const searchParam = this.searchQuery ? `&query=${this.searchQuery}` : '';
    if (this.props.historyService) {
      this.props.historyService.history.replace(
        `/metadata?view=${newSearchType}${searchParam}`
      );
    }
  };

  newDocument = () => {
    const { metadataService, historyService } = this.props;
    if (metadataService && historyService) {
      const newDocId = metadataService.addNewDocument();
      historyService.history.push(`/metadata/edit/${newDocId}`);
    }
  };

  docMatches = (doc: MetaDocument): boolean => {
    const emptyQuery = this.searchQuery === '';
    const nameMatches =
      doc.datasetName &&
      roughlyIncludes({
        item: doc.datasetName,
        query: this.searchQuery,
      });
    const authorMatches = roughlyIncludes({
      item: `${doc.author.firstName} ${doc.author.lastName}`,
      query: this.searchQuery,
    });
    return emptyQuery || nameMatches || authorMatches;
  };

  getLinkToUpload = (): React.ReactNode => {
    return null;
  };

  renderContent = () => {
    const { metadataService } = this.props;
    if (!metadataService) {
      return null;
    }

    let contents: React.ReactNode;
    if (metadataService.loaded) {
      const docs =
        this.searchType === PENDING_AND_DRAFTS
          ? metadataService.docsByProjectName
          : metadataService.publishedDocsByProjectName;
      const matchingEntries = Object.entries(docs)
        .map(([projectName, documents]) => {
          const filteredDocs = documents.filter(this.docMatches);
          return [projectName, filteredDocs];
        })
        .filter(([projectName, documents]) => {
          return documents.length > 0;
        }) as [string, MetaDocument[]][];
      if (matchingEntries.length > 0) {
        contents = matchingEntries.map(([projectName, documents]) => (
          <StyledProjectSection key={projectName}>
            <Link
              variant="h2"
              to={`/project/${projectName}`}
              sx={{
                textTransform: 'uppercase',
                marginBottom: '0.75rem',
              }}
            >
              {projectName}
            </Link>
            <DocumentsTable
              isPublishedSearchType={this.searchType === PUBLISHED}
              documents={documents}
              searchQuery={this.searchQuery}
            />
          </StyledProjectSection>
        ));
      } else {
        contents = <Typography>No Matching Results</Typography>;
      }
    }
    return (
      <MetadataGuard>
        <div>
          <Typography variant="h1">
            Metadata Submissions
          </Typography>
          {this.getLinkToUpload()}

          <StyledSplitBarDiv>
            <StyledSearchBox
              value={this.searchQuery}
              onChange={this.setSearchQuery}
              label="Search Submissions"
            />
            <FormControl>
              <StyledSelect
                value={this.searchType}
                onChange={(event) => this.setSearchType(event.target.value)}
                variant='standard'
                sx={{
                  marginLeft: '0.7rem',
                  width: '139px',
                  paddingBottom: '0.2rem',
                  ".MuiSelect-select": {
                    paddingTop: '0.1rem',
                  },
                  ".MuiSvgIcon-root": {
                    marginTop: '-0.25rem',
                  },
                }}
              >
                <MenuItem
                  key={PENDING_AND_DRAFTS}
                  value={PENDING_AND_DRAFTS}
                >
                  <em>Pending &amp; Draft</em>
                </MenuItem>
                <MenuItem key={PUBLISHED} value={PUBLISHED} sx={{ justifyContent: "flex-start" }}>
                  <em>Published</em>
                </MenuItem>
              </StyledSelect>
            </FormControl>
            <Button
              variant="contained"
              sx={{
                backgroundColor: `${theme.palette.primary.main} !important`,
                fontSize: '13px',
                fontWeight: 500,
                whiteSpace: 'nowrap',
                height: theme.spacing(5),
                padding: `${theme.spacing(0.5)} ${theme.spacing(2.375)}`
              }}
              size="small"
              onClick={this.newDocument}
            >
              Add New Metadata
            </Button>
          </StyledSplitBarDiv>
          <StyledContentWrapperDiv>{contents}</StyledContentWrapperDiv>
        </div>
      </MetadataGuard>
    );
  }

  render() {
    return this.renderContent();
  }
}

export default inject((store: any) => ({
  metadataService: store.metadataService,
  historyService: store.historyService,
}))(DocumentLanding);

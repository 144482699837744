import React from 'react';
import find from 'lodash/find';
import { reaction } from 'mobx';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import GroupIcon from '@mui/icons-material/Group';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Typography, Tabs, Tab, Box } from '@mui/material';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';

import {
  ABILITIES,
  ISecurityService,
} from '@extensions/services/ISecurityService';
import {
  IDs,
  INotificationService,
  Notification,
} from '@extensions/services/INotificationService';
import { IDatasetService } from '@extensions/services/IDatasetService';
import { IHistoryService } from '@extensions/services/IHistoryService';

import User from '@extensions/models/User';
import Project from '@extensions/models/Project';
import Link from '@extensions/components/core/Link';
import DatasetModel from '@extensions/models/Dataset';
import NotFound from '@extensions/components/page/NotFound';
import DistributionType from '@extensions/models/DistributionType';
import DatasetHelp from '@extensions/components/dataset/DatasetHelp';
import MustSignInAlert from '@extensions/components/core/MustSignInAlert';
import ManageFiles from '@extensions/components/dataset/admin/ManageFiles';
import DatasetSummary from '@extensions/components/dataset/DatasetSummary';
import SwaggerMetadata from '@extensions/components/dataset/SwaggerMetadata';
import DatasetAccess from '@extensions/components/dataset/admin/DatasetAccess';
import AwaitApprovalAlert from '@extensions/components/core/AwaitApprovalAlert';
import MustVerifyEmailAlert from '@extensions/components/core/MustVerifyEmailAlert';
import { OrderMode, LEGACY_ORDER_MAX_FILES } from '@dapclient/components/dataset/DistributionTable';
import LargeDataOrder from '@extensions/components/dataset/LargeDataOrder';
import SmallDataOrder from '@extensions/components/dataset/SmallDataOrder';
import theme from '@extensions/services/Theme';

const StyledRootDiv = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));

const StyledTab = styled(Tab)(({theme}) => ({
  '&.MuiTab-root': {
    color: theme.palette.text.primary,
  },
  '&.Mui-selected': {
    color: '#000',
  }
}));

const StyledTabBox = styled(Box)(({theme}) => ({
  fontSize: '14px',
  color: theme.palette.grey[700],
}));

const ContentWrapper = styled('div')(() => ({}));

export interface IDatasetProps extends RouteComponentProps {
  className?: string;
  datasetService: IDatasetService;
  securityService: ISecurityService;
  notificationService: INotificationService;
  historyService: IHistoryService;
}

export interface IDatasetState {
  activeTab: string;
  datasetLoadError: boolean;
  showOrderModal: boolean;
  orderMode: OrderMode | null;
}

@observer
export class Dataset<
ExtraProps extends object = {}
> extends React.Component<
  IDatasetProps & ExtraProps,
  IDatasetState
> {
  static defaultProps = {
    datasetService: undefined,
    notificationService: undefined,
    securityService: undefined,
    projectService: undefined,
  };
  loadDatasetReaction: any;
  datasetErrorReaction: any;
  loadProjectReaction: any;

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'summary',
      datasetLoadError: false,
      showOrderModal: false,
      orderMode: null,
    };
  }

  componentDidMount() {
    const loggedInUser = this.props.securityService.user;
    const loading = this.loadDataset(loggedInUser);
    if (loading === undefined) {
      // Redirect...
      return;
    }
    if (!loading) {
      // register a mobx reaction to call loadDataset for the case when the user goes
      // directly to a dataset page; wait for autologin to succeed
      this.loadDatasetReaction = reaction(
        () => this.props.securityService.user,
        (user: User | null) => {
          if (user) {
            this.loadDataset(user);
          }
        }
      );
    }
    reaction(
      () => this.props.datasetService.dataset?.dynamoFileCount,
      (fileCount) => {
        if (fileCount != null) {
          const { REACT_APP_LARGE_ORDERS = 'false' } = process.env;
          const largeOrdersOn = {
            false: false,
            true: true,
          }[REACT_APP_LARGE_ORDERS.toLowerCase()];
          if (largeOrdersOn === undefined) {
            throw Error(
              `Unknown value for REACT_APP_LARGE_ORDERS: ${REACT_APP_LARGE_ORDERS}`
            );
          }
          this.handleFullFileList(largeOrdersOn, fileCount, this.props.datasetService);
        }
      },
      { fireImmediately: true }
    );
    this.datasetErrorReaction = reaction(
      () => this.props.notificationService.error,
      (error: Notification | null) => {
        if (error && error.id === IDs.GET_DATASET) {
          this.setState({ datasetLoadError: true });
        }
      }
    );
  }

  componentWillUnmount() {
    // dispose of the reactions, new ones will be created when another dataset loads
    if (this.loadDatasetReaction) {
      this.loadDatasetReaction();
    }
    if (this.datasetErrorReaction) {
      this.datasetErrorReaction();
    }
    if (this.loadProjectReaction) {
      this.loadProjectReaction();
    }
  }

  handleFullFileList(largeOrdersOn: boolean | undefined, fileCount: number, datasetService: IDatasetService) {
    if (!largeOrdersOn || fileCount < LEGACY_ORDER_MAX_FILES) {
      this.setState({ orderMode: OrderMode.SMALL });
    } else {
      this.setState({ orderMode: OrderMode.LARGE });
    }
  }

  async loadDataset(loggedInUser: User | null) {
    if (
      process.env.REACT_APP_SHOW_PUBLIC_DS ||
      (loggedInUser && loggedInUser.agreed)
    ) {
      const path = this.props.location.pathname;
      const pathSegments: string[] = path.split('/');
      const datasetName = `${pathSegments[2]}/${pathSegments[3]}`;

      const needsLogin = await this.props.datasetService.loadDataset(datasetName);
      if (needsLogin) {
        this.props.securityService.requireLogin(null);
        return undefined;
      }
      if (pathSegments.length === 5) {
        this.setState({ activeTab: pathSegments[4] });
      }
      return true;
    }
    return false;
  }

  onTabsChange = (event, activeKey: string) => {
    this.setState({ activeTab: activeKey });
    const path = this.props.location.pathname;
    const pathSegments: string[] = path.split('/');
    const datasetName = `${pathSegments[2]}/${pathSegments[3]}`;
    this.props.historyService.redirect(`/ds/${datasetName}/${activeKey}`);
  };

  getUserAlert = (loggedInUser: User | null) => {
    if (!process.env.REACT_APP_SHOW_PUBLIC_DS) {
      if (!loggedInUser || !loggedInUser.authenticated) {
        return <MustSignInAlert actionDescription="view datasets" />;
      } else if (!loggedInUser.emailVerified) {
        return <MustVerifyEmailAlert />;
      } else if (loggedInUser.approvalPending) {
        return <AwaitApprovalAlert />;
      }
    }

    if (this.state.datasetLoadError) {
      return <NotFound />;
    }
    return null;
  };

  getBrowseDataButton = (): React.ReactNode => {
    return (
      <Button
        variant="contained"
        color="secondary"
        onClick={this.openDataOrderModal}
        sx={{ marginBottom: theme.spacing(1) }}
      >
        Browse Data
      </Button>
    );
  };

  renderDataOrderModal = (): React.ReactNode | null => {
    // This return is commented out because it is not being used.
    const { orderMode } = this.state;
    switch (orderMode) {
      case OrderMode.SMALL:
        return (
          <SmallDataOrder
            visible={this.state.showOrderModal}
            onOrderCancel={this.closeDataOrderModal}
          />
        );
      case OrderMode.LARGE:
        return (
          <LargeDataOrder visible={this.state.showOrderModal} onOrderCancel={this.closeDataOrderModal} />
        );
      default:
        return null;
    }
  }

  getTimelines = (): React.ReactNode => {
    return null;
  }

  getDatasetSummary = (): React.ReactNode => {
    return <DatasetSummary showApiTab={(e) => this.onTabsChange(e, 'api')} />
  }

  openDataOrderModal = (event) => {
    this.setState({ showOrderModal: true });
  };

  closeDataOrderModal = (event) => {
    this.setState({ showOrderModal: false });
  };

  renderTabContent(activeTab) {
    switch (activeTab) {
      case 'summary':
        return this.getDatasetSummary();
      case 'api':
        return <SwaggerMetadata />;
      case 'api-help':
        return <DatasetHelp />;
      case 'upload':
        return <ManageFiles />;
      case 'manage':
        return <DatasetAccess />;
      default:
        return null;
    }
  }

  createWrapper = (content: React.ReactNode) => {
    return <ContentWrapper>{content}</ContentWrapper>
  }

  renderContent() {
    let content = <div />;
    const loggedInUser: User | null = this.props.securityService.user;
    const dataset: DatasetModel | null = this.props.datasetService.dataset;
    const project: Project | null = this.props.datasetService.project;
    const userAlert = this.getUserAlert(loggedInUser);
    if (userAlert) {
      content = userAlert;
    } else if (dataset && project) {
      const abilities: string[] | null = dataset.abilities;
      const hasApi =
        find(dataset.distribution, {
          distributionType: DistributionType.API,
        }) !== undefined;

      const hasFiles = dataset.getDownloadDistribution() !== undefined;
      const showManageTab = abilities && abilities.includes(ABILITIES.ADMIN);
      const showUploadTab =
        hasFiles && abilities && abilities.includes(ABILITIES.UPLOAD);
      const browseDataButton =
        hasFiles && this.state.activeTab === 'summary'
          ? this.getBrowseDataButton()
          : null;
      content = (
        <div>
          <Helmet>
            <title>{dataset.title}</title>
            <meta name="description" content={dataset.description}></meta>
          </Helmet>
          {this.renderDataOrderModal()}
          <Link to={`/project/${project.identifier}`}>{project.title}</Link>
          <Typography variant="h2" sx={{ marginTop: theme.spacing(1.5) }}>{dataset.title}</Typography>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              position: "relative",
              display: "flex",
              flex: "none",
              alignItems: "center"
            }}
          >
            <Tabs
              value={this.state.activeTab}
              onChange={this.onTabsChange}
              sx={{ flex: 'auto' }}
            >
              <StyledTab
                value="summary"
                label={
                  <StyledTabBox display="flex" alignItems="center">
                    <ListAltIcon style={{ marginRight: 8 }} fontSize='small'/>
                    Summary
                  </StyledTabBox>
                }
              />
              {hasApi && (
                <StyledTab
                  value="api"
                  label={
                    <StyledTabBox display="flex" alignItems="center">
                      <SettingsEthernetIcon style={{ marginRight: 8 }} fontSize='small'/>
                      API Details
                    </StyledTabBox>
                  }
                  key="api-help"
                />
              )}
              {hasApi && (
                <StyledTab
                  value="api-help"
                  label={
                    <StyledTabBox display="flex" alignItems="center">
                      <HelpOutlineIcon style={{ marginRight: 8 }} fontSize='small'/>
                      API Help
                    </StyledTabBox>
                  }
                  key="api"
                />
              )}
              {showUploadTab && (
                <StyledTab
                  value="upload"
                  label={
                    <StyledTabBox display="flex" alignItems="center">
                      <CloudUploadIcon style={{ marginRight: 8 }} fontSize='small'/>
                      Upload
                    </StyledTabBox>
                  }
                  key="upload"
                />
              )}
              {showManageTab && (
                <StyledTab
                  value="manage"
                  label={
                    <StyledTabBox display="flex" alignItems="center">
                      <GroupIcon style={{ marginRight: 8 }} fontSize='small'/>
                      Permissions
                    </StyledTabBox>
                  }
                  key="manage"
                />
              )}
            </Tabs>
            {browseDataButton}
          </Box>
          <div style={{ marginTop: '1rem' }}>
            {this.renderTabContent(this.state.activeTab)}
          </div>
        </div>
      );
    }
    return content;
  }

  render() {
    const content = this.renderContent()
    return <StyledRootDiv>{this.createWrapper(content)}</StyledRootDiv>;
  }
}

export default inject((store: any) => ({
  datasetService: store.datasetService,
  securityService: store.securityService,
  notificationService: store.notificationService,
  historyService: store.historyService,
}))(Dataset);

import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { styled, useMediaQuery } from '@mui/material';

// import DatasetSearch from '@extensions/components/page/DatasetSearch';
import DatasetSearchEnvData from '@extensions/components/page/DatasetSearchEnvData';
import ProjectSearchEnv from '@extensions/components/project-search/ProjectSearchEnv';
import PublicationsSearch from '@extensions/components/publications/PublicationsSearch';
import DatasetSearchWindEnergy from '@extensions/components/page/DatasetSearchWindEnergy';
import { SEARCH_CATEGORIES } from '@extensions/components/global-search/SearchCategories';
import GlobalSearchProvider from '@extensions/components/global-search/GlobalSearchProvider';
import ProjectSearchWindEnergy from '@extensions/components/project-search/ProjectSearchWindEnergy';
import HomeSearchResultContainer from '@extensions/components/page/home-page/HomeSearchResultContainer';
import HomePageSearchBarContainer from '@extensions/components/global-search/HomePageSearchBarContainer';

import theme from '@extensions/services/Theme';
import { useGlobalSearchService, useHistoryService } from '@extensions/hooks/useService';

const StyledDiv = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
    position: 'absolute',
    top: isMobile ? '3.8rem' : '0.8rem',
    left: '50%',
    transform: 'translateX(-50%)',
}));

interface IGlobalSearchBarWrapProps {
    renderChildren(): React.ReactNode;
}

const GlobalSearchBarWrap: React.FC<IGlobalSearchBarWrapProps> = ({ renderChildren }) => {
    const history = useHistory();
    const historyService = useHistoryService();
    const globalSearchService = useGlobalSearchService();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const selectedCategory = globalSearchService.selectedCategory;

        if (selectedCategory === SEARCH_CATEGORIES.WDH_PROJECT) {
            history.push('/projects/wind-energy');
        } else if (selectedCategory === SEARCH_CATEGORIES.ENV_PROJECT) {
            history.push('/projects/env-project');
        } else if (selectedCategory === SEARCH_CATEGORIES.WDH_DATA) {
            history.push('/data/wind-energy');
        } else if (selectedCategory === SEARCH_CATEGORIES.ENV_DATA) {
            history.push('/data/env-data');
        }
        else if (selectedCategory === SEARCH_CATEGORIES.PUBLICATIONS) {
            history.push('/publications');
        }
    }, [globalSearchService.selectedCategory, historyService, history]);

    const renderPageOnCondition = (selectedCategory) => {
        switch (selectedCategory) {
            case SEARCH_CATEGORIES.ENV_PROJECT:
                return <ProjectSearchEnv selectedCategory={SEARCH_CATEGORIES.ENV_PROJECT}/>
            case SEARCH_CATEGORIES.WDH_PROJECT:
                return <ProjectSearchWindEnergy selectedCategory={SEARCH_CATEGORIES.WDH_PROJECT}/>
            case SEARCH_CATEGORIES.ENV_DATA:
                return <DatasetSearchEnvData selectedCategory={SEARCH_CATEGORIES.ENV_DATA}/>
            case SEARCH_CATEGORIES.WDH_DATA:
                return <DatasetSearchWindEnergy selectedCategory={SEARCH_CATEGORIES.WDH_DATA}/>
            case SEARCH_CATEGORIES.PUBLICATIONS:
                return <PublicationsSearch selectedCategory={SEARCH_CATEGORIES.PUBLICATIONS}/>
            case '':
                return renderChildren();
            default:
                return renderChildren();
        }
    }

    const renderSearchResultSection = (): React.ReactNode => {
        const { searchContent, clickedButton, needStay, selectedCategory } = globalSearchService;
        if ((searchContent === '' && !clickedButton && !needStay) || (searchContent !== '' && clickedButton && !needStay) || (clickedButton)) {
            return renderChildren();
        } else if ((searchContent !== '' && !clickedButton && needStay) || (searchContent === '' && !clickedButton && needStay)) {
            renderPageOnCondition(selectedCategory);
        }
        if ((selectedCategory === SEARCH_CATEGORIES.ALL || !selectedCategory || selectedCategory === '') && searchContent !== '') {
            return <HomeSearchResultContainer />
        } else {
            renderPageOnCondition(selectedCategory);
        }
        return renderChildren();
    }

    const renderSearchSections = (): React.ReactNode => {
        const { selectedCategory } = globalSearchService;
        const categoryToComponentMap = {
            [SEARCH_CATEGORIES.WDH_PROJECT]: <ProjectSearchWindEnergy selectedCategory={SEARCH_CATEGORIES.WDH_PROJECT}/>,
            [SEARCH_CATEGORIES.ENV_PROJECT]: <ProjectSearchEnv selectedCategory={SEARCH_CATEGORIES.ENV_PROJECT}/>,
            [SEARCH_CATEGORIES.WDH_DATA]: <DatasetSearchEnvData selectedCategory={SEARCH_CATEGORIES.WDH_DATA}/>,
            [SEARCH_CATEGORIES.ENV_DATA]: <DatasetSearchEnvData selectedCategory={SEARCH_CATEGORIES.ENV_DATA}/>,
            [SEARCH_CATEGORIES.PUBLICATIONS]: <PublicationsSearch selectedCategory={SEARCH_CATEGORIES.PUBLICATIONS}/>,
        };

        if (categoryToComponentMap[selectedCategory]) {
            return categoryToComponentMap[selectedCategory];
        }

        return (
            <div>
                <GlobalSearchProvider>
                    <StyledDiv isMobile={isMobile}>
                        <HomePageSearchBarContainer isRedirecting={false} />
                    </StyledDiv>
                    {renderSearchResultSection()}
                </GlobalSearchProvider>
            </div>
        );
    }

    return renderSearchSections();
}

export default observer(GlobalSearchBarWrap);
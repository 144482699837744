import {
    Box,
    Typography,
    Button,
    styled
} from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactiveList, ReactiveBase } from '@appbaseio/reactivesearch';

import { useTokenService } from '@extensions/hooks/useService';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';
import NewPublicationsList from '@extensions/components/page/home-page/NewPublicationsList';

import theme from '@extensions/services/Theme';

const StyledButton = styled(Button)(({
    fontSize: '0.75rem',
    color: theme.palette.common.white
}));

interface IHomePageNewPublicationsProps { };

export const HomePageNewPublications: React.FC<IHomePageNewPublicationsProps> = () => {

    const tokenService = useTokenService();
    const history = useHistory();

    const handleNavigation = () => {
        history.push('/publications');
    };

    const customQuery = {
        query: {
            match_all: {},
        },
        size: 5,
        _source: {
            includes: ["*"],
            excludes: [],
        },
        sort: [
            {
                'publicationDate': {
                    missing: '_last',
                    order: 'desc',
                }
            }
        ]
    }

    return (
        <>
            <Typography variant='h3' gutterBottom>
                New Publications
            </Typography>
            <Box>

                <ReactiveBase
                    app="api/refs"
                    url={`${window.location.protocol}//${window.location.host}`}
                    headers={{ 'x-csrf-token': tokenService.dapToken }}
                    transformRequest={(req) => {
                        req.body = req.body.replace(
                            '{"query":{"match_all":{}},"size":10,"_source":{"includes":["*"],"excludes":[]}}',
                            JSON.stringify(customQuery)
                        );
                        return req;
                    }}
                >
                    <ReactiveList
                        infiniteScroll={false}
                        showResultStats={false}
                        dataField="title.keyword"
                        componentId="SearchResult"
                        render={({ loading, error, data }) => {
                            if (loading) {
                                return <CenteredCircularProgress />;
                            }
                            if (error) {
                                return (
                                    <div>
                                        Something went wrong! Please email{' '}
                                        <a href={process.env.REACT_APP_TEAM_EMAIL}>our team</a> if
                                        this issue persists.
                                    </div>
                                );
                            }
                            return (<NewPublicationsList publications={data} />);
                        }}
                    >
                    </ReactiveList>
                </ReactiveBase>
                <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <StyledButton
                        onClick={handleNavigation}
                        size="small"
                        variant="contained"
                    >
                        Browse Publications
                    </StyledButton>
                </Box>
            </Box>
        </>
    )
}

export default HomePageNewPublications;
import React from 'react';

import {
  Card,
  CardContent,
  Typography,
  styled
} from '@mui/material';

import { JournalArticle } from '@extensions/models/Publication';

const StyledArticleCard = styled(Card)(() => ({
  marginTop: '10px', 
  minHeight: '40px'  
}));

const StyledTitle = styled(Typography)(() => ({
  color: '#000000',
  fontSize: '1.2em',
}));

export interface IPublicationJournalArticle {
  article: JournalArticle;
}

const PublicationJournalArticle: React.FC<IPublicationJournalArticle> = ({ article }) => {
  return (
    <StyledArticleCard variant='outlined'>
      <CardContent>
        <StyledTitle>Journal</StyledTitle>
        <Typography>
          <strong>Name:{' '}</strong>
          {article.journalName}
          </Typography>
        <Typography>
          <strong>Volume:{' '}</strong>
          {article.journalVolume}
          </Typography>
        <Typography>
          <strong>Issue:{' '}</strong>
          {article.journalIssue}
          </Typography>
      </CardContent>
    </StyledArticleCard>
  )
}

export default PublicationJournalArticle

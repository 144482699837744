import {
    Button,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import theme from '@extensions/services/Theme';
import { useContactUsService } from '@extensions/hooks/useService';

interface IContactMenuProps {
    project: any;
}

const ContactMenu: React.FC<IContactMenuProps> = ({project}) => {
    const contactUsService = useContactUsService();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        contactUsService.openModal();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderValue = (data: any) => {
        if (typeof data === 'number') {
            return data;
        }

        if (Array.isArray(data)) {
            return data.length
        }

        if (!data) {
            return 'N/A'
        }

        return data;
    };

    // const renderContactAllButton = (project) => {
    //     if (project && project.contactPoint && project.contactPoint.length > 1) {
    //         const emailAddresses = project.contactPoint.map((cp) => cp.hasEmail.substring(7)).join(',');
    //         const mailtoLink = `mailto:${emailAddresses}`;
    //         return (
    //             <MenuItem onClick={handleClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //                 <Link href={mailtoLink} underline="none">Contact All</Link>
    //             </MenuItem>
    //         )
    //     }
    //     return null;
    // };

    return (
        <div>
            <Button
                id="contact-menu-button"
                aria-controls={open ? 'contact-menu-button' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                endIcon={
                    <ContactMailIcon
                        sx={{
                            mt: '-1.45rem',
                            color: theme.palette.primary.dark
                        }}
                    />
                }
                onClick={handleClick}
                sx={{
                    ml: '-0.5rem',
                    pb: '0',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                    },
                }}
                disableRipple
            >
                <Typography variant='body1' sx={{ mt: '-0.4rem', textDecoration: 'underline' }}>
                    {renderValue(project.contactPoint)}
                </Typography>
            </Button>
            {/* <Menu
                id="contact-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-menu-button',
                }}
            >
                {project.contactPoint.map((cp, i) => {
                    return (
                        <Tooltip key={`tooltip-${i}`} title="Click to Send an Email">
                        <MenuItem key={`menu-item-${i}`} onClick={handleClose}>
                            <Link 
                                href={cp.hasEmail} 
                                underline="hover" 
                                sx={{ 
                                    display: 'flex', 
                                    color: theme.palette.text.primary
                                }}
                            >
                                <EmailIcon sx={{mr: '0.5rem'}}/>{cp.fn}
                            </Link>
                        </MenuItem>
                        </Tooltip>
                    )
                })}
                {renderContactAllButton(project)}
            </Menu> */}
        </div>
    );
}

export default ContactMenu;
import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  styled
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import theme from '@extensions/services/Theme';
import { FilterType } from '@dapclient/models/Dataset';
import { useCartService, useSecurityService, useGlobalSearchService } from '@extensions/hooks/useService';

import DateFilter from '@extensions/components/cart/DateFilter';
import OrderButton from '@extensions/components/cart/OrderButton';
import OrderSummary from '@extensions/components/cart/OrderSummary';
import AppliedFilters from '@extensions/components/cart/AppliedFilters';
import FileTypesFilter from '@extensions/components/cart/FileTypesFilter';
import ExtensionsFilters from '@extensions/components/cart/ExtensionsFilters';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

const StyledAppliedFilters = styled(AppliedFilters)(({
  marginBottom: theme.spacing(2),
}));

const StyledDateFilter = styled(DateFilter)(({
  marginBottom: theme.spacing(2),
}));

const StyledOrderButton = styled(OrderButton)(({
  marginTop: theme.spacing(2),
}));

const StyledWrapper = styled('div')(({ theme }) => ({
  margin: '0 1em',
  [theme.breakpoints.down('xl')]: {
    margin: '0 1em',
  },
  [theme.breakpoints.down('xs')]: {
    margin: '0 1em',
  },
}));

const Cart: React.FC = observer(() => {
  const [closedLargeBanner, setClosedLargeBanner] = useState<boolean>(false);
  const [closedFilterBanner, setClosedFilterBanner] = useState<boolean>(false);

  const cartService = useCartService();
  const securityService = useSecurityService();
  const globalSearchService = useGlobalSearchService();

  if (!cartService || !securityService) {
    return null;
  };

  const needsTimeFilter = (cartService.datasets || []).some(
    (d) => d.dynamoDataBegins !== null && d.dynamoDataEnds !== null
  );
  const showLargeBanner = cartService.unusuallyLarge && !closedLargeBanner;
  const showFilterDownBanner = cartService.unusuallyLarge && !closedFilterBanner;

  let extensionLabels: string[] = [];

  if (cartService.datasets) {
    const labelSet = new Set<string>();
    for (const ds of cartService.datasets) {
      for (const fileExtension of ds.dynamoFileExtensions) {
        if (fileExtension.filterType === FilterType.HIDE) continue;
        labelSet.add(fileExtension.label);
      }
    }
    extensionLabels = Array.from(labelSet).sort();
  }

  const orderDetails = (
    <>
      <OrderSummary />
      <StyledOrderButton />
    </>
  );

  const renderContent = () => {
    return (
      <StyledWrapper>
        <Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={8} lg={9} sx={{}}>
              <Typography variant="h1">Data Cart</Typography>
              {showLargeBanner && (
                <Box
                  sx={{
                    backgroundColor: '#e06f6b',
                    color: theme.palette.common.white,
                    p: 2,
                    mb: 2,
                    borderRadius: '4px',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ mr: 2 }}>
                    You have an unusually large volume of data in your cart. It is
                    recommended that you filter the dataset down using the filters on
                    the right. You can filter globally or select a dataset individually to down select.
                  </Box>
                  <IconButton
                    onClick={() => setClosedLargeBanner(true)}
                    size="small"
                    sx={{ color: '#ffffff' }}
                  >
                    <CloseIcon sx={{ fontSize: '1.4rem' }} />
                  </IconButton>
                </Box>
              )}
              {showFilterDownBanner && (
                <Box
                  sx={{
                    backgroundColor: '#f1d876',
                    color: theme.palette.common.white,
                    p: 2,
                    mb: 3,
                    borderRadius: '4px',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ mr: 2 }}>
                    To filter down a dataset, or accompanying dataset files,
                    please select the desired files on the right and use the
                    filters to get the files you are wanting to download.
                  </Box>
                  <IconButton
                    onClick={() => setClosedFilterBanner(true)}
                    size="small"
                    sx={{ color: '#ffffff' }}
                  >
                    <CloseIcon sx={{ fontSize: '1.4rem' }} />
                  </IconButton>
                </Box>
              )}
              {cartService.statsAreLoading ? (
                <CenteredCircularProgress />
              ) : (
                orderDetails
              )}
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{marginTop: '1rem'}}>
              {needsTimeFilter && (
                <Card
                  sx={{
                    minWidth: 275,
                    marginBottom: 2,
                    overflow: 'visible',
                    position: 'relative',
                  }}
                >
                  <CardContent sx={{ px: '1 rem', py: '1 rem' }}>
                    <Typography
                      sx={{
                        color: 'text.secondary',
                      }}
                    >
                      Date Range
                    </Typography>
                    <Divider sx={{ mx: -2 }} />
                    <Box
                      sx={{
                        mt: '1rem'
                      }}
                    >
                      <StyledDateFilter />
                    </Box>
                  </CardContent>
                </Card>
              )}
              {cartService.orderItems.length !== 0 && (
                <Card
                  sx={{
                    minWidth: 275,
                    marginBottom: 2,
                    overflow: 'visible',
                    position: 'relative',
                  }}
                >
                  <CardContent
                    sx={{
                      px: '1 rem',
                      py: '1 rem',
                      '&.MuiCardContent-root': {
                        paddingBottom: '0px'
                      }
                    }}
                  >
                    <Typography
                      sx={{ color: 'text.secondary' }}
                    >
                      Applied Filters
                    </Typography>
                    <Divider sx={{ mx: -2 }} />
                    <Box sx={{ mt: 1 }}>
                      <StyledAppliedFilters />
                    </Box>
                  </CardContent>
                  <CardContent
                    sx={{
                      px: '1 rem',
                      py: '1 rem',
                      '&.MuiCardContent-root': {
                        paddingTop: '0px',
                        paddingBottom: '0px'
                      }
                    }}
                  >
                    <Typography
                      sx={{ color: 'text.secondary' }}
                    >
                      File Type
                    </Typography>
                    <Divider sx={{ mx: -2 }} />
                    <Box sx={{ mt: 1, maxHeight: 350, overflowY: 'auto' }}>
                      <FileTypesFilter
                        styleObject={{
                          root: {
                            marginBottom: theme.spacing(2),
                            width: '100%',
                          },
                          filterOptionList: {
                            maxHeight: 'inherit',
                            overflowY: 'visible',
                            overflowX: 'hidden',
                          },
                        }}
                      />
                    </Box>
                  </CardContent>
                  {extensionLabels.map((label, idx) => (
                    <CardContent
                      sx={{
                        px: '1 rem',
                        py: '1 rem',
                        '&.MuiCardContent-root': {
                          paddingTop: '0px',
                          paddingBottom: '0px'
                        }
                      }}
                    >
                      <Typography
                        sx={{ color: 'text.secondary', mb: 1 }}
                      >
                        {`Extension ${idx + 1}`}
                      </Typography>
                      <Divider sx={{ mx: -2 }} />
                      <Box sx={{ mt: 1, maxHeight: 350, overflowY: 'auto' }}>
                        <ExtensionsFilters
                          label={label}
                          styleObject={{
                            filterBlock: {
                              marginBottom: theme.spacing(2),
                            },
                            optionsList: {
                              borderBottom: `1px solid ${theme.palette.grey[400]}`,
                            },
                          }}
                        />
                      </Box>
                    </CardContent>
                  ))}
                </Card>
              )}
            </Grid>
          </Grid>
        </Box>
      </StyledWrapper>
    )
  }
  globalSearchService.setSelectedCategory('');
  globalSearchService.setSearchContent('');
  return <GlobalSearchBarWrap renderChildren={() => renderContent()} />
});

export default Cart;

import React from 'react';
import filesize from 'filesize';
import { Card, CardContent, Typography, styled } from '@mui/material';

import FileSummaryItem from '@extensions/components/order/cards/FileSummaryItem';

import theme from '@extensions/services/Theme';
import FileOrderGroup from '@extensions/models/FileOrderGroup';

const StyledHeading = styled(Typography)({
  fontSize: '1.5rem',
  marginBottom: theme.spacing(2),
});

interface FileSummaryProps {
  className?: string;
  order: FileOrderGroup;
}

const FileSummary: React.FC<FileSummaryProps> = ({ order }) => (
  <Card>
    <CardContent>
      <StyledHeading>
        File Summary
      </StyledHeading>
      {order.fileTypes.length > 0 && (
        <FileSummaryItem label="File Types" value={order.fileTypes.join(', ')} />
      )}
      {order.dateRange.startDate && order.dateRange.endDate && (
        <FileSummaryItem
          label="Date Range"
          value={`${order.dateRange.startDate.format('YYYY-MM-DD')} to ${order.dateRange.endDate.format('YYYY-MM-DD')}`}
        />
      )}
      <FileSummaryItem label="Total Size" value={filesize(order.totalSize)} />
      <FileSummaryItem label="File Count" value={order.fileCount} />
    </CardContent>
  </Card>
);

export default FileSummary;

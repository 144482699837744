import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    TableContainer,
    Paper,
    Typography,
    TableRow,
    TableCell,
    Table,
    TableBody
} from '@mui/material';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DataRelevantEvent from '@extensions/models/DataRelevantEvent';
import QualityWarning from '@extensions/components/dataset/QualityWarning';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    '& td': {
        verticalAlign: 'top', 
        textAlign: 'left',
    },
    '& td:first-of-type': {
        textAlign: 'right',
        color: theme.palette.grey[500],
        minWidth: '100px',
        width: '150px',
    },
    '& td:nth-of-type(2)': {
        textAlign: 'left',
    },
}));

export interface IDataRelevantEventsListProps {
    className?: string;
    events?: DataRelevantEvent[] | null;
}

const DataRelevantEventsList: React.FC<IDataRelevantEventsListProps> = observer(({ events }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    useEffect(() => {
        if (location.hash === "#event") {
            setIsExpanded(true);
            wrapperRef.current?.scrollIntoView();
        }
    }, [location.hash]);

    const onChangeExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const renderHazardIcon = (events: DataRelevantEvent[] | null | undefined) => {
        if (events && events.length > 0 && events.some((event) => event.affectsDataQuality)) {
            return <QualityWarning />;
        }
        return null;
    };

    const renderEventTable = (events: DataRelevantEvent[] | null | undefined) => {
        if (events && events.length > 0) {
            return events.map((event, idx) => (
                <StyledTableContainer component={Paper} key={idx}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>Start Date</TableCell>
                                <TableCell><code>{event.startDate}</code></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>End Date</TableCell>
                                <TableCell><code>{event.endDate || '-'}</code></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Affects Data Quality</TableCell>
                                <TableCell>
                                    {event.affectsDataQuality ? (
                                        <>
                                            <QualityWarning />
                                            Yes
                                        </>
                                    ) : 'No'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>{event.description}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            ));
        }
        return null;
    };

    return (
        <Accordion
            expanded={isExpanded}
            onChange={onChangeExpanded}
            ref={wrapperRef}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="events-content"
                id="events-header"
            >
                <Typography variant="h3" sx={{fontSize: '18px'}}>
                    Data Relevant Events
                    {renderHazardIcon(events)}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    {renderEventTable(events)}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
});

export default DataRelevantEventsList;
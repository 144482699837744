import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  styled,
  Typography,
} from '@mui/material';

import DatasetModel from '@extensions/models/Dataset';
import { formatBytes } from '@extensions/utils/format';
import moment from 'moment';

interface DataSummaryProps {
  dataset: DatasetModel;
}

const StyledCard = styled(Card)(({
  marginTop: '10px',
  marginBottom: '0.25rem',
}));

const StyledTitle = styled(Typography)(() => ({
  color: '#000000',
  fontSize: '18px',
}));

const StyledMetricType = styled(Typography)(() => ({
  margin: '0 0',
  fontSize: '16px',
  color: '#B9B9B9',
}));

const StyledNumber = styled(Typography)(() => ({
  fontSize: '16px',
  textAlign: 'right',
  display: 'block',
}));

const DataSummary: React.FC<DataSummaryProps> = ({ dataset }) => {
  return (
    <StyledCard variant='outlined'>
      <CardContent>
        <StyledTitle>Data Summary</StyledTitle>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledMetricType>File Type(s)</StyledMetricType>
          </Grid>

          <Grid item xs={6}>
            <StyledNumber>
              {dataset.dynamoFileTypes && dataset.dynamoFileTypes.length > 0
                ? dataset.dynamoFileTypes.join(', ')
                : 'N/A'}
            </StyledNumber>
          </Grid>

          <Grid item xs={6}>
            <StyledMetricType>Total Size</StyledMetricType>
          </Grid>

          <Grid item xs={6}>
            <StyledNumber>
              {dataset.dynamoTotalFileSize
                ? formatBytes(dataset.dynamoTotalFileSize)
                : 'N/A'}
            </StyledNumber>
          </Grid>

          <Grid item xs={6}>
            <StyledMetricType>Start Date</StyledMetricType>
          </Grid>

          <Grid item xs={6}>
            <StyledNumber>
              {dataset.dynamoDataBegins
                ? moment(dataset.dynamoDataBegins).format('YYYY-MM-DD')
                : 'N/A'}
            </StyledNumber>
          </Grid>

          <Grid item xs={6}>
            <StyledMetricType>End Date</StyledMetricType>
          </Grid>

          <Grid item xs={6}>
            <StyledNumber>
              {dataset.dynamoDataEnds
                ? moment(dataset.dynamoDataEnds).format('YYYY-MM-DD')
                : 'N/A'}
            </StyledNumber>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  )
}

export default DataSummary

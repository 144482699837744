import React from 'react';
import {
  Legend,
  Cell,
  Pie,
  Line,
  PieChart,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { inject } from 'mobx-react';
import {
  faNewspaper,
  faChartBar,
  faFile,
  faDatabase,
  faHandshake,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Grid, Typography, styled } from '@mui/material';

import {
  Metrics as DapMetrics,
} from '@dapclient/components/page/Metrics';
import MetricsCard from '@extensions/components/core/MetricsCard';
import HeadManager from '@extensions/components/page/HeadManager';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import MetricsModel from '@extensions/models/Metrics';
import MetricsUtils from '@dapclient/utils/MetricsUtils';

import theme from '@extensions/services/Theme';

const StyledWrapper = styled('div')(({ theme }) => ({
  margin: '0 1em',
  [theme.breakpoints.down('xl')]: {
    margin: '0 1em',
  },
  [theme.breakpoints.down('xs')]: {
    margin: '0 0em',
  },
}));

const StyledSmallChartSpan = styled('span')(({
  width: '100%'
}));

export class Metrics extends DapMetrics {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderUsersRegistered = (metrics: MetricsModel,) => {
    if (metrics.userCountsByMonth.length === 0) {
      return null;
    }
    const barColor = MetricsUtils.getBarColor(theme);
    const xInterval = this.getXAxisIntervalForTicks(
      metrics.userCountsByMonth.length,
      5
    );

    return (
      <MetricsCard
        cardTitle='Users'
        icon={faUser}
        count={metrics.userCount}
      >
        <StyledSmallChartSpan>
          <Typography gutterBottom>Cumulative user registrations</Typography>
          <ResponsiveContainer height={250} width="100%">
            <LineChart data={metrics.userCountsByMonth}>
              <XAxis
                mirror={true}
                interval={xInterval}
                dataKey="0"
                tickFormatter={(tickValue) =>
                  this.formatLaravelxAxisTicks(
                    tickValue,
                    metrics.userCountsByMonth[0][0],
                    metrics.userCountsByMonth[
                    metrics.userCountsByMonth.length - 1
                    ][0]
                  )
                }
              />
              <YAxis
                mirror={true}
                tickFormatter={(value) => (value === 0 ? '' : value)}
              />
              <Line dataKey="1" stroke={barColor} dot={false} strokeWidth={2} />
              <Tooltip
                labelFormatter={this.formatLaravelCountsTooltipLabel}
                formatter={(value, name, props) => [value, 'Users']}
              />
            </LineChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={250} width="100%">
            <PieChart width={250} height={250}>
              <Pie
                data={metrics.usersByDomain}
                fill="#8884d8"
                dataKey="1"
                nameKey="0"
                label={false}
                labelLine={false}
              >
                {metrics.usersByDomain.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={this.getColor(index)} />
                ))}
              </Pie>
              <Legend />
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </StyledSmallChartSpan>
      </MetricsCard>
    )
  }

  renderProjectCount = (metrics: MetricsModel) => {
    if (metrics.projectCountsByMonth.length === 0) {
      return null;
    }

    const barColor = MetricsUtils.getBarColor(theme);
    const xInterval = this.getXAxisIntervalForTicks(
      metrics.projectCountsByMonth.length,
      5
    );

    return (
      <MetricsCard
        cardTitle='Projects'
        icon={faHandshake}
        count={metrics.projectCount}
      >
        <StyledSmallChartSpan>
          <Typography gutterBottom>Cumulative project count</Typography>
          <ResponsiveContainer height={250} width="100%">
            <LineChart data={metrics.projectCountsByMonth}>
              <XAxis
                mirror={true}
                interval={xInterval}
                dataKey="0"
                tickFormatter={(tickValue) =>
                  this.formatLaravelxAxisTicks(
                    tickValue,
                    metrics.projectCountsByMonth[0][0],
                    metrics.projectCountsByMonth[
                    metrics.projectCountsByMonth.length - 1
                    ][0]
                  )
                }
              />
              <YAxis
                mirror={true}
                tickFormatter={(value) => (value === 0 ? '' : value)}
              />
              <Line dataKey="1" stroke={barColor} dot={false} strokeWidth={2} />
              <Tooltip
                labelFormatter={this.formatLaravelCountsTooltipLabel}
                formatter={(value, name, props) => [value, 'Projects']}
              />
            </LineChart>
          </ResponsiveContainer>
        </StyledSmallChartSpan>
      </MetricsCard>
    )
  }

  renderPublicationCount = (metrics: MetricsModel) => {
    const { publicationStats } = metrics;
    if (publicationStats) {
      const pieChartData = Object.entries(publicationStats.pubsCountsByType);
      return (
        <MetricsCard
          icon={faNewspaper}
          count={publicationStats.totalNumPubs}
          cardTitle={'Publications'}
        >
          <StyledSmallChartSpan>
            <Typography gutterBottom>Publications by Type</Typography>
            <ResponsiveContainer height={250} width="100%">
              <PieChart width={250} height={250}>
                <Pie
                  data={pieChartData}
                  fill="#8884d8"
                  dataKey="1"
                  nameKey="0"
                  label={false}
                  labelLine={false}
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={this.getColor(index)} />
                  ))}
                </Pie>
                <Legend />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </StyledSmallChartSpan>
        </MetricsCard>
      );
    }
    return null;
  };

  renderDatasetCount = (metrics: MetricsModel) => {
    if (metrics.datasetCountsByMonth.length === 0) {
      return null;
    }

    const barColor = MetricsUtils.getBarColor(theme);
    const xInterval = this.getXAxisIntervalForTicks(
      metrics.datasetCountsByMonth.length,
      5
    );

    return (
      <MetricsCard
        icon={faChartBar}
        count={metrics.datasetCount}
        cardTitle={'Datasets'}
      >
        <StyledSmallChartSpan>
          <Typography gutterBottom>Cumulative dataset count</Typography>
          <ResponsiveContainer height={250} width="100%">
            <LineChart
              data={metrics.datasetCountsByMonth}
              margin={{ bottom: 45, right: 15 }}
            >
              <XAxis
                mirror={true}
                interval={xInterval}
                dataKey="0"
                tickFormatter={(tickValue) =>
                  this.formatLaravelxAxisTicks(
                    tickValue,
                    metrics.datasetCountsByMonth[0][0],
                    metrics.datasetCountsByMonth[
                    metrics.datasetCountsByMonth.length - 1
                    ][0]
                  )
                }
                angle={30}
                dy={35}
              />
              <YAxis
                mirror={true}
                tickFormatter={MetricsUtils.formatYAxisTick}
              />
              <Line dataKey="1" stroke={barColor} dot={false} strokeWidth={2} />
              <Tooltip
                labelFormatter={this.formatLaravelCountsTooltipLabel}
                formatter={(value, name, props) => [value, 'Datasets']}
              />
            </LineChart>
          </ResponsiveContainer>
        </StyledSmallChartSpan>
      </MetricsCard>
    );
  };

  renderFilesStored = (metrics: MetricsModel) => {
    return (
      <MetricsCard
        icon={faFile}
        count={metrics.fileCount}
        cardTitle={'Files Stored'}
      />
    );
  };

  renderBytesStored = (metrics: MetricsModel) => {
    return (
      <MetricsCard
        icon={faDatabase}
        count={metrics.fileSizeStat.value}
        cardTitle={`${metrics.fileSizeStat.symbol} Stored`}
      />
    );
  };

  renderContentSections = (metrics: MetricsModel) => {
    return (
      <StyledWrapper>
        <Grid container spacing={2}>
          <HeadManager
            title="WDH: Wind Data Hub Metrics"
            description="The Wind Data Hub, or WDH is established by the U.S. Department of Energy Office of Energy Efficiency and
              Renewable Energy's Wind Energy Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052)."
          />
          <Grid item xs={12} md={6} lg={6}>
            {this.renderUsersRegistered(metrics)}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            {this.renderProjectCount(metrics)}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {this.renderFilesStored(metrics)}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {this.renderBytesStored(metrics)}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {this.renderDatasetCount(metrics)}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {this.renderPublicationCount(metrics)}
          </Grid>
          <Grid item xs={12} md={12}>
            {this.renderDownloadFileSizes(metrics)}
          </Grid>
          <Grid item xs={12} md={12}>
            {this.renderDownloadFileCounts(metrics)}
          </Grid>
          <Grid item xs={12} md={12}>
            {this.renderDownloadUserCount(metrics)}
          </Grid>
          <Grid item xs={12} md={12}>
            {this.renderDownloadDatasetCount(metrics)}
          </Grid>
          <Grid item xs={12} md={12}>
            {this.renderWebsiteUsage()}
          </Grid>
        </Grid>
      </StyledWrapper>
    );
  }

  renderContent = (metrics: MetricsModel) => {
    return <GlobalSearchBarWrap renderChildren={() => this.renderContentSections(metrics)} />
  };
}

export default inject((store: any) => ({
  metricsService: store.metricsService,
}))(Metrics);

import React from 'react';

import { 
  Card, 
  CardContent, 
  Typography,
  styled
} from '@mui/material';

import Link from '@dapclient/components/core/Link';
import { PubProject } from '@extensions/models/Publication';

const StyledProjectCard = styled(Card)(() => ({
  marginTop: '10px',
  height: '200px',
  overflowY: 'auto', 
  '&::-webkit-scrollbar': {
    width: '6px', 
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555', 
  },
}));

const StyledTitle = styled(Typography)(() => ({
  position: 'sticky',
  top: 0,
  backgroundColor: '#ffffff', 
  zIndex: 1, 
  padding: '10px 0',
  fontSize: '1.2em',
}));

export interface IProjectsListProps {
  projects: PubProject[] | string[];
}

const ProjectsList: React.FC<IProjectsListProps> = ({ projects }) => {
  return (
    <StyledProjectCard variant='outlined'>
      <CardContent>
        <StyledTitle>Projects</StyledTitle>
          {projects.length > 0 ? (
            projects.map((project, index: number) => (
              <Link to={`/project/${project.name}`} key={index}>
                <Typography>{project.name}</Typography>
              </Link>
            ))
          ) : (
            <Typography>No Projects Provided</Typography>
          )} 
      </CardContent>
    </StyledProjectCard>
  )
}

export default ProjectsList;

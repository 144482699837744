import { Card, CardContent, Stack, styled } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import MultiList from '@extensions/components/core/MultiList';
import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils';
import { SEARCH_BAR_REACTIVE_ID } from '@extensions/components/search-core';

export const PROJECT_KEYWORDS_ID = 'PROJECT_KEYWORDS_ID';
export const PROJECT_PARTICIPATING_ORGS_ID = 'PROJECT_PARTICIPATING_ORGS';
export const REACTIVE_IDS = [PROJECT_PARTICIPATING_ORGS_ID, PROJECT_KEYWORDS_ID];

export const DATASET_KEYWORDS_ID = 'DATASET_KEYWORDS';
export const DATASET_PARTICIPATING_ORGS_ID = 'DATASET_PARTICIPATING_ORGS';
export const PROJECT_DATA_ACCESS_ID = 'PROJECT_DATA_ACCESS';

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '& .data-filter': {
    marginBottom: theme.spacing(2),
  },
}));

interface IProjectFiltersProps { }

const ProjectFilters: React.FC<IProjectFiltersProps> = () => {

  const getFilterReactiveIds = useCallback(() => REACTIVE_IDS, []);

  const allReactiveIdsExcept = useCallback(
    (excludeThisOne: string): string[] => {
      return getAllReactiveIdsExcept([
        ...getFilterReactiveIds(),
        SEARCH_BAR_REACTIVE_ID,
      ])(excludeThisOne);
    },
    [getFilterReactiveIds]
  );

  const renderProjectCriteria = () => (
    <Card variant='outlined'>
      <StyledCardContent>
        <MultiList
          componentId={PROJECT_PARTICIPATING_ORGS_ID}
          title="Participating Organizations"
          dataField="contactPoint.hasOrg.keyword"
          react={{
            and: allReactiveIdsExcept(PROJECT_PARTICIPATING_ORGS_ID),
          }}
          maxLabelWidth="none"
          URLParams
          filterLabel="Participating Organizations"
          className="data-filter"
        />
        <MultiList
          componentId={PROJECT_KEYWORDS_ID}
          title="Keyword"
          filterLabel="Keyword"
          dataField="keywords.keyword"
          react={{
            and: allReactiveIdsExcept(PROJECT_KEYWORDS_ID),
          }}
          maxLabelWidth="none"
          URLParams
        />
      </StyledCardContent>
    </Card>
  );

  return (
    <>
      {renderProjectCriteria()}
    </>
  );
};

export default observer(ProjectFilters);

import { Location } from 'history';
import { observer } from 'mobx-react';
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { Button, Tab, Tabs, Alert, AlertTitle, styled } from '@mui/material';

import User from '@extensions/models/User';

import theme from '@extensions/services/Theme';
import { useHistoryService, useSecurityService, useGlobalSearchService } from '@extensions/hooks/useService';

import DataOrders from '@extensions/components/user/DataOrders';
import WdhUserProfileForm from '@extensions/components/user/WdhUserProfileForm';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

const StyledWrapper = styled('div')(({ theme }) => ({
    margin: '0 15em',
    [theme.breakpoints.down('lg')]: {
        margin: '0 0em',
    },
}));

const StyledFormDiv = styled('div')({
    display: 'block',
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '500px',
});

const StyledAlert = styled(Alert)(() => ({
    ...theme.MuiAlert.outlinedWarning,
    maxWidth: '450px',
    marginBottom: theme.spacing(2),
}));

const StyledDataOrders = styled(DataOrders)(() => ({
    marginTop: '2rem',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    '&.MuiTab-root': {
        color: theme.palette.text.primary,
    },
    '&.Mui-selected': {
        color: '#000',
    },
}));

const Profile: React.FC = observer(() => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const location = useLocation();

    const securityService = useSecurityService();
    const historyService = useHistoryService();
    const globalSearchService = useGlobalSearchService();

    const updateActiveTab = useCallback((): void => {
        const newActiveTab = activeTabFromLocation(location);
        setActiveTab(newActiveTab);
    }, [location]);

    useEffect(() => {
        securityService.checkForCallback(location.search);
        updateActiveTab();
    }, [location, securityService, updateActiveTab]);

    const activeTabFromLocation = (location: Location): number => {
        switch (location.pathname) {
            case '/profile/orders':
                return 1;
            case '/profile':
                return 0;
            default:
                console.error(`Unknown path: ${location.pathname}`);
                return 0;
        }
    };

    const onTabsChange = (event: React.ChangeEvent<{}>, newActiveTab: number) => {
        setActiveTab(newActiveTab);
        const url = newActiveTab === 0 ? '/profile' : '/profile/orders';
        historyService.redirect(url);
    };

    const onResendEmail = () => {
        if (securityService.user) {
            securityService.resendEmailAction(securityService.user.email);
        }
    };

    const renderProfilePage = (user: User) => {
        const notVerifiedAlert = !user.emailVerified ? (
            <StyledAlert severity="warning">
                <AlertTitle>Verify Email</AlertTitle>
                Please check your inbox to verify your email account. If you don't
                receive an email within a few minutes, check your junk/spam folder.{' '}
                <Button variant="contained" onClick={onResendEmail}>
                    Re-send verification email
                </Button>
            </StyledAlert>
        ) : null;

        const awaitingApprovalAlert =
            user.emailVerified && user.approvalPending ? (
                <StyledAlert severity="warning">
                    <AlertTitle>Awaiting Approval</AlertTitle>
                    Your account is pending approval. This may take up to 2 business days.
                </StyledAlert>
            ) : null;

        const tabContent =
            activeTab === 0 ? (
                <>
                    {notVerifiedAlert}
                    {awaitingApprovalAlert}

                    <StyledFormDiv>
                        {/* Adding a unique key will force the form to recreate itself every time this page is rendered */}
                        <WdhUserProfileForm key={new Date().getMilliseconds()} />
                    </StyledFormDiv>
                </>
            ) : (
                <StyledDataOrders />
            );

        return (
            <StyledWrapper>
                <Tabs
                    value={activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="user tabs"
                    onChange={onTabsChange}
                >
                    <StyledTab label="Profile" />
                    <StyledTab label="Data Orders" />
                </Tabs>
                {tabContent}
            </StyledWrapper>
        );
    };

    const user: User | null = securityService.user;

    if (user && user.authenticated) {
        globalSearchService.setSelectedCategory('');
        globalSearchService.setSearchContent('');
        return <GlobalSearchBarWrap renderChildren={() => renderProfilePage(user)} />
    } else {
        return <Redirect to={{ pathname: '/signin' }} />;
    }
});

export default Profile;

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import { DataSearch } from '@appbaseio/reactivesearch';
import { DataSearchProps } from '@appbaseio/reactivesearch/lib/components/search/DataSearch';

import SearchCategoryMenu from '@extensions/components/global-search/SearchCategoryMenu';

import theme from '@extensions/services/Theme';
import { IGlobalSearchService } from '@extensions/services/IGlobalSearchService';

export const REACTIVE_ID = 'SearchBar';

const StyledDataSearch = styled(DataSearch)({
    marginTop: '15px',
    paddingBottom: '20px',
    // Responsive Mobile Interface
    [theme.breakpoints.down(500)]: {
        width: '300px',
    },
    [theme.breakpoints.up(500)]: {
        width: '400px',
    },
    [theme.breakpoints.up(800)]: {
        width: '450px',
    },
    [theme.breakpoints.up(900)]: {
        width: '400px',
    },
    [theme.breakpoints.up(1000)]: {
        width: '500px',
    },
    [theme.breakpoints.up(1200)]: {
        width: '600px',
    },
    [theme.breakpoints.up(1400)]: {
        width: '800px',
    },
    [theme.breakpoints.up(1800)]: {
        width: '1200px',
    },
    [theme.breakpoints.up(2500)]: {
        width: '1500px',
    },
    // Style for search bar input
    '& input': {
        border: `2px solid ${theme.palette.primary.main}`,
        borderLeft: '2px solid #8a8a8a',
        '&:focus': {
            outline: 'none',
            border: `2px solid ${theme.palette.primary.main}`,
            borderLeft: '2px solid #8a8a8a',
        },
        borderTopRightRadius: '30px !important',
        borderBottomRightRadius: '30px !important',
        height: '42px',
    },
    '& .search-icon': {
        fill: theme.palette.secondary.main,
    },
    '& .input-group': {
        borderTopLeftRadius: '30px !important',
        borderBottomLeftRadius: '30px !important',
        boxShadow: '0 0 0',
        height: '42px',
    },
    '& .input-addon': {
        padding: 0,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRight: '2px solid #8a8a8a',
        borderTopLeftRadius: '30px !important',
        borderBottomLeftRadius: '30px !important',
    },
    '& .MuiInputBase-root': {
        border: '0px solid transparent',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },
    '& .MuiSelect-root': {
        borderColor: 'transparent',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
        },
    },
}) as unknown as typeof DataSearch;

interface ExtraDataSearchProps extends DataSearchProps {
    globalSearchService?: IGlobalSearchService;
    isRedirecting?: boolean;
}

const SearchBar: React.FC<Omit<ExtraDataSearchProps, 'componentId' | 'innerClass'>> = ({ globalSearchService, ...props }) => {
    const [value, setValue] = useState<string>('');
    return (
        <StyledDataSearch
            value={value}
            componentId={REACTIVE_ID}
            customQuery={
                props.customQuery ||
                ((searchValue: string, queryProps: any) => {
                    if (!searchValue) {
                        return {};
                    }
                    const escaped = searchValue
                        .replace(/\//g, '\\/')
                        .toLowerCase();
                    const nested = queryProps.dataField && queryProps.dataField.reduce((acc, field) => {
                        if (acc) {
                            const n = field.split(':');
                            if (n.length > 1) {
                                const p = n.shift();
                                const f = field.replace(':', '.');
                                acc[p] = [...(acc[p] || []), f];
                            }
                            return acc;
                        }
                        return acc;
                    }, {});
                    let query = {
                        query: {
                            query_string: {
                                query: escaped,
                                fields: queryProps.dataField,
                                default_operator: queryProps.queryFormat,
                            },
                        },
                    };
                    if (nested && Object.keys(nested).length) {
                        return {
                            query: {
                                bool: {
                                    should: [
                                        query.query,
                                        ...Object.entries(nested).map(([key, value]) => ({
                                            nested: {
                                                path: key,
                                                query: {
                                                    query_string: {
                                                        query: escaped,
                                                        fields: value,
                                                        default_operator: queryProps.queryFormat,
                                                    },
                                                },
                                            },
                                        })),
                                    ],
                                },
                            },
                        };
                    }
                    return query;
                })
            }
            showIcon={true}
            iconPosition={'right'}
            onChange={
                (newValue: string) => {
                    if (globalSearchService) {
                        globalSearchService.setSearchContent(newValue);
                    }
                    setValue(newValue)
                }
            }
            addonBefore={<SearchCategoryMenu isRedirecting={props.isRedirecting}/>}
            {...props}
        />
    );
};

export default inject('globalSearchService')(observer(SearchBar));
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Box, useMediaQuery, Grid } from '@mui/material';

import {
    SEARCH_BAR_REACTIVE_ID,
    SearchProvider,
    SearchResults,
    SelectedFilters,
} from '@extensions/components/search-core';
import SearchBar from '@extensions/components/global-search/SearchBar';
import { SEARCH_CATEGORIES } from '@extensions/components/global-search/SearchCategories';
import PublicationResultCard from '@extensions/components/publications/PublicationResultCard';
import PublicationsSearchFilters from '@extensions/components/publications/PublicationsSearchFilters';

import theme from '@extensions/services/Theme';
import { useSecurityService, useGlobalSearchService } from '@extensions/hooks/useService';

const STATUS_ID = 'STATUS';
const PROJECT_ID = 'PROJECT';
const DATASET_ID = 'DATASET';
const DATE_ID = 'DATE';
const TYPE_ID = 'TYPE';
const KEYWORD_ID = 'KEYWORDS';
const AUTHOR_ID = 'AUTHORS';

const FILTER_REACTIVE_IDS = [
    STATUS_ID,
    PROJECT_ID,
    DATE_ID,
    TYPE_ID,
    KEYWORD_ID,
    AUTHOR_ID,
    SEARCH_BAR_REACTIVE_ID,
    DATASET_ID,
];

const StyledWrapper = styled('div')(({ theme }) => ({
    margin: '0 1em',
    [theme.breakpoints.down('xl')]: {
        margin: '0 1em',
    },
    [theme.breakpoints.down('xs')]: {
        margin: '0 0em',
    },
}));

const StyledSelectedFilters = styled(SelectedFilters)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StyledSearchHitDiv = styled('div')({
    marginTop: '24px',
    marginBottom: '24px',
});

const StyledButton = styled(Button)(() => ({
    backgroundColor: '#e0e0e0',
    color: '#000',
    '&:hover': {
        backgroundColor: '#bab8b8',
    },
}));

const StyledBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',

    '@media (max-width: 600px)': {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const StyledDiv = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
    position: 'absolute',
    top: isMobile ? '3.8rem' : '0.8rem',
    left: '50%',
    transform: 'translateX(-50%)',
}));

export interface IPublicationsSearchProps {
    className?: string;
    selectedCategory?: string;
}

const searchFields: any = {
    'title': 'Publication Title',
    'abstract': 'Publication Abstract',
    'doi': 'Publication DOI',
    'keywords': 'Keywords',
    'authorNames': 'Author Name',
    'journalName': 'Journal Name',
    'authorInstitutions': 'Author Institutions',
    'sponsors': 'Sponsors'
}

const PublicationsSearch: React.FC<IPublicationsSearchProps> = observer((props) => {
    const { selectedCategory } = props;
    const securityService = useSecurityService();

    const globalSearchService = useGlobalSearchService();

    useEffect(() => {
        if (selectedCategory) {
            globalSearchService?.setSelectedCategory(selectedCategory);
        } else if (globalSearchService.selectedCategory) {
            globalSearchService?.setSelectedCategory(globalSearchService.selectedCategory);
        } else {
            globalSearchService?.setSelectedCategory(SEARCH_CATEGORIES.PUBLICATIONS);
        }
    }, [globalSearchService, selectedCategory]);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const searchHitGuard = (hit: any) => {
        if (securityService && hit) {
            return (
                <StyledSearchHitDiv key={hit.id}>
                    <PublicationResultCard
                        publication={hit}
                        key={hit.id}
                        searchFieldLabels={searchFields}
                    />
                </StyledSearchHitDiv>
            );
        }
        return null;
    };

    return (
        <SearchProvider
            elasticIndex="refs"
            apiPrefix="api"
            theme={{
                colors: {
                    primaryColor: '#53b03f',
                },
            }}
        >
            <StyledDiv isMobile={isMobile}>
                <SearchBar
                    dataField={[
                        'title',
                        'abstract',
                        'doi',
                        'keywords',
                        'authorNames',
                        'journalName',
                        'authorInstitutions',
                        'sponsors',
                    ]}
                    highlight={true}
                    customHighlight={(d) => ({
                        highlight: {
                            pre_tags: ['<mark>'],
                            post_tags: ['</mark>'],
                            fields: {
                                ...d.dataField.reduce((carry, field) => {
                                    return {
                                        ...carry,
                                        [field]: field === 'description' || field === 'title'
                                            ? { number_of_fragments: 0 }
                                            : {},
                                    };
                                }, {}),
                            },
                        },
                    })}
                    filterLabel="Search"
                    autosuggest={false}
                    URLParams={true}
                    queryFormat="and"
                />
            </StyledDiv>
            <StyledWrapper>
                {Boolean(securityService?.user && (securityService.user.canAdminPubs || securityService.user.canEdit)) && (
                    <StyledBox>
                        <StyledButton variant="contained" href="/publication/new">
                            New Publication
                        </StyledButton>
                    </StyledBox>
                )}
                <StyledSelectedFilters />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={5} lg={3} xl={3}>
                        <PublicationsSearchFilters />
                    </Grid>
                    <Grid item xs={12} sm={8} md={7} lg={9} xl={9}>
                        <SearchResults
                            resultsText="publications"
                            dataField="title.keyword"
                            sortBy="desc"
                            react={{
                                and: [SEARCH_BAR_REACTIVE_ID, ...FILTER_REACTIVE_IDS],
                            }}
                            renderItem={(hit) => searchHitGuard(hit)}
                        />
                    </Grid>
                </Grid>
            </StyledWrapper>
        </SearchProvider>
    );
});

export default PublicationsSearch;

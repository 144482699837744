import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Link,
    Box,
    Divider,
    styled
} from '@mui/material';
import React from 'react';
import moment from 'moment';

import theme from '@extensions/services/Theme';
import Dataset from '@extensions/models/Dataset';

interface INewDatasetsListProps {
    datasets: Dataset[];
};

const StyledTypographyHTwo = styled(Typography)(() => ({
    fontSize: '1rem',
    fontWeight: 400,
}));

const StyledCard = styled(Card)(({
    marginBottom: '1rem',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
    marginLeft: '-1rem'
}));

const StyledCardContent = styled(CardContent)(({
    paddingBottom: '0px !important',
    paddingTop: '0px !important'
}));

const StyledTypographyBodyOne = styled(Typography)(({
    marginBottom: '0px !important'
}));

const StyledTypographyBodyTwo = styled(Typography)(({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '0.5rem',
    color: theme.palette.text.secondary
}));

export const NewDatasetsList: React.FC<INewDatasetsListProps> = ({ datasets }) => {

    const getUpdatedTime = (value) => {
        if (value.dapFileSummary.updated) {
            const statsLastUpdated = moment(value.dapFileSummary.updated);
            const durationSinceUpdate = moment.duration(
                moment().diff(statsLastUpdated)
            );
            return (`Updated ${durationSinceUpdate.humanize()} ago`);
        }
    };

    const getTruncatedText = (description: string) => {
        if (description.length > 200) {
            return description.slice(0, 200) + '...';
        }
        return description;
    };

    return (
        <Box>
            {
                datasets.map((dataset, i) => (
                    <StyledCard key={i}>
                        <CardHeader
                            title={
                                <StyledTypographyHTwo
                                    variant="h2"
                                >
                                    <Link
                                        href={`/data/${dataset.name}`}
                                        underline='hover'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        {dataset.title}
                                    </Link>
                                </StyledTypographyHTwo>
                            }
                            sx={{ mt: '0rem' }}
                        >
                        </CardHeader>
                        <StyledCardContent>
                            <StyledTypographyBodyOne
                                variant='body1'
                            >
                                {getTruncatedText(dataset.description)}
                            </StyledTypographyBodyOne>
                            <StyledTypographyBodyTwo
                                variant='body2'
                            >
                                {getUpdatedTime(dataset)}
                            </StyledTypographyBodyTwo>
                        </StyledCardContent>
                        <Divider />
                    </StyledCard>
                ))
            }
        </Box>
    )
}

export default NewDatasetsList;
import React from 'react';
import { observer } from 'mobx-react';
import { Button, Grid, styled } from '@mui/material'

import { useContactUsService } from '@extensions/hooks/useService';

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  bottom: '0px',
  right: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px 10px 0 0',
  backgroundColor: theme.palette.secondary.main,
  padding: '0.75rem',
  outline: 'none',
  border: 'none',
  color: 'white',
  boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.2)',
  transform: 'translateY(2px)',
  transition: 'background-color 0.25s ease, transform 0.1s ease',
  cursor: 'pointer',

  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
    transform: 'translateY(0)',
  },

  "&:active": {
    transform: 'translateY(2px)',
  }
}));

const StyledText = styled('div')(({
  color: 'white',
  fontSize: '16px'
}));

const FeedbackTool: React.FC = observer(() => {
  const contactUsService = useContactUsService();

  return (
    <Grid>
      {contactUsService && (
        <StyledButton onClick={() => contactUsService.openModal(undefined, undefined, 'feedback')}>
          <StyledText>Feedback</StyledText>
        </StyledButton>
      )}
    </Grid>
  );
});


export default FeedbackTool


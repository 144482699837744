import React from 'react';
import { Box } from '@mui/material';

interface IUserFavoriteProjectTabProps {
    userFavProjects: string[] | undefined;
    children?: React.ReactNode;
    index: number;
    value: number;
    height: number;
}

const UserFavoriteProjectTab: React.FC<IUserFavoriteProjectTabProps> = (props) => {
    const { children, value, index, userFavProjects, height, ...other } = props;
    const adjustHeight = () => {
        const hasProjects = userFavProjects && userFavProjects.length > 0;
        const numProjects = userFavProjects ? userFavProjects.length : 0;
        if (!hasProjects || numProjects === 0) {
            return height * 0.3;
        }
        return height * 0.8;
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`user-fav-project-${index}`}
            aria-labelledby={`fav-project-tab-${index}`}
            style={{
                height: adjustHeight(),
                overflowY: 'auto'
            }}
            {...other}
        >
            {value === index && <Box sx={{ mt: 3 }}>{children}</Box>}
        </div>
    );
}

export default UserFavoriteProjectTab;
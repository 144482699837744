import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, styled } from '@mui/material';

import PublicationForm from '@extensions/components/publications/PublicationForm';
import PublicationNotFound from '@extensions/components/publications/PublicationNotFound';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import { usePublicationService, useGlobalSearchService } from '@extensions/hooks/useService';

const StyledWrapper = styled('div')(({ theme }) => ({
    margin: '0 1em',
    [theme.breakpoints.down('xl')]: {
        margin: '0 1em',
    },
    [theme.breakpoints.down('xs')]: {
        margin: '0 0em',
    },
}));

const StyledPublicationForm = styled(PublicationForm)(({
    marginTop: '1rem'
}));

const EditPublication: React.FC = () => {
    const publicationService = usePublicationService();
    const globalSearchService = useGlobalSearchService();

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (publicationService) {
            publicationService.selectPublication(Number(id));
        }
    }, [publicationService, id]);

    const renderContent = () => {
        if (publicationService?.selectedPublicationNotFound) {
            return <PublicationNotFound />;
        }
        if (publicationService?.selectedPublication) {
            return (
                <StyledWrapper>
                    <Typography variant="h1">Edit Publication</Typography>
                    <StyledPublicationForm
                        publication={publicationService.selectedPublication}
                        isEdit={true}
                    />
                </StyledWrapper>
            );
        }
        return null;
    }

    globalSearchService.setSearchContent('');
    globalSearchService.setSelectedCategory('');
    return <GlobalSearchBarWrap renderChildren={() => renderContent()} />

}

export default observer(EditPublication);
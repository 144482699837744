import { 
  Alert, 
  AlertTitle, 
  Grid, 
  Stack, 
  Typography 
} from '@mui/material';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { styled } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Link from '@extensions/components/core/Link';
import FileOrderGroup from '@extensions/models/FileOrderGroup';
import Breadcrumb from '@extensions/components/core/Breadcrumb';
import HeadManager from '@extensions/components/page/HeadManager';
import Citation from '@extensions/components/order/cards/Citation';
import OrderOptions from '@extensions/components/order/OrderOptions';
import FeedbackForm from '@extensions/components/core/FeedbackForm';
import DatasetList from '@extensions/components/order/cards/DatasetList';
import FileSummary from '@extensions/components/order/cards/FileSummary';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import { 
  useCachingService, 
  useNotificationService, 
  useSecurityService,
  useGlobalSearchService
} from '@extensions/hooks/useService';
import { Status } from '@extensions/services/INotificationService';

const StyledWrapper = styled('div')(({ theme }) => ({
  margin: '0 1em',
  [theme.breakpoints.down('xl')]: {
    margin: '0 1em',
  },
  [theme.breakpoints.down('xs')]: {
    margin: '0 0em',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  marginBottom: theme.spacing(3),
  marginTop: '0.5rem',
}));

const StyledDatasetList = styled(DatasetList)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const StyledFileSummary = styled(FileSummary)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const StyledCitation = styled(Citation)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const StyledBreadcrumb = styled(Breadcrumb)(({
  marginBottom: '0.5rem',
}));

interface OrderDetailsProps extends RouteComponentProps<{ id: string }> {
}

const OrderDetails: React.FC<OrderDetailsProps> = observer(({ match }) => {
  const cachingService = useCachingService();
  const notificationService = useNotificationService();
  const securityService = useSecurityService();
  const globalSearchService = useGlobalSearchService();

  const [order, setOrder] = useState<FileOrderGroup | null>(null);
  const [orderNotFound, setOrderNotFound] = useState<boolean | null>(null);

  const helmetDescription = 
    "The Wind Data Hub, or WDH is established by the U.S. Department of " + 
    "Energy Office of Energy Efficiency and Renewable Energy's Wind Energy " + 
    "Technologies Office (WETO). DOI: 10.21947/WDH-DAP/1910052 (https://www.osti.gov/biblio/1910052).";

  let helmet;
  let body: React.ReactNode;

  useEffect(() => {
    if(securityService.userIsLoggedIn) {
      cachingService
        .getOrder(match.params.id)
        .then((order) => {
          runInAction(() => {
            setOrder(order)
            setOrderNotFound(false)
          });
          order.loadDatasets();
        })
        .catch((error) => {
          if (error.status === 404) {
            runInAction(() => {
              setOrderNotFound(true);
            });
          } else {
            notificationService.addNotification(
              'ORDER_DETAILS',
              Status.Error,
              'Could not fetch order',
              error
            );
          }
        });
    }
  }, [cachingService, match.params.id, notificationService, securityService.userIsLoggedIn]);

  if (!securityService.userIsLoggedIn) {
    helmet = (
      <HeadManager
        title="WDH: Wind Data Hub Order Detail - Access Restricted"
        description={helmetDescription}
      />
    )
    body = (
      <Alert severity="error">
        <AlertTitle>
          You must <Link to="/signin">sign in </Link> to view data orders.
        </AlertTitle>
      </Alert>
    )
  } else if (orderNotFound) {
    helmet = ( 
      <HeadManager
        title="WDH: Wind Data Hub Order Not Found"
        description={helmetDescription}
      />
    )
    body = (
      <Alert severity="error">
        <AlertTitle>Not Found</AlertTitle>
        The order you are looking for can not be found.
      </Alert>
    );
  } else if (order === null || (order && order.insufficientAuth === null)) {
    body = <CenteredCircularProgress />
  } else if (order === null || (order.insufficientAuth === true)) {
      helmet = (
        <HeadManager
          title="WDH: Wind Data Hub Order Detail - Access Restricted"
          description={helmetDescription}
        />
      )
      body = (
        <Alert severity="error">
          <AlertTitle>
            You must sign in using Multifactor Authentication (MFA) to view this
            order
          </AlertTitle>
          This order contains proprietary data which can not be downloaded
          without Multifactor Authentication (MFA).
        </Alert>
      );
  } else if(order.insufficientAuth === false) {
    helmet = ( 
      <HeadManager
        title="WDH: Wind Data Hub Order Details"
        description={helmetDescription}
      />
    )
    body = (
      <StyledGrid container spacing={5} >
        <Grid item md={12}>
          <OrderOptions order={order} />
        </Grid>
        <Grid item md={8}>
          <Stack spacing={2}>
            <StyledDatasetList order={order} />
            <StyledFileSummary order={order} />
            <StyledCitation order={order} />
          </Stack>
        </Grid>
        <Grid item md={4}>
          <FeedbackForm />
        </Grid>
      </StyledGrid>
    );
  }

  const renderContent = () => {
    return (
      <StyledWrapper>
        <StyledBreadcrumb
          to="/profile/orders"
          name="My Data Orders"
        />
        <Typography variant='h1'>Data Order</Typography>
        {helmet}
        {body}
      </StyledWrapper>
    )
  }

  globalSearchService.setSelectedCategory('');
  globalSearchService.setSearchContent('');
  return <GlobalSearchBarWrap renderChildren={() => renderContent()}/>
});

export default OrderDetails

import moment, { Moment } from 'moment';

import BaseDataset, {
  DatasetStats as BaseDatasetStats,
  DatasetRawStats as BaseDatasetRawStats,
  StatSegment as BaseStatSegment,
  Stats as BaseStats,
  IFileExtension as BaseIFileExtension,
  Downloadable as BaseDownloadable,
  Gap as BaseGap,
} from '@dapclient/models/Dataset';
import Measurement from '@extensions/models/Measurement';
import Dimension from '@extensions/models/Dimension';

export default class Dataset extends BaseDataset {
  rawCitation: string | null;
  dataDetails: string | null;
  dataQualityDescription: string | null;
  uncertaintyDescription: string | null;
  constraintsDescription: string | null;
  referencesDescription: string | null;
  measurements: Measurement[] | null;
  dimensions: Dimension[] | null;

  constructor(response) {
    super(response);
    const meta = response.meta || response;

    this.rawCitation = meta.citation ?? null;
    this.dataDetails = meta.dataDetails ?? null;
    this.dataQualityDescription = meta.dataQualityDescription ?? null;
    this.uncertaintyDescription = meta.uncertaintyDescription ?? null;
    this.constraintsDescription = meta.constraintsDescription ?? null;
    this.referencesDescription = meta.referencesDescription ?? null;
    this.measurements = meta.measurements ?? null;
    this.dimensions = meta.dimensions ?? null;
  }

  getCitationWithAccessDate(
    dateAccessed?: Moment,
    dataDateRange?: [Moment, Moment]
  ): string {
    if (this.rawCitation) {
      let citation = this.rawCitation;
      if (dateAccessed) {
        citation = citation.replace(
          'accessed <YYYY-MM-DD>',
          `accessed ${dateAccessed.format('YYYY-MM-DD')}`
        );
      }
      if (dataDateRange) {
        citation = citation.replace(
          '<YYYY-MM-DD> to <YYYY-MM-DD>',
          [
            dataDateRange[0].format('YYYY-MM-DD'),
            dataDateRange[1].format('YYYY-MM-DD'),
          ].join(' to ')
        );
      }
      return citation;
    }
    const accessedString = dateAccessed
      ? `${dateAccessed.format('DD MM YYYY')}`
      : '<DD Mon YYYY>';
    const doiString = this.doi ? ` DOI: ${this.doi}.` : '';
    const dateString = this.dynamoDataEnds
      ? ` ${moment(this.dynamoDataEnds).format('YYYY')}.`
      : '';
    return `
      Wind Data Hub. ${this.name}. ${dateString}
      Maintained by Wind Data Hub for U.S. Department of Energy, Office of Energy Efficiency and Renewable Energy. 
      ${doiString}
      Accessed: ${accessedString}.
    `;
  }
}

export type IFileExtension = BaseIFileExtension;

export type DatasetStats = BaseDatasetStats;

export type DatasetRawStats = BaseDatasetRawStats;

export type StatSegment = BaseStatSegment;

export type Stats = BaseStats;

export type Downloadable = BaseDownloadable;

export type Gap = BaseGap;

import moment from 'moment';
import { Divider, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';

import DatePicker, {
  DateRange,
} from '@extensions/components/core/date-picker/DatePicker';
import FilterTitle from '@extensions/components/search-core/FilterTitle';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

import theme from '@extensions/services/Theme';

export interface IDateFilterProps {
  className?: string;
  dataField: string;
  showFilter?: boolean;
  componentId: string;
  filterLabel?: string;
  react: Record<string, any>;
  URLParams: boolean;
  plainTitle?: boolean;
}

export interface IDateFilterState { }

const DATE_FORMAT = 'YYYY-MM-DD';

@observer
export class DateFilter extends React.Component<
  IDateFilterProps,
  IDateFilterState
> {
  render() {
    const {
      dataField,
      componentId,
      react,
      URLParams,
      showFilter = true,
      filterLabel = 'Date',
      plainTitle,
    } = this.props;
    return (
      <div style={{ marginBottom: '1rem', paddingTop: '0.75rem' }}>
        {
          plainTitle
            ? <>
              <Typography
              sx={{
                mt: '0.5rem',
                mb: '0.5rem !important',
                ml: '0.5rem',
                color: theme.palette.text.secondary
              }}
            >Publication Date</Typography>
            <Divider />
            </>
            : <FilterTitle>Publication Date</FilterTitle>
        }
        
        <ReactiveComponent
          componentId={componentId}
          defaultQuery={() => ({
            aggs: {
              maxDate: { max: { field: dataField } },
              minDate: { min: { field: dataField } },
            },
          })}
          react={react}
          showFilter={showFilter || true}
          filterLabel={filterLabel || 'Date'}
          URLParams={URLParams}
          render={({ aggregations, setQuery, value }) => (
            <AttachedDatePicker
              aggregations={aggregations}
              setQuery={setQuery}
              dataField={dataField}
              value={value}
            />
          )}
        />
        <p style={{ fontSize: '0.75rem', fontStyle: 'italic' }}>
          Date format: YYYY-MM-DD
        </p>
      </div>
    );
  }
}

export default inject((store: any) => ({}))(DateFilter);

function AttachedDatePicker({ aggregations, setQuery, dataField, value }) {
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });
  useEffect(() => {
    const rangeInUrl = fromValue(value);
    if (
      rangeInUrl.endDate !== dateRange.endDate ||
      rangeInUrl.startDate !== dateRange.startDate
    ) {
      setDateRange(rangeInUrl);
      setQuery(getQueryForRange({ range: rangeInUrl, dataField }));
    }
  }, [value]);
  const ready = aggregations && aggregations.maxDate && aggregations.minDate;
  if (!ready) {
    return <CenteredCircularProgress />;
  }
  const minDate = aggregations.minDate.value;
  const maxDate = aggregations.maxDate.value;

  return (
    <div style={{ marginTop: '1rem' }} >
      <DatePicker
        startDate={dateRange.startDate}
        startDateId="publication-start-date"
        endDate={dateRange.endDate}
        endDateId="publication-end-date"
        oldestAllowed={minDate && moment(minDate)}
        newestAllowed={maxDate && moment(maxDate)}
        onDatesChange={(newDateRange) => {
          const { startDate: newStartDate, endDate: newEndDate } = newDateRange;
          setDateRange(newDateRange);
          if (newStartDate && newEndDate) {
            setQuery({
              query: {
                range: {
                  [dataField]: {
                    gte: newStartDate.valueOf(),
                    lte: newEndDate.valueOf(),
                    format: 'epoch_millis',
                  },
                },
              },
              value: toValue(newDateRange),
            });
          }
        }}
      />
    </div>
  );
}

function getQueryForRange({
  range,
  dataField,
}: {
  range: DateRange;
  dataField: string;
}): Record<string, any> {
  if (range.endDate === null || range.startDate === null) {
    return {
      query: {
        match_all: {},
      },
      value: null,
    };
  } else {
    return {
      query: {
        range: {
          [dataField]: {
            gte: range.startDate.valueOf(),
            lte: range.endDate.valueOf(),
            format: 'epoch_millis',
          },
        },
      },
      value: toValue(range),
    };
  }
}

function fromValue(value: string): DateRange {
  if (value === null) {
    return {
      startDate: null,
      endDate: null,
    };
  }
  const [startStr, endStr] = value.split(' to ');
  return {
    startDate: moment(startStr, DATE_FORMAT),
    endDate: moment(endStr, DATE_FORMAT),
  };
}

function toValue(range: DateRange | null): string | null {
  if (range === null || range.startDate === null || range.endDate === null) {
    return null;
  }
  return `${range.startDate.format(DATE_FORMAT)} to ${range.endDate.format(
    DATE_FORMAT
  )}`;
}

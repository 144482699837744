import React from 'react';

import {
  Card,
  CardContent,
  Typography,
  styled
} from '@mui/material';

import { TechnicalReport } from '@extensions/models/Publication';
import Link from '@extensions/components/core/Link';

const StyledReportCard = styled(Card)(() => ({
  marginTop: '10px', 
  minHeight: '40px'  
}));

const StyledTitle = styled(Typography)(() => ({
  color: '#000000',
  fontSize: '1.2em',
}));

export interface TechnicalReportProps {
  report: TechnicalReport;
  editMode?: boolean;
}

const PublicationTechnicalReport: React.FC<TechnicalReportProps> = ({ report, editMode }) => {
  let reportLink: string | undefined = '';
  let reportDownloadButton: React.ReactNode;
  if (report.pdfUrl && report.pdfUrl !== '') {
    reportLink = report.pdfUrl
  }
  if (report.url && report.url !== '') {
    reportLink = report.url
  }
  if (reportLink !== '' && !editMode) {
    reportDownloadButton =
      <Link to={reportLink} target='_blank' rel="noopener noreferrer" variant="body1">
          Download Report
      </Link>
  }

  return (
    <StyledReportCard variant='outlined'>
      <CardContent>
        <StyledTitle>Report Number</StyledTitle>
        <Typography>{report.reportNumber || 'N/A'}</Typography>
        {reportDownloadButton}
      </CardContent>
    </StyledReportCard>
  )
}

export default PublicationTechnicalReport

import React from 'react';
import DatasetModel from '@extensions/models/Dataset';
import { styled, Typography } from '@mui/material';

import { Map, Marker, TileLayer, Popup } from 'react-leaflet';
import { LatLngBounds } from 'leaflet';
import { getBounds, getCenter } from 'geolib';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';

import LatLong from '@extensions/models/LatLong';

const StyledMap = styled('div')(() => ({
  ".leaflet-container": {
    height: "300px",
    width: "100%",
  }
}));

export interface DatasetMapProps {
  dataset: DatasetModel;
}

const DatasetMap: React.FC<DatasetMapProps> = ({ dataset }) => {
  let map: React.ReactNode;
  if (dataset.latLongs && dataset.latLongs.length > 0) {
    // noticed the mep dataset has duplicate latlongs and causes map to not render
    const uniqLatLongs = uniqWith(dataset.latLongs, isEqual);
    const markers = uniqLatLongs.map((latlong) => (
      <Marker
        key={`marker${latlong.latitude}${latlong.longitude}`}
        position={[+latlong.latitude, +latlong.longitude]}
      >
        <Popup>
          <p><code>Latitude: {latlong.latitude}</code></p>
          <p><code>Longitude: {latlong.longitude}</code></p>
        </Popup>
      </Marker>
    ));

    const center: LatLong = getCenter(dataset.latLongs) as unknown as LatLong;
    const mapCenter = { lat: +center.latitude, lng: +center.longitude };
    const bounds = getBounds(dataset.latLongs);
    const mapBounds = new LatLngBounds(
      { lat: +bounds.minLat, lng: +bounds.minLng },
      { lat: +bounds.maxLat, lng: +bounds.maxLng }
    );

    if (markers.length > 1) {
      map = (
        <StyledMap>
          <Map bounds={mapBounds} gestureHandling={true}>
            <TileLayer
              attribution="&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markers}
          </Map>
        </StyledMap>
      );
    } else {
      map = (
        <StyledMap>
          <Map center={mapCenter} zoom={5} gestureHandling={true}>
            <TileLayer
              attribution="&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markers}
          </Map>
        </StyledMap>
      );
    }
  }

  return (
    <div>
      {map}
      <Typography>
        This map shows the geographic locations covered within the dataset
      </Typography>
    </div>
  )
}

export default DatasetMap

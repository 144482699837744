import theme from '@extensions/services/Theme';
import { Card, CardContent, Stack, styled } from '@mui/material';
import React, { useCallback } from 'react';
import {
    RecommendedFilter,
    DataLevelFilter,
    InstrumentFilter,
    MeasurementFilter,
    ModelFilter,
    StandardizationFilter,
    FileTypeFilter,
    DateRangeFilter,
    ProjectFilter,
    RECOMMENDED_REACTIVE_ID,
    DATA_LEVEL_REACTIVE_ID,
    INSTRUMENT_REACTIVE_ID,
    MEASUREMENT_REACTIVE_ID,
    MODEL_REACTIVE_ID,
    STANDARDIZATION_REACTIVE_ID,
    FILE_TYPE_REACTIVE_ID,
    DATE_RANGE_REACTIVE_ID,
    PROJECT_REACTIVE_ID,
    AREA_FILTER_REACTIVE_ID,
} from '@extensions/components/page/search-components';
import { FiltersWrapper, SEARCH_BAR_REACTIVE_ID } from '@extensions/components/search-core';
import { observer } from 'mobx-react';
import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils';

const REACTIVE_IDS = [
    DATA_LEVEL_REACTIVE_ID,
    RECOMMENDED_REACTIVE_ID,
    INSTRUMENT_REACTIVE_ID,
    MEASUREMENT_REACTIVE_ID,
    MODEL_REACTIVE_ID,
    STANDARDIZATION_REACTIVE_ID,
    FILE_TYPE_REACTIVE_ID,
    DATE_RANGE_REACTIVE_ID,
    PROJECT_REACTIVE_ID,
    AREA_FILTER_REACTIVE_ID,
];

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    '& .data-filter': {
        marginBottom: theme.spacing(2),
    },
}));

interface DatasetFiltersProps {
    projectContext?: string;
}

const DatasetSearchFilters: React.FC<DatasetFiltersProps> = ({ projectContext }) => {
    const getFilterReactiveIds = useCallback(() => REACTIVE_IDS, []);

    const allReactiveIdsExcept = useCallback(
        (excludeThisOne: string): string[] => {
            return getAllReactiveIdsExcept([
                ...getFilterReactiveIds(),
                SEARCH_BAR_REACTIVE_ID,
            ])(excludeThisOne);
        },
        [getFilterReactiveIds]
    );

    const renderDatasetFilters = () => (
        <Card variant='outlined' sx={{ overflow: 'visible' }}>
            <StyledCardContent>
                <ProjectFilter
                    fixedValue={projectContext}
                    react={{ and: allReactiveIdsExcept(PROJECT_REACTIVE_ID) }}
                />
                <DateRangeFilter
                    react={{ and: allReactiveIdsExcept(DATE_RANGE_REACTIVE_ID) }}
                    startDateField="dapFileSummary.begins"
                    endDateField="dapFileSummary.ends"
                />
                <RecommendedFilter react={{ and: allReactiveIdsExcept(RECOMMENDED_REACTIVE_ID) }} />
                <DataLevelFilter react={{ and: allReactiveIdsExcept(DATA_LEVEL_REACTIVE_ID) }} />
                <InstrumentFilter react={{ and: allReactiveIdsExcept(INSTRUMENT_REACTIVE_ID) }} />
                {/* <MeasurementFilter react={{ and: allReactiveIdsExcept(MEASUREMENT_REACTIVE_ID) }} /> */}
                <ModelFilter react={{ and: allReactiveIdsExcept(MODEL_REACTIVE_ID) }} />
                <StandardizationFilter react={{ and: allReactiveIdsExcept(STANDARDIZATION_REACTIVE_ID) }} />
                <FileTypeFilter react={{ and: allReactiveIdsExcept(FILE_TYPE_REACTIVE_ID) }} />
            </StyledCardContent>
        </Card>
    );


    return (
        <FiltersWrapper>
            <div>
                {renderDatasetFilters()}
            </div>
        </FiltersWrapper>
    );

};

export default observer(DatasetSearchFilters);
import React from 'react';
import { Card } from '@mui/material';
import { react } from '@appbaseio/reactivesearch/lib/types';

import MultiList from '@extensions/components/core/MultiList';

export const MEASUREMENT_REACTIVE_ID = 'MeasurementFilter';

interface MeasurementFilterProps {
  react?: react;
}

const MeasurementFilter: React.FC<MeasurementFilterProps> = ({ react }) => {
  return (
    <Card sx={{ borderRadius: 0, borderBottom: 'none', boxShadow: 'none' }}>
      <MultiList
        className="data-filter"
        componentId={MEASUREMENT_REACTIVE_ID}
        dataField="measurements.standardName.displayName.keyword"
        title="Standard Measurement"
        filterLabel="Measurement"
        showSearch={false}
        react={react}
        URLParams={true}
      />
    </Card>
  );
};

export default MeasurementFilter;

import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Typography, TextField, styled } from '@mui/material';

import FaqList from '@extensions/components/page/faq/FaqList';
import GlobalSearchBarWrap from '@extensions/components/global-search/GlobalSearchBarWrap';

import { useFaqService } from '@extensions/hooks/useService';

const StyledWrapper = styled('div')(({ theme }) => ({
  margin: '0 1em',
  [theme.breakpoints.down('xl')]: {
    margin: '0 1em',
  },
  [theme.breakpoints.down('xs')]: {
    margin: '0 0em',
  },
}));

const StyledTitle = styled(Typography)(() => ({
  textAlign: 'left',
  display: 'block',
}));

const StyledTextField = styled(TextField)(() => ({
  margin: '0.25rem 0',
}));

const FAQ: React.FC = observer(() => {
  const faqService = useFaqService();
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    faqService.loadFaqsIfNeeded();
  }, [faqService]);

  const renderContent = () => {
    return (
      <StyledWrapper>
        <StyledTitle variant='h1'>Frequently Asked Questions</StyledTitle>
        <StyledTextField
          label="How can we help you?"
          id="faq-search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <FaqList faqSections={faqService.sections} searchText={searchText} />
      </StyledWrapper>
    )
  }

  return <GlobalSearchBarWrap renderChildren={() => renderContent()} />
});

export default FAQ;
import { makeAutoObservable } from 'mobx';

import { IGlobalSearchService } from '@extensions/services/IGlobalSearchService';

export default class GlobalSearchService implements IGlobalSearchService {
    selectedCategory: string;
    searchContent: string;
    clickedButton: boolean;
    needStay: boolean;

    constructor(selectedCategory: string = '', searchContent: string = '', clickedButton = false, needStay = false) {
        this.selectedCategory = selectedCategory;
        this.searchContent = searchContent;
        this.clickedButton = clickedButton;
        this.needStay = needStay;
        makeAutoObservable(this);
    }

    setSelectedCategory(category: string = '') {
        this.selectedCategory = category;
    }

    setSearchContent(content: string = '') {
        this.searchContent = content;
    }

    setClickedButton(isClicked: boolean) {
        this.clickedButton = isClicked;
    }

    setNeedStay(stay: boolean) {
        this.needStay = stay;
    }
}
